<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Chart } from 'highcharts-vue';

import { Company } from '../../../../../shared/models/index.js';
import { CTable, UpgradeCtaModal } from '../../../../components/index.js';
import { EPermissions } from '../../../../../shared/models/user/index.js';

import { EPlans } from '../../../../../shared/enums.js';

@Component({
  name: 'company-details-management',
  components: { Chart, CTable, UpgradeCtaModal },
  data: () => ({ EPlans })
})
class CompanyDetailsManagement extends Vue {
  loaded = false;
  company = null;

  showUpgradeModal = false;

  async loadCompany() {
    const companyCode = this?.$route?.params?.companyCode;
    this.company = new Company({ companyCode });
    await this.company.load({ general: true, management: true });
  }

  async mounted() {
    await this.loadCompany();
    this.setTitle(`${this?.company?.general?.name} - Management`);
  }

  handleDataClick(person) {
    if (this.isBlurred(person)) {
      this.showUpgradeModal = true;
    }
  }

  get hasBlurredData() {
    if (this?.company?.management?.people?.length) {
      return this?.company?.management?.people?.map((person) => this.isBlurred(person)).includes(true);
    }
    return false;
  }

  isBlurred(person) {
    const limitTitles = ['CEO', 'Chief Executive Officer'];
    if (!this.store.user.hasPermission(EPermissions.COMPANY_MANAGEMENT.READ)) {
      return !limitTitles.map((title) => person.title.includes(title)).includes(true);
    }
    return false;
  }
}
export default CompanyDetailsManagement;
</script>
