<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import { EBalanceSheet, ETime, EPlans } from '../../../shared/enums.js';

import CTable from '../c-table/component.vue';
import ExportCsvBtn from '../export-csv-btn/component.vue';
import UpgradeCtaModal from '../upgrade-cta-modal/component.vue';
import { CompanyList } from '../../../shared/models/company/CompanyList.js';
import { CurrencyPair } from '../../../shared/models/index.js';
import { EPermissions } from '../../../shared/models/user/index.js';

const DEFAULT_CURRENCY = 'USD';

@Component({
  name: 'company-comparison-table',
  data: () => ({ EBalanceSheet, DEFAULT_CURRENCY, EPlans, EPermissions }),
  components: { CTable, ExportCsvBtn, UpgradeCtaModal },
  props: {
    companyCodes: {
      type: Array,
      required: true
    },
    exportable: {
      type: Boolean,
      default: true
    }
  }
})
class CompanyComparisonTable extends Vue {
  dataLoaded = false;

  companyList = [];
  currencyPairs = {};

  sortKey = null;
  sortDirection = true;

  comparisonData = {};

  showUpgradeModal = false;

  hrLabels = {
    companyCode: 'Company code',
    name: 'Name',
    ticker: 'Ticker',
    sector: 'Sector',
    industry: 'Industry',
    website: 'Website',
    websiteFullUrl: 'Website (full url)',
    country: 'Country',
    financialStatementsDate: 'Statement date',
    exchangeName: 'Exchange',
    sharePriceLive: 'Share price',
    sharePriceCurrency: 'Share price currency',
    sharePriceInDefaultCurrency: `Share price (${DEFAULT_CURRENCY})`,
    sharesOutstanding: 'Shares outstanding',
    marketCapUsd: 'Market cap (USD)',
    dividendYield: 'Dividend yield',
    payoutRatio: 'Dividend payout ratio',
    avgDividendYieldGrowth1year: 'Avg. Dividend yield growth (1 year)',
    avgDividendYieldGrowth3year: 'Avg. Dividend yield growth (3 year)',
    avgDividendYieldGrowth5year: 'Avg. Dividend yield growth (5 year)',
    avgDividendYieldGrowth10year: 'Avg. Dividend yield growth (10 year)',
    avgRevenueGrowth1year: 'Avg. Revenue growth (1 year)',
    avgRevenueGrowth3year: 'Avg. Revenue growth (3 year)',
    avgRevenueGrowth5year: 'Avg. Revenue growth (5 year)',
    avgRevenueGrowth10year: 'Avg. Revenue growth (10 year)',
    avgGrossProfitGrowth1year: 'Avg. Gross profit growth (1 year)',
    avgGrossProfitGrowth3year: 'Avg. Gross profit growth (3 year)',
    avgGrossProfitGrowth5year: 'Avg. Gross profit growth (5 year)',
    avgGrossProfitGrowth10year: 'Avg. Gross profit growth (10 year)',
    avgEbitdaGrowth1year: 'Avg. EBITDA growth (1 year)',
    avgEbitdaGrowth3year: 'Avg. EBITDA growth (3 year)',
    avgEbitdaGrowth5year: 'Avg. EBITDA growth (5 year)',
    avgEbitdaGrowth10year: 'Avg. EBITDA growth (10 year)',
    avgEbitGrowth1year: 'Avg. EBIT growth (1 year)',
    avgEbitGrowth3year: 'Avg. EBIT growth (3 year)',
    avgEbitGrowth5year: 'Avg. EBIT growth (5 year)',
    avgEbitGrowth10year: 'Avg. EBIT growth (10 year)',
    avgNetIncomeGrowth1year: 'Avg. Net income growth (1 year)',
    avgNetIncomeGrowth3year: 'Avg. Net income growth (3 year)',
    avgNetIncomeGrowth5year: 'Avg. Net income growth (5 year)',
    avgNetIncomeGrowth10year: 'Avg. Net income growth (10 year)',
    avgEpsGrowth1year: 'Avg. EPS growth (1 year)',
    avgEpsGrowth3year: 'Avg. EPS growth (3 year)',
    avgEpsGrowth5year: 'Avg. EPS growth (5 year)',
    avgEpsGrowth10year: 'Avg. EPS growth (10 year)',
    avgCashAndEquivalentsGrowth1year: 'Avg. Cash and Equivalents growth (1 year)',
    avgCashAndEquivalentsGrowth3year: 'Avg. Cash and Equivalents growth (3 year)',
    avgCashAndEquivalentsGrowth5year: 'Avg. Cash and Equivalents growth (5 year)',
    avgCashAndEquivalentsGrowth10year: 'Avg. Cash and Equivalents growth (10 year)',
    avgTotalAssetsGrowth1year: 'Avg. Total assets growth (1 year)',
    avgTotalAssetsGrowth3year: 'Avg. Total assets growth (3 year)',
    avgTotalAssetsGrowth5year: 'Avg. Total assets growth (5 year)',
    avgTotalAssetsGrowth10year: 'Avg. Total assets growth (10 year)',
    avgTotalDebtGrowth1year: 'Avg. Total debt growth (1 year)',
    avgTotalDebtGrowth3year: 'Avg. Total debt growth (3 year)',
    avgTotalDebtGrowth5year: 'Avg. Total debt growth (5 year)',
    avgTotalDebtGrowth10year: 'Avg. Total debt growth (10 year)',
    avgTotalEquityGrowth1year: 'Avg. Total Equity growth (1 year)',
    avgTotalEquityGrowth3year: 'Avg. Total Equity growth (3 year)',
    avgTotalEquityGrowth5year: 'Avg. Total Equity growth (5 year)',
    avgTotalEquityGrowth10year: 'Avg. Total Equity growth (10 year)',
    returnOnEquityAfterTax: 'Return on equity after tax (ROE)',
    returnOnAssetsAfterTax: 'Return on assets after tax (ROA)',
    returnOnCapitalEmployed: 'Return on capital employed (ROCE)',
    priceOverEarningsMean: 'Price/earnings (P/E)',
    priceOverSalesMean: 'Price/sales (P/S)',
    priceOverBookMean: 'Price/book (P/B)',
    priceOverCashFlowMean: 'Price/Cash flow (P/CF)',
    grossProfitMargin: 'Gross profit margin',
    ebitdaMargin: 'EBITDA Margin',
    ebitMargin: 'EBIT margin',
    netIncomeMargin: 'Net income margin',
    totalCash: `Cash (${DEFAULT_CURRENCY})`,
    totalCashPerShare: `Cash/share (${DEFAULT_CURRENCY})`,
    netDebt: `Net debt (${DEFAULT_CURRENCY})`,
    debtOverEquityRatio: 'Debt/equity',
    shortTermDebt: `Short term debt (${DEFAULT_CURRENCY})`,
    longTermDebt: `Long term debt (${DEFAULT_CURRENCY})`,
    currentRatio: 'Current ratio',
    quickRatio: 'Quick ratio',
    coveredRatio: 'Covered ratio',
    bookValuePerShare: 'Book value/share',
    totalRevenue: `Revenue (${DEFAULT_CURRENCY})`,
    totalRevenuePerShare: `Revenue/share (${DEFAULT_CURRENCY})`,
    eps: `EPS (${DEFAULT_CURRENCY})`,
    netIncome: `Net income (${DEFAULT_CURRENCY})`,
    grossProfit: `Gross profit (${DEFAULT_CURRENCY})`,
    ebitda: `EBITDA (${DEFAULT_CURRENCY})`,
    operatingIncome: `Operating income (${DEFAULT_CURRENCY})`,
    changeToOperatingActivities: `Change to operating activities (${DEFAULT_CURRENCY})`,
    leveredFreeCashFlow: `Levered free cash flow (${DEFAULT_CURRENCY})`,
    totalCashFromOperatingActivities: `Cash from operating activities (${DEFAULT_CURRENCY})`,
    capitalExpenditures: `Capital expenditures (${DEFAULT_CURRENCY})`
  };

  async getCompaniesData() {
    const companyList = new CompanyList({ companyCodes: this.companyCodes });
    await companyList.load({
      general: true,
      financialStatements: {
        balanceSheets: true,
        incomeStatements: true,
        cashFlowStatements: true
      },
      ratios: true,
      shares: {
        outstanding: true
      },
      calculated: true,
      sharePriceLive: true,
      currencies: true
    });
    companyList.companies.sort((a, b) => (b?.calculatedData?.marketCapUsd || 0) - (a?.calculatedData?.marketCapUsd || 0));

    companyList.companies.slice(1).forEach((company) => {
      if (this.isDataBlurred(company)) {
        company.maskData({
          financialStatements: true,
          ratios: true,
          shares: true,
          calculated: true,
          sharePriceLive: true
        });
      }
    });

    this.companyList = companyList;
  }

  async loadExchangeRates() {
    this.currencyPairs = {};
    this.companyList.companies.slice(this.store.user.hasPermission(EPermissions.COMPANY_COMPETITORS.READ) ? null : 1).forEach((company) => {
      const currencyPairFinancialData = new CurrencyPair(company.currencies.financialData.iso(), DEFAULT_CURRENCY);
      const currencyPairSharePrices = new CurrencyPair(company.currencies.sharePrices.iso(), DEFAULT_CURRENCY);
      if (!this.currencyPairs[currencyPairFinancialData.toString()]) {
        this.currencyPairs[currencyPairFinancialData.toString()] = currencyPairFinancialData;
      }
      if (!this.currencyPairs[currencyPairSharePrices.toString()]) {
        this.currencyPairs[currencyPairSharePrices.toString()] = currencyPairSharePrices;
      }
    });

    const promises = [];

    Object.values(this.currencyPairs).forEach((currencyPair) => {
      promises.push(
        new Promise((resolve) => {
          currencyPair
            .loadExchangeRateHistory({
              from: new Date(Date.now() - ETime.milliseconds.perDay * ETime.days.perYear),
              till: new Date()
            })
            .then(resolve)
            .catch((err) => {
              console.error(err);
              resolve();
            });
        })
      );
    });

    await Promise.all(promises);
  }

  getFinancialDataYear(companyCode) {
    const fiscalYearEndMonth = this.companyData?.[companyCode]?.general?.fiscalYearEndMonth;
    return new Date(this.companyData?.[companyCode]?.financialStatements?.balanceSheets?.yearly?.first().date)?.getFiscalPeriod(
      fiscalYearEndMonth
    )?.year;
  }

  convertedSharePrice(company) {
    const sharePrice = company.sharePriceLive;
    const currencyPair = new CurrencyPair(company?.currencies?.sharePrices?.iso(), DEFAULT_CURRENCY);
    return this.currencyPairs[currencyPair.toString()]?.convert({ value: sharePrice, to: DEFAULT_CURRENCY }) || sharePrice;
  }

  convertedFinancialData(company, value) {
    const currencyPair = new CurrencyPair(company?.currencies?.financialData?.iso(), DEFAULT_CURRENCY);
    return this.currencyPairs[currencyPair.toString()]?.convert({
      value,
      to: DEFAULT_CURRENCY,
      date: company.lastQuarter
    });
  }

  async composeComparisonData() {
    this.comparisonData = {};

    this.companyList.companies.forEach((company) => {
      this.comparisonData[company.companyCode] = {
        companyCode: company?.companyCode,
        name: company?.general?.name,
        ticker: company?.ticker,

        sector: company?.general?.sector,
        industry: company?.general?.industry,
        website: company?.general?.website?.split('://')?.last(),
        websiteFullUrl: company?.general?.website,
        country: company?.general?.address?.country?.name,
        financialStatementsDate: this.Filter.momentDate(company?.lastQuarter),

        exchangeName: company?.general?.exchangeName,
        sharePriceLive: this.Filter.financial(company.sharePriceLive),
        sharePriceCurrency: company?.currencies?.sharePrices?.iso(),
        sharePriceInDefaultCurrency: this.Filter.financial(this.convertedSharePrice(company)),
        sharesOutstanding: this.Filter.number(company?.sharesOutstandingAtDate(), 0, 0),
        marketCapUsd: this.Filter.hrFinancial(company?.calculated?.marketCapUsd),

        dividendYield: this.Filter.percentage(company.calculated.dividend.yield, 2),
        payoutRatio: this.Filter.percentage(company.calculated.dividend.payoutRatio, 2),
        avgDividendYieldGrowth1year: company?.calculated?.dividend?.avgYieldGrowth?.['1year'],
        avgDividendYieldGrowth3year: company?.calculated?.dividend?.avgYieldGrowth?.['3year'],
        avgDividendYieldGrowth5year: company?.calculated?.dividend?.avgYieldGrowth?.['5year'],
        avgDividendYieldGrowth10year: company?.calculated?.dividend?.avgYieldGrowth?.['10year'],

        avgRevenueGrowth1year: company?.calculated?.growth?.avgRevenue?.['1year'],
        avgRevenueGrowth3year: company?.calculated?.growth?.avgRevenue?.['3year'],
        avgRevenueGrowth5year: company?.calculated?.growth?.avgRevenue?.['5year'],
        avgRevenueGrowth10year: company?.calculated?.growth?.avgRevenue?.['10year'],

        avgGrossProfitGrowth1year: company?.calculated?.growth?.avgGrossProfit?.['1year'],
        avgGrossProfitGrowth3year: company?.calculated?.growth?.avgGrossProfit?.['3year'],
        avgGrossProfitGrowth5year: company?.calculated?.growth?.avgGrossProfit?.['5year'],
        avgGrossProfitGrowth10year: company?.calculated?.growth?.avgGrossProfit?.['10year'],

        avgEbitdaGrowth1year: company?.calculated?.growth?.avgEbitda?.['1year'],
        avgEbitdaGrowth3year: company?.calculated?.growth?.avgEbitda?.['3year'],
        avgEbitdaGrowth5year: company?.calculated?.growth?.avgEbitda?.['5year'],
        avgEbitdaGrowth10year: company?.calculated?.growth?.avgEbitda?.['10year'],

        avgEbitGrowth1year: company?.calculated?.growth?.avgEbit?.['1year'],
        avgEbitGrowth3year: company?.calculated?.growth?.avgEbit?.['3year'],
        avgEbitGrowth5year: company?.calculated?.growth?.avgEbit?.['5year'],
        avgEbitGrowth10year: company?.calculated?.growth?.avgEbit?.['10year'],

        avgNetIncomeGrowth1year: company?.calculated?.growth?.avgNetIncome?.['1year'],
        avgNetIncomeGrowth3year: company?.calculated?.growth?.avgNetIncome?.['3year'],
        avgNetIncomeGrowth5year: company?.calculated?.growth?.avgNetIncome?.['5year'],
        avgNetIncomeGrowth10year: company?.calculated?.growth?.avgNetIncome?.['10year'],

        avgEpsGrowth1year: company?.calculated?.growth?.avgEps?.['1year'],
        avgEpsGrowth3year: company?.calculated?.growth?.avgEps?.['3year'],
        avgEpsGrowth5year: company?.calculated?.growth?.avgEps?.['5year'],
        avgEpsGrowth10year: company?.calculated?.growth?.avgEps?.['10year'],

        avgCashAndEquivalentsGrowth1year: company?.calculated?.growth?.avgCashAndEquivalents?.['1year'],
        avgCashAndEquivalentsGrowth3year: company?.calculated?.growth?.avgCashAndEquivalents?.['3year'],
        avgCashAndEquivalentsGrowth5year: company?.calculated?.growth?.avgCashAndEquivalents?.['5year'],
        avgCashAndEquivalentsGrowth10year: company?.calculated?.growth?.avgCashAndEquivalents?.['10year'],

        avgTotalAssetsGrowth1year: company?.calculated?.growth?.avgTotalAssets?.['1year'],
        avgTotalAssetsGrowth3year: company?.calculated?.growth?.avgTotalAssets?.['3year'],
        avgTotalAssetsGrowth5year: company?.calculated?.growth?.avgTotalAssets?.['5year'],
        avgTotalAssetsGrowth10year: company?.calculated?.growth?.avgTotalAssets?.['10year'],

        avgTotalDebtGrowth1year: company?.calculated?.growth?.avgTotalDebt?.['1year'],
        avgTotalDebtGrowth3year: company?.calculated?.growth?.avgTotalDebt?.['3year'],
        avgTotalDebtGrowth5year: company?.calculated?.growth?.avgTotalDebt?.['5year'],
        avgTotalDebtGrowth10year: company?.calculated?.growth?.avgTotalDebt?.['10year'],

        avgTotalEquityGrowth1year: company?.calculated?.growth?.avgTotalEquity?.['1year'],
        avgTotalEquityGrowth3year: company?.calculated?.growth?.avgTotalEquity?.['3year'],
        avgTotalEquityGrowth5year: company?.calculated?.growth?.avgTotalEquity?.['5year'],
        avgTotalEquityGrowth10year: company?.calculated?.growth?.avgTotalEquity?.['10year'],

        returnOnEquityAfterTax: this.Filter.percentage(company?.ratios?.yearly?.last()?.returnOnEquityAfterTax),
        returnOnAssetsAfterTax: this.Filter.percentage(company?.ratios?.yearly?.last()?.returnOnAssetsAfterTax),
        returnOnCapitalEmployed: this.Filter.percentage(company?.ratios?.yearly?.last()?.returnOnCapitalEmployed),
        priceOverEarningsMean: this.Filter.percentage(company?.ratios?.yearly?.last()?.priceOverEarningsMean),
        priceOverSalesMean: this.Filter.percentage(company?.ratios?.yearly?.last()?.priceOverSalesMean),
        priceOverBookMean: this.Filter.percentage(company?.ratios?.yearly?.last()?.priceOverBookMean),
        priceOverCashFlowMean: this.Filter.percentage(company?.ratios?.yearly?.last()?.priceOverCashFlowMean),

        grossProfitMargin: this.Filter.percentage(company?.ratios?.yearly?.last()?.grossProfitMargin),
        ebitdaMargin: this.Filter.percentage(company?.ratios?.yearly?.last()?.ebitdaMargin),
        ebitMargin: this.Filter.percentage(company?.ratios?.yearly?.last()?.ebitMargin),
        netIncomeMargin: this.Filter.percentage(company?.ratios?.yearly?.last()?.netIncomeMargin),

        totalCash: this.Filter.number(
          this.convertedFinancialData(company, company?.financialStatements?.balanceSheets?.quarterly?.last()?.cash),
          0,
          0
        ),
        totalCashPerShare: this.Filter.financial(
          this.convertedFinancialData(company, company?.financialStatements?.balanceSheets?.quarterly?.last()?.cash) /
            company?.shares?.getSharesOutstanding(company?.lastQuarter)?.shares,
          2,
          2
        ),
        netDebt: this.Filter.number(
          this.convertedFinancialData(company, company?.financialStatements?.balanceSheets?.quarterly?.last()?.netDebt),
          0,
          0
        ),
        debtOverEquityRatio: this.Filter.percentage(company?.ratios?.quarterly?.last()?.debtOverEquityRatio),
        shortTermDebt: this.Filter.number(
          this.convertedFinancialData(company, company?.financialStatements?.balanceSheets?.quarterly?.last()?.shortTermDebt),
          0,
          0
        ),
        longTermDebt: this.Filter.number(
          this.convertedFinancialData(
            company?.currencies?.financialData,
            company?.financialStatements?.balanceSheets?.quarterly?.last()?.longTermDebt
          ),
          0,
          0
        ),
        currentRatio: this.Filter.number(company?.ratios?.quarterly?.last()?.currentRatio, 2, 2),
        quickRatio: this.Filter.number(company?.ratios?.quarterly?.last()?.quickRatio, 2, 2),
        coveredRatio: this.Filter.number(company?.ratios?.quarterly?.last()?.coverageRatio, 2, 2),
        bookValuePerShare: this.Filter.number(
          this.convertedFinancialData(company, company?.ratios?.quarterly?.last()?.bookValuePerShare),
          2,
          2
        ),

        totalRevenue: this.Filter.number(
          this.convertedFinancialData(company, company?.financialStatements?.incomeStatements?.quarterly?.last()?.totalRevenue),
          0,
          0
        ),
        totalRevenuePerShare: this.Filter.number(
          this.convertedFinancialData(company, company?.financialStatements?.incomeStatements?.quarterly?.last()?.totalRevenue) /
            company?.shares?.getSharesOutstanding(company?.lastQuarter)?.shares,
          2,
          2
        ),
        eps: this.Filter.number(this.convertedFinancialData(company, company?.ratios?.quarterly?.last()?.eps), 2, 2),
        netIncome: this.Filter.number(
          this.convertedFinancialData(company, company?.financialStatements?.incomeStatements?.quarterly?.last()?.netIncome),
          0,
          0
        ),
        grossProfit: this.Filter.number(
          this.convertedFinancialData(company, company?.financialStatements?.incomeStatements?.quarterly?.last()?.grossProfit),
          0,
          0
        ),
        ebitda: this.Filter.number(this.convertedFinancialData(company, company?.ratios?.quarterly?.last()?.ebitda), 0, 0),
        operatingIncome: this.Filter.number(
          this.convertedFinancialData(company, company?.financialStatements?.incomeStatements?.quarterly?.last()?.operatingIncome),
          0,
          0
        ),

        changeToOperatingActivities: this.Filter.number(
          this.convertedFinancialData(
            company,
            company?.financialStatements?.cashFlowStatements?.quarterly?.last()?.changeToOperatingActivities
          ),
          0,
          0
        ),
        leveredFreeCashFlow: this.Filter.number(
          this.convertedFinancialData(company, company?.ratios?.quarterly?.last()?.leveredFreeCashFlow),
          0,
          0
        ),
        totalCashFromOperatingActivities: this.Filter.number(
          this.convertedFinancialData(
            company,
            company?.financialStatements?.cashFlowStatements?.quarterly?.last()?.totalCashFromOperatingActivities
          ),
          0,
          0
        ),
        capitalExpenditures: this.Filter.number(
          this.convertedFinancialData(company, company?.financialStatements?.cashFlowStatements?.quarterly?.last()?.capitalExpenditures),
          0,
          0
        )
      };
    });
  }

  exportData() {
    const data = [];

    const skipKeys = ['companyCode', 'name', 'ticker', 'websiteFullUrl'];

    Object.keys(Object.values(this.comparisonData)?.first()).forEach((key) => {
      if (skipKeys.includes(key)) return;

      const record = {
        0: this.hrLabels[key]
      };
      Object.keys(this.comparisonData).forEach((companyCode) => {
        record[this.comparisonData[companyCode].name] = this.comparisonData[companyCode][key];
      });
      data.push(record);
    });
    return data;
  }

  isDataBlurred(company) {
    return this.companyCodes?.first() !== company.companyCode && !this.store.user.hasPermission(EPermissions.COMPANY_COMPETITORS.READ);
  }

  handleDataClick(company) {
    if (this.isDataBlurred(company)) {
      this.showUpgradeModal = true;
    }
  }

  async reloadPage() {
    this.dataLoaded = false;

    this.companyList = [];

    await this.getCompaniesData();
    await this.loadExchangeRates();
    await this.composeComparisonData();

    this.dataLoaded = true;

    this.$emit('ready');
  }

  async mounted() {
    await this.reloadPage();
  }
}
export default CompanyComparisonTable;
</script>
