<template src="./template.vue" />

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import AuthLogin from '../auth-login/component.vue';
import { EAuthStrategies } from '../../../shared/enums.js';

const AUTH_API_PREFIX = '/api/auth';

const AUTH_STRATEGIES = [EAuthStrategies.GOOGLE, EAuthStrategies.TWITTER, EAuthStrategies.FACEBOOK];

const MODAL_ID = 'authenticate-modal';

@Component({
  name: 'authenticate-modal',
  components: { AuthLogin },
  data: () => ({ AUTH_API_PREFIX, AUTH_STRATEGIES, MODAL_ID }),
  props: {
    value: { type: Boolean, default: false }
  },
  watch: {
    value(value) {
      if (value) {
        this.$bvModal.show(MODAL_ID);
      } else {
        this.$bvModal.hide(MODAL_ID);
      }
    }
  }
})
class AuthenticateModal extends Vue {
  legalCheckbox = false;

  handleHideModal() {
    this.$emit('input', false);
  }

  handleShowModal() {
    this.$emit('input', true);
  }

  hideModal() {
    this.$refs.modal.hide();
  }

  mounted() {
    if (this.value) {
      this.$bvModal.show(MODAL_ID);
    }
  }
}
export default AuthenticateModal;
</script>
