import { cleanupObject } from '../../../utils/index.js';

/**
 * @name CompanyScreenerFilter
 * @param {object} obj The filter
 * @param {string} obj.key The key used in the database
 * @param {string} obj.collection The MongoDB collection where the value can be found
 * @param {boolean} obj.sortable=true Whether or not the field is sortable (both frontend and supported in the backend)
 * @param {any} obj.value=null Whether or not the field is sortable (both frontend and supported in the backend)
 * <br/><strong>Name</strong>
 * @param {object} obj.name The names/labels of the filter
 * @param {string} obj.name.default Default name
 * @param {string} obj.name.filterModal=undefined Name used in the filter modal
 * @param {string} obj.name.columnsModal=undefined Name used in the columns modal
 * <br/><strong>Column</strong>
 */
class CompanyScreenerFilter {
  static #VALUE_TYPES = {
    MIN_MAX: 'minMax',
    SINGLE: 'single',
    VALUES_ARRAY: 'valuesArray'
  };

  static INPUT_TYPES = {
    NUMBER: {
      SLIDER_RANGE: {
        key: 'numberSliderRange',
        type: this.#VALUE_TYPES.MIN_MAX
      },
      SLIDER_OPTIONS: {
        key: 'numberSliderOptions',
        type: this.#VALUE_TYPES.MIN_MAX
      }
    },
    STRING: {
      TEXT_SEARCH: {
        key: 'stringTextSearch',
        type: this.#VALUE_TYPES.SINGLE
      },
      SELECT: {
        key: 'stringSelect',
        type: this.#VALUE_TYPES.SINGLE
      },
      MULTISELECT: {
        key: 'stringMultiSelect',
        type: this.#VALUE_TYPES.VALUES_ARRAY
      },
      SLIDER_OPTION: {
        key: 'stringSliderOption',
        type: this.#VALUE_TYPES.SINGLE
      }
    }
  };

  constructor({ name, key, value = null, collection, inputType, validator, options = {} }) {
    this.name = name;
    this.key = key;
    this.value = value;
    this.collection = collection;
    this.inputType = inputType;
    this.validator = validator;
    this.options = {
      placeholder: options?.placeholder, // Placeholder for input fields
      items: options?.items, // Items to be used for the (multi) select dropdown
      itemName: options?.itemName, // Item name used for the multi-select "2 countries selected"
      disabled: options?.disabled, // Whether or not the input is disabled
      rangeMin: options?.rangeMin, // Option for InputSlider of type range
      rangeMax: options?.rangeMax, // Option for InputSlider of type range
      rangeStepSize: options?.rangeStepSize // Option for InputSlider of type range
    };
  }

  get isValid() {
    return !!this?.errors?.length;
  }

  get errors() {
    let errors = [];
    if (this.validator) {
      // Check min-max values
      if (this.inputType.type === CompanyScreenerFilter.#VALUE_TYPES.MIN_MAX) {
        Object.values(this.value || {}).forEach((value) => {
          const validator = this.validator(value, this?.name?.default || this?.name || this.key);
          if (!validator.isValid) {
            errors = errors.concat(validator.errors);
          }
        });
        return errors;
      }
      // Check native types
      if (this.inputType.type === CompanyScreenerFilter.#VALUE_TYPES.SINGLE) {
        const validator = this.validator(this.value, this?.name?.default || this?.name || this.key);
        if (!validator.isValid) {
          return validator.errors;
        }
      }
      // Check array of values
      if (this.inputType.type === CompanyScreenerFilter.#VALUE_TYPES.VALUES_ARRAY) {
        (this.value || []).forEach((value) => {
          const validator = this.validator(value, this?.name?.default || this?.name || this.key);
          if (!validator.isValid) {
            errors = errors.concat(validator.errors);
          }
        });
        return errors;
      }
    }
    return [];
  }

  reset() {
    this.value = null;
  }

  mongoQuery(includeCollectionName = true) {
    const prefix = includeCollectionName ? `${this.collection}.` : '';
    let object;
    switch (this.inputType.key) {
      case CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_OPTIONS.key:
      case CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE.key:
        object = cleanupObject({
          [prefix + this.key]: cleanupObject({
            $gte: this.value?.min,
            $lte: this.value?.max
          })
        });
        return Object.keys(object)?.length ? object : null;
      case CompanyScreenerFilter.INPUT_TYPES.STRING.TEXT_SEARCH.key:
        object = cleanupObject({
          $text: cleanupObject({
            $search: this.value
          })
        });
        return Object.keys(object)?.length ? object : null;
      case CompanyScreenerFilter.INPUT_TYPES.STRING.SELECT.key:
      case CompanyScreenerFilter.INPUT_TYPES.STRING.SLIDER_OPTION.key:
        object = cleanupObject({
          [prefix + this.key]: cleanupObject({
            $eq: this.value
          })
        });
        return Object.keys(object)?.length ? object : null;
      case CompanyScreenerFilter.INPUT_TYPES.STRING.MULTISELECT.key:
        object = cleanupObject({
          $or: this?.value?.map((value) => ({
            [prefix + this.key]: { $eq: value }
          }))
        });
        return Object.keys(object)?.length ? object : null;
      default:
        return null;
    }
  }
}

export { CompanyScreenerFilter };
