class CompanyRatioStatement {
  constructor({
    date = null,

    // General
    enterpriseValue = null,
    marketCap = null,
    balanceTotal = null,
    totalEquity = null,

    // Assets
    goodwill = null,
    otherIntangibleAssets = null,
    totalIntangibleAssets = null,

    cash = null,
    shortTermInvestments = null,
    cashAndShortTermInvestments = null,

    currentRatio = null,
    quickRatio = null,

    // Equity and liabilities
    equityRatio = null,
    liabilitiesRatio = null,

    debtRatio = null,
    debtOverEquityRatio = null,
    longTermDebtOverEquityRatio = null,

    debtOverCashRatio = null,
    operatingCashFlowOverDebt = null,

    // Income statement
    totalRevenue = null,
    grossProfit = null,
    ebitda = null,
    ebit = null,
    ebt = null,
    netIncome = null,

    // Cashflow
    totalCashFromOperatingActivities = null,
    totalCashFlowFromInvestingActivities = null,
    totalCashFromFinancingActivities = null,

    leveredFreeCashFlow = null,
    unleveredFreeCashFlow = null,
    operatingCashFlowOverSales = null,

    // Growth
    revenueGrowth = null,
    grossProfitGrowth = null,
    ebitdaGrowth = null,
    ebitGrowth = null,
    netIncomeGrowth = null,
    epsGrowth = null,
    cashFromOperatingActivitiesGrowth = null,

    // Margins
    grossProfitMargin = null,
    ebitdaMargin = null,
    ebitMargin = null,
    netIncomeMargin = null,

    // Value creation
    returnOnCapitalEmployed = null,
    returnOnEquityBeforeTax = null,
    returnOnEquityAfterTax = null,
    returnOnAssetsBeforeTax = null,
    returnOnAssetsAfterTax = null,

    // Productivity
    revenuePer100Equity = null,
    revenuePer100Assets = null,

    // Valuation - Price/Earnings
    priceOverEarningsHigh = null,
    priceOverEarningsMean = null,
    priceOverEarningsLow = null,

    // Valuation - Price/Book
    priceOverBookHigh = null,
    priceOverBookMean = null,
    priceOverBookLow = null,

    // Valuation - Price/Cashflow
    priceOverCashFlowHigh = null,
    priceOverCashFlowMean = null,
    priceOverCashFlowLow = null,

    // Valuation - Price/Sales
    priceOverSalesHigh = null,
    priceOverSalesMean = null,
    priceOverSalesLow = null,

    // Other ratios
    ebitdaOverMarketCapHigh = null,
    ebitdaOverMarketCapMean = null,
    ebitdaOverMarketCapLow = null,

    netIncomeOverMarketCapHigh = null,
    netIncomeOverMarketCapMean = null,
    netIncomeOverMarketCapLow = null,

    enterpriseValueOverEbitdaHigh = null,
    enterpriseValueOverEbitdaMean = null,
    enterpriseValueOverEbitdaLow = null,

    enterpriseValueOverSalesHigh = null,
    enterpriseValueOverSalesMean = null,
    enterpriseValueOverSalesLow = null,
    pegRatioHigh = null,
    pegRatioMean = null,
    pegRatioLow = null,

    // Other
    netWorkingCapitalOverSales = null,

    equityOverNonCurrentAssetsRatio = null,
    equityPlusNonCurrentLiabilitiesOverNonCurrentAssets = null,
    gearing = null,
    netFinancialDebtOverEquity = null,
    netFinancialDebtOverEbitda = null,

    returnOnInvestment = null,
    bookValuePerShare = null,
    bookValueChange = null,
    bookValue = null,

    eps = null
  }) {
    this.date = date;

    // General
    this.enterpriseValue = enterpriseValue;
    this.marketCap = marketCap;
    this.balanceTotal = balanceTotal;
    this.totalEquity = totalEquity;

    // Assets
    this.goodwill = goodwill;
    this.otherIntangibleAssets = otherIntangibleAssets;
    this.totalIntangibleAssets = totalIntangibleAssets;

    this.cash = cash;
    this.shortTermInvestments = shortTermInvestments;
    this.cashAndShortTermInvestments = cashAndShortTermInvestments;

    this.currentRatio = currentRatio;
    this.quickRatio = quickRatio;

    // Equity and liabilities
    this.equityRatio = equityRatio;
    this.liabilitiesRatio = liabilitiesRatio;

    this.debtRatio = debtRatio;
    this.debtOverEquityRatio = debtOverEquityRatio;
    this.longTermDebtOverEquityRatio = longTermDebtOverEquityRatio;

    this.debtOverCashRatio = debtOverCashRatio;
    this.operatingCashFlowOverDebt = operatingCashFlowOverDebt;

    // Income statement
    this.totalRevenue = totalRevenue;
    this.grossProfit = grossProfit;
    this.ebitda = ebitda;
    this.ebit = ebit;
    this.ebt = ebt;
    this.netIncome = netIncome;

    // Cash flow
    this.totalCashFromOperatingActivities = totalCashFromOperatingActivities;
    this.totalCashFlowFromInvestingActivities = totalCashFlowFromInvestingActivities;
    this.totalCashFromFinancingActivities = totalCashFromFinancingActivities;

    this.leveredFreeCashFlow = leveredFreeCashFlow;
    this.unleveredFreeCashFlow = unleveredFreeCashFlow;
    this.operatingCashFlowOverSales = operatingCashFlowOverSales;

    // Growth
    this.revenueGrowth = revenueGrowth;
    this.grossProfitGrowth = grossProfitGrowth;
    this.ebitdaGrowth = ebitdaGrowth;
    this.ebitGrowth = ebitGrowth;
    this.netIncomeGrowth = netIncomeGrowth;
    this.epsGrowth = epsGrowth;
    this.cashFromOperatingActivitiesGrowth = cashFromOperatingActivitiesGrowth;

    // Margins
    this.grossProfitMargin = grossProfitMargin;
    this.ebitdaMargin = ebitdaMargin;
    this.ebitMargin = ebitMargin;
    this.netIncomeMargin = netIncomeMargin;

    // Value creation
    this.returnOnCapitalEmployed = returnOnCapitalEmployed;
    this.returnOnEquityBeforeTax = returnOnEquityBeforeTax;
    this.returnOnEquityAfterTax = returnOnEquityAfterTax;
    this.returnOnAssetsBeforeTax = returnOnAssetsBeforeTax;
    this.returnOnAssetsAfterTax = returnOnAssetsAfterTax;

    // Productivity
    this.revenuePer100Equity = revenuePer100Equity;
    this.revenuePer100Assets = revenuePer100Assets;

    // Valuation - Price/Earnings
    this.priceOverEarningsHigh = priceOverEarningsHigh;
    this.priceOverEarningsMean = priceOverEarningsMean;
    this.priceOverEarningsLow = priceOverEarningsLow;

    // Valuation - Price/Book
    this.priceOverBookHigh = priceOverBookHigh;
    this.priceOverBookMean = priceOverBookMean;
    this.priceOverBookLow = priceOverBookLow;

    // Valuation - Price/Cashflow
    this.priceOverCashFlowHigh = priceOverCashFlowHigh;
    this.priceOverCashFlowMean = priceOverCashFlowMean;
    this.priceOverCashFlowLow = priceOverCashFlowLow;

    // Valuation - Price/Sales
    this.priceOverSalesHigh = priceOverSalesHigh;
    this.priceOverSalesMean = priceOverSalesMean;
    this.priceOverSalesLow = priceOverSalesLow;

    // Other ratios
    this.ebitdaOverMarketCapHigh = ebitdaOverMarketCapHigh;
    this.ebitdaOverMarketCapMean = ebitdaOverMarketCapMean;
    this.ebitdaOverMarketCapLow = ebitdaOverMarketCapLow;

    this.netIncomeOverMarketCapHigh = netIncomeOverMarketCapHigh;
    this.netIncomeOverMarketCapMean = netIncomeOverMarketCapMean;
    this.netIncomeOverMarketCapLow = netIncomeOverMarketCapLow;

    this.enterpriseValueOverEbitdaHigh = enterpriseValueOverEbitdaHigh;
    this.enterpriseValueOverEbitdaMean = enterpriseValueOverEbitdaMean;
    this.enterpriseValueOverEbitdaLow = enterpriseValueOverEbitdaLow;

    this.enterpriseValueOverSalesHigh = enterpriseValueOverSalesHigh;
    this.enterpriseValueOverSalesMean = enterpriseValueOverSalesMean;
    this.enterpriseValueOverSalesLow = enterpriseValueOverSalesLow;
    this.pegRatioHigh = pegRatioHigh;
    this.pegRatioMean = pegRatioMean;
    this.pegRatioLow = pegRatioLow;

    // Other
    this.netWorkingCapitalOverSales = netWorkingCapitalOverSales;

    this.equityOverNonCurrentAssetsRatio = equityOverNonCurrentAssetsRatio;
    this.equityPlusNonCurrentLiabilitiesOverNonCurrentAssets = equityPlusNonCurrentLiabilitiesOverNonCurrentAssets;
    this.gearing = gearing;
    this.netFinancialDebtOverEquity = netFinancialDebtOverEquity;
    this.netFinancialDebtOverEbitda = netFinancialDebtOverEbitda;

    this.returnOnInvestment = returnOnInvestment;
    this.bookValuePerShare = bookValuePerShare;
    this.bookValueChange = bookValueChange;
    this.bookValue = bookValue;

    this.eps = eps;
  }
}

export { CompanyRatioStatement };
