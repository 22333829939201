<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import flatten from 'flat';
import draggable from 'vuedraggable';

import CTable from '../c-table/component.vue';
import ExportCsvBtn from '../export-csv-btn/component.vue';
import MultiSelectDropdown from '../multi-select-dropdown/component.vue';
import CompanyListingComparison from '../company-listing-comparison/component.vue';
import InputSlider from '../input-slider/component.vue';
import Lazyload from '../lazyload/component.vue';
import UpgradeCtaModal from '../upgrade-cta-modal/component.vue';
import CompanyScreenerFilterModal from '../company-screener-filter-modal/component.vue';
import StarRating from '../star-rating/component.vue';

import { CompanyScreener } from '../../../shared/models/company/screener/CompanyScreener.js';
import { CompanyScreenerItem } from '../../../shared/models/company/screener/CompanyScreenerItem.js';
import { CompanyScreenerFilter } from '../../../shared/models/company/screener/CompanyScreenerFilter.js';
import { EPlans } from '../../../shared/enums.js';

const COMPANY_LIMIT_PER_PAGE = 25;
const DEFAULT_CURRENT_PAGE = 1;

const PRESET_COLUMNS = [
  {
    name: 'Overview',
    keys: [
      'country',
      'companyName',
      'sectorIndustry',
      'marketCapUsd',
      'sharePrice',
      // 'sharePrice1DayChangePct',
      'rating',
      // 'analystRating',
      'fundamentlValuation'
    ]
  },
  {
    name: 'Dividend',
    keys: ['country', 'companyName', 'sectorIndustry', 'dividendYield', 'dividendPayoutRatio', 'dividendPayoutFrequency']
  },
  {
    name: 'Margins',
    keys: ['country', 'companyName', 'sectorIndustry', 'grossProfitMargin', 'ebitdaMargin', 'ebitMargin', 'netIncomeMargin']
  },
  // {
  //   name: 'Share performance',
  //   keys: [
  //     'country',
  //     'companyName'
  //     // 'sharePriceYtdChangePct',
  //     // 'sharePrice1WeekChangePct',
  //     // 'sharePrice1MonthChangePct',
  //     // 'sharePrice3MonthChangePct',
  //     // 'sharePrice6MonthChangePct',
  //     // 'sharePrice1YearChangePct',
  //     // 'sharePrice3YearChangePct',
  //     // 'sharePrice5YearChangePct',
  //     // 'sharePrice10YearChangePct',
  //   ]
  // },
  {
    name: 'Valuation',
    keys: [
      'country',
      'companyName',
      'sectorIndustry',
      'priceOverEarnings',
      'priceOverSales',
      'priceOverBook',
      'priceOverCashFlow',
      'enterpriseValueOverEbitda',
      'fundamentlValuation'
    ]
  },
  {
    name: 'Growth (1Y)',
    keys: [
      'country',
      'companyName',
      'sectorIndustry',
      'avgRevenueGrowth1Year',
      'avgGrossProfitGrowth1Year',
      'avgEbitdaGrowth1Year',
      'avgEbitGrowth1Year',
      'avgNetIncomeGrowth1Year',
      'avgEpsGrowth1Year'
    ]
  },
  {
    name: 'Growth (3Y)',
    keys: [
      'country',
      'companyName',
      'sectorIndustry',
      'avgRevenueGrowth3Year',
      'avgGrossProfitGrowth3Year',
      'avgEbitdaGrowth3Year',
      'avgEbitGrowth3Year',
      'avgNetIncomeGrowth3Year',
      'avgEpsGrowth3Year'
    ]
  },
  {
    name: 'Growth (5Y)',
    keys: [
      'country',
      'companyName',
      'sectorIndustry',
      'avgRevenueGrowth5Year',
      'avgGrossProfitGrowth5Year',
      'avgEbitdaGrowth5Year',
      'avgEbitGrowth5Year',
      'avgNetIncomeGrowth5Year',
      'avgEpsGrowth5Year'
    ]
  },
  {
    name: 'Growth (10Y)',
    keys: [
      'country',
      'companyName',
      'sectorIndustry',
      'avgRevenueGrowth10Year',
      'avgGrossProfitGrowth10Year',
      'avgEbitdaGrowth10Year',
      'avgEbitGrowth10Year',
      'avgNetIncomeGrowth10Year',
      'avgEpsGrowth10Year'
    ]
  }
];

@Component({
  name: 'company-screener',
  data: () => ({ CompanyScreenerItem, CompanyScreenerFilter, PRESET_COLUMNS, EPlans }),
  components: {
    MultiSelectDropdown,
    CTable,
    Lazyload,
    draggable,
    CompanyListingComparison,
    InputSlider,
    CompanyScreenerFilterModal,
    ExportCsvBtn,
    UpgradeCtaModal,
    StarRating
  },
  props: {
    showActionBar: {
      type: Boolean,
      default: true,
      required: false
    },
    presetFilters: {
      type: Object,
      required: false
    },
    presetColumnSort: {
      type: Object,
      required: false,
      default: () => ({
        column: 'marketCapUsd',
        desc: true
      })
    },
    visibleColumns: {
      type: Array,
      required: false
    },
    companiesAmount: {
      type: Number,
      required: false
    },
    isFullscreen: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    $route: {
      query: {
        page(value) {
          this.handlePageQueryParameterUpdate(value);
        }
      }
    },
    visibleColumns() {
      this.handleVisibleColumns();
    }
  }
})
class CompanyScreenerView extends Vue {
  companies = [];

  screener = new CompanyScreener({
    limit: this.companiesAmount || COMPANY_LIMIT_PER_PAGE
  });

  columnsChanged = false;

  isLoading = false;
  lazyloadObserver = null;
  lastLazyLoadedCompanyAmount = 0;

  recordsPerPage = COMPANY_LIMIT_PER_PAGE;
  currentPage = DEFAULT_CURRENT_PAGE;

  sectorIndustries = null;

  filtersModalVisible = false;
  filters = null;

  columnOrder = [];
  pendingColumnOrder = [];

  showExportDataUpgradeModal = false;

  columnsView = null;

  /* *************** */
  /* *** General *** */
  /* *************** */

  async handlePageQueryParameterUpdate(value) {
    this.currentPage = value;
    this.screener.offset = this.currentPage * COMPANY_LIMIT_PER_PAGE;
    await this.loadCompanies();
  }

  async loadCompanies() {
    try {
      this.isLoading = true;
      await this.$nextTick();
      await this.screener.loadCompanies();
      this.isLoading = false;
    } catch (err) {
      this.isLoading = false;
      console.error(err);
    }
  }

  async loadCompaniesCount() {
    try {
      await this.screener.loadCompanies({ totalRecords: true });
    } catch (err) {
      console.error(err);
    }
  }

  handleCompanyClick(event, company) {
    this.$emit('companyClick', { event, company });
  }

  linkGenerator(page) {
    return { name: this.$route.name, query: { page } };
  }

  async handleSortClick(columnKey) {
    if (this.screener.sortColumn === columnKey) {
      this.screener.sortDirection *= -1; // Toggle sort direction
    } else {
      this.screener.sortColumn = columnKey;
      this.screener.sortDirection = -1;
    }
    this.screener.offset = 0;
    await this.loadCompanies();
  }

  get columnsShownAmount() {
    return this.filters.filter((filter) => filter?.column?.shown).length;
  }

  async updateColumns() {
    this.$bvModal.hide(`columns-modal-${this._uid}`);
    if (this.columnsChanged) {
      this.columnsChanged = false;
      await this.$nextTick();
      this.handleColumnsChange();
      this.screener.offset = 0;
      await this.loadCompanies();
    }
  }

  async handleColumnsChange() {
    this.$emit('columnsChange');
    const orderedColumns = [...this.pendingColumnOrder];
    const columnKeys = Object.keys(this.screener.attributes).filter((key) => this.screener.attributes[key]?.column);
    for (let i = 0; i < columnKeys.length; i++) {
      const columnKey = columnKeys[i];
      if (orderedColumns.includes(columnKey) && !this.screener.attributes[columnKey].column.shown) {
        orderedColumns.splice(orderedColumns.indexOf(columnKey), 1);
      } else if (!orderedColumns.includes(columnKey) && this.screener.attributes[columnKey].column.shown) {
        orderedColumns.push(columnKey);
      }
    }
    this.columnOrder = orderedColumns;
  }

  toggleColumn(key) {
    this.columnsChanged = true;
    if (this.pendingColumnOrder.includes(key)) {
      this.pendingColumnOrder.splice(this.pendingColumnOrder.indexOf(key), 1);
    } else {
      this.pendingColumnOrder.push(key);
    }
  }

  handleVisibleColumns() {
    if (this.visibleColumns) {
      const columnKeys = Object.keys(this.screener.attributes).filter((key) => this.screener.attributes[key]?.column);
      for (let i = 0; i < columnKeys.length; i++) {
        const columnKey = columnKeys[i];
        this.screener.attributes[columnKey].column.shown = false;
      }
      for (let i = 0; i < this.visibleColumns.length; i++) {
        const columnKey = this.visibleColumns[i];
        if (this.screener.attributes?.[columnKey]?.column) {
          this.screener.attributes[columnKey].column.shown = true;
        }
      }
      this.columnOrder = this.visibleColumns;
      this.handleColumnsChange();
    } else {
      this.columnOrder = Object.keys(this.screener.attributes).filter((columnKey) => this.screener.attributes[columnKey]?.column?.shown);
      this.handleColumnsChange();
    }
  }

  handlePresetFilters() {
    if (this.presetFilters) {
      Object.keys(this.presetFilters).forEach((filterKey) => {
        if (this.screener?.attributes?.[filterKey]?.filter) {
          this.screener.attributes[filterKey].filter.value = this.presetFilters[filterKey];
        }
      });
    }
  }

  handlePresetColumnSort() {
    if (this.presetColumnSort) {
      this.screener.sortColumn = this.presetColumnSort.column;
      this.screener.sortDirection = this.presetColumnSort.desc ? CompanyScreener.SORT_DIRECTION.DESC : CompanyScreener.SORT_DIRECTION.ASC;
    }
  }

  categoryColumnKeys(category) {
    const categoryColumns = Object.keys(this.filters).filter(
      (columnKey) => this.filters[columnKey].category === category && this.filters[columnKey]?.column
    );
    return categoryColumns;
  }

  initLazyLoadObserver() {
    // Lazyload observer
    if (!this.companiesAmount && this.$refs?.lazyloadIntersection) {
      this.lazyloadObserver = new IntersectionObserver(async (entries) => {
        if (entries[0].isIntersecting && !this.isLoading) {
          this.currentPage++;
          if (this.screener?.companies?.length % COMPANY_LIMIT_PER_PAGE === 0) {
            this.screener.offset = this.currentPage * COMPANY_LIMIT_PER_PAGE;
            await this.loadCompanies();
          }
        }
      });
      this.lazyloadObserver.observe(this.$refs.lazyloadIntersection);
    }
  }

  async handlePresetViewButtonClick(presetColumn) {
    presetColumn.keys.forEach((key) => {
      this.screener.columns[key].shown = true;
    });
    this.columnOrder = presetColumn.keys;

    this.screener.offset = 0;
    await this.loadCompanies();
  }

  /* *************** */
  /* *** Filters *** */
  /* *************** */

  async handleFiltersChange(attributeKey) {
    // Add column to table
    if (this.screener.columns[attributeKey]) {
      this.screener.columns[attributeKey].shown = true;
      if (!this.columnOrder.includes(attributeKey)) {
        this.columnOrder.push(attributeKey);
      }
    }

    await this.loadCompanies();
    await this.loadCompaniesCount();

    this.$emit('filtersChange');
  }

  async mounted() {
    this.currentPage = this.$route.query.page || this.currentPage;

    this.columnOrder = Object.values(this.screener.columns)
      .filter((column) => column.shown)
      .map((column) => column.key);

    if (this.showActionBar) {
      if (this.store.user) {
        // this.getTags();
      }
    }

    this.handleVisibleColumns();
    this.handlePresetFilters();
    this.handlePresetColumnSort();

    await this.loadCompanies();
    this.loadCompaniesCount();

    this.initLazyLoadObserver();
  }

  beforeDestroy() {
    if (!this.companiesAmount && this.$refs?.lazyloadIntersection) {
      this.lazyloadObserver.unobserve(this.$refs.lazyloadIntersection);
    }
  }
}
export default CompanyScreenerView;
</script>

<style scoped>
@import './style.scss';
</style>
