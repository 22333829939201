<template src="./template.vue"></template>
<script>
import Component from 'vue-class-component';
import Vue from 'vue';

import Lazyload from '../lazyload/component.vue';

const TYPE = {
  RANGE: 'range',
  OPTIONS: 'options'
};

const DOT = {
  LEFT: 'left',
  RIGHT: 'right'
};

const DOT_SIZE = 15;

@Component({
  name: 'input-slider',
  data: () => ({ DOT }),
  components: { Lazyload },
  props: {
    value: {
      type: [Number, Object],
      required: false
    },
    type: {
      type: String,
      default: TYPE.RANGE
    },
    rangeMin: {
      type: Number,
      default: 0
    },
    rangeMax: {
      type: Number,
      default: 100
    },
    rangeStepSize: {
      type: Number,
      default: 1
    },
    rangeUnlimitedMin: {
      type: Boolean,
      default: true
    },
    rangeUnlimitedMax: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => [
        { label: '1M', value: 1000000 },
        { label: '10M', value: 10000000 },
        { label: '100M', value: 100000000 },
        { label: '1B', value: 1000000000 },
        { label: '1T', value: 1000000000000 }
      ]
    },
    single: {
      type: Boolean,
      default: true
    }
  }
})
class InputSlider extends Vue {
  TYPE = TYPE;

  showSlider = true;
  loaded = false;

  minValue = null;
  maxValue = null;

  offsetLeftDot = 0;
  offsetRightDot = 0;

  isDragging = null;

  showMinValueInputField = false;
  showMaxValueInputField = false;

  closest(px, options) {
    return [...options].sort((a, b) => Math.abs(a.px - px) - Math.abs(b.px - px)).first();
  }

  get _options() {
    if (!this.loaded) return null;

    const trackLength = this.$refs.track.clientWidth;

    let rangeOptions = null;
    if (this.type === this.TYPE.RANGE) {
      rangeOptions = [
        ...(this.rangeUnlimitedMin ? [{ label: `<${this.rangeMin}`, value: -Infinity }] : []),
        ...Math.ceil((this.rangeMax - this.rangeMin) / this.rangeStepSize).map((step) => ({
          label: this.Filter.hrFinancial(this.rangeMin + step * this.rangeStepSize, undefined, 0),
          value: this.rangeMin + step * this.rangeStepSize
        })),
        {
          label: this.Filter.hrFinancial(
            this.rangeMin + Math.ceil((this.rangeMax - this.rangeMin) / this.rangeStepSize) * this.rangeStepSize,
            undefined,
            0
          ),
          value: this.rangeMin + Math.ceil((this.rangeMax - this.rangeMin) / this.rangeStepSize) * this.rangeStepSize
        },
        ...(this.rangeUnlimitedMin ? [{ label: `>${this.Filter.hrFinancial(this.rangeMax, undefined, 0)}`, value: Infinity }] : [])
      ];
    }

    const options = rangeOptions || this.options;
    return options
      .map((option, step) => ({
        ...option,
        px: (trackLength / (options.length - 1)) * step
      }))
      .sort((a, b) => a.px - b.px);
  }

  get _optionsReversed() {
    const options = this._options;
    return options.map((option, index) => ({
      ...option,
      px: options[options.length - 1 - index].px
    }));
  }

  optionIndex(optionToFind) {
    return this._options.findIndex((option) => option.value === optionToFind.value && option.label === optionToFind.label);
  }

  dotGrab(event, left) {
    const offset = this.single ? DOT_SIZE : DOT_SIZE * 2;

    const baseX = event?.x || [...event?.touches]?.first()?.clientX;
    const baseOffsetLeftDot = this.offsetLeftDot;
    const baseOffsetRightDot = this.offsetRightDot;
    const trackLength = this.$refs.track.clientWidth;

    const mouseMoveEventHandler = (e) => {
      const mouseX = e?.x || [...e?.touches]?.first()?.clientX;
      if (left) {
        let newOffset = baseOffsetLeftDot + mouseX - baseX;
        if (newOffset < 0) newOffset = 0;
        if (newOffset > trackLength - this.offsetRightDot) newOffset = trackLength - this.offsetRightDot;
        this.minValue =
          this.optionIndex(this.closest(newOffset, this._options)) >= this.optionIndex(this.maxValue)
            ? this._options[this.optionIndex(this.maxValue) - 1]
            : this.closest(newOffset, this._options);
        if (newOffset > trackLength - this.offsetRightDot - offset) newOffset = trackLength - this.offsetRightDot - offset;
        this.offsetLeftDot = newOffset;
      } else {
        let newOffset = baseOffsetRightDot + baseX - mouseX;
        if (newOffset < 0) newOffset = 0;
        if (newOffset > trackLength - this.offsetLeftDot) newOffset = trackLength - this.offsetLeftDot;
        this.maxValue =
          this.optionIndex(this.closest(newOffset, this._optionsReversed)) <= this.optionIndex(this.minValue)
            ? this._options[this.optionIndex(this.minValue) + 1]
            : this.closest(newOffset, this._optionsReversed);
        // this.maxValue = this.closest(newOffset, this._optionsReversed);
        if (newOffset > trackLength - this.offsetLeftDot - offset) newOffset = trackLength - this.offsetLeftDot - offset;
        this.offsetRightDot = newOffset;
      }
    };
    document.addEventListener('mousemove', mouseMoveEventHandler);
    document.addEventListener('touchmove', mouseMoveEventHandler);

    document.body.classList.add('no-select', 'cursor-grabbing');
    document.querySelector('html').classList.add('overscroll-none');
    this.isDragging = left ? DOT.LEFT : DOT.RIGHT;

    const mouseUpEventHandler = () => {
      document.removeEventListener('mousemove', mouseMoveEventHandler);
      document.removeEventListener('touchmove', mouseMoveEventHandler);
      document.removeEventListener('mouseup', mouseUpEventHandler);
      document.removeEventListener('touchend', mouseUpEventHandler);
      document.body.classList.remove('no-select', 'cursor-grabbing');
      document.querySelector('html').classList.remove('overscroll-none');
      if (this.single) {
        this.$emit('input', this.maxValue.value);
      } else {
        this.$emit('input', { min: this.minValue.value, max: this.maxValue.value });
      }
      this.isDragging = null;
    };
    document.addEventListener('mouseup', mouseUpEventHandler);
    document.addEventListener('touchend', mouseUpEventHandler);
  }

  closestOption(isMaxValue, value) {
    const closestOption = (isMaxValue ? [...this._optionsReversed].reverse() : [...this._options])
      .sort((a, b) => (Math.abs(value - a.value) < Math.abs(value - b.value) ? -1 : 1))
      ?.first();
    if (!this.single) {
      if (isMaxValue && closestOption.value < this.minValue.value) {
        const minValueOptionIndex = [...this._optionsReversed].reverse().findIndex((option) => option.value === this.minValue.value);
        return [...this._optionsReversed].reverse()[minValueOptionIndex + 1];
      }
      if (!isMaxValue && closestOption.value > this.maxValue.value) {
        const maxValueOptionIndex = this._options.findIndex((option) => option.value === this.maxValue.value);
        return this._options[maxValueOptionIndex - 1];
      }

      const highestValueOption = this._options
        .map((option) => option.value)
        .filter((optionValue) => optionValue !== Infinity)
        .max();
      const lowestValueOption = this._options
        .map((option) => option.value)
        .filter((optionValue) => optionValue !== -Infinity)
        .min();
      if (isMaxValue && highestValueOption < value && this.rangeUnlimitedMax) {
        return this._options.find((option) => option.value === Infinity);
      }
      if (!isMaxValue && lowestValueOption > value && this.rangeUnlimitedMin) {
        return this._options.find((option) => option.value === -Infinity);
      }
    }
    return closestOption;
  }

  largeNumberStringsToNumbers(stringValue) {
    const value = parseFloat(stringValue);
    if (stringValue?.slice(-1)[0].toUpperCase() === 'T') return value * 1000 ** 4;
    if (stringValue?.slice(-1)[0].toUpperCase() === 'B') return value * 1000 ** 3;
    if (stringValue?.slice(-1)[0].toUpperCase() === 'M') return value * 1000 ** 2;
    if (stringValue?.slice(-1)[0].toUpperCase() === 'K') return value * 1000 ** 1;
    return value;
  }

  handleMinValueInputFieldBlur(event) {
    const rawInput = event.target.innerText === '' ? '-Infinity' : event.target.innerText;
    const input = this.largeNumberStringsToNumbers(rawInput);
    event.target.innerText = rawInput.trim();
    event.target.blur();

    this.showMinValueInputField = false;

    const closestOption = this.closestOption(false, input && !isNaN(input) ? input : -Infinity);
    if (closestOption && closestOption.value !== this.minValue.value) {
      this.minValue = closestOption;
      this.offsetLeftDot = this.minValue.px;

      if (this.single) {
        this.$emit('input', this.maxValue.value);
      } else {
        this.$emit('input', { min: this.minValue.value, max: this.maxValue.value });
      }
    } else if (closestOption) {
      this.minValue = closestOption;
    }
    this.rerenderSlider();
  }

  handleMaxValueInputFieldBlur(event) {
    const rawInput = event.target.innerText === '' ? 'Infinity' : event.target.innerText;
    const input = this.largeNumberStringsToNumbers(rawInput);
    event.target.innerText = rawInput.trim();
    event.target.blur();

    this.showMaxValueInputField = false;

    const closestOption = this.closestOption(true, input && !isNaN(input) ? input : Infinity);
    if (closestOption && closestOption.value !== this.maxValue.value) {
      this.maxValue = closestOption;
      this.offsetRightDot = this.maxValue.px;

      if (this.single) {
        this.$emit('input', this.maxValue.value);
      } else {
        this.$emit('input', { min: this.minValue.value, max: this.maxValue.value });
      }
    } else if (closestOption) {
      this.maxValue = closestOption;
    }
    this.rerenderSlider();
  }

  handleInputFieldClick($event) {
    $event.target.focus();
    document.execCommand('selectAll', true, null);
  }

  async rerenderSlider() {
    this.showSlider = false;
    await this.$nextTick();
    this.showSlider = true;
  }

  async handleLoad() {
    this.loaded = true;

    await this.$nextTick();

    this.minValue = this._options.find((option) => option.value === this.value?.min) || this._options.first();
    this.maxValue =
      [...this._optionsReversed].reverse().find((option) => option.value === this.value?.max || option.value === this.value) ||
      this._optionsReversed.last();

    if (this.minValue?.px) this.offsetLeftDot = this.minValue?.px;
    if (this.maxValue?.px) this.offsetRightDot = this.maxValue?.px;
  }
}
export default InputSlider;
</script>
<style lang="scss" scoped>
@import './style.scss';
</style>
