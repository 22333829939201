import { Company } from '../company/Company.js';
import { ETransactionType, PortfolioTransactionCash, PortfolioTransactionShares } from './PortfolioTransaction.js';
import { ETime } from '../../enums.js';
import { CurrencyPair } from '../currency/index.js';

class PortfolioState {
  constructor({ transactions = [], date = new Date(), portfolioCurrency }) {
    this.transactions = transactions.sort((a, b) => a.date.getTime() - b.date.getTime());
    this.portfolioCurrency = portfolioCurrency;
    this.date = date.removeTime();
    this.companies = {};
    this.currencyPairs = {};
    this.timeline = [];

    this.initState();
  }

  refresh() {
    this.initState();
  }

  // Initializes the state
  async initState() {
    await this.loadCompanyData();
    await this.loadCurrencyExchangeRateData();
    this._buildWallet();
  }

  // Load company data
  async loadCompanyData() {
    const companyCodes = this.transactions
      .filter((transaction) => transaction.type === ETransactionType.SHARES)
      .map((transaction) => transaction.companyCode)
      .distinct();

    const companyShareTransactions = this.transactions;

    if (!companyCodes?.length || !companyShareTransactions?.length) return;

    const startDate = companyShareTransactions.first().date.removeTime();
    const endDate = new Date(this.date.getTime() + ETime.milliseconds.perDay).removeTime(); // 1 extra day

    const companyPromises = [];
    const companies = {};
    companyCodes.forEach((companyCode) => {
      companies[companyCode] = new Company({
        companyCode
      });
      companyPromises.push(companies[companyCode].load({ currencies: true, generalData: true }));
      companyPromises.push(companies[companyCode].getSharePriceHistory(startDate, endDate));
    });

    await Promise.all(companyPromises);

    Object.keys(companies).forEach((companyCode) => {
      this.companies[companyCode] = companies[companyCode];
    });
  }

  // Load currency exchange rate data
  async loadCurrencyExchangeRateData() {
    const currencies = this.transactions
      .filter((transaction) => transaction.type === ETransactionType.SHARES)
      .map((transaction) => this.companies?.[transaction.companyCode]?.currencies?.sharePrices.iso())
      .distinct();

    if (!currencies?.length) return null;

    const startDate = this.transactions.first().date.removeTime();
    const endDate = new Date(this.date.getTime() + ETime.milliseconds.perDay).removeTime(); // 1 extra day

    const promises = [];
    currencies.forEach((currency) => {
      const currencyPair = new CurrencyPair(currency, this.portfolioCurrency.iso());
      this.currencyPairs[currencyPair.toString()] = currencyPair;
      promises.push(this.currencyPairs[currencyPair.toString()].loadExchangeRateHistory({ from: startDate, till: endDate }));
    });
    return Promise.all(promises);
  }

  // Builds the wallet based on transactions and specified state date
  async _buildWallet() {
    const transactions = [...this.transactions]
      .filter((transaction) => !transaction?.addedAutomatically)
      .sort((a, b) => a.date.getTime() - b.date.getTime());
    if (!transactions.length) {
      this.timeline = [];
      return;
    }

    // Create timeline
    const timeline = [];
    const startDate = transactions.first().date.getTime();
    const endDate = this.date.getTime() + ETime.milliseconds.perDay; // 1 extra day
    const daysAmount = (endDate - startDate) / ETime.milliseconds.perDay;

    // Loop over days since first transaction till set date (or current date)
    for (let day = 0; day < daysAmount; day++) {
      // Fetch current date and transactions
      const date = new Date(startDate + day * ETime.milliseconds.perDay);
      const transactionsToday = transactions.filter(
        (transaction) => transaction.date.removeTime().getTime() === date.removeTime().getTime()
      );

      // Copy wallet state from previous day
      const prevDayWallet = timeline?.last() || null;
      const currentDayWallet = {
        shares: {},
        cash: prevDayWallet?.cash || 0,
        date,
        portfolioValue: 0
      };
      Object.keys(prevDayWallet?.shares || []).forEach((companyCode) => {
        currentDayWallet.shares[companyCode] = {
          ...prevDayWallet?.shares[companyCode]
        };
      });

      // If no transactions today -> skip day
      if (transactionsToday?.length) {
        // Loop over today's transactions and assemble wallet for each day
        transactionsToday.forEach((rawTransaction) => {
          if (rawTransaction.type === ETransactionType.CASH) {
            // Cash transaction
            const transaction = new PortfolioTransactionCash(rawTransaction);
            if (transaction.deposit) {
              currentDayWallet.cash += transaction.amount;
            } else {
              currentDayWallet.cash -= transaction.amount;
            }
          } else if (rawTransaction.type === ETransactionType.SHARES) {
            // Shares transaction
            const transaction = new PortfolioTransactionShares(rawTransaction);
            currentDayWallet.shares[transaction.companyCode] = {
              amount: currentDayWallet?.shares?.[transaction?.companyCode]?.amount || 0
            };
            currentDayWallet.shares[transaction.companyCode].amount += transaction.amount * (transaction.buy ? 1 : -1);
            currentDayWallet.cash += new CurrencyPair(
              this.companies[transaction.companyCode].currencies.sharePrices.iso(),
              this.portfolioCurrency.iso()
            ).convert({
              from: this.companies[transaction.companyCode].currencies.sharePrices.iso(),
              to: this.portfolioCurrency.iso(),
              value: transaction.amount * transaction.sharePrice * (transaction.buy ? -1 : 1),
              exchangeRate: transaction.exchangeRate
            });
          }
        });
      }

      // Loop over today's wallet assets and calculate total wallet value + add values for each individual stock
      const companyCodes = Object.keys(currentDayWallet?.shares || {});
      for (let i = 0; i < companyCodes.length; i++) {
        const companyCode = companyCodes[i];

        const company = this.companies[companyCode];
        const currencyPair = new CurrencyPair(company.currencies.sharePrices.iso(), this.portfolioCurrency.iso());
        const sharesAmount = currentDayWallet.shares[companyCode].amount;

        // eslint-disable-next-line no-await-in-loop
        const companySharePrice = await company.getSharePrice(date);

        // eslint-disable-next-line no-await-in-loop
        const sharePricePortfolioCurrency = await this.currencyPairs[currencyPair.toString()].convert({
          date,
          to: this.portfolioCurrency.iso(),
          value: companySharePrice
        });

        currentDayWallet.shares[companyCode].foreignValue = companySharePrice * sharesAmount;
        currentDayWallet.shares[companyCode].value = sharePricePortfolioCurrency * sharesAmount;

        currentDayWallet.portfolioValue += sharePricePortfolioCurrency * sharesAmount;
      }

      // Check if money balance is negative -> add additional cash transaction to accommodate for this
      if (currentDayWallet.cash < 0) {
        const cashTransaction = new PortfolioTransactionCash({
          deposit: true,
          amount: Math.abs(currentDayWallet.cash),
          date
        });
        cashTransaction.addedAutomatically = true;
        transactions.push(cashTransaction);
        day--;
      } else {
        currentDayWallet.portfolioValue += currentDayWallet.cash;
        timeline.push(currentDayWallet);
      }
    }

    // this.transactions = transactions;
    this.timeline = timeline;
  }

  calculateCostBasis(companyCode) {
    const transactions = this.transactions
      .filter((transaction) => transaction.type === ETransactionType.SHARES && transaction.companyCode === companyCode)
      .filter((transaction) => transaction.date.removeTime().getTime() <= this.date.removeTime().getTime())
      .map((transaction) => ({
        buy: transaction.buy,
        amount: transaction.amount,
        sharePrice: transaction.sharePrice
      }));

    const buyTransactions = transactions.filter((transaction) => transaction.buy);
    const sellTransactions = transactions.filter((transaction) => !transaction.buy);

    const fifoTransactions = buyTransactions.reverse();
    sellTransactions.forEach((sellTransaction) => {
      let amountLeftOver = sellTransaction.amount;
      for (let i = fifoTransactions.length - 1; i >= 0; i--) {
        if (fifoTransactions[i].amount <= amountLeftOver) {
          amountLeftOver -= fifoTransactions[i].amount;
          fifoTransactions.splice(i, 1);
        } else if (amountLeftOver > 0) {
          fifoTransactions[i].amount -= amountLeftOver;
          amountLeftOver = 0;
          break;
        }
      }
    });
    fifoTransactions.reverse();

    let totalShares = 0;
    let totalInvested = 0;
    fifoTransactions.forEach((transaction) => {
      totalShares += transaction.amount;
      totalInvested += transaction.sharePrice * transaction.amount;
    });

    return totalInvested / totalShares;
  }

  calculateRoi(companyCode) {
    const costBasis = this.calculateCostBasis(companyCode);
    return (this.companies[companyCode]?.sharePriceLive / costBasis - 1) * 100;
  }

  get totalRoi() {
    return (this.portfolioValue / (this.totalInvested + this.cash) - 1) * 100;
  }

  get portfolioValue() {
    return this.state?.portfolioValue;
  }

  get cashPercentage() {
    return (this.cash / this.portfolioValue) * 100;
  }

  get investedPercentage() {
    return 100 - this.cashPercentage;
  }

  get totalInvested() {
    const companyCodes = Object.keys(this.companies || {});

    let totalInvested = 0;
    companyCodes.forEach((companyCode) => {
      const shares = this.shares?.[companyCode]?.amount;
      if (shares) {
        const costBasisPerShare = this.calculateCostBasis(companyCode);
        const currencyPair = new CurrencyPair(
          this.companies[companyCode].currencies.sharePrices.iso(),
          this.portfolioCurrency.iso()
        ).toString();
        if (currencyPair) {
          totalInvested += this.currencyPairs[currencyPair].convert({
            to: this.portfolioCurrency.iso(),
            value: shares * costBasisPerShare
          });
        }
      }
    });

    return totalInvested;
  }

  get state() {
    return this.timeline.find((timelineItem) => timelineItem.date.removeTime().getTime() === this.date.removeTime().getTime());
  }

  get shares() {
    const shares = this.state?.shares;
    const companyCodes = Object.keys(shares || {});
    for (let i = companyCodes.length - 1; i >= 0; i--) {
      const companyCode = companyCodes[i];
      if (!shares?.[companyCode]?.amount) {
        delete shares?.[companyCode];
      }
    }
    return shares || {};
  }

  get cash() {
    return this.state?.cash;
  }
}

export { PortfolioState };
