import axios from 'axios';

import { handleResponse } from './utils/index.js';

class UsersApi {
  constructor() {
    this.endpoint = `${process.env.BACKEND_PROTOCOL}://${process.env.BACKEND_HOSTNAME}:${process.env.BACKEND_PORT}/api/users`;
  }

  async userList() {
    try {
      const response = await axios.get(`${this.endpoint}`);
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  }

  async userDetails({ id, mongoDbProject }) {
    const queryParams = [];
    if (mongoDbProject) queryParams.push(`mongoDbProject=${encodeURIComponent(JSON.stringify(mongoDbProject))}`);

    try {
      const response = await axios.get(`${this.endpoint}/${id}${queryParams ? `?${queryParams.join('&')}` : ''}`);
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  }
}

export { UsersApi };
