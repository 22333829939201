<template>
  <div class="d-inline popover-chart">
    <Chart
      v-if="sparklineOptions && chartData.length"
      :id="`popover-chart-${_uid}`"
      class="share-price-chart d-inline-block"
      :options="sparklineOptions"
      constructor-type="chart"
    ></Chart>
    <b-popover custom-class="bg-gray-700" placement="right" :target="`popover-chart-${_uid}`" triggers="focus hover">
      <Lazyload tag="div" class="share-price-chart-wrapper">
        <Chart v-if="chartOptions" class="share-price-chart" :options="chartOptions" constructor-type="chart"></Chart>
      </Lazyload>
    </b-popover>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Chart } from 'highcharts-vue';

import { Lazyload } from '../../components/index.js';

@Component({
  name: 'popover-chart',
  components: { Chart, Lazyload },
  data: () => ({}),
  props: {
    title: {
      type: String,
      default: null
    },
    chartData: {
      type: Array,
      required: true
    }
  }
})
class PopoverChart extends Vue {
  chartOptions = null;
  sparklineOptions = null;

  generateChart() {
    const seriesData = [];
    for (let i = 0; i < this.chartData.length; i++) {
      const record = this.chartData[i];
      const date = new Date(record.date).getTime();
      const value = parseFloat(record?.value?.toFixed(3));
      seriesData.push([date, value]);
    }
    this.chartOptions = {
      stockTools: null,
      title: {
        text: this.title
      },
      rangeSelector: {
        enabled: false
      },
      yAxis: {
        title: {
          text: ''
        }
      },
      xAxis: {
        type: 'datetime'
      },
      plotOptions: {
        column: {
          color: '#3f78b6',
          negativeColor: '#8f3e44'
        }
      },
      series: [{ name: this.title, type: 'column', data: seriesData, showInLegend: false }]
    };

    this.sparklineOptions = {
      stockTools: null,
      rangeSelector: {
        enabled: false
      },
      chart: {
        type: 'column',
        backgroundColor: null,
        borderWidth: 0,
        margin: [2, 0, 2, 0],
        width: this.chartData.slice(-4).length * 5 + 1,
        height: 20,
        style: {
          overflow: 'visible'
        },
        // small optimalization, saves 1-2 ms each sparkline
        skipClone: true
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      xAxis: {
        width: this.chartData.slice(-4).length * 5 + 1,
        visible: false,
        labels: {
          enabled: false
        },
        title: {
          text: null
        },
        startOnTick: false,
        endOnTick: false
      },
      yAxis: {
        visible: false,
        endOnTick: false,
        startOnTick: false,
        labels: {
          enabled: false
        },
        title: {
          text: null
        },
        tickPositions: [0]
      },
      legend: {
        enabled: false
      },
      tooltip: true,
      plotOptions: {
        series: {
          animation: false,
          lineWidth: 0,
          shadow: false,
          groupPadding: 0,
          pointPadding: 0,
          borderWidth: 0,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          marker: {
            radius: 1,
            states: {
              hover: {
                radius: 2
              }
            }
          },
          fillOpacity: 0.25
        },
        column: {
          color: '#3f78b6',
          negativeColor: '#8f3e44',
          borderWidth: 0,
          pointWidth: 4,
          minPointLength: 1
        }
      },
      series: [{ pointStart: 1, data: seriesData.slice(-4), showInLegend: false }]
    };
  }

  mounted() {
    this.generateChart();
  }
}
export default PopoverChart;
</script>

<style scoped>
.popover-chart {
  width: 21px;
  height: 20px;
}
.share-price-chart-wrapper {
  min-height: 400px;
}
</style>
