import { CompanyBalanceSheet } from './CompanyBalanceSheet.js';
import { CompanyCashFlowStatement } from './CompanyCashFlowStatement.js';
import { CompanyIncomeStatement } from './CompanyIncomeStatement.js';
import { EBalanceSheet, ECashFlowStatement, EIncomeStatement } from '../../../enums.js';
import { ERoles } from '../../user/index.js';

const ROLE_SPECIFIC_EXPORT_LIMITS = {
  [ERoles.packageTier1.id]: {
    years: 5,
    quarters: 4
  },
  default: {
    years: 10,
    quarters: 8
  }
};

class CompanyFinancialStatements {
  constructor({ balanceSheets = {}, cashFlowStatements = {}, incomeStatements = {} }) {
    this.balanceSheets = {
      yearly: balanceSheets?.yearly?.map((bs) => new CompanyBalanceSheet(bs)).sort((a, b) => a.date - b.date),
      quarterly: balanceSheets?.quarterly?.map((bs) => new CompanyBalanceSheet(bs)).sort((a, b) => a.date - b.date)
    };
    this.cashFlowStatements = {
      yearly: cashFlowStatements?.yearly?.map((bs) => new CompanyCashFlowStatement(bs)).sort((a, b) => a.date - b.date),
      quarterly: cashFlowStatements?.quarterly?.map((bs) => new CompanyCashFlowStatement(bs)).sort((a, b) => a.date - b.date)
    };
    this.incomeStatements = {
      yearly: incomeStatements?.yearly?.map((bs) => new CompanyIncomeStatement(bs)).sort((a, b) => a.date - b.date),
      quarterly: incomeStatements?.quarterly?.map((bs) => new CompanyIncomeStatement(bs)).sort((a, b) => a.date - b.date)
    };
  }

  exportBalanceSheets(periodKey = 'yearly', maxStatements, user) {
    return this._export(this.balanceSheets, periodKey, EBalanceSheet, maxStatements, user);
  }

  exportCashFlowStatements(periodKey = 'yearly', maxStatements, user) {
    return this._export(this.cashFlowStatements, periodKey, ECashFlowStatement, maxStatements, user);
  }

  exportIncomeStatements(periodKey = 'yearly', maxStatements, user) {
    return this._export(this.incomeStatements, periodKey, EIncomeStatement, maxStatements, user);
  }

  _export(statements, periodKey, statementEnum, maxStatements = null, user) {
    if (periodKey !== 'yearly' && periodKey !== 'quarterly') {
      periodKey = 'yearly';
    }

    let maxExportYears = 0;
    let maxExportQuarters = 0;
    Object.keys(ROLE_SPECIFIC_EXPORT_LIMITS).forEach((roleId) => {
      const role = ERoles?.[roleId];
      if (role && user && user.hasRole(role)) {
        if (maxExportYears < ROLE_SPECIFIC_EXPORT_LIMITS[roleId].years) {
          maxExportYears = ROLE_SPECIFIC_EXPORT_LIMITS[roleId].years;
        }
        if (maxExportQuarters < ROLE_SPECIFIC_EXPORT_LIMITS[roleId].quarters) {
          maxExportQuarters = ROLE_SPECIFIC_EXPORT_LIMITS[roleId].quarters;
        }
      }
    });
    maxExportYears = maxExportYears || ROLE_SPECIFIC_EXPORT_LIMITS.default.years;
    maxExportQuarters = maxExportQuarters || ROLE_SPECIFIC_EXPORT_LIMITS.default.quarters;

    if (periodKey === 'yearly' && (!maxStatements || maxStatements > maxExportYears)) maxStatements = maxExportYears;
    if (periodKey === 'quarterly' && (!maxStatements || maxStatements > maxExportQuarters)) maxStatements = maxExportQuarters;

    const data = [];
    const dates = statements[periodKey]
      .sort((a, b) => a.date - b.date)
      .map((bs) => new Date(bs.date))
      .slice(-maxStatements);

    const keys = Object.keys(statementEnum);
    keys.forEach((key) => {
      const periodData = {};
      periodData['0'] = statementEnum?.[key] || key;
      dates.forEach((date) => {
        const year = new Date(date).getFullYear();
        const month = (new Date(date).getMonth() + 1).toString();
        const periodString = `${year}-${month.length === 1 ? `0${month}` : month}`;
        const periodStatement = statements[periodKey].find((statement) => new Date(statement.date).getTime() === date.getTime());
        periodData[periodString] = (periodStatement?.[key]?.iso ? periodStatement?.[key]?.iso() : null) || periodStatement?.[key];
      });
      data.push(periodData);
    });

    return data;
  }
}

export { CompanyFinancialStatements };
