import { ETime } from '../../../enums.js';

class CompanyHistoricValuation {
  constructor({ _id = null, year = null, growth = null, multiple = null, included = null, valuation = null }) {
    this._id = _id;
    this.year = year;
    this.growth = {
      netProfit: growth?.netProfit,
      revenues: growth?.revenues,
      bookValue: growth?.bookValue,
      operationalCashFlow: growth?.operationalCashFlow
    };
    this.multiple = {
      netProfit: multiple?.netProfit,
      revenues: multiple?.revenues,
      bookValue: multiple?.bookValue,
      operationalCashFlow: multiple?.operationalCashFlow
    };
    this.valuation = {
      netProfit: valuation?.netProfit,
      revenues: valuation?.revenues,
      bookValue: valuation?.bookValue,
      operationalCashFlow: valuation?.operationalCashFlow
    };
    this.included = {
      netProfit: typeof included?.netProfit === typeof Boolean() ? included?.netProfit : true,
      revenues: typeof included?.revenues === typeof Boolean() ? included?.revenues : true,
      bookValue: typeof included?.bookValue === typeof Boolean() ? included?.bookValue : true,
      operationalCashFlow: typeof included?.operationalCashFlow === typeof Boolean() ? included?.operationalCashFlow : true
    };
  }

  static perShareData(company) {
    const sharesAmount = new Date(company?.ratios?.yearly?.last()?.date)?.closest(company?.shares?.outstanding, 'date')?.shares;

    return {
      netProfitPerShare: company?.financialStatements?.incomeStatements?.yearly?.last()?.netIncome / sharesAmount,
      revenuePerShare: company?.financialStatements?.incomeStatements?.yearly?.last()?.totalRevenue / sharesAmount,
      bookValuePerShare: company?.ratios?.yearly?.last()?.bookValue / sharesAmount,
      operationalCashFlowPerShare:
        company?.financialStatements?.cashFlowStatements?.yearly?.last()?.totalCashFromOperatingActivities / sharesAmount
    };
  }

  static financialData(company) {
    return {
      netProfit: company?.financialStatements?.incomeStatements?.yearly.map((is) => is?.netIncome || 0),
      revenues: company?.financialStatements?.incomeStatements?.yearly?.map((is) => is?.totalRevenue || 0),
      bookValue: company?.ratios?.yearly?.map((ratio) => ratio?.bookValue || 0),
      operationalCashFlow: company?.financialStatements?.cashFlowStatements?.yearly?.map((cf) => cf?.totalCashFromOperatingActivities || 0)
    };
  }

  static financialDataLastXYear(company, years) {
    const allData = this.financialData(company);
    return {
      netProfit: allData.netProfit.slice(-years),
      revenues: allData.revenues.slice(-years),
      bookValue: allData.bookValue.slice(-years),
      operationalCashFlow: allData.operationalCashFlow.slice(-years)
    };
  }

  static growthAverage(company, years, growthMultiplier = 1) {
    const round = 2;
    const financialData = this.financialDataLastXYear(company, years + 1);
    return {
      netProfit: (financialData.netProfit.cagr() * growthMultiplier).round(round),
      revenues: (financialData.revenues.cagr() * growthMultiplier).round(round),
      bookValue: (financialData.bookValue.cagr() * growthMultiplier).round(round),
      operationalCashFlow: (financialData.operationalCashFlow.cagr() * growthMultiplier).round(round)
    };
  }

  static growthData(company, years) {
    const financialData = this.financialDataLastXYear(company, years);

    const growthDataLength = [
      financialData.netProfit.length,
      financialData.revenues.length,
      financialData.bookValue.length,
      financialData.operationalCashFlow.length
    ].max();

    return {
      netProfit: new Array(growthDataLength - financialData.netProfit.length).fill(null).concat(financialData.netProfit.pctChange()),
      revenues: new Array(growthDataLength - financialData.revenues.length).fill(null).concat(financialData.revenues.pctChange()),
      bookValue: new Array(growthDataLength - financialData.bookValue.length).fill(null).concat(financialData.bookValue.pctChange()),
      operationalCashFlow: new Array(growthDataLength - financialData.operationalCashFlow.length)
        .fill(null)
        .concat(financialData.operationalCashFlow.pctChange())
    };
  }

  static growthAverages(company) {
    const twoYears = 2;
    const twoYearGrowthData = this.growthAverage(company, twoYears);

    const fiveYears = 5;
    const fiveYearGrowthData = this.growthAverage(company, fiveYears);

    const tenYears = 10;
    const tenYearGrowthData = this.growthAverage(company, tenYears);

    return {
      twoYearGrowthData,
      fiveYearGrowthData,
      tenYearGrowthData
    };
  }

  static historicValuationYear(company) {
    return new Date(company.financialStatements?.balanceSheets?.yearly?.last()?.date).getFullYear() + 1;
  }

  static autoValuation(company, years) {
    const round = 2;

    const perShareData = this.perShareData(company);
    const historicValuationYear = this.historicValuationYear(company);

    let growthMultiplier = 1;
    if (historicValuationYear) {
      growthMultiplier =
        (Date.now() - new Date(historicValuationYear - 1, company?.general?.fiscalYearEndMonth + 1, 0).getTime()) /
        ETime.milliseconds.perYear;
    }

    const growth = this.growthAverage(company, years, growthMultiplier);

    const multiple = {
      netProfit: company?.ratios?.yearly
        ?.slice(-years)
        ?.map((r) => r.priceOverEarningsMean)
        ?.mean()
        ?.round(round),
      revenues: company?.ratios?.yearly
        ?.slice(-years)
        ?.map((r) => r.priceOverSalesMean)
        ?.mean()
        ?.round(round),
      bookValue: company?.ratios?.yearly
        ?.slice(-years)
        ?.map((r) => r.priceOverBookMean)
        ?.mean()
        ?.round(round),
      operationalCashFlow: company?.ratios?.yearly
        ?.slice(-years)
        ?.map((r) => r.priceOverCashFlowMean)
        ?.mean()
        ?.round(round)
    };

    const valuation = {
      netProfit: (perShareData?.netProfitPerShare * (growth.netProfit / 100 + 1) * multiple.netProfit).round(round),
      revenues: (perShareData?.revenuePerShare * (growth.revenues / 100 + 1) * multiple.revenues).round(round),
      bookValue: (perShareData?.bookValuePerShare * (growth.bookValue / 100 + 1) * multiple.bookValue).round(round),
      operationalCashFlow: (
        perShareData?.operationalCashFlowPerShare *
        (growth.operationalCashFlow / 100 + 1) *
        multiple.operationalCashFlow
      ).round(round)
    };

    const included = {
      netProfit: valuation.netProfit > 0,
      revenues: valuation.revenues > 0,
      bookValue: valuation.bookValue > 0,
      operationalCashFlow: valuation.operationalCashFlow > 0
    };

    return new CompanyHistoricValuation({
      growth,
      multiple,
      included,
      valuation
    });
  }
}

export { CompanyHistoricValuation };
