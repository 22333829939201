class ExchangeTradingHours {
  constructor({ open = null, close = null, openUtc = null, closeUtc = null, workingDays = null }) {
    this.open = open;
    this.close = close;
    this.openUtc = openUtc;
    this.closeUtc = closeUtc;
    this.workingDays = workingDays;
  }
}

export { ExchangeTradingHours };
