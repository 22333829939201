import Vue from 'vue';
import VueRouter from 'vue-router';
import VueCookie from 'vue-cookie';
import VueGtag from 'vue-gtag';
import Vue2TouchEvents from 'vue2-touch-events';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import axios from 'axios';
import { v4 } from 'uuid';

import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import stockInit from 'highcharts/modules/stock';
import solidGauge from 'highcharts/modules/solid-gauge';

import '../shared/prototypes.js';

import { createRouter, pageNames } from './routes';

import { UsersApi, StockInvestingApi } from './api';

import { EPermissions, User } from '../shared/models/user/index.js';

import { Filter } from '../shared/utils/index.js';
import { config } from '../shared/config.js';

import App from './App.vue';

import './style/index.scss';

const UNAUTHORIZED_HTTP_STATUS_CODE = 401;

const router = createRouter();

// Axios settings + interceptor to login after 401 response
axios.interceptors.response.use(null, (error) => {
  if (error.response?.status === UNAUTHORIZED_HTTP_STATUS_CODE) {
    // Do something?
  }
  return error;
});
axios.defaults.withCredentials = true;

Vue.use(VueCookie);
Vue.use(VueRouter);
Vue.use(Vue2TouchEvents);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(
  VueGtag,
  {
    config: { id: process.env.TRACKING_UA_ID },
    includes: [{ id: process.env.TRACKING_GA4_ID }]
  },
  router
);

stockInit(Highcharts);
highchartsMore(Highcharts);
solidGauge(Highcharts);
Highcharts.setOptions({
  lang: { numericSymbols: ['k', 'M', 'B', 'T', 'P', 'E'] }
});

const store = Vue.observable({
  user: new User({}),
  adminMode: false,
  hasUnsavedChanges: false,
  tests: {},
  hotjarEnabled: false
});

class LazyLoadIntersectionObserver {
  constructor() {
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const id = entry.target.observerId;
          try {
            this.subs[id]();
          } catch (err) {
            console.error(err);
          }
        }
      });
    });

    this.subs = {};
  }

  observe(element, callback) {
    const id = v4();
    element.observerId = id;

    this.subs[id] = callback;

    this.observer.observe(element);
  }

  unobserve(element) {
    const id = element.observerId;
    delete this.subs[id];
    this.observer.unobserve(element);
  }
}
const intersectionObserver = 'IntersectionObserver' in window ? new LazyLoadIntersectionObserver() : null;

Vue.mixin({
  data: () => ({
    get pageNames() {
      return pageNames;
    },
    get store() {
      return store;
    },
    get intersectionObserver() {
      return intersectionObserver;
    },
    get tests() {
      return store.tests;
    },
    Filter,
    UsersApi: new UsersApi(),
    StockInvestingApi: new StockInvestingApi(),
    EPermissions,
    config
  }),
  mounted() {
    // highchart-vue's beforeDestroy function takes too long. This is a workaround to not execute the beforeDestroy function
    setTimeout(() => {
      const attributes = Object.values(this?.$refs?.chart?.attributes || []).map((node) => node.name);
      if (attributes.includes('data-highcharts-chart')) {
        // eslint-disable-next-line no-underscore-dangle
        this._isDestroyed = true;
      }
    });
  },
  methods: {
    setTitle(title, suffix = true) {
      const fullTitle = suffix ? `${title} - Fundamentl` : title;
      document.getElementsByTagName('title')[0].innerHTML = fullTitle;
    },
    ToastSuccess(msg, title = 'Success') {
      this.$bvToast.toast(msg, {
        title,
        variant: 'success',
        toaster: 'b-toaster-bottom-right',
        solid: true,
        toastClass: 'toast toast-success'
      });
    },
    ToastError(msg, title = 'Error') {
      this.$bvToast.toast(msg, {
        title,
        variant: 'danger',
        toaster: 'b-toaster-bottom-right',
        solid: true,
        toastClass: 'toast toast-error'
      });
    },
    updateTests() {
      if (!Object.keys(this?.tests || {}).length) {
        const attrs = Object.values([...window.document.body.attributes]);
        if (attrs?.length) {
          attrs
            .map((attr) => attr.name)
            .forEach((testName) => {
              if (testName.includes('test-')) {
                const simpleTestName = testName.replace('test-', '');
                this.$set(this.store.tests, simpleTestName.kebabToCamel(), this.tests[simpleTestName.kebabToCamel()] || true);
              }
            });
          return true;
        }
      }
      return false;
    }
  }
});

Vue.filter('number', Filter.number);
Vue.filter('hrFinancial', Filter.hrFinancial);
Vue.filter('financial', Filter.financial);
Vue.filter('ratio', Filter.ratio);
Vue.filter('percentage', Filter.percentage);
Vue.filter('date', Filter.date);
Vue.filter('time', Filter.time);
Vue.filter('moment', Filter.moment);
Vue.filter('momentDate', Filter.momentDate);

new Vue({
  el: '#app',
  router,
  render: (createElement) => createElement(App)
});
