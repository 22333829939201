class CompanyValuationMethod {
  constructor({ valuationMethod = null, valuation = null, weight = null }) {
    this.valuationMethod = valuationMethod;
    this.valuation = valuation;
    this.weight = weight;
  }
}

class CompanyValuationSummary {
  constructor({
    _id = null,
    date = null,
    valuation = null,
    adjustedValuation = null,
    comment = null,
    valuationMethods = null,
    userId = null
  }) {
    this._id = _id;
    this.date = date;
    this.valuation = valuation;
    this.adjustedValuation = adjustedValuation;
    this.comment = comment;
    this.userId = userId;
    this.valuationMethods = Object.keys(valuationMethods).map(
      (valuationMethod) => new CompanyValuationMethod({ valuationMethod, ...valuationMethods[valuationMethod] })
    );
  }
}

export { CompanyValuationSummary };
