<template src="./template.vue"></template>
<script>
import Component from 'vue-class-component';
import Vue from 'vue';

@Component({
  name: 'media-net-ad',
  data: () => ({}),
  components: {},
  props: {
    id: {
      type: String,
      default: '792905211'
    },
    size: {
      type: String,
      default: '728x90'
    }
  }
})
class MediaNetAd extends Vue {
  mounted() {
    try {
      // eslint-disable-next-line no-underscore-dangle
      window._mNHandle.queue.push(() => {
        // eslint-disable-next-line no-underscore-dangle
        window._mNDetails.loadTag(this.id, this.size, this.id);
      });
    } catch (err) {
      console.error(err);
    }
  }
}
export default MediaNetAd;
</script>
<style lang="scss" scoped>
@import './style.scss';
</style>
