import { ETime } from '../../shared/enums.js';

const DATA = {
  TIMESTAMP: 0,
  VALUE: 1
};

const serializeChartData = (serie) => {
  const serializedSerie = [];

  serie.sort((a, b) => a[DATA.TIMESTAMP] - b[DATA.TIMESTAMP]);

  for (let i = 0; i < serie.length; i++) {
    serie[i][DATA.TIMESTAMP] = new Date(serie[i][DATA.TIMESTAMP]).removeTime().toUTC();
  }
  for (let i = 1; i < serie.length; i++) {
    const startPoint = serie[i - 1];
    const endPoint = serie[i];
    const days = (endPoint[DATA.TIMESTAMP].getTime() - startPoint[DATA.TIMESTAMP].getTime()) / ETime.milliseconds.perDay;
    const changePerDay = (endPoint[DATA.VALUE] - startPoint[DATA.VALUE]) / days;
    if (days) {
      days.forEach((day) => {
        serializedSerie.push([
          startPoint[DATA.TIMESTAMP].getTime() + day * ETime.milliseconds.perDay,
          (startPoint[DATA.VALUE] + day * changePerDay).round(2)
        ]);
      });
    }
  }
  return serializedSerie;
};

export { serializeChartData };
