<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import { ETime, EIncomeStatement, EThousands, EPlans, EDataTaggingType } from '../../../../../shared/enums.js';

import { CTable, Lazyload, AuthenticateModal, ExportCsvBtn, UpgradeCtaModal } from '../../../../components/index.js';
import PopoverChart from '../../popover-chart.vue';
import UpgradeCta from '../upgrade-cta.vue';
import AddNote from '../add-note.vue';
import { CompanyFinancialsViews } from '../../../../../shared/models/company/CompanyFinancialsViews.js';
import { Company } from '../../../../../shared/models/company/Company.js';
import { ERoles } from '../../../../../shared/models/user/index.js';
import { CompanyDataTag } from '../../../../../shared/models/company/data-tagging/CompanyDataTag.js';

const YEARS_HISTORY = {
  YEARS_10: { periods: 10, label: '10Y' },
  YEARS_20: { periods: 20, label: '20Y' },
  YEARS_ALL: { periods: Infinity, label: 'All' }
};
const DEFAULT_YEARS_HISTORY = YEARS_HISTORY.YEARS_10;

const QUARTERS_HISTORY = {
  YEARS_3: { periods: 12, label: '3Y' },
  YEARS_5: { periods: 20, label: '5Y' },
  YEARS_10: { periods: 40, label: '10Y' },
  YEARS_20: { periods: 80, label: '20Y' },
  YEARS_ALL: { periods: Infinity, label: 'All' }
};
const DEFAULT_QUARTERS_HISTORY = QUARTERS_HISTORY.YEARS_3;

const FILTERS = {
  NUMBER: 'number',
  PERCENTAGE: 'percentage'
};

const VIEW_PERIODS = {
  YEARLY: 'yearly',
  QUARTERLY: 'quarterly'
};

const VIEW_METHODS = {
  ABSOLUTE: 'absolute',
  PERIOD_OVER_PERIOD: 'periodOverPeriod',
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical'
};

const VIEW_ORDER = {
  ASC: 'asc',
  DESC: 'desc'
};

@Component({
  name: 'company-details-income-statement',
  components: { PopoverChart, CTable, Lazyload, AuthenticateModal, UpgradeCta, ExportCsvBtn, UpgradeCtaModal, AddNote },
  data: () => ({ VIEW_PERIODS, VIEW_METHODS, YEARS_HISTORY, QUARTERS_HISTORY, VIEW_ORDER, EIncomeStatement, ETime, ERoles, EPlans }),
  watch: {
    periods() {
      this.prepareViews();
    },
    'view.period': function viewPeriodChange() {
      this.$gtag.event('company_fin_is_viewChange', {
        company_code: this.company?.companyCode,
        view_method: this.view.method,
        view_period: this.view.period,
        view_order: this.view.order
      });
      this.scrollScreen();
    },
    'view.method': function viewMethodChange() {
      this.$gtag.event('company_fin_is_viewChange', {
        company_code: this.company?.companyCode,
        view_method: this.view.method,
        view_period: this.view.period,
        view_order: this.view.order
      });
      this.scrollScreen();
    },
    'view.order': function viewOrderChange() {
      this.$gtag.event('company_fin_is_viewChange', {
        company_code: this.company?.companyCode,
        view_method: this.view.method,
        view_period: this.view.period,
        view_order: this.view.order
      });
      this.prepareViews();
      this.scrollScreen();
    },
    'tests.fsDefaultViewOrder': function fsDefaultViewOrder(val) {
      if (val) {
        this.view.order = VIEW_ORDER.DESC;
      } else {
        this.view.order = VIEW_ORDER.ASC;
      }
    }
  }
})
class CompanyDetailsIncomeStatement extends Vue {
  loaded = false;

  currency = null;
  popoverChartData = null;
  views = null;
  thousands = null;

  yearsHistory = DEFAULT_YEARS_HISTORY.periods;
  quartersHistory = DEFAULT_QUARTERS_HISTORY.periods;

  showAuthenticateModal = false;
  showExportDataUpgradeModal = false;

  view = {
    period: VIEW_PERIODS.YEARLY,
    method: VIEW_METHODS.ABSOLUTE,
    order: VIEW_ORDER.ASC
  };

  filters = {
    absolute: {
      default: FILTERS.NUMBER
    },
    periodOverPeriod: {
      default: FILTERS.PERCENTAGE
    },
    horizontal: {
      default: FILTERS.PERCENTAGE
    },
    vertical: {
      default: FILTERS.PERCENTAGE
    }
  };

  negativeKeys = ['costOfRevenue', 'researchDevelopment', 'sellingGeneralAdministrative', 'incomeTaxExpense', 'totalOperatingExpenses'];

  revenuesKeys = ['costOfRevenue'];

  operatingExpensesAndIncomeKeys = ['researchDevelopment', 'sellingGeneralAdministrative', 'otherOperatingIncome'];

  earningsFromContinuingOperationsKeys = ['incomeTaxExpense', 'otherNetIncomeFromContinuingOps'];

  netIncomeKeys = ['otherItems'];

  otherKeys = [
    'effectOfAccountingCharges',
    'taxProvision',
    'preferredStockAndOtherAdjustments',
    'ebit',
    null,
    'otherOperatingExpenses',
    'interestExpense',
    'minorityInterest',
    'interestIncome',
    'netInterestIncome',
    'nonRecurring',
    'discontinuedOperations',
    'netIncomeApplicableToCommonShares',
    'extraordinaryItems',
    'nonOperatingIncomeNetOther'
  ];

  totalKeys = [
    'incomeBeforeTax',
    'grossProfit',
    'netIncome',
    'operatingIncome',
    'totalRevenue',
    'totalOperatingExpenses',
    'costOfRevenue',
    'totalOtherIncomeExpenseNet',
    'netIncomeFromContinuingOps'
  ];

  get periods() {
    if (this.view.period === VIEW_PERIODS.YEARLY) {
      return this.yearsHistory;
    }
    return this.quartersHistory;
  }

  getValue(key, period) {
    let filter = this.filters[this.view.method].default;
    if (this.filters[this.view.method][key]) {
      filter = this.filters[this.view.method][key];
    }

    const negativeMultiplier = this.negativeKeys.includes(key) ? -1 : 1;
    const value = period[key] ? period[key] * negativeMultiplier : null;

    return Vue.filter(filter)(value);
  }

  get periodsData() {
    return this.views[this.view.period][this.view.method].slice(-this.periods);
  }

  get fiscalYearEndMonth() {
    return new Date(null, this?.company?.general?.fiscalYearEndMonth).toLocaleDateString('en', {
      month: 'short'
    });
  }

  get hasLockedContent() {
    return this.store.user.maxDataHistory < this.yearsHistory;
  }

  async prepareLockedContentContainers(tries = 0) {
    await this.$nextTick();

    try {
      setTimeout(() => {
        this.$refs.contentTable.$el.querySelectorAll('tbody').forEach(async (tbody) => {
          tbody.querySelectorAll('tr').forEach((row) => {
            if (row.querySelector('td')) {
              row.querySelector('td').style.width = null;
            }
          });
        });
      });
      setTimeout(() => {
        this.$refs.contentTable.$el.querySelectorAll('tbody').forEach(async (tbody) => {
          if (!tbody.querySelector('.blur-signup-button')) return;

          // Amount of columns that are content locked
          const colsLocked = this.periodsData.length - this.store.user.maxDataHistory;

          // Set width of content blocker based on table columns
          const cols = [...tbody.querySelector('tr').querySelectorAll('td')];
          const firstColPosition =
            this.view.order === VIEW_ORDER.ASC ? cols.first().offsetLeft : cols[this.store.user.maxDataHistory].offsetLeft;
          const lastColPosition =
            this.view.order === VIEW_ORDER.ASC
              ? cols?.[colsLocked - 1]?.offsetLeft + cols?.[colsLocked - 1]?.clientWidth
              : cols.last().offsetLeft + cols?.last()?.clientWidth;

          if (colsLocked > 1) {
            tbody.querySelector('.blur-signup-button > div').style.width = `${lastColPosition - firstColPosition}px`;
          } else if (colsLocked === 1) {
            let colWidth = 300;
            tbody.querySelectorAll('tr').forEach((row) => {
              const col = row.querySelector('td');
              if (col.clientWidth < colWidth) {
                col.style.width = `${colWidth}px`;
              } else {
                colWidth = row.querySelector('td').clientWidth;
              }
            });
            tbody.querySelector('.blur-signup-button > div').style.width = `${colWidth}px`;
          }

          // Set height of content blocker based on table rows
          const rows = [...tbody.querySelectorAll('tr')];
          const firstRowPosition = rows.first().offsetTop;
          const lastRowPosition = rows.last().offsetTop + rows.last().clientHeight;
          tbody.querySelector('.blur-signup-button > div').style.height = `${lastRowPosition - firstRowPosition}px`;
        });
      }, 1);
    } catch (err) {
      setTimeout(() => {
        if (tries < 3) {
          this.prepareLockedContentContainers(tries + 1);
        }
      }, 1000);
    }
  }

  preparePopoverChartData() {
    const keys = [
      ...this.revenuesKeys,
      ...this.operatingExpensesAndIncomeKeys,
      ...this.earningsFromContinuingOperationsKeys,
      ...this.netIncomeKeys,
      ...this.otherKeys,
      ...this.totalKeys
    ];

    const popoverChartData = {};
    keys.forEach((key) => {
      popoverChartData[key] = this.company.financialStatements.incomeStatements.yearly
        .map((incomeStatement) => ({
          date: new Date(incomeStatement.date).getFiscalPeriod(this.company.general.fiscalYearEndMonth).year.toString(),
          value: incomeStatement[key] || 0
        }))
        .slice(-this.store.user.maxDataHistory);
    });
    this.popoverChartData = popoverChartData;
  }

  async prepareViews() {
    this.thousands =
      EThousands?.[
        Math.floor(
          (Object.values(this.company?.financialStatements?.incomeStatements?.yearly?.last() || {})
            ?.filter((value) => typeof value === typeof Number() && value > 0)
            ?.min()
            ?.parseInt()
            ?.toString()?.length -
            1) /
            3
        )
      ] || null;

    const yearlyViews = new CompanyFinancialsViews({
      statements: this.company.financialStatements.incomeStatements.yearly,
      type: CompanyFinancialsViews.EFinancialStatementType.INCOME_STATEMENT,
      periodsAmount: this.periods,
      thousands: this.thousands
    });

    const quarterlyViews = new CompanyFinancialsViews({
      statements: this.company.financialStatements.incomeStatements.quarterly,
      type: CompanyFinancialsViews.EFinancialStatementType.INCOME_STATEMENT,
      periodsAmount: this.periods,
      thousands: this.thousands
    });

    this.views = {
      yearly: {
        label: 'Yearly',
        totalAmount: yearlyViews.statements.length,
        headers: this.view.order === VIEW_ORDER.ASC ? yearlyViews.dates : yearlyViews.dates.reverse(),
        absolute: this.view.order === VIEW_ORDER.ASC ? yearlyViews.absoluteView : yearlyViews.absoluteView.reverse(),
        periodOverPeriod:
          this.view.order === VIEW_ORDER.ASC ? yearlyViews.periodOverPeriodView : yearlyViews.periodOverPeriodView.reverse(),
        horizontal: this.view.order === VIEW_ORDER.ASC ? yearlyViews.horizontalView : yearlyViews.horizontalView.reverse(),
        vertical: this.view.order === VIEW_ORDER.ASC ? yearlyViews.verticalView : yearlyViews.verticalView.reverse()
      },
      quarterly: {
        label: 'Quarterly',
        totalAmount: quarterlyViews.statements.length,
        headers: this.view.order === VIEW_ORDER.ASC ? quarterlyViews.dates : quarterlyViews.dates.reverse(),
        absolute: this.view.order === VIEW_ORDER.ASC ? quarterlyViews.absoluteView : quarterlyViews.absoluteView.reverse(),
        periodOverPeriod:
          this.view.order === VIEW_ORDER.ASC ? quarterlyViews.periodOverPeriodView : quarterlyViews.periodOverPeriodView.reverse(),
        horizontal: this.view.order === VIEW_ORDER.ASC ? quarterlyViews.horizontalView : quarterlyViews.horizontalView.reverse(),
        vertical: this.view.order === VIEW_ORDER.ASC ? quarterlyViews.verticalView : quarterlyViews.verticalView.reverse()
      }
    };

    this.prepareLockedContentContainers();
    await this.$nextTick();
    this.scrollScreen();
  }

  async handleDataTagUpdate(updatedDataTag) {
    const index = (this?.company?.dataTags || [])?.findIndex((dataTag) => dataTag?._id === updatedDataTag._id);
    if (updatedDataTag.comment && updatedDataTag.comment !== '') {
      if (typeof index === typeof Number() && index >= 0) {
        // Update
        const dataTags = [...this?.company?.dataTags];
        dataTags.splice(index, 1);
        this.company.dataTags = null;
        this.$forceUpdate();
        this.company.dataTags = [...dataTags, updatedDataTag];
      } else {
        // Create
        const dataTags = [...this?.company?.dataTags, updatedDataTag];
        this.$forceUpdate();
        this.company.dataTags = dataTags;
      }
    } else {
      // Delete
      const dataTags = [...this?.company?.dataTags];
      dataTags.splice(index, 1);
      this.company.dataTags = null;
      this.$forceUpdate();
      this.company.dataTags = [...dataTags];
    }
  }

  getDataTag(date, key) {
    const dataTag = this?.company?.dataTags?.find(
      (companyDataTag) =>
        companyDataTag?.dataLocationDetails?.date?.getTime() === new Date(date).getTime() &&
        companyDataTag?.dataLocationDetails?.period === this.view.period &&
        companyDataTag?.dataLocationDetails?.key === key
    );
    return (
      dataTag ||
      new CompanyDataTag({
        userId: this.store.user._id,
        companyCode: this.company.companyCode,
        comment: '',
        dataType: EDataTaggingType.INCOME_STATEMENT,
        dataLocationDetails: { date, period: this.view.period, key }
      })
    );
  }

  async loadCompany() {
    const companyCode = this?.$route?.params?.companyCode;
    this.company = new Company({ companyCode });
    await this.company.load({
      financialStatements: {
        incomeStatements: true
      },
      currencies: true,
      general: true
    });
    await this.company.loadDataTags(EDataTaggingType.INCOME_STATEMENT);
  }

  scrollScreen() {
    // If years are ascending -> scroll all the way to the right
    if (this.view.order === VIEW_ORDER.ASC) {
      window.scroll(window.document.body.scrollWidth, window.pageYOffset);
    } else {
      window.scroll(0, window.pageYOffset);
    }
  }

  async mounted() {
    await this.loadCompany();
    this.setTitle(`${this?.company?.general?.name} - Income statement`);

    this.prepareViews();
    this.preparePopoverChartData();

    this.currency = this.company?.financialStatements?.incomeStatements?.yearly.first().currency;

    this.loaded = true;

    if (this.tests.fsDefaultViewOrder) {
      this.view.order = VIEW_ORDER.DESC;
    }

    this.scrollScreen();
  }
}
export default CompanyDetailsIncomeStatement;
</script>
