<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import { ETime } from '../../../../../shared/enums.js';

import { CompanyScreener } from '../../../../components/index.js';

const POLL_INTERVAL = ETime.milliseconds.perSecond * 2; // 2 seconds

const FAIL_TIMEOUT = ETime.milliseconds.perHour * 3; // 3 hours

const ROLLING_UPDATE_AMOUNT = 10;

@Component({
  name: 'data-manager-status',
  components: { CompanyScreener }
})
class DataManagerStatus extends Vue {
  status = null;
  statusPollInterval = null;
  fetchingStatus = false;
  updates = {};

  async fetchStatus() {
    if (this.fetchingStatus) return;
    try {
      this.fetchingStatus = true;
      this.status = await this.StockInvestingApi.dataManagerStatus();
      this.fetchingStatus = false;
    } catch (err) {
      console.error(err);
      this.fetchingStatus = false;
    }
  }

  calculateFinishTimestamp(statusObject, type, key) {
    this.updates[type + key] = this.updates[type + key] || [];
    if (!this.updates[type + key].last()?.processed || this.updates[type + key].last()?.processed < statusObject?.processed) {
      this.updates[type + key].push(statusObject);
      if (this.updates[type + key]?.length > ROLLING_UPDATE_AMOUNT) {
        this.updates[type + key].splice(0, 1);
      }
    }

    try {
      return new Date(
        Date.now() +
          ((new Date(statusObject.lastUpdate)?.getTime() - new Date(this.updates[type + key].first().lastUpdate)?.getTime()) /
            (statusObject.processed - this.updates[type + key].first().processed)) *
            (statusObject.total - statusObject.processed)
      );
    } catch (err) {
      console.error(err);
      return '-';
    }
  }

  calculatePercentage(status) {
    return (status.processed / status.total) * 100;
  }

  calculateProgressBarVariant(statusObject) {
    if (this.hasFailed(statusObject)) {
      return 'danger';
    }
    if (this.isDone(statusObject)) {
      return 'success';
    }
    return 'warning';
  }

  isDone(statusObject) {
    return !!statusObject.total && statusObject.total === statusObject.processed;
  }

  hasFailed(statusObject) {
    return !this.isDone(statusObject) && Date.now() - new Date(statusObject.lastUpdate).getTime() > FAIL_TIMEOUT;
  }

  camelToSentence(text) {
    const result = text.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  async mounted() {
    await this.fetchStatus();
    this.statusPollInterval = setInterval(this.fetchStatus, POLL_INTERVAL);
  }

  beforeDestroy() {
    clearInterval(this.statusPollInterval);
  }
}
export default DataManagerStatus;
</script>
