<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import { DataManagerStatus } from './tabs/index.js';

@Component({
  name: 'data-manager',
  components: { DataManagerStatus },
  data: () => ({})
})
class DataManager extends Vue {
  mounted() {
    this.setTitle('Data manager');
  }
}
export default DataManager;
</script>
