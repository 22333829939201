<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import { BillingDetails as BillingDetailsForm, Steps } from '../../../components/index.js';

@Component({
  name: 'billing-details',
  components: {
    BillingDetailsForm,
    Steps
  },
  data: () => ({})
})
class BillingDetails extends Vue {
  customer = null;

  async handleSaveSuccess() {
    await this.$router.push({ name: this.pageNames.stockInvesting.UPGRADE.CHECKOUT });
  }

  handleSaveFail(err) {
    if (err?.errors?.length) {
      this.ToastError(err?.errors.first());
    } else {
      this.ToastError('Something went wrong, please try again.');
    }
  }

  async mounted() {
    this.setTitle('Billing details - Upgrade');
    this.$gtag.event('upgrade_step2_pv');

    if (!this.store?.checkoutSession) {
      await this.$router.push({ name: this.pageNames.stockInvesting.UPGRADE.PLANS });
    }

    try {
      const customer = await this.StockInvestingApi.paymentCustomerDetails();
      if (customer?.billingDetails?.address?.country) {
        customer.billingDetails.address.country = customer.billingDetails.address.country.iso;
      }
      this.customer = customer;
    } catch (err) {
      console.error(err);
    }
  }
}
export default BillingDetails;
</script>
<style lang="scss" scoped>
@import './style.scss';
</style>
