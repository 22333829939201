import { ERoles, RoleGuest } from './Role.js';
import { config } from '../../config.js';

const VISIBLE_DATA_HISTORY = {
  [ERoles.guest.id]: 2,
  [ERoles.user.id]: 5,
  [ERoles.packageTier1.id]: 10,
  [ERoles.packageTier2.id]: Infinity,
  [ERoles.packageTier3.id]: Infinity,

  [ERoles.legacy20220831.id]: Infinity
};
export class User {
  constructor({ _id = null, personal = {}, roles = [], stripeId = null, trialEnd = null, preferences = {} }) {
    this._id = _id;
    this.personal = {
      displayName: personal?.displayName || null,
      firstName: personal?.firstName || null,
      lastName: personal?.lastName || null,
      photoUrl: personal?.photoUrl || null,
      email: personal?.email || null
    };
    this.stripeId = stripeId || null;
    this.trialEnd = trialEnd ? new Date(trialEnd) : null;
    this.preferences = preferences || {};

    this.roles = roles.map((role) => ERoles[role] || role).filter((role) => role && typeof role !== typeof String());
    this.roles = this.roles?.length ? this.roles : [RoleGuest];
  }

  hasPermission(permission = []) {
    const userRoleIds = this.roles.map((userRole) => userRole?.id).filter((role) => role);
    const allowedRoleIds = permission.map((allowedRole) => allowedRole.id);

    let hasPermission = false;
    userRoleIds.forEach((userRoleId) => {
      hasPermission = allowedRoleIds.includes(userRoleId) || hasPermission;
    });

    return hasPermission;
  }

  hasRole(role) {
    return this.roles.map((r) => r?.id === role?.id)?.includes(true);
  }

  get isPaidAccount() {
    return this.roles.map((role) => role?.isSubscription)?.includes(true);
  }

  get isLoggedIn() {
    return !!this._id;
  }

  get trialStatus() {
    return {
      isAllowed: !this?.trialEnd?.getTime() || (this?.trialEnd?.getTime() < Date.now() - config.trial.refreshTime && !this.isPaidAccount),
      isInTrial: this?.trialEnd?.getTime() && this?.trialEnd?.getTime() > Date.now()
    };
  }

  get maxDataHistory() {
    let maxVisibleHistory = Object.values(VISIBLE_DATA_HISTORY).min();
    Object.keys(VISIBLE_DATA_HISTORY).forEach((roleKey) => {
      const role = Object.values(ERoles).find((userRole) => userRole.id === roleKey);
      if (this?.hasRole(role) && VISIBLE_DATA_HISTORY[role.id] > maxVisibleHistory) {
        maxVisibleHistory = VISIBLE_DATA_HISTORY[role.id];
      }
    });
    return maxVisibleHistory;
  }
}
