import { CompanyBalanceSheet } from './financial-statements/CompanyBalanceSheet.js';
import { CompanyIncomeStatement } from './financial-statements/CompanyIncomeStatement.js';
import { CompanyCashFlowStatement } from './financial-statements/CompanyCashFlowStatement.js';
import { CompanyRatioStatement } from './ratios/CompanyRatioStatement.js';
import { EThousands } from '../../enums.js';

const EFinancialStatementType = {
  BALANCE_SHEET: 'balanceSheet',
  INCOME_STATEMENT: 'incomeStatement',
  CASH_FLOW_STATEMENT: 'cashFlowStatement',
  RATIOS: 'ratios'
};

const config = {
  [EFinancialStatementType.BALANCE_SHEET]: {
    getVerticalViewDenominatorValue: (statement) => statement.totalAssets,
    class: CompanyBalanceSheet
  },
  [EFinancialStatementType.INCOME_STATEMENT]: {
    getVerticalViewDenominatorValue: (statement) => statement.totalRevenue,
    class: CompanyIncomeStatement
  },
  [EFinancialStatementType.CASH_FLOW_STATEMENT]: {
    getVerticalViewDenominatorValue: () => null,
    class: CompanyCashFlowStatement
  },
  [EFinancialStatementType.RATIOS]: {
    getVerticalViewDenominatorValue: () => null,
    class: CompanyRatioStatement
  }
};

class CompanyFinancialsViews {
  static EFinancialStatementType = EFinancialStatementType;

  constructor({ statements = [], type = null, periodsAmount = null, thousands = EThousands.DEFAULT }) {
    this.type = type;
    this.periodsAmount = periodsAmount;
    this.statements = statements.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    this.thousands = thousands;
  }

  _statementsCopy() {
    return [...this.statements].map((statement) => {
      // eslint-disable-next-line new-cap
      return new config[this.type].class({ ...statement });
    });
  }

  get dates() {
    return this.statements.slice(-this.periodsAmount).map((period) => new Date(period.date));
  }

  get absoluteView() {
    const viewStatements = this._statementsCopy().slice(-this.periodsAmount);

    viewStatements.forEach((statement, index) => {
      Object.keys(statement).forEach((key) => {
        if (typeof statement[key] === typeof Number()) {
          viewStatements[index][key] = statement[key] / this.thousands.divider;
        }
      });
    });
    return viewStatements;
  }

  get verticalView() {
    const viewStatements = this._statementsCopy().slice(-this.periodsAmount);

    viewStatements.forEach((statement, index) => {
      const denominatorValue = config[this.type].getVerticalViewDenominatorValue(statement);
      Object.keys(statement).forEach((key) => {
        if (typeof statement[key] === typeof Number()) {
          viewStatements[index][key] = (statement[key] / denominatorValue) * 100;
        }
      });
    });
    return viewStatements;
  }

  get horizontalView() {
    const viewStatements = this._statementsCopy().slice(-this.periodsAmount);

    let keys = [];
    viewStatements.forEach((viewStatement) => {
      keys = keys.concat(Object.keys(viewStatement));
    });

    keys.distinct().forEach((key) => {
      let firstValue = null;
      viewStatements.forEach((statement) => {
        firstValue = firstValue || statement?.[key] || null;
        if (typeof statement[key] === typeof Number()) {
          statement[key] = (statement[key] / firstValue) * 100 || null;
        }
      });
    });
    return viewStatements;
  }

  get periodOverPeriodView() {
    const viewStatements = this._statementsCopy().slice(-this.periodsAmount);

    let keys = [];
    viewStatements.forEach((viewStatement) => {
      keys = keys.concat(Object.keys(viewStatement));
    });

    keys.distinct().forEach((key) => {
      let lastValue = null;
      viewStatements.forEach((statement, index) => {
        const currentValue = viewStatements[index][key] || null;
        if (lastValue && typeof currentValue === typeof Number()) {
          viewStatements[index][key] = (currentValue / lastValue - 1) * 100;
        } else {
          viewStatements[index][key] = null;
        }
        lastValue = currentValue;
      });
    });
    return viewStatements;
  }
}

export { CompanyFinancialsViews };
