import { StockInvestingApi } from '../../../frontend/api/index.js';

const stockInvestingApi = new StockInvestingApi();

class Workspace {
  constructor({ companies = [] }) {
    this.companies = companies;
  }

  async load() {
    const response = await stockInvestingApi.workspaceGet();
    this.companies = response || [];
  }

  async add(companyCode) {
    try {
      this.companies.push(companyCode);
      await stockInvestingApi.workspaceCreate({ companyCode });
    } catch (err) {
      this.companies.splice(this.companies.indexOf(companyCode), 1);
      console.error(err);
    }
  }

  async delete(companyCode) {
    try {
      this.companies.splice(this.companies.indexOf(companyCode), 1);
      await stockInvestingApi.workspaceDelete({ companyCode });
    } catch (err) {
      this.companies.push(companyCode);
      console.error(err);
    }
  }
}

export { Workspace };
