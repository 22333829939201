<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'multi-select-dropdown',
  props: {
    items: {
      type: Array,
      required: true
    },
    value: {
      type: Array, // Array of objects -> { label, value }
      default: []
    },
    size: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    itemName: {
      type: String,
      default: 'item(s)'
    }
  },
  data: () => ({}),
  watch: {
    value(values) {
      if (values) {
        for (let i = 0; i < values.length; i++) {
          const value = values[i];
          if (!this.selectedItems.includes(value)) {
            this.selectedItems.push(value);
          }
        }
      }
    }
  }
})
class MultiSelectDropdown extends Vue {
  currentValue = null;
  selectedItems = [];

  get selectedItemsCount() {
    return this.selectedItems.length || this.items.length;
  }

  handleItemClick(item) {
    this.currentValue = null;
    if (this.selectedItems.map((selectedItem) => selectedItem.label).includes(item.label)) {
      const itemIndex = this.selectedItems.indexOf(item);
      this.selectedItems.splice(itemIndex, 1);
    } else {
      this.selectedItems.push(item);
    }
  }

  handleSelectAll() {
    this.selectedItems = [...this.items];
  }

  handleDeselectAll() {
    this.selectedItems = [];
  }

  handleDropdownClose() {
    this.$emit('input', this.selectedItems);
    this.$emit('change', this.selectedItems);
  }

  mounted() {
    this.selectedItems = this.value || [];
  }
}
export default MultiSelectDropdown;
</script>
