<template>
  <div
    :id="`note-popover-container-${_uid}`"
    class="add-note align-items-center d-flex"
    :class="{ 'has-note': dataTag && dataTag.comment && dataTag.comment.length }"
  >
    <b-icon-chat-left-text-fill
      :id="`note-popover-${_uid}`"
      v-b-tooltip.bottomleft="isPopoverVisible ? '' : tooltipComment"
      tabindex="0"
      :class="{
        'position-absolute text-transparent': saving,
        'text-primary': dataTag && dataTag.comment && dataTag.comment.length && !saving
      }"
    />
    <b-spinner v-show="saving" small variant="light" />
    <b-popover
      :container="`note-popover-container-${_uid}`"
      :target="`note-popover-${_uid}`"
      triggers="focus"
      placement="bottomleft"
      @hide="handlePopoverHide"
      @show="handlePopoverShow"
    >
      <b-textarea
        autofocus
        :value="dataTag.comment"
        placeholder="Insert note here..."
        :rows="3"
        :max-rows="6"
        maxlength="512"
        @input="handleNoteInput"
      ></b-textarea>
    </b-popover>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { ERoles } from '../../../../shared/models/user/index.js';

import { AuthenticateModal } from '../../../components/index.js';
import { CompanyDataTag } from '../../../../shared/models/company/data-tagging/CompanyDataTag.js';

@Component({
  name: 'add-note',
  components: { AuthenticateModal },
  props: {
    dataTag: {
      typeof: Object,
      required: true
    }
  },
  data: () => ({
    ERoles
  })
})
class AddNote extends Vue {
  exists = false;
  lastAction = null;
  lastNotificationAction = null;
  debounceTimeout = null;
  saving = false;
  notificationInterval = null;
  isPopoverVisible = false;

  get tooltipComment() {
    const tooltipMaxLength = 50;

    if (this.isPopoverVisible) return null;

    if (this?.dataTag?.comment?.length > tooltipMaxLength) {
      return `${this?.dataTag?.comment?.slice(0, 50)}...` || null;
    }
    return this?.dataTag?.comment || null;
  }

  async handleNoteInput(value) {
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(async () => {
      // Delete
      if (value === '' && this.exists) {
        try {
          this.saving = true;
          await this.StockInvestingApi.dataTaggingDelete({ _id: this.dataTag._id });
          this.$emit('update', new CompanyDataTag({ ...this.dataTag, comment: value }));
          this.exists = false;
          this.lastAction = 'deleted';
        } catch (err) {
          console.error(err);
        }
      }
      // Update
      if (value !== '' && this.exists) {
        try {
          this.saving = true;
          const updatedDataTag = await this.StockInvestingApi.dataTaggingUpdate({ _id: this.dataTag._id, comment: value });
          this.$emit('update', new CompanyDataTag(updatedDataTag));
          this.exists = true;
          this.lastAction = 'updated';
        } catch (err) {
          console.error(err);
        }
      }
      // Create
      if (value !== '' && !this.exists) {
        try {
          this.saving = true;
          const createdDataTag = await this.StockInvestingApi.dataTaggingCreate({
            companyCode: this.dataTag.companyCode,
            comment: value,
            dataType: this.dataTag.dataType,
            dataLocationDetails: this.dataTag.dataLocationDetails
          });
          this.$emit('update', new CompanyDataTag(createdDataTag));
          this.exists = true;
          this.lastAction = 'created';
        } catch (err) {
          console.error(err);
        }
      }

      this.saving = false;
    }, 300);
  }

  mounted() {
    this.exists = !!this?.dataTag?._id;
  }

  handlePopoverHide() {
    this.isPopoverVisible = false;
    this.notificationInterval = setInterval(() => {
      if (!this.saving) {
        if (this.lastAction && this.lastAction !== this.lastNotificationAction) {
          this.lastNotificationAction = this.lastAction;
          this.ToastSuccess(`Note was ${this.lastAction}`);
        }
        clearInterval(this.notificationInterval);
      }
    }, 100);
  }

  handlePopoverShow() {
    this.isPopoverVisible = true;
  }
}
export default AddNote;
</script>

<style lang="scss">
@import '../../../style/variables.scss';

.add-note {
  svg,
  .icon {
    color: $secondary;
    cursor: pointer;

    &:hover,
    &:focus {
      color: $gray-300;
      outline: none;
    }
  }

  .text-transparent {
    color: transparent;
  }

  .popover-body {
    padding: 0 !important;
    overflow: hidden;
  }

  .popover .arrow:after {
    border-bottom-color: $gray-600;
  }

  textarea {
    font-size: 13px;
    font-weight: normal;
    min-width: 200px;
    border-radius: $border-radius;
  }
}
</style>
