import { Currency } from '../../currency/index.js';

class CompanyCashFlowStatement {
  constructor({
    date = null,
    filingDate = null,
    currency = null,
    beginPeriodCashFlow = null,
    capitalExpenditures = null,
    cashAndCashEquivalentsChanges = null,
    cashFlowsOtherOperating = null,
    changeInCash = null,
    changeReceivables = null,
    changeToAccountReceivables = null,
    changeToInventory = null,
    changeToLiabilities = null,
    changeToNetIncome = null,
    changeToOperatingActivities = null,
    depreciation = null,
    dividendsPaid = null,
    endPeriodCashFlow = null,
    investments = null,
    netBorrowings = null,
    netIncome = null,
    otherCashFlowFromFinancingActivities = null,
    salePurchaseOfStock = null,
    totalCashFlowFromInvestingActivities = null,
    totalCashFromFinancingActivities = null,
    totalCashFromOperatingActivities = null
  }) {
    this.date = date;
    this.filingDate = filingDate;
    this.currency = new Currency(currency);

    this.beginPeriodCashFlow = beginPeriodCashFlow;
    this.capitalExpenditures = capitalExpenditures;
    this.cashAndCashEquivalentsChanges = cashAndCashEquivalentsChanges;
    this.cashFlowsOtherOperating = cashFlowsOtherOperating;
    this.changeInCash = changeInCash;
    this.changeReceivables = changeReceivables;
    this.changeToAccountReceivables = changeToAccountReceivables;
    this.changeToInventory = changeToInventory;
    this.changeToLiabilities = changeToLiabilities;
    this.changeToNetIncome = changeToNetIncome;
    this.changeToOperatingActivities = changeToOperatingActivities;
    this.depreciation = depreciation;
    this.dividendsPaid = dividendsPaid;
    this.endPeriodCashFlow = endPeriodCashFlow;
    this.investments = investments;
    this.netBorrowings = netBorrowings;
    this.netIncome = netIncome;
    this.otherCashFlowFromFinancingActivities = otherCashFlowFromFinancingActivities;
    this.salePurchaseOfStock = salePurchaseOfStock;
    this.totalCashFlowFromInvestingActivities = totalCashFlowFromInvestingActivities;
    this.totalCashFromFinancingActivities = totalCashFromFinancingActivities;
    this.totalCashFromOperatingActivities = totalCashFromOperatingActivities;

    this._calculate();
  }

  _calculate() {
    this.otherCashFromOperatingActivities =
      this.totalCashFromOperatingActivities -
      (this.netIncome +
        this.depreciation +
        this.changeToAccountReceivables +
        this.changeToInventory +
        this.changeToLiabilities +
        this.changeReceivables);
    this.otherCashFlowFromInvestingActivities = this.totalCashFlowFromInvestingActivities - this.capitalExpenditures * -1;
    this.otherCashFromFinancingActivities =
      this.totalCashFromFinancingActivities - (this.netBorrowings + this.dividendsPaid + this.salePurchaseOfStock);
    this.changeInCashWithoutExchangeRateChanges =
      this.totalCashFromOperatingActivities + this.totalCashFlowFromInvestingActivities + this.totalCashFromFinancingActivities;
    this.exchangeRateChanges = this.changeInCash - this.changeInCashWithoutExchangeRateChanges;
  }
}

export { CompanyCashFlowStatement };
