<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Chart } from 'highcharts-vue';

import { Company } from '../../../../../shared/models/index.js';

@Component({
  name: 'company-details-general-data',
  components: { Chart },
  data: () => ({})
})
class CompanyDetailsGeneralData extends Vue {
  loaded = false;
  company = null;

  logoAvgColor = 'light';

  async loadCompany() {
    const companyCode = this?.$route?.params?.companyCode;
    this.company = new Company({ companyCode });
    await this.company.load({ general: true, calculated: true, currencies: true, shares: { outstanding: true } });
  }

  getAverageLogoColor(src) {
    if (src) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      if (typeof src === 'string') {
        const img = new Image();
        img.setAttribute('crossOrigin', '');
        img.src = src;
        img.onload = () => {
          context.imageSmoothingEnabled = true;
          context.drawImage(img, 0, 0, img.width, img.height, 0, 0, 150, (img.height / img.width) * 150);

          const imageData = [...context.getImageData(1, 1, canvas.width, canvas.height).data];
          const colors = [];
          for (let i = imageData.length - 1; i >= 0; i -= 4) {
            const color = imageData.splice(i - 3, 4);

            // If pixel's opacity is zero, then do not include the color
            if (imageData[i] !== 0) {
              colors.push(color.slice(0, 3).mean());
            }
          }

          this.logoAvgColor = colors.filter((color) => color || color === 0).mean() > 100 ? 'light' : 'dark';
        };
      }
    }
  }

  async mounted() {
    await this.loadCompany();
    this.setTitle(`${this?.company?.general?.name} - General information`);

    this.getAverageLogoColor(this.company?.general?.logoUrl);
  }
}
export default CompanyDetailsGeneralData;
</script>
