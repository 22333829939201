import { Currency } from '../../currency/index.js';

class CompanyIncomeStatement {
  constructor({
    date = null,
    filingDate = null,
    currency = null,
    costOfRevenue = null,
    discontinuedOperations = null,
    ebit = null,
    effectOfAccountingCharges = null,
    extraordinaryItems = null,
    grossProfit = null,
    incomeBeforeTax = null,
    incomeTaxExpense = null,
    interestExpense = null,
    interestIncome = null,
    minorityInterest = null,
    netIncome = null,
    netIncomeApplicableToCommonShares = null,
    netIncomeFromContinuingOps = null,
    netInterestIncome = null,
    nonOperatingIncomeNetOther = null,
    nonRecurring = null,
    operatingIncome = null,
    otherItems = null,
    otherOperatingExpenses = null,
    preferredStockAndOtherAdjustments = null,
    researchDevelopment = null,
    sellingGeneralAdministrative = null,
    taxProvision = null,
    totalOperatingExpenses = null,
    totalOtherIncomeExpenseNet = null,
    totalRevenue = null
  }) {
    this.date = date;
    this.filingDate = filingDate;
    this.currency = new Currency(currency);

    this.costOfRevenue = costOfRevenue;
    this.discontinuedOperations = discontinuedOperations;
    this.ebit = ebit;
    this.effectOfAccountingCharges = effectOfAccountingCharges;
    this.extraordinaryItems = extraordinaryItems;
    this.grossProfit = grossProfit;
    this.incomeBeforeTax = incomeBeforeTax;
    this.incomeTaxExpense = incomeTaxExpense;
    this.interestExpense = interestExpense;
    this.interestIncome = interestIncome;
    this.minorityInterest = minorityInterest;
    this.netIncome = netIncome;
    this.netIncomeApplicableToCommonShares = netIncomeApplicableToCommonShares;
    this.netIncomeFromContinuingOps = netIncomeFromContinuingOps;
    this.netInterestIncome = netInterestIncome;
    this.nonOperatingIncomeNetOther = nonOperatingIncomeNetOther;
    this.nonRecurring = nonRecurring;
    this.operatingIncome = operatingIncome;
    this.otherItems = otherItems;
    this.otherOperatingExpenses = otherOperatingExpenses;
    this.preferredStockAndOtherAdjustments = preferredStockAndOtherAdjustments;
    this.researchDevelopment = researchDevelopment;
    this.sellingGeneralAdministrative = sellingGeneralAdministrative;
    this.taxProvision = taxProvision;
    this.totalOperatingExpenses = totalOperatingExpenses;
    this.totalOtherIncomeExpenseNet = totalOtherIncomeExpenseNet;
    this.totalRevenue = totalRevenue;

    this._calculate();
  }

  _calculate() {
    this.otherOperatingIncome =
      this.totalRevenue - this.operatingIncome - this.costOfRevenue - this.researchDevelopment - this.sellingGeneralAdministrative || null;
    this.otherNetIncomeFromContinuingOps =
      -(
        this.totalRevenue -
        this.netIncomeFromContinuingOps -
        this.costOfRevenue -
        this.researchDevelopment -
        this.sellingGeneralAdministrative -
        this.incomeTaxExpense
      ) || null;
  }
}

export { CompanyIncomeStatement };
