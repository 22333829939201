class CompanyHolder {
  constructor({
    name = null,
    date = null,
    currentShares = null,
    currentSharesPct = null,
    sharesChange = null,
    sharesChangePct = null,
    totalAssetsPct = null
  }) {
    this.name = name;
    this.date = new Date(date);
    this.currentShares = currentShares;
    this.currentSharesPct = currentSharesPct;
    this.sharesChange = sharesChange;
    this.sharesChangePct = sharesChangePct;
    this.totalAssetsPct = totalAssetsPct;
  }
}

export { CompanyHolder };
