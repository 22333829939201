<template>
  <div class="d-flex justify-content-center align-items-center flex-column">
    <AuthenticateModal v-model="showAuthenticateModal" />
    <template v-if="store.user.hasRole(ERoles.guest)">
      <div class="d-flex flex-column align-items-center justify-content-center">
        <span class="ml-2 go-upgrade-text font-weight-normal text-body"> Access 5 years of data <em class="underlined">for free</em> </span>
        <b-button variant="warning" class="mt-1" @click="showAuthenticateModal = true">
          <b-icon-lock-fill :scale="0.9" class="mr-1 pl-0" />
          Sign in
        </b-button>
      </div>
    </template>
    <template v-else>
      <span class="go-upgrade-text text-body">
        Access <em class="underlined colored">10 years</em> of data for just $2<small class="cta-per-month">/month</small>
      </span>
      <b-button
        variant="warning"
        class="go-upgrade-button align-items-center d-flex mt-2"
        :to="{ name: pageNames.stockInvesting.UPGRADE.PLANS }"
      >
        <template v-if="store.user.trialStatus.isAllowed">
          <div class="d-inline-block">Start free {{ config.trial.duration.days }}-day trial</div>
          <b-icon-arrow-right :scale="0.9" class="ml-1 pl-0" />
        </template>
        <template v-else>
          <b-icon-unlock-fill :scale="0.9" class="mr-1 pl-0" />
          <div class="d-inline-block">Unlock</div>
        </template>
      </b-button>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { ERoles } from '../../../../shared/models/user/index.js';

import { AuthenticateModal } from '../../../components/index.js';

@Component({
  name: 'upgrade-cta',
  components: { AuthenticateModal },
  data: () => ({
    ERoles
  })
})
class UpgradeCta extends Vue {
  showAuthenticateModal = false;
}
export default UpgradeCta;
</script>

<style lang="scss" scoped>
@import '../../../style/variables.scss';

.go-upgrade-text {
  em {
    font-style: normal;
  }
  em.underlined {
    border-bottom: solid 2px $warning;
  }
  em.colored {
    color: $white !important;
  }
  .cta-per-month {
    font-size: 0.7em;
  }
}

.go-upgrade-button {
  text-transform: initial;
}
</style>
