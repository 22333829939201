import Vue from 'vue';
import VueRouter from 'vue-router';
import Hotjar from 'vue-hotjar';

import jwt from 'jsonwebtoken';
import pages from './pages/index.js';
import { User } from '../shared/models/user/User.js';

const pageNames = {
  UNKNOWN: null,
  stockInvesting: {
    HOMEPAGE: 'homepage',
    MY_ACCOUNT: 'my-account',
    UPGRADE: {
      DEFAULT: 'upgrade',
      PLANS: 'upgrade-plans',
      BILLING_DETAILS: 'upgrade-billing-details',
      CHECKOUT: 'upgrade-checkout',
      SUCCESS: 'upgrade-success'
    },
    MY_PORTFOLIO: 'my-portfolio',
    STOCK_SCREENER: 'stock-screener',
    STOCK_COMPARISON: 'stock-comparison',
    COMPANY_DETAILS: {
      DEFAULT: 'company-details',
      GENERAL: 'company-details-general',
      SHARE_DATA_CHART: 'company-details-share-data-chart',
      SHARE_DATA_SUMMARY: 'company-details-share-data-summary',
      FINANCIALS_BALANCE_SHEET: 'company-details-financials-balance-sheet',
      FINANCIALS_INCOME_STATEMENT: 'company-details-financials-income-statement',
      FINANCIALS_CASH_FLOW_STATEMENT: 'company-details-financials-cash-flow-statement',
      FINANCIALS_RATIOS: 'company-details-financials-ratios',
      VALUATION_HISTORIC_VALUATION: 'company-details-valuation-historic-valuation',
      VALUATION_DCF: 'company-details-valuation-dcf',
      VALUATION_FUNDAMENTL_VALUATION: 'company-details-valuation-fundamentl-valuation',
      VALUATION_SUMMARY: 'company-details-valuation-summary',
      COMPETITORS: 'company-details-competitors',
      ANALYST_RATINGS: 'company-details-analyst-ratings',
      MANAGEMENT: 'company-details-management',
      OWNERSHIP: 'company-details-ownership',
      INSIDER_TRANSACTIONS: 'company-details-insider-transactions',
      ESG: 'company-details-esg'
    },
    VISUALIZER: 'visualizer',
    WORKSPACE: 'workspace',
    DATA_MANAGER: 'data-manager',
    FORMULAS: 'formulas',
    FEATURES: 'features'
  }
};

const routes = [
  { path: '/', component: pages.Homepage, name: pageNames.stockInvesting.HOMEPAGE },
  { path: '/my-account', component: pages.MyAccount, name: pageNames.stockInvesting.MY_ACCOUNT },
  {
    path: '/upgrade',
    redirect: { name: pageNames.stockInvesting.UPGRADE.PLANS },
    name: pageNames.stockInvesting.UPGRADE,
    component: pages.Upgrade.Default,
    children: [
      { path: 'plans', component: pages.Upgrade.Plans, name: pageNames.stockInvesting.UPGRADE.PLANS, meta: { mode: 'focus' } },
      {
        path: 'billing-details',
        component: pages.Upgrade.BillingDetails,
        name: pageNames.stockInvesting.UPGRADE.BILLING_DETAILS,
        meta: { mode: 'focus' }
      },
      {
        path: 'checkout',
        component: pages.Upgrade.Checkout,
        name: pageNames.stockInvesting.UPGRADE.CHECKOUT,
        meta: { mode: 'focus' }
      },
      {
        path: 'success',
        component: pages.Upgrade.Success,
        name: pageNames.stockInvesting.UPGRADE.SUCCESS
      }
    ]
  },
  { path: '/my-portfolio', component: pages.Portfolios, name: pageNames.stockInvesting.MY_PORTFOLIO },
  { path: '/stock-screener', component: pages.StockScreener, name: pageNames.stockInvesting.STOCK_SCREENER },
  { path: '/stock-comparison', component: pages.StockComparison, name: pageNames.stockInvesting.STOCK_COMPARISON },
  {
    path: '/company/:companyCode',
    redirect: { name: pageNames.stockInvesting.COMPANY_DETAILS.GENERAL },
    component: pages.CompanyDetails.CompanyDetails,
    name: pageNames.stockInvesting.COMPANY_DETAILS.DEFAULT,
    children: [
      {
        path: 'general',
        component: pages.CompanyDetails.CompanyDetailsGeneralData,
        name: pageNames.stockInvesting.COMPANY_DETAILS.GENERAL
      },
      {
        path: 'competitors',
        component: pages.CompanyDetails.CompanyDetailsCompetitors,
        name: pageNames.stockInvesting.COMPANY_DETAILS.COMPETITORS
      },
      {
        path: 'share-data',
        redirect: { name: pageNames.stockInvesting.COMPANY_DETAILS.SHARE_DATA_CHART }
      },
      {
        path: 'share-data/chart',
        component: pages.CompanyDetails.CompanyDetailsShareChart,
        name: pageNames.stockInvesting.COMPANY_DETAILS.SHARE_DATA_CHART
      },
      {
        path: 'share-data/summary',
        component: pages.CompanyDetails.CompanyDetailsShareSummary,
        name: pageNames.stockInvesting.COMPANY_DETAILS.SHARE_DATA_SUMMARY
      },
      {
        path: 'financials',
        redirect: { name: pageNames.stockInvesting.COMPANY_DETAILS.FINANCIALS_BALANCE_SHEET }
      },
      {
        path: 'financials/balance-sheet',
        component: pages.CompanyDetails.CompanyDetailsBalanceSheet,
        name: pageNames.stockInvesting.COMPANY_DETAILS.FINANCIALS_BALANCE_SHEET
      },
      {
        path: 'financials/income-statement',
        component: pages.CompanyDetails.CompanyDetailsIncomeStatement,
        name: pageNames.stockInvesting.COMPANY_DETAILS.FINANCIALS_INCOME_STATEMENT
      },
      {
        path: 'financials/cash-flow-statement',
        component: pages.CompanyDetails.CompanyDetailsCashFlowStatement,
        name: pageNames.stockInvesting.COMPANY_DETAILS.FINANCIALS_CASH_FLOW_STATEMENT
      },
      {
        path: 'financials/ratios',
        component: pages.CompanyDetails.CompanyDetailsRatios,
        name: pageNames.stockInvesting.COMPANY_DETAILS.FINANCIALS_RATIOS
      },
      {
        path: 'valuation',
        redirect: { name: pageNames.stockInvesting.COMPANY_DETAILS.VALUATION_SUMMARY }
      },
      {
        path: 'valuation/historic-valuation',
        component: pages.CompanyDetails.CompanyDetailsHistoricValuation,
        name: pageNames.stockInvesting.COMPANY_DETAILS.VALUATION_HISTORIC_VALUATION
      },
      {
        path: 'valuation/dcf',
        component: pages.CompanyDetails.CompanyDetailsDcf,
        name: pageNames.stockInvesting.COMPANY_DETAILS.VALUATION_DCF
      },
      {
        path: 'valuation/fundamentl-valuation',
        component: pages.CompanyDetails.CompanyDetailsFundamentlValuation,
        name: pageNames.stockInvesting.COMPANY_DETAILS.VALUATION_FUNDAMENTL_VALUATION
      },
      {
        path: 'valuation/summary',
        component: pages.CompanyDetails.CompanyDetailsValuationSummary,
        name: pageNames.stockInvesting.COMPANY_DETAILS.VALUATION_SUMMARY
      },
      {
        path: 'analyst-ratings',
        component: pages.CompanyDetails.CompanyDetailsAnalystRatings,
        name: pageNames.stockInvesting.COMPANY_DETAILS.ANALYST_RATINGS
      },
      {
        path: 'management',
        component: pages.CompanyDetails.CompanyDetailsManagement,
        name: pageNames.stockInvesting.COMPANY_DETAILS.MANAGEMENT
      },
      {
        path: 'ownership',
        component: pages.CompanyDetails.CompanyDetailsOwnership,
        name: pageNames.stockInvesting.COMPANY_DETAILS.OWNERSHIP
      },
      {
        path: 'insider-transactions',
        component: pages.CompanyDetails.CompanyDetailsInsiderTransactions,
        name: pageNames.stockInvesting.COMPANY_DETAILS.INSIDER_TRANSACTIONS
      },
      {
        path: 'esg',
        component: pages.CompanyDetails.CompanyDetailsEsg,
        name: pageNames.stockInvesting.COMPANY_DETAILS.ESG
      }
    ]
  },
  { path: '/visualizer', component: pages.Visualizer, name: pageNames.stockInvesting.VISUALIZER },
  { path: '/workspace', component: pages.Workspace, name: pageNames.stockInvesting.WORKSPACE },
  { path: '/features', component: pages.Features, name: pageNames.stockInvesting.FEATURES },
  { path: '/data-manager', component: pages.DataManager, name: pageNames.stockInvesting.DATA_MANAGER },
  { path: '/formulas', component: pages.Formulas, name: pageNames.stockInvesting.FORMULAS }
];

const createRouter = () => {
  const moduleRoutes = [];

  routes.forEach((module) => {
    let path = `${module.name}`;
    if (module.path) path = `${module.path}`;
    moduleRoutes.push({ path, ...module });
  });

  moduleRoutes.push({
    path: '*',
    redirect: '/'
  });

  const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes: moduleRoutes
  });

  router.beforeEach(async (to, from, next) => {
    if (router?.app?.store?.hasUnsavedChanges) {
      if (!window.confirm('Do you really want to leave? You have unsaved changes!')) {
        return next(false);
      }
      router.app.store.hasUnsavedChanges = false;
    }

    document.getElementsByTagName('title')[0].innerHTML = '';
    if (router?.app?.store?.user || router?.app?.$cookie.get(process.env.JWT_TOKEN_NAME)) {
      try {
        if (process?.env?.NODE_ENV === 'development' && router?.app?.store?.user?.rolesManipulated) {
          // Piece of code used to be able to change roles via dev-tools
          const cookieRoles = jwt.decode(router?.app.$cookie.get(process.env.JWT_TOKEN_NAME)).roles;
          const storeUserRoles = router.app.store.user.roles;

          await router?.app?.StockInvestingApi?.authCheck({});

          const cookie = jwt.decode(router?.app.$cookie.get(process.env.JWT_TOKEN_NAME));
          const user = new User(jwt.decode(router?.app.$cookie.get(process.env.JWT_TOKEN_NAME)));
          user.roles = storeUserRoles;
          cookie.roles = cookieRoles;

          router.app.store.user = user;
          this.$cookie.set(process.env.JWT_TOKEN_NAME, jwt.sign(cookie, 'notsecret'), {
            expires: new Date(cookie.exp * 1000),
            path: '/',
            domain: '.fundamentl.io',
            sameSite: 'Lax'
          });
        } else {
          // Regular code for users
          await router?.app?.StockInvestingApi?.authCheck({});
          router.app.store.user = new User(jwt.decode(router?.app.$cookie.get(process.env.JWT_TOKEN_NAME)));
        }

        if (!router.app.store.hotjarEnabled) {
          router.app.store.hotjarEnabled = true;
          Vue.use(Hotjar, {
            id: process.env.TRACKING_HOTJAR_ID,
            isProduction: process.env.ENV === 'prd'
          });
        }
      } catch (err) {
        console.error(err);
      }
    }
    return next();
  });

  return router;
};

export { createRouter, pageNames };
