<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import { EBalanceSheet, EIncomeStatement, ECashFlowStatement, ERatios, EPlans } from '../../../../../shared/enums.js';

import { CTable, SignUpModal, LoginModal, UpgradeCtaModal, Formula } from '../../../../components/index.js';
import { Company } from '../../../../../shared/models/company/Company.js';
import { EPermissions, ERoles } from '../../../../../shared/models/user/index.js';
import PopoverChart from '../../popover-chart.vue';

const LAST_X_YEARS = 4;
const NEXT_X_YEARS = 5;

@Component({
  name: 'company-details-dcf',
  components: { CTable, SignUpModal, LoginModal, UpgradeCtaModal, PopoverChart, Formula },
  watch: {
    source() {
      this.reloadTab();
    }
  },
  data: () => ({ EBalanceSheet, EIncomeStatement, ECashFlowStatement, ERatios, EPlans, ERoles, LAST_X_YEARS, NEXT_X_YEARS })
})
class CompanyDetailsDcf extends Vue {
  company = null;
  loaded = false;

  showUpgradeModal = false;

  dcfValuationYear = null;
  dcfValuations = {};

  saving = false;
  loadingNewYear = false;

  operatingData = null;
  balanceSheetData = null;
  waccData = null;
  terminalValueData = null;
  costOfCapitalData = null;
  taxRateData = null;
  costOfDebtData = null;
  enterpriseValueData = null;

  updateTrigger = {
    operatingData: true,
    balanceSheetData: true,
    cashFlowBuildup: true,
    wacc: true,
    costOfCapital: true,
    terminalValue: true
  };

  popoverChartData = null;

  loginModalVisible = false;
  signUpModalVisible = false;

  get lastYears() {
    const lastYears = [];
    for (let i = LAST_X_YEARS; i > 0; i--) {
      lastYears.push(this.dcfValuationYear - i);
    }
    return lastYears;
  }

  get nextYears() {
    const nextYears = [];
    for (let i = 0; i < this.NEXT_X_YEARS; i++) {
      nextYears.push(this.dcfValuationYear + i);
    }
    return nextYears;
  }

  prepareOperatingData() {
    const incomeStatements = this.lastYears.map((year) =>
      this.company.financialStatements.incomeStatements.yearly.find((is) => new Date(is.date).getFullYear() === year)
    );
    const cashFlowStatements = this.lastYears.map((year) =>
      this.company.financialStatements.cashFlowStatements.yearly.find((cf) => new Date(cf.date).getFullYear() === year)
    );
    const ratios = this.lastYears.map((year) =>
      this.company.ratios.yearly.find((ratiosPeriod) => new Date(ratiosPeriod.date).getFullYear() === year)
    );

    // Revenue
    const revenue = incomeStatements.map((is) => is?.totalRevenue || null);
    const revenueGrowth = revenue.pctChange();
    const revenueGrowthAvg = revenueGrowth.mean();

    // Ebitda
    const ebitda = ratios.map((ratiosPeriod) => ratiosPeriod?.ebitda || null);
    const ebitdaMargin = this.lastYears.map((year, index) => (ebitda[index] / revenue[index]) * 100);
    const ebitdaMarginAvg = ebitdaMargin.mean();

    // Ebit
    const ebit = incomeStatements.map((is) => is?.ebit || null);
    const ebitMargin = ebit.map((value, index) => (ebit[index] / revenue[index]) * 100);
    const ebitMarginAvg = ebitMargin.mean();

    // Depreciation // @TODO: Make sure that cashFlowStatementsYearly.$.depreciation is the correct variable here
    const depreciation = cashFlowStatements.map((cf) => cf?.depreciation || null);
    const depreciationMargin = this.lastYears.map((year, index) => (depreciation[index] / revenue[index]) * 100);
    const depreciationMarginAvg = depreciationMargin.mean();

    this.operatingData = {
      revenue,
      revenueGrowth,
      revenueGrowthAvg,
      ebitda,
      ebitdaMargin,
      ebitdaMarginAvg,
      ebit,
      ebitMargin,
      ebitMarginAvg,
      depreciation,
      depreciationMargin,
      depreciationMarginAvg
    };
  }

  async updateOperatingDataForm(key, index, value) {
    this.dcfValuations[this.dcfValuationYear].operatingData[key][index].pct = parseFloat(value);
    this.updateTrigger.operatingData = !this.updateTrigger.operatingData;
  }

  get projectedOperatingData() {
    const dcf = this.dcfValuations[this.dcfValuationYear];

    // Revenue
    const revenue = [];
    for (let i = 0; i < this.nextYears.length; i++) {
      const year = this.nextYears[i];
      const growth = dcf.operatingData.revenueGrowth.find((data) => year === data.year).pct;
      const revenueLastYear = i === 0 ? this.operatingData.revenue.last() : revenue[i - 1];
      revenue.push(revenueLastYear * (growth / 100 + 1));
    }

    // Ebit
    const ebit = this.nextYears.map(
      (year, index) => revenue[index] * (dcf.operatingData.ebitMargin.find((data) => year === data.year).pct / 100)
    );

    // Depreciation
    const depreciation = this.nextYears.map(
      (year, index) => revenue[index] * (dcf.operatingData.depreciationMargin.find((data) => year === data.year).pct / 100)
    );

    // Ebitda
    const ebitda = this.nextYears.map((year, index) => ebit[index] + depreciation[index]);

    return {
      revenue,
      ebitda,
      ebit,
      depreciation,
      updateTrigger: this.updateTrigger.operatingData
    };
  }

  prepareBalanceSheetData() {
    const balanceSheets = this.lastYears.map((year) =>
      this.company.financialStatements.balanceSheets.yearly.find((bs) => new Date(bs.date).getFullYear() === year)
    );

    // Cash and Short Term Investments
    const cashAndShortTermInvestments = balanceSheets.map((bs) => bs.cashAndShortTermInvestments || 0);
    const cashAndShortTermInvestmentsGrowth = cashAndShortTermInvestments.pctChange();
    const cashAndShortTermInvestmentsGrowthAvg = cashAndShortTermInvestmentsGrowth.mean();

    // Accounts receivable // @TODO: We have no data for netReceivables, we do have netReceivables (explanation: https://www.investopedia.com/terms/n/netreceivables.asp)
    const netReceivables = balanceSheets.map((bs) => bs.netReceivables || 0);
    const netReceivablesGrowth = netReceivables.pctChange();
    const netReceivablesGrowthAvg = netReceivablesGrowth.mean();

    // Inventories
    const inventory = balanceSheets.map((bs) => bs.inventory || 0);
    const inventoryGrowth = inventory.pctChange();
    const inventoryGrowthAvg = inventoryGrowth.mean();

    // Deferred Long Term Asset Charges
    const deferredLongTermAssetCharges = balanceSheets.map((bs) => bs.deferredLongTermAssetCharges || 0);
    const deferredLongTermAssetChargesGrowth = deferredLongTermAssetCharges.pctChange();
    const deferredLongTermAssetChargesGrowthAvg = deferredLongTermAssetChargesGrowth.mean();

    // Accounts payable
    const accountsPayable = balanceSheets.map((bs) => bs.accountsPayable || 0);
    const accountsPayableGrowth = accountsPayable.pctChange();
    const accountsPayableGrowthAvg = accountsPayableGrowth.mean();

    // Total current liabilities
    const totalCurrentLiabilities = balanceSheets.map((bs) => bs.totalCurrentLiabilities || 0);
    const totalCurrentLiabilitiesGrowth = totalCurrentLiabilities.pctChange();
    const totalCurrentLiabilitiesGrowthAvg = totalCurrentLiabilitiesGrowth.mean();

    // Debt total
    const debtTotal = balanceSheets.map((bs) => bs.shortLongTermDebtTotal || 0);
    const debtTotalGrowth = debtTotal.pctChange();
    const debtTotalGrowthAvg = debtTotalGrowth.mean();

    // Gross PP&E
    const grossPpne = balanceSheets.map((bs) => bs.propertyPlantAndEquipmentGross || 0);
    const grossPpneGrowth = grossPpne.pctChange();
    const grossPpneGrowthAvg = grossPpneGrowth.mean();

    this.balanceSheetData = {
      cashAndShortTermInvestments,
      cashAndShortTermInvestmentsGrowth,
      cashAndShortTermInvestmentsGrowthAvg,
      netReceivables,
      netReceivablesGrowth,
      netReceivablesGrowthAvg,
      inventory,
      inventoryGrowth,
      inventoryGrowthAvg,
      deferredLongTermAssetCharges,
      deferredLongTermAssetChargesGrowth,
      deferredLongTermAssetChargesGrowthAvg,
      accountsPayable,
      accountsPayableGrowth,
      accountsPayableGrowthAvg,
      totalCurrentLiabilities,
      totalCurrentLiabilitiesGrowth,
      totalCurrentLiabilitiesGrowthAvg,
      debtTotal,
      debtTotalGrowth,
      debtTotalGrowthAvg,
      grossPpne,
      grossPpneGrowth,
      grossPpneGrowthAvg
    };
  }

  async updateBalanceSheetDataForm(key, index, value) {
    this.dcfValuations[this.dcfValuationYear].balanceSheetData[key][index].pct = parseFloat(value);
    this.updateTrigger.balanceSheetData = !this.updateTrigger.balanceSheetData;
  }

  get projectedBalanceSheetData() {
    const dcf = this.dcfValuations[this.dcfValuationYear];

    // Cash & equivalents
    const cashAndShortTermInvestments = [];
    for (let i = 0; i < this.nextYears.length; i++) {
      const year = this.nextYears[i];
      const growth = dcf.balanceSheetData.cashAndShortTermInvestmentsGrowth.find((data) => data.year === year).pct;
      const cashAndShortTermInvestmentsLastYear =
        i === 0 ? this.balanceSheetData.cashAndShortTermInvestments.last() : cashAndShortTermInvestments[i - 1];
      cashAndShortTermInvestments.push(cashAndShortTermInvestmentsLastYear * (growth / 100 + 1));
    }

    // Accounts receivable
    const netReceivables = [];
    for (let i = 0; i < this.nextYears.length; i++) {
      const year = this.nextYears[i];
      const growth = dcf.balanceSheetData.netReceivablesGrowth.find((data) => data.year === year).pct;
      const netReceivablesLastYear = i === 0 ? this.balanceSheetData.netReceivables.last() : netReceivables[i - 1];
      netReceivables.push(netReceivablesLastYear * (growth / 100 + 1));
    }

    // Inventory
    const inventory = [];
    for (let i = 0; i < this.nextYears.length; i++) {
      const year = this.nextYears[i];
      const growth = dcf.balanceSheetData.inventoryGrowth.find((data) => data.year === year).pct;
      const inventoryLastYear = i === 0 ? this.balanceSheetData.inventory.last() : inventory[i - 1];
      inventory.push(inventoryLastYear * (growth / 100 + 1));
    }

    // Deferred Long Term Asset Charges
    const deferredLongTermAssetCharges = [];
    for (let i = 0; i < this.nextYears.length; i++) {
      const year = this.nextYears[i];
      const growth = dcf.balanceSheetData.deferredLongTermAssetChargesGrowth.find((data) => data.year === year).pct;
      const deferredLongTermAssetChargesLastYear =
        i === 0 ? this.balanceSheetData.deferredLongTermAssetCharges.last() : deferredLongTermAssetCharges[i - 1];
      deferredLongTermAssetCharges.push(deferredLongTermAssetChargesLastYear * (growth / 100 + 1));
    }

    // AccountsPayable
    const accountsPayable = [];
    for (let i = 0; i < this.nextYears.length; i++) {
      const year = this.nextYears[i];
      const growth = dcf.balanceSheetData.accountsPayableGrowth.find((data) => data.year === year).pct;
      const accountsPayableLastYear = i === 0 ? this.balanceSheetData.accountsPayable.last() : accountsPayable[i - 1];
      accountsPayable.push(accountsPayableLastYear * (growth / 100 + 1));
    }

    // Total current liabilities
    const totalCurrentLiabilities = [];
    for (let i = 0; i < this.nextYears.length; i++) {
      const year = this.nextYears[i];
      const growth = dcf.balanceSheetData.totalCurrentLiabilitiesGrowth.find((data) => data.year === year).pct;
      const totalCurrentLiabilitiesLastYear =
        i === 0 ? this.balanceSheetData.totalCurrentLiabilities.last() : totalCurrentLiabilities[i - 1];
      totalCurrentLiabilities.push(totalCurrentLiabilitiesLastYear * (growth / 100 + 1));
    }

    // Total debt
    const debtTotal = [];
    for (let i = 0; i < this.nextYears.length; i++) {
      const year = this.nextYears[i];
      const growth = dcf.balanceSheetData.debtTotalGrowth.find((data) => data.year === year).pct;
      const debtTotalLastYear = i === 0 ? this.balanceSheetData.debtTotal.last() : debtTotal[i - 1];
      debtTotal.push(debtTotalLastYear * (growth / 100 + 1));
    }

    // Gross PP&E
    const grossPpne = [];
    for (let i = 0; i < this.nextYears.length; i++) {
      const year = this.nextYears[i];
      const growth = dcf.balanceSheetData.grossPpneGrowth.find((data) => data.year === year).pct;
      const grossPpneLastYear = i === 0 ? this.balanceSheetData.grossPpne.last() : grossPpne[i - 1];
      grossPpne.push(grossPpneLastYear * (growth / 100 + 1));
    }

    return {
      cashAndShortTermInvestments,
      netReceivables,
      inventory,
      deferredLongTermAssetCharges,
      accountsPayable,
      totalCurrentLiabilities,
      debtTotal,
      grossPpne,
      updateTrigger: this.updateTrigger.balanceSheetData
    };
  }

  async updateCashFlowBuildupDataForm(key, index, value) {
    this.dcfValuations[this.dcfValuationYear].cashFlowBuildup[key][index].pct = parseFloat(value);
    this.updateTrigger.cashFlowBuildup = !this.updateTrigger.cashFlowBuildup;
  }

  get calculatedCashFlowBuildupData() {
    const dcf = this.dcfValuations[this.dcfValuationYear];
    const years = [this.lastYears.last(), ...this.nextYears];

    // EBIAT
    const ebitData = [this.operatingData.ebit.last(), ...this.projectedOperatingData.ebit];
    const taxRates = [this.taxRateData?.lastYear || 0, ...dcf.cashFlowBuildup.taxRate.map((taxRate) => taxRate.pct)];
    const ebiat = ebitData.map((ebit, index) => ebit * ((100 - taxRates[index]) / 100));

    // Accounts receivable
    const balanceSheetnetReceivables = [
      ...this.balanceSheetData.netReceivables.slice(-2),
      ...this.projectedBalanceSheetData.netReceivables
    ];
    const netReceivables = [];
    for (let i = 1; i < balanceSheetnetReceivables.length; i++) {
      const netReceivablesYear1 = balanceSheetnetReceivables[i - 1];
      const netReceivablesYear2 = balanceSheetnetReceivables[i];
      netReceivables.push(netReceivablesYear1 || 0 - netReceivablesYear2 || 0);
    }

    // Inventories
    const balanceSheetInventory = [...this.balanceSheetData.inventory.slice(-2), ...this.projectedBalanceSheetData.inventory];
    const inventory = [];
    for (let i = 1; i < balanceSheetInventory.length; i++) {
      const inventoryYear1 = balanceSheetInventory[i - 1];
      const inventoryYear2 = balanceSheetInventory[i];
      inventory.push(inventoryYear1 || 0 - inventoryYear2 || 0);
    }

    // Deferred Long Term Asset Charges
    const balanceSheetDeferredLongTermAssetCharges = [
      ...this.balanceSheetData.deferredLongTermAssetCharges.slice(-2),
      ...this.projectedBalanceSheetData.deferredLongTermAssetCharges
    ];
    const deferredLongTermAssetCharges = [];
    for (let i = 1; i < balanceSheetDeferredLongTermAssetCharges.length; i++) {
      const deferredLongTermAssetChargesYear1 = balanceSheetDeferredLongTermAssetCharges[i - 1];
      const deferredLongTermAssetChargesYear2 = balanceSheetDeferredLongTermAssetCharges[i];
      deferredLongTermAssetCharges.push(deferredLongTermAssetChargesYear1 || 0 - deferredLongTermAssetChargesYear2 || 0);
    }

    // Accounts payable
    const balanceSheetAccountsPayable = [
      ...this.balanceSheetData.accountsPayable.slice(-2),
      ...this.projectedBalanceSheetData.accountsPayable
    ];
    const accountsPayable = [];
    for (let i = 1; i < balanceSheetAccountsPayable.length; i++) {
      const accountsPayableYear1 = balanceSheetAccountsPayable[i - 1];
      const accountsPayableYear2 = balanceSheetAccountsPayable[i];
      accountsPayable.push((accountsPayableYear1 || 0 - accountsPayableYear2 || 0) * -1);
    }

    // Total current liabilities
    const balanceSheetTotalCurrentLiabilities = [
      ...this.balanceSheetData.totalCurrentLiabilities.slice(-2),
      ...this.projectedBalanceSheetData.totalCurrentLiabilities
    ];
    const totalCurrentLiabilities = [];
    for (let i = 1; i < balanceSheetTotalCurrentLiabilities.length; i++) {
      const totalCurrentLiabilitiesYear1 = balanceSheetTotalCurrentLiabilities[i - 1];
      const totalCurrentLiabilitiesYear2 = balanceSheetTotalCurrentLiabilities[i];
      totalCurrentLiabilities.push((totalCurrentLiabilitiesYear1 || 0 - totalCurrentLiabilitiesYear2 || 0) * -1);
    }

    // Gross PP&E
    const balanceSheetGrossPpne = [...this.balanceSheetData.grossPpne.slice(-2), ...this.projectedBalanceSheetData.grossPpne];
    const grossPpne = [];
    for (let i = 1; i < balanceSheetGrossPpne.length; i++) {
      const grossPpneYear1 = balanceSheetGrossPpne[i - 1];
      const grossPpneYear2 = balanceSheetGrossPpne[i];
      grossPpne.push(grossPpneYear1 || 0 - grossPpneYear2 || 0);
    }

    // Unlevered free cashflows
    const unleveredFreeCashFlows = [];
    for (let i = 0; i < years.length; i++) {
      const depreciation = [this.operatingData.depreciation.last(), ...this.projectedOperatingData.depreciation];

      unleveredFreeCashFlows.push(
        ebiat[i] +
          depreciation[i] +
          netReceivables[i] +
          inventory[i] +
          deferredLongTermAssetCharges[i] +
          accountsPayable[i] +
          totalCurrentLiabilities[i] +
          grossPpne[i]
      );
    }

    // Present value of free cashflows
    let presentValueFreeCashFlows = [];
    if (this.waccData) {
      for (let i = 1; i < years.length; i++) {
        presentValueFreeCashFlows.push(unleveredFreeCashFlows?.[i] / (this.calculatedWaccData?.wacc / 100 + 1) ** (i + 1));
      }
    } else {
      presentValueFreeCashFlows = new Array(years.length - 1).fill(0);
    }

    // Sum of present value of free cash flows
    const sumPresentValueFreeCashFlows = presentValueFreeCashFlows.reduce((a, b) => a + b);

    return {
      ebiat,
      netReceivables,
      inventory,
      deferredLongTermAssetCharges,
      accountsPayable,
      totalCurrentLiabilities,
      grossPpne,
      unleveredFreeCashFlows,
      presentValueFreeCashFlows,
      sumPresentValueFreeCashFlows,
      updateTrigger: this.updateTrigger.cashFlowBuildup
    };
  }

  async prepareWaccData() {
    // Share price
    let sharePrice = null;
    try {
      sharePrice = (await this.StockInvestingApi.fnCompaniesPrice({ companyCodes: [this.company.companyCode] }))[this.company.companyCode];
    } catch (err) {
      console.error(err);
    }

    // Total shares outstanding
    const year = this.lastYears.last();
    const totalSharesOutstanding = new Date(year.toString()).closest(this.company.shares.outstanding, 'date')?.shares;

    // Total equity
    const totalEquity = sharePrice * totalSharesOutstanding;

    // Total capital
    const totalCapital = this.balanceSheetData.debtTotal.last() + totalEquity;

    // Debt weighting
    const debtWeighting = (this.balanceSheetData.debtTotal.last() / totalCapital) * 100;

    // Equity weighting
    const equityWeighting = (totalEquity / totalCapital) * 100;

    this.waccData = {
      sharePrice,
      totalSharesOutstanding,
      totalEquity,
      totalCapital,
      debtWeighting,
      equityWeighting
    };
  }

  async updateWaccDataForm(key, value) {
    this.dcfValuations[this.dcfValuationYear].wacc[key] = parseFloat(value);
    this.updateTrigger.wacc = !this.updateTrigger.wacc;
  }

  get calculatedWaccData() {
    const dcf = this.dcfValuations[this.dcfValuationYear];

    // Ater-tax cost of debt
    const afterTaxCostDebt = dcf.wacc.costOfDebt * ((100 - dcf.wacc.taxRate) / 100);

    // WACC
    const wacc = (this.waccData.equityWeighting * dcf.wacc.costOfEquity) / 100 + (this.waccData.debtWeighting * afterTaxCostDebt) / 100;

    return {
      afterTaxCostDebt,
      wacc,
      updateTrigger: this.updateTrigger.wacc
    };
  }

  prepareTerminalValueData() {
    this.terminalValueData = {};
  }

  async updateTerminalValueDataForm(key, value) {
    this.dcfValuations[this.dcfValuationYear].terminalValue[key] = parseFloat(value);
    this.updateTrigger.terminalValue = !this.updateTrigger.terminalValue;
  }

  get calculatedTerminalValueData() {
    const dcf = this.dcfValuations[this.dcfValuationYear];

    // Free cashflow
    const freeCashFlow =
      this.calculatedCashFlowBuildupData.unleveredFreeCashFlows.last() * (dcf.terminalValue.longTermGrowthRate / 100 + 1);

    // Terminal value
    const terminalValuePerpetuity = freeCashFlow / (this.calculatedWaccData.wacc / 100 - dcf.terminalValue.longTermGrowthRate / 100);

    // Present value of terminal value
    const presentValueTerminalValuePerpetuity = terminalValuePerpetuity / (this.calculatedWaccData.wacc / 100 + 1) ** this.nextYears.length;

    // Multiple
    const multiple = (this.waccData.totalCapital + this.costOfCapitalData.netDebtNci) / this.operatingData.ebitda.last();

    // Terminal value
    const terminalValueEbitda = this.projectedOperatingData.ebitda.last() * dcf.terminalValue.multiple;

    // Present value of terminal value
    const presentValueTerminalValueEbitda = terminalValueEbitda / (this.calculatedWaccData.wacc / 100 + 1) ** this.nextYears.length;

    return {
      freeCashFlow,
      terminalValuePerpetuity,
      presentValueTerminalValuePerpetuity,
      multiple,
      terminalValueEbitda,
      presentValueTerminalValueEbitda,
      updateTrigger: this.updateTrigger.terminalValue
    };
  }

  prepareCostOfCapitalData() {
    const balanceSheet = this.lastYears
      .map((year) => this.company.financialStatements.balanceSheets.yearly.find((bs) => new Date(bs.date).getFullYear() === year))
      .last();

    // Beta
    // const { beta } = this.company.general_data[this.sources.general]; // @TODO: Get data in database and use it; use beta 1 for now
    const beta = 1;

    // Debt YE
    const debtYe = balanceSheet.longTermDebt + balanceSheet.capitalLeaseObligations;

    // Net debt + NCI
    const netDebtNci = debtYe - balanceSheet.cash + (balanceSheet.totalStockholderEquity - balanceSheet.commonStockTotalEquity);

    this.costOfCapitalData = {
      beta,
      debtYe,
      netDebtNci
    };
  }

  async updateCostOfCapitalDataForm(key, value) {
    this.dcfValuations[this.dcfValuationYear].costOfCapital[key] = parseFloat(value);
    this.updateTrigger.costOfCapital = !this.updateTrigger.costOfCapital;
  }

  get calculatedCostOfCapitalData() {
    const dcf = this.dcfValuations[this.dcfValuationYear];

    // Cost of equity
    const costOfEquity = this.costOfCapitalData.beta * dcf.costOfCapital.marketRiskPremium + parseFloat(dcf.costOfCapital.riskFreeRate);

    return {
      costOfEquity,
      updateTrigger: this.updateTrigger.costOfCapital
    };
  }

  prepareTaxRateData() {
    const incomeStatements = this.lastYears.map((year) =>
      this.company.financialStatements.incomeStatements.yearly.find((is) => new Date(is.date).getFullYear() === year)
    );

    // Last year
    const lastYear = (incomeStatements.last().incomeTaxExpense / incomeStatements.last().incomeBeforeTax) * 100;

    // Average 4 years
    let years = 4;
    const avgRate4Years =
      (incomeStatements
        .slice(-years)
        .map((is) => is.incomeTaxExpense)
        .mean() /
        incomeStatements
          .slice(-years)
          .map((is) => is.incomeBeforeTax)
          .mean()) *
      100;

    // Average 10 years
    years = 10;
    const avgRate10Years =
      (incomeStatements
        .slice(-years)
        .map((is) => is.incomeTaxExpense)
        .mean() /
        incomeStatements
          .slice(-years)
          .map((is) => is.incomeBeforeTax)
          .mean()) *
      100;

    this.taxRateData = {
      lastYear,
      avgRate4Years,
      avgRate10Years
    };
  }

  prepareCostOfDebtData() {
    const balanceSheets = this.lastYears.map((year) =>
      this.company.financialStatements.balanceSheets.yearly.find((bs) => new Date(bs.date).getFullYear() === year)
    );

    const incomeStatements = this.lastYears.map((year) =>
      this.company.financialStatements.incomeStatements.yearly.find((is) => new Date(is.date).getFullYear() === year)
    );

    // Last year
    let years = 1;
    let lastYear;
    {
      const interestExpenses = incomeStatements.slice(-years)?.map((is) => is.interestExpense || 0);
      const longTermDebts = balanceSheets.slice(-years)?.map((is) => is.longTermDebt || 0);
      const capitalLeases = balanceSheets.slice(-years)?.map((is) => is.capitalLeaseObligations || 0);
      lastYear = (-interestExpenses.sum() / (longTermDebts.sum() + capitalLeases.sum())) * 100;
    }

    // Average 4 years
    years = 4;
    let avg4Years;
    {
      const interestExpenses = incomeStatements.slice(-years)?.map((is) => is.interestExpense || 0);
      const longTermDebts = balanceSheets.slice(-years)?.map((is) => is.longTermDebt || 0);
      const capitalLeases = balanceSheets.slice(-years)?.map((is) => is.capitalLeaseObligations || 0);
      avg4Years = (-interestExpenses.sum() / (longTermDebts.sum() + capitalLeases.sum())) * 100;
    }

    // Average 10 years
    years = 10;
    let avg10Years;
    {
      const interestExpenses = incomeStatements.slice(-years)?.map((is) => is.interestExpense || 0);
      const longTermDebts = balanceSheets.slice(-years)?.map((is) => is.longTermDebt || 0);
      const capitalLeases = balanceSheets.slice(-years)?.map((is) => is.capitalLeaseObligations || 0);
      avg10Years = (-interestExpenses.sum() / (longTermDebts.sum() + capitalLeases.sum())) * 100;
    }

    this.costOfDebtData = {
      lastYear,
      avg4Years,
      avg10Years
    };
  }

  prepareEnterpriseValueData() {
    this.enterpriseValueData = {};
  }

  get calculatedEnterpriseValueData() {
    // Net debt
    const netDebt = -(this.balanceSheetData.cashAndShortTermInvestments.last() - this.balanceSheetData.debtTotal.last());

    // Ebitda - Enterprise value
    const ebitdaEnterpriseValue =
      this.calculatedCashFlowBuildupData.sumPresentValueFreeCashFlows + this.calculatedTerminalValueData.presentValueTerminalValueEbitda;

    // Perpetuity - Enterprise value
    const perpetuityEnterpriseValue =
      this.calculatedCashFlowBuildupData.sumPresentValueFreeCashFlows +
      this.calculatedTerminalValueData.presentValueTerminalValuePerpetuity;

    // Ebitda - Equity value
    const ebitdaEquityValue = ebitdaEnterpriseValue - netDebt;

    // Perpetuity - Equity value
    const perpetuityEquityValue = perpetuityEnterpriseValue - netDebt;

    // Ebitda - Equity value per share
    const ebitdaEquityValuePerShare = ebitdaEquityValue / this.waccData.totalSharesOutstanding;

    // Perpetuity - Equity value per share
    const perpetuityEquityValuePerShare = perpetuityEquityValue / this.waccData.totalSharesOutstanding;

    return {
      netDebt,
      ebitda: {
        enterpriseValue: ebitdaEnterpriseValue,
        equityValue: ebitdaEquityValue,
        equityValuePerShare: ebitdaEquityValuePerShare
      },
      perpetuity: {
        enterpriseValue: perpetuityEnterpriseValue,
        equityValue: perpetuityEquityValue,
        equityValuePerShare: perpetuityEquityValuePerShare
      }
    };
  }

  async handleSaveButtonClick() {
    this.saving = true;

    const dcf = {
      balanceSheetData: this.dcfValuations[this.dcfValuationYear].balanceSheetData,
      cashFlowBuildup: this.dcfValuations[this.dcfValuationYear].cashFlowBuildup,
      costOfCapital: this.dcfValuations[this.dcfValuationYear].costOfCapital,
      operatingData: this.dcfValuations[this.dcfValuationYear].operatingData,
      terminalValue: this.dcfValuations[this.dcfValuationYear].terminalValue,
      wacc: this.dcfValuations[this.dcfValuationYear].wacc,
      valuation: {
        perpetuity: this.calculatedEnterpriseValueData.perpetuity.equityValuePerShare,
        ebitda: this.calculatedEnterpriseValueData.ebitda.equityValuePerShare
      }
    };

    try {
      if (this.dcfValuations[this.dcfValuationYear]?._id) {
        // Updating existing DCF
        this.$gtag.event('company_val_dcf_update', {
          company_code: this.company?.companyCode,
          valuation_year: this.dcfValuationYear
        });
        await this.StockInvestingApi.dcfValuationsUpdate({
          _id: this.dcfValuations[this.dcfValuationYear]._id,
          companyCode: this.company.companyCode,
          year: this.dcfValuationYear,
          ...dcf
        });
      } else {
        // Creating new DCF
        this.$gtag.event('company_val_dcf_create', {
          company_code: this.company?.companyCode,
          valuation_year: this.dcfValuationYear
        });
        const result = await this.StockInvestingApi.dcfValuationsCreate({
          companyCode: this.company.companyCode,
          year: this.dcfValuationYear,
          ...dcf
        });
        this.dcfValuations[this.dcfValuationYear] = result;
      }
      this.ToastSuccess('DCF is updated');
    } catch (err) {
      console.error(err);
      this.ToastError('Something went wrong while updating the DCF');
    }

    this.saving = false;
  }

  generatePercentageProjectionArray(startYear) {
    const arr = [];
    for (let i = 0; i < NEXT_X_YEARS; i++) {
      arr.push({
        year: startYear + i,
        pct: 0
      });
    }

    return arr.sort((a, b) => a.year - b.year);
  }

  handleFormInteraction() {
    this.$gtag.event('company_val_dcf_formInteract', {
      company_code: this.company?.companyCode,
      valuation_year: this.dcfValuationYear
    });
  }

  async handleYearChange(year) {
    this.loadingNewYear = true;
    this.dcfValuationYear = parseInt(year);

    this.prepareOperatingData();
    this.prepareBalanceSheetData();
    await this.prepareWaccData();
    this.prepareTerminalValueData();
    this.prepareCostOfCapitalData();
    this.prepareTaxRateData();
    this.prepareCostOfDebtData();
    this.prepareEnterpriseValueData();
    this.loadingNewYear = false;
  }

  handleDcfInteraction(event) {
    if (!this.store.user.hasPermission(EPermissions.DCF_VALUATION.UI)) {
      event.preventDefault();
      event.target.blur();
      this.showUpgradeModal = true;
    }
  }

  preparePopoverChartData() {
    const popoverChartData = {};

    // Operating data

    // Revenue
    const totalRevenues = this.company.financialStatements.incomeStatements?.yearly?.map((is) => is.totalRevenue);
    popoverChartData.totalRevenue = this.company.financialStatements.incomeStatements?.yearly
      ?.map((is) => ({ date: new Date(is.date).getFullYear().toString(), value: is.totalRevenue }))
      .slice(-this.store.user.maxDataHistory);
    popoverChartData.totalRevenueGrowth = totalRevenues
      .pctChange()
      .map((revenueGrowth, index) => ({
        date: new Date(this.company.financialStatements.incomeStatements?.yearly?.[index + 1]?.date).getFullYear().toString(),
        value: revenueGrowth.round(2)
      }))
      .slice(-this.store.user.maxDataHistory);

    // EBIT
    popoverChartData.ebit = this.company.ratios?.yearly
      ?.map((ratio) => ({ date: new Date(ratio.date).getFullYear().toString(), value: ratio.ebit }))
      .slice(-this.store.user.maxDataHistory);
    popoverChartData.ebitPct = this.company.ratios?.yearly
      ?.map((ratio) => {
        const ratioYear = new Date(ratio.date).getFullYear();
        const revenue = this.company.financialStatements.incomeStatements?.yearly?.find(
          (statement) => new Date(statement.date).getFullYear() === ratioYear
        )?.totalRevenue;
        return { date: new Date(ratio.date).getFullYear().toString(), value: (ratio.ebit / revenue) * 100 };
      })
      .slice(-this.store.user.maxDataHistory);

    // Depreciation and amortization
    popoverChartData.depreciation = this.company.financialStatements?.cashFlowStatements?.yearly
      ?.map((cf) => ({ date: new Date(cf.date).getFullYear().toString(), value: cf.depreciation }))
      .slice(-this.store.user.maxDataHistory);
    popoverChartData.depreciationPct = this.company.financialStatements?.cashFlowStatements?.yearly
      ?.map((cf) => {
        const ratioYear = new Date(cf.date).getFullYear();
        const revenue = this.company.financialStatements.incomeStatements?.yearly?.find(
          (statement) => new Date(statement.date).getFullYear() === ratioYear
        )?.totalRevenue;
        return { date: new Date(cf.date).getFullYear().toString(), value: (cf.depreciation / revenue) * 100 };
      })
      .slice(-this.store.user.maxDataHistory);

    // EBITDA
    popoverChartData.ebitda = this.company.ratios?.yearly
      ?.map((ratio) => ({ date: new Date(ratio.date).getFullYear().toString(), value: ratio.ebitda }))
      .slice(-this.store.user.maxDataHistory);
    popoverChartData.ebitdaPct = this.company.ratios?.yearly
      ?.map((ratio) => {
        const ratioYear = new Date(ratio.date).getFullYear();
        const revenue = this.company.financialStatements.incomeStatements?.yearly?.find(
          (statement) => new Date(statement.date).getFullYear() === ratioYear
        )?.totalRevenue;
        return { date: new Date(ratio.date).getFullYear().toString(), value: (ratio.ebitda / revenue) * 100 };
      })
      .slice(-this.store.user.maxDataHistory);

    // Cash and equivalents
    popoverChartData.cashAndShortTermInvestments = this.company.financialStatements?.balanceSheets?.yearly
      ?.map((bs) => ({ date: new Date(bs.date).getFullYear().toString(), value: bs.cashAndShortTermInvestments }))
      .slice(-this.store.user.maxDataHistory);
    popoverChartData.cashAndShortTermInvestmentsGrowth = this.company.financialStatements.balanceSheets?.yearly
      ?.map((bs) => bs.cashAndShortTermInvestments)
      .pctChange()
      .map((growth, index) => ({
        date: new Date(this.company.financialStatements.balanceSheets?.yearly?.[index + 1]?.date).getFullYear().toString(),
        value: growth.round(2)
      }))
      .slice(-this.store.user.maxDataHistory);

    // Net receivables
    popoverChartData.netReceivables = this.company.financialStatements?.balanceSheets?.yearly
      ?.map((bs) => ({ date: new Date(bs.date).getFullYear().toString(), value: bs.netReceivables }))
      .slice(-this.store.user.maxDataHistory);
    popoverChartData.netReceivablesGrowth = this.company.financialStatements.balanceSheets?.yearly
      ?.map((bs) => bs.netReceivables)
      .pctChange()
      .map((growth, index) => ({
        date: new Date(this.company.financialStatements.balanceSheets?.yearly?.[index + 1]?.date).getFullYear().toString(),
        value: growth.round(2)
      }))
      .slice(-this.store.user.maxDataHistory);

    // Inventory
    popoverChartData.inventory = this.company.financialStatements?.balanceSheets?.yearly
      ?.map((bs) => ({ date: new Date(bs.date).getFullYear().toString(), value: bs.inventory }))
      .slice(-this.store.user.maxDataHistory);
    popoverChartData.inventoryGrowth = this.company.financialStatements.balanceSheets?.yearly
      ?.map((bs) => bs.inventory)
      .pctChange()
      .map((growth, index) => ({
        date: new Date(this.company.financialStatements.balanceSheets?.yearly?.[index + 1]?.date).getFullYear().toString(),
        value: growth.round(2)
      }))
      .slice(-this.store.user.maxDataHistory);

    // Prepaid expenses
    popoverChartData.deferredLongTermAssetCharges = this.company.financialStatements?.balanceSheets?.yearly
      ?.map((bs) => ({ date: new Date(bs.date).getFullYear().toString(), value: bs.deferredLongTermAssetCharges }))
      .slice(-this.store.user.maxDataHistory);
    popoverChartData.deferredLongTermAssetChargesGrowth = this.company.financialStatements.balanceSheets?.yearly
      ?.map((bs) => bs.deferredLongTermAssetCharges)
      .pctChange()
      .map((growth, index) => ({
        date: new Date(this.company.financialStatements.balanceSheets?.yearly?.[index + 1]?.date).getFullYear().toString(),
        value: growth.round(2)
      }))
      .slice(-this.store.user.maxDataHistory);

    // Accounts payable
    popoverChartData.accountsPayable = this.company.financialStatements?.balanceSheets?.yearly
      ?.map((bs) => ({ date: new Date(bs.date).getFullYear().toString(), value: bs.accountsPayable }))
      .slice(-this.store.user.maxDataHistory);
    popoverChartData.accountsPayableGrowth = this.company.financialStatements.balanceSheets?.yearly
      ?.map((bs) => bs.accountsPayable)
      .pctChange()
      .map((growth, index) => ({
        date: new Date(this.company.financialStatements.balanceSheets?.yearly?.[index + 1]?.date).getFullYear().toString(),
        value: growth.round(2)
      }))
      .slice(-this.store.user.maxDataHistory);

    // Total current liabilities
    popoverChartData.totalCurrentLiabilities = this.company.financialStatements?.balanceSheets?.yearly
      ?.map((bs) => ({ date: new Date(bs.date).getFullYear().toString(), value: bs.totalCurrentLiabilities }))
      .slice(-this.store.user.maxDataHistory);
    popoverChartData.totalCurrentLiabilitiesGrowth = this.company.financialStatements.balanceSheets?.yearly
      ?.map((bs) => bs.totalCurrentLiabilities)
      .pctChange()
      .map((growth, index) => ({
        date: new Date(this.company.financialStatements.balanceSheets?.yearly?.[index + 1]?.date).getFullYear().toString(),
        value: growth.round(2)
      }))
      .slice(-this.store.user.maxDataHistory);

    // Total debt
    popoverChartData.debtTotal = this.company.financialStatements?.balanceSheets?.yearly
      ?.map((bs) => ({ date: new Date(bs.date).getFullYear().toString(), value: bs.shortLongTermDebtTotal }))
      .slice(-this.store.user.maxDataHistory);
    popoverChartData.debtTotalGrowth = this.company.financialStatements.balanceSheets?.yearly
      ?.map((bs) => bs.shortLongTermDebtTotal)
      .pctChange()
      .map((growth, index) => ({
        date: new Date(this.company.financialStatements.balanceSheets?.yearly?.[index + 1]?.date).getFullYear().toString(),
        value: growth.round(2)
      }))
      .slice(-this.store.user.maxDataHistory);

    // Gross PP&E
    popoverChartData.grossPpne = this.company.financialStatements?.balanceSheets?.yearly
      ?.map((bs) => ({ date: new Date(bs.date).getFullYear().toString(), value: bs.propertyPlantAndEquipmentGross }))
      .slice(-this.store.user.maxDataHistory);
    popoverChartData.grossPpneGrowth = this.company.financialStatements.balanceSheets?.yearly
      ?.map((bs) => bs.propertyPlantAndEquipmentGross)
      .pctChange()
      .map((growth, index) => ({
        date: new Date(this.company.financialStatements.balanceSheets?.yearly?.[index + 1]?.date).getFullYear().toString(),
        value: growth.round(2)
      }))
      .slice(-this.store.user.maxDataHistory);

    this.popoverChartData = popoverChartData;
  }

  async reloadTab() {
    // Get all available years
    this.years = this.company.ratios.yearly
      .map((ratiosPeriod) => new Date(ratiosPeriod.date).getFiscalPeriod(this.company.general.fiscalYearEndMonth).year)
      .filter((year) => !isNaN(parseInt(year)))
      .sort();

    // Determine the next year
    this.dcfValuationYear = parseInt(this.years.last()) + 1;

    // Structure data in JSON format with year-keys
    for (let i = 0; i < this.company?.valuations?.dcfValuations?.length; i++) {
      const dcf = this.company?.valuations?.dcfValuations[i];
      const { year } = dcf;
      this.dcfValuations[year] = dcf;
    }

    // New DCF template
    const newDcf = {
      operatingData: {
        revenueGrowth: this.generatePercentageProjectionArray(this.dcfValuationYear),
        ebitdaMargin: this.generatePercentageProjectionArray(this.dcfValuationYear),
        ebitMargin: this.generatePercentageProjectionArray(this.dcfValuationYear),
        depreciationMargin: this.generatePercentageProjectionArray(this.dcfValuationYear)
      },
      balanceSheetData: {
        cashAndShortTermInvestmentsGrowth: this.generatePercentageProjectionArray(this.dcfValuationYear),
        netReceivablesGrowth: this.generatePercentageProjectionArray(this.dcfValuationYear),
        inventoryGrowth: this.generatePercentageProjectionArray(this.dcfValuationYear),
        deferredLongTermAssetChargesGrowth: this.generatePercentageProjectionArray(this.dcfValuationYear),
        accountsPayableGrowth: this.generatePercentageProjectionArray(this.dcfValuationYear),
        totalCurrentLiabilitiesGrowth: this.generatePercentageProjectionArray(this.dcfValuationYear),
        debtTotalGrowth: this.generatePercentageProjectionArray(this.dcfValuationYear),
        grossPpneGrowth: this.generatePercentageProjectionArray(this.dcfValuationYear)
      },
      cashFlowBuildup: {
        taxRate: this.generatePercentageProjectionArray(this.dcfValuationYear)
      },
      costOfCapital: {
        riskFreeRate: 0,
        marketRiskPremium: 0
      },
      wacc: {
        costOfDebt: 0,
        taxRate: 0,
        costOfEquity: 0
      },
      terminalValue: {
        longTermGrowthRate: 0,
        multiple: 0
      }
    };

    this.dcfValuations[this.dcfValuationYear] ||= newDcf;

    // Sort years in ascending order
    const valuationYears = Object.keys(this.dcfValuations);
    for (let i = 0; i < valuationYears.length; i++) {
      const year = valuationYears[i];

      this.dcfValuations[year].operatingData.revenueGrowth.sort((a, b) => a.year - b.year);
      this.dcfValuations[year].operatingData.ebitdaMargin.sort((a, b) => a.year - b.year);
      this.dcfValuations[year].operatingData.ebitMargin.sort((a, b) => a.year - b.year);
      this.dcfValuations[year].operatingData.depreciationMargin.sort((a, b) => a.year - b.year);

      this.dcfValuations[year].balanceSheetData.cashAndShortTermInvestmentsGrowth.sort((a, b) => a.year - b.year);
      this.dcfValuations[year].balanceSheetData.netReceivablesGrowth.sort((a, b) => a.year - b.year);
      this.dcfValuations[year].balanceSheetData.inventoryGrowth.sort((a, b) => a.year - b.year);
      this.dcfValuations[year].balanceSheetData.deferredLongTermAssetChargesGrowth.sort((a, b) => a.year - b.year);
      this.dcfValuations[year].balanceSheetData.accountsPayableGrowth.sort((a, b) => a.year - b.year);
      this.dcfValuations[year].balanceSheetData.totalCurrentLiabilitiesGrowth.sort((a, b) => a.year - b.year);
      this.dcfValuations[year].balanceSheetData.debtTotalGrowth.sort((a, b) => a.year - b.year);
      this.dcfValuations[year].balanceSheetData.grossPpneGrowth.sort((a, b) => a.year - b.year);

      this.dcfValuations[year].cashFlowBuildup.taxRate.sort((a, b) => a.year - b.year);
    }

    this.prepareOperatingData();
    this.prepareBalanceSheetData();
    await this.prepareWaccData();
    this.prepareTerminalValueData();
    this.prepareCostOfCapitalData();
    this.prepareTaxRateData();
    this.prepareCostOfDebtData();
    this.prepareEnterpriseValueData();
  }

  async loadCompany() {
    const companyCode = this?.$route?.params?.companyCode;
    this.company = new Company({ companyCode });
    await this.company.load({
      general: true,
      financialStatements: {
        balanceSheets: {
          yearly: true
        },
        incomeStatements: {
          yearly: true
        },
        cashFlowStatements: {
          yearly: true
        }
      },
      ratios: {
        yearly: true
      },
      shares: {
        outstanding: true
      },
      valuations: {
        dcfValuations: true
      },
      currencies: true,
      sharePriceLive: true
    });
  }

  async mounted() {
    await this.loadCompany();
    this.setTitle(`${this?.company?.general?.name} - DCF`);

    await this.reloadTab();
    this.loaded = true;

    this.preparePopoverChartData();
  }
}
export default CompanyDetailsDcf;
</script>
