const adjustForSplits = (candlesticks, splits) => {
  if (!splits?.length || !candlesticks?.length) {
    return candlesticks;
  }

  const fixedSplits = splits.map((split) => {
    const splitDate = new Date(split.date).getTime();
    const previousCandlestick = candlesticks
      .filter((candlestick) => new Date(candlestick.date).getTime() - splitDate < 0)
      .sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      })
      .last();
    const nextCandlestick = candlesticks
      .filter((candlestick) => new Date(candlestick.date).getTime() - splitDate > 0)
      .sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      })
      .first();

    if (
      Math.abs(previousCandlestick?.close - (nextCandlestick.open / split.oldShares) * split.newShares) >
      Math.abs(previousCandlestick?.close - (nextCandlestick.open / split.newShares) * split.oldShares)
    ) {
      return {
        ...split,
        oldShares: split.newShares,
        newShares: split.oldShares
      };
    }
    return split;
  });

  return candlesticks.map((candlestick) => {
    const adjustedCandlestick = { ...candlestick };
    fixedSplits.forEach((split) => {
      const splitDate = new Date(split.date).getTime();
      const candlestickDate = new Date(adjustedCandlestick.date).getTime();
      if (splitDate > candlestickDate) {
        adjustedCandlestick.open *= split.oldShares / split.newShares;
        adjustedCandlestick.high *= split.oldShares / split.newShares;
        adjustedCandlestick.low *= split.oldShares / split.newShares;
        adjustedCandlestick.close *= split.oldShares / split.newShares;
        adjustedCandlestick.volume *= split.newShares / split.oldShares;
      }
    });
    return adjustedCandlestick;
  });
};

export { adjustForSplits };
