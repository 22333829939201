class CompanyManagementPerson {
  constructor({ name = null, title = null, yearBorn = null }) {
    this.name = name;
    this.title = title;
    this.yearBorn = yearBorn;
  }

  get age() {
    if (!this.yearBorn) {
      return null;
    }
    return new Date().getFullYear() - this.yearBorn;
  }
}

export { CompanyManagementPerson };
