<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'invoices',
  components: {}
})
class Invoices extends Vue {
  invoices = null;
  creditNotes = null;

  get accountancyNotes() {
    return [...(this.invoices || []), ...(this.creditNotes || [])].sort((a, b) => b.created - a.created);
  }

  async mounted() {
    try {
      this.invoices = await this.StockInvestingApi.paymentAccountancyInvoicesList();
      this.creditNotes = await this.StockInvestingApi.paymentAccountancyCreditNotesList();
    } catch (err) {
      console.error(err);
    }
  }
}
export default Invoices;
</script>
