<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment';
import Highcharts from 'highcharts';
import { Chart } from 'highcharts-vue';

import { ETime, EPlans } from '../../../../../shared/enums.js';
import { HowItWorks, ToggleButton, UpgradeCtaModal, AuthenticateModal } from '../../../../components/index.js';

import { adjustForSplits } from '../../../../../shared/utils/adjust-for-splits.js';
import { Company } from '../../../../../shared/models/company/Company.js';
import { ERoles } from '../../../../../shared/models/user/index.js';

const DISCOUNT_PREMIUM_MAX_LIMIT = 200;
const DISCOUNT_PREMIUM_MIN_LIMIT = -100;

@Component({
  name: 'company-details-fundamentl-valuation',
  components: { Chart, HowItWorks, ToggleButton, UpgradeCtaModal, AuthenticateModal },
  data: () => ({ EPlans }),
  watch: {
    async showAdvanced() {
      if (this.store.user.hasRole(ERoles.packageTier2)) {
        setTimeout(this.setChartOptions, 300);
      } else {
        this.upgradeCtaModalPlan = EPlans.packageTier2;
        this.showUpgradeCtaModal = true;
        this.showAdvanced = false;
      }
    }
  }
})
class CompanyDetailsFundamentlValuation extends Vue {
  loaded = false;

  loadingChart = true;

  closePrices = null;
  valuationsData = null;
  discountData = null;

  showAdvanced = false;

  chartOptions = false;

  showUpgradeCtaModal = false;
  upgradeCtaModalPlan = EPlans.packageTier1;
  showAuthModal = false;

  round(number, round) {
    return parseFloat(number?.toFixed(round));
  }

  async initChart() {
    if (!this.historyPricingData) {
      const response = await this.StockInvestingApi.companySharePriceData({ companyCode: this.company.companyCode });

      this.currency = response?.currency;
      const candlesticks = response.candlesticks.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

      this.historyPricingData = adjustForSplits(candlesticks, this.company?.shares?.splits);

      // Pricing data - make sure every date has a close price (even weekends and holidays)
      // [ unixDate, closePrice ]
      const closePricesRaw = this.historyPricingData.map((candlestick) => [
        new Date(candlestick.date).getTime(),
        candlestick.close?.round()
      ]);
      const closePricesStartDate = closePricesRaw.first().first();
      const closePricesEndDate = closePricesRaw.last().first();
      const closePriceTotalDays = (closePricesEndDate - closePricesStartDate) / ETime.milliseconds.perDay;
      const closePrices = [];
      closePriceTotalDays.forEach((day) => {
        const foundClosePrice = closePricesRaw.find(
          (closePrice) => closePrice.first() === closePricesStartDate + day * ETime.milliseconds.perDay
        );
        if (foundClosePrice) {
          closePrices.push(foundClosePrice);
        } else {
          closePrices.push([closePricesStartDate + day * ETime.milliseconds.perDay, closePrices.last().last()]);
        }
      });

      const valuationsData = {
        netProfit: [],
        revenue: [],
        bookValue: [],
        cashFlow: [],
        average: []
      };

      // Structure fundamentl valuation data
      const fundamentlValuations = this.company.valuations.fundamentlValuations?.filter(
        (fundamentlValuation) => fundamentlValuation.average
      );
      Object.keys(valuationsData).forEach((key) => {
        for (let i = 1; i < fundamentlValuations?.length || 0; i++) {
          const periodStartDate = new Date(fundamentlValuations[i - 1].date).getTime();
          const periodEndDate = new Date(fundamentlValuations[i].date).getTime();
          const days = (periodEndDate - periodStartDate) / ETime.milliseconds.perDay;

          const periodStartValuation = fundamentlValuations[i - 1][key];
          const periodEndValuation = fundamentlValuations[i][key];
          const diff = periodEndValuation - periodStartValuation;

          const step = diff / days;

          days.forEach((day) => {
            valuationsData[key].push([
              new Date(periodStartDate + ETime.milliseconds.perDay * day).getTime(),
              (periodStartValuation + step * day).round()
            ]);
          });
        }
      });

      // Discount data
      const requiredData = {};
      for (let i = 0; i < valuationsData.average.length; i++) {
        const timestamp = moment(valuationsData.average[i][0]).format('YYYY-MM-DD');
        const valuation = valuationsData.average[i][1];
        requiredData[timestamp] = requiredData[timestamp] || {};
        requiredData[timestamp].valuation = valuation.round();
      }
      for (let i = 0; i < closePrices.length; i++) {
        const timestamp = moment(closePrices[i][0]).format('YYYY-MM-DD');
        const price = closePrices[i][1];
        requiredData[timestamp] = requiredData[timestamp] || {};
        requiredData[timestamp].price = price.round();
      }

      const discountData = [];
      const timestamps = Object.keys(requiredData);
      for (let i = 0; i < timestamps.length; i++) {
        const data = requiredData[timestamps[i]];
        if (data?.valuation && data?.price) {
          let discount = null;
          if (data?.valuation > data?.price) {
            discount = (data?.valuation / data?.price - 1) * 100;
          } else {
            discount = -(data?.price / data?.valuation - 1) * 100;
          }

          if (discount > DISCOUNT_PREMIUM_MAX_LIMIT) {
            discount = DISCOUNT_PREMIUM_MAX_LIMIT;
          }
          if (discount < DISCOUNT_PREMIUM_MIN_LIMIT) {
            discount = DISCOUNT_PREMIUM_MIN_LIMIT;
          }

          discountData.push([new Date(timestamps[i]).getTime(), discount]);
        }
      }

      this.closePrices = closePrices;
      this.valuationsData = valuationsData;
      this.discountData = discountData;
    }
    this.loadingChart = false;
  }

  setChartOptions() {
    this.chartOptions = {
      currency: this.company?.currencies?.sharePrices?.symbol(),
      scrollbar: {
        enabled: false
      },
      chart: {
        events: {
          load() {
            const chart = this;
            if (chart?.stockTools?.showhideBtn) {
              chart.stockTools.showhideBtn.click();
            }
          }
        }
      },
      rangeSelector: {
        buttons: [
          { type: 'year', count: 1, text: '1Y' },
          { type: 'year', count: 2, text: '2Y' },
          {
            type: 'year',
            count: 5,
            text: '5Y',
            events: {
              click: () => {
                if (this.store.user.maxDataHistory < 5) {
                  this.showAuthModal = true;
                  return false; // Return false to prevent zooming
                }
                return true;
              }
            }
          },
          {
            type: 'year',
            count: 10,
            text: '10Y',
            events: {
              click: () => {
                if (this.store.user.maxDataHistory < 10) {
                  this.upgradeCtaModalPlan = EPlans.packageTier1;
                  this.showUpgradeCtaModal = true;
                  return false; // Return false to prevent zooming
                }
                return true;
              }
            }
          },
          {
            type: 'all',
            count: Infinity,
            text: 'All',
            events: {
              click: () => {
                if (this.store.user.maxDataHistory < Infinity) {
                  this.upgradeCtaModalPlan = EPlans.packageTier2;
                  this.showUpgradeCtaModal = true;
                  return false; // Return false to prevent zooming
                }
                return true;
              }
            }
          }
        ],
        selected: {
          1: 0,
          2: 1,
          5: 2,
          10: 3,
          Infinity: 3
        }?.[this.store.user.maxDataHistory],
        inputEnabled: false,
        animation: false
      },
      legend: {
        enabled: true,
        verticalAlign: 'top',
        align: 'right',
        itemMarginTop: -35
      },
      time: {
        timezoneOffset: new Date().getTimezoneOffset()
      },
      yAxis: [
        {
          labels: {
            align: 'left',
            x: 10,
            y: 3
          },
          resize: {
            enabled: true
          },
          lineWidth: 1,
          tickLength: 5,
          tickWidth: 1,
          tickPixelInterval: 30,
          height: '82%'
        },
        {
          style: {
            overflow: 'hidden'
          },
          labels: {
            align: 'left',
            x: 10,
            y: 3,
            format: '{value}%'
          },
          lineWidth: 1,
          tickLength: 5,
          tickWidth: 1,
          tickPixelInterval: 10,
          ceiling: DISCOUNT_PREMIUM_MAX_LIMIT,
          floor: DISCOUNT_PREMIUM_MIN_LIMIT,
          top: '85%',
          height: '15%',
          resize: {
            enabled: true
          },
          offset: 0
        }
      ],
      navigator: {
        height: 40,
        maskFill: 'rgba(50,50,50,0.5)',
        maskInside: false,
        outlineWidth: 0.5,
        outlineColor: 'transparent',
        series: {
          color: 'transparent',
          lineColor: 'var(--white)'
        },
        xAxis: {
          overscroll: ETime.milliseconds.perMonth
        }
      },
      xAxis: {
        tickLength: 5,
        overscroll: ETime.milliseconds.perMonth,
        tickPixelInterval: 50,
        maxRange: Date.now() - this.valuationsData?.average?.first()?.[0],
        labels: {
          formatter: (val) => {
            const prevTick =
              val?.axis?.tickPositions?.[val.axis.tickPositions.indexOf(val.value) - 1] ||
              val.value - (val.axis.tickPositions[1] - val.axis.tickPositions[0]);
            const prevMonth = new Date(prevTick).getMonth();
            const prevYear = new Date(prevTick).getFullYear();
            const thisMonth = new Date(val.value).getMonth();
            const thisYear = new Date(val.value).getFullYear();
            if (val.tickPositionInfo.unitName === 'month') {
              if (prevYear !== thisYear) {
                return new Date(val.value).getFullYear();
              }
              return `<span style="color: #888">${new Date(val.value).toLocaleDateString('en-US', { month: 'short' })}</span>`;
            }
            if (val.tickPositionInfo.unitName === 'week') {
              if (prevMonth === thisMonth && prevYear === thisYear) {
                return `<span style="color: #888">${new Date(val.value).toLocaleDateString('en-US', { day: 'numeric' })}</span>`;
              }
              return new Date(val.value).toLocaleDateString('en-US', { month: 'short' });
            }
            return Highcharts.dateFormat(val.dateTimeLabelFormat, val.value);
          }
        }
      },
      stockTools: {
        collapsed: true
      },
      tooltip: {
        shape: 'square',
        headerShape: 'callout',
        borderWidth: 0,
        shadow: false
      },
      series: [
        {
          type: 'line',
          id: `${this?.company?.companyCode}-ohlc`,
          name: `Price`,
          data: this.closePrices,
          color: 'var(--white)',
          lineWidth: 1,
          marker: {
            symbol: 'circle'
          },
          tooltip: {
            pointFormat: `<span style="color:var(--white)">●</span> Share price: ${this?.company?.currencies?.sharePrices?.symbol()}{point.y:.2f}`
          }
        },
        ...(this.showAdvanced
          ? [
              {
                type: 'line',
                id: `${this?.company?.companyCode}-hv-net-profit`,
                name: `(HV) Net profit`,
                data: this.valuationsData?.netProfit,
                color: 'var(--success)',
                lineWidth: 1,
                marker: {
                  symbol: 'circle'
                },
                tooltip: {
                  pointFormat: `<span style="color:var(--success)">●</span> <span style="color: var(--light)"> (HV) Net profit: ${this?.company?.currencies?.sharePrices?.symbol()}{point.y:.2f}</span>`
                }
              },
              {
                type: 'line',
                id: `${this?.company?.companyCode}-hv-revenue`,
                name: `(HV) Revenue`,
                data: this.valuationsData?.revenue,
                color: 'var(--danger)',
                lineWidth: 1,
                marker: {
                  symbol: 'circle'
                },
                tooltip: {
                  pointFormat: `<span style="color:var(--danger)">●</span> <span style="color: var(--light)"> (HV) Revenue: ${this?.company?.currencies?.sharePrices?.symbol()}{point.y:.2f}</span>`
                }
              },
              {
                type: 'line',
                id: `${this?.company?.companyCode}-hv-book-value`,
                name: `(HV) Book Value`,
                data: this.valuationsData?.bookValue,
                color: 'var(--warning)',
                lineWidth: 1,
                marker: {
                  symbol: 'circle'
                },
                tooltip: {
                  pointFormat: `<span style="color:var(--warning)">●</span> <span style="color: var(--light)"> (HV) Book Value: ${this?.company?.currencies?.sharePrices?.symbol()}{point.y:.2f}</span>`
                }
              },
              {
                type: 'line',
                id: `${this?.company?.companyCode}-hv-cash-flow`,
                name: `(HV) Cash Flow`,
                data: this.valuationsData?.cashFlow,
                color: 'var(--info)',
                lineWidth: 1,
                marker: {
                  symbol: 'circle'
                },
                tooltip: {
                  pointFormat: `<span style="color:var(--info)">●</span> <span style="color: var(--light)"> (HV) Cash Flow: ${this?.company?.currencies?.sharePrices?.symbol()}{point.y:.2f}</span>`
                }
              }
            ]
          : []),
        {
          type: 'line',
          id: `${this?.company?.companyCode}-hv-average`,
          name: `Fundamentl valuation`,
          data: this.valuationsData?.average,
          color: 'var(--primary)',
          lineWidth: 1,
          marker: {
            symbol: 'circle'
          },
          tooltip: {
            pointFormat: `<span style="color:var(--primary)">●</span> FV: ${this?.company?.currencies?.sharePrices?.symbol()}{point.y:.2f}`
          }
        },
        {
          type: 'area',
          id: `${this?.company?.companyCode}-discount`,
          name: `Discount/premium`,
          data: this.discountData,
          color: 'var(--success)',
          fillColor: 'var(--success)',
          negativeColor: 'var(--danger)',
          negativeFillColor: 'var(--danger)',
          yAxis: 1,
          marker: {
            symbol: 'circle'
          },
          tooltip: {
            pointFormatter() {
              if (this.y < 0) {
                return `<span style="color:var(--danger)">●</span> Premium: ${-this.y >= -DISCOUNT_PREMIUM_MIN_LIMIT ? '>' : ''}${
                  -this.y >= -DISCOUNT_PREMIUM_MIN_LIMIT ? -DISCOUNT_PREMIUM_MIN_LIMIT : -this.y.round().toLocaleString()
                }%`;
              }
              return `<span style="color:var(--success)">●</span> Discount: ${this.y >= DISCOUNT_PREMIUM_MAX_LIMIT ? '>' : ''}${
                this.y >= DISCOUNT_PREMIUM_MAX_LIMIT ? DISCOUNT_PREMIUM_MAX_LIMIT : this.y.round().toLocaleString()
              }%`;
            }
          }
        }
      ],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: '100%'
            },
            chartOptions: {
              rangeSelector: {
                inputEnabled: false
              }
            }
          }
        ]
      }
    };
  }

  async loadCompany() {
    const companyCode = this?.$route?.params?.companyCode;
    this.company = new Company({ companyCode });
    await this.company.load({
      general: true,
      shares: {
        splits: true
      },
      currencies: true,
      valuations: {
        fundamentlValuations: true
      }
    });
  }

  async mounted() {
    await this.loadCompany();
    this.setTitle(`${this?.company?.general?.name} - Fundamentl valuation`);

    await this.initChart();
    this.setChartOptions();
    this.loaded = true;
  }
}
export default CompanyDetailsFundamentlValuation;
</script>
