<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import financialDataEnum from '../financial-data-enum.json';

import { CompanyScreener, CompanyComparisonTable, SignUpModal, LoginModal, CompanySearch, Lazyload } from '../../components/index.js';

@Component({
  name: 'stock-comparison',
  data: () => ({ financialDataEnum }),
  components: { CompanyScreener, CompanyComparisonTable, SignUpModal, LoginModal, CompanySearch, Lazyload }
})
class StockComparison extends Vue {
  dataLoaded = false;
  editModeEnabled = false;
  currentTab = 0;

  comparisons = null;
  companyCodes = [];

  newComparisonForm = {
    name: '',
    order: 0,
    companyCodes: []
  };

  signUpModalVisible = false;
  loginModalVisible = false;

  handleAddAllCompaniesClick(comparison) {
    if (!comparison) {
      const { companies } = this.$refs.companyScreener;
      companies.forEach((company) => {
        const { companyCode } = company;
        if (!this.newComparisonForm.companyCodes.includes(companyCode)) {
          this.newComparisonForm.companyCodes.push(companyCode);
        }
      });
    } else {
      const { companies } = this.$refs[`companyScreener-${comparison._id}`].first();
      companies.forEach((company) => {
        const { companyCode } = company;
        if (!comparison.companyCodes.includes(companyCode)) {
          comparison.companyCodes.push(companyCode);
        }
      });
    }
  }

  handleCompanyClick({ company }, comparison) {
    const { companyCode } = company;
    if (!comparison && !this.newComparisonForm.companyCodes.includes(companyCode)) {
      this.newComparisonForm.companyCodes.push(companyCode);
    } else if (comparison && !comparison.companyCodes.includes(companyCode)) {
      comparison.companyCodes.push(companyCode);
    }
  }

  async handleSaveComparisonClick(comparison) {
    if (!comparison) {
      // Create new comparison
      try {
        await this.StockInvestingApi.comparisonsCreate(this.newComparisonForm);
        this.ToastSuccess('Comparison was added');
        this.reloadPage();
      } catch (err) {
        console.error(err);
        if (err?.errors?.length) {
          this.ToastError(err?.errors[0]);
        }
      }
    } else {
      // Update existing comparison
      try {
        await this.StockInvestingApi.comparisonsUpdate(comparison);
        this.ToastSuccess('Comparison was updated');
      } catch (err) {
        console.error(err);
        if (err?.errors?.length) {
          this.ToastError(err?.errors[0]);
        }
      }
    }
  }

  async handleDeleteComparisonClick(comparison) {
    if (!confirm(`Are you sure you want to delete ${comparison.name}?`)) return;

    try {
      await this.StockInvestingApi.comparisonsDelete(comparison);
      this.ToastSuccess('Comparison was deleted');
      this.reloadPage();
    } catch (err) {
      console.error(err);
      if (err?.errors?.length) {
        this.ToastError(err.errors[0]);
      }
    }
  }

  async loadComparisons() {
    try {
      if (this.store.user) {
        this.comparisons = await this.StockInvestingApi.comparisonsList();
      }
    } catch (err) {
      console.error(err);
    }
  }

  handleTabChange(comparison, tabIndex) {
    this.currentTab = tabIndex;
  }

  async handleEditModeToggle() {
    if (!this.editModeEnabled) {
      this.editModeEnabled = true;
    } else {
      await this.reloadPage();
    }
  }

  handleComparisonTableReady(tabIndex) {
    if (tabIndex === 0) {
      this.handleTabChange(this.comparisons[0], 0);
    }
  }

  async reloadPage() {
    this.dataLoaded = false;
    this.editModeEnabled = false;
    this.currentTab = 0;

    this.comparisons = null;
    this.companyCodes = [];
    this.newComparisonForm.companyCodes = [];

    await this.loadComparisons();
    this.comparisons.sort((a, b) => a.order - b.order);

    this.dataLoaded = true;
  }

  async mounted() {
    this.setTitle('Comparison');
    await this.reloadPage();
  }
}
export default StockComparison;
</script>
