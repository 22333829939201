import { ETime } from './enums.js';

/* eslint-disable no-extend-native, func-names */

/** ************* */
/** ** STRING *** */
/** ************* */

// First, checks if it isn't implemented yet.
if (!String.prototype.format) {
  String.prototype.format = function (...args) {
    return this.replace(/{(\d+)}/g, (match, number) => {
      return typeof args[number] !== 'undefined' ? args[number] : match;
    });
  };
}

if (!String.prototype.kebabToCamel) {
  String.prototype.kebabToCamel = function () {
    return this.replace(/-./g, (x) => x[1].toUpperCase());
  };
}

if (!String.prototype.camelToKebab) {
  String.prototype.camelToKebab = function () {
    return this.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
  };
}

/** ************* */
/** ** NUMBER *** */
/** ************* */

if (!Number.prototype.forEach) {
  Number.prototype.forEach = function (callback) {
    return new Array(Math.floor(this))
      .fill(null)
      .map((value, index) => index)
      .forEach((i) => callback(i));
  };
}

if (!Number.prototype.map) {
  Number.prototype.map = function (callback) {
    return new Array(Math.floor(this)).fill(null).map((value, index) => callback(index));
  };
}

if (!Number.prototype.round) {
  Number.prototype.round = function (maxDigits = 2) {
    return parseFloat(this.toFixed(maxDigits));
  };
}
if (!Number.prototype.precision) {
  Number.prototype.precision = function (digits = 3) {
    return Number(Number(this).toPrecision(digits));
  };
}

if (!Number.prototype.parseInt) {
  Number.prototype.parseInt = function () {
    return parseInt(this);
  };
}

if (!Number.prototype.parseFloat) {
  Number.prototype.parseFloat = function () {
    return parseFloat(this);
  };
}

/** *********** */
/** ** DATE *** */
/** *********** */

// Set time (hours, minutes, seconds, milliseconds) to 0)
if (!Date.prototype.removeTime) {
  Date.prototype.removeTime = function () {
    return new Date(this.setHours(0, 0, 0, 0));
  };
}

// Set time to UTC time
if (!Date.prototype.toUTC) {
  Date.prototype.toUTC = function () {
    return new Date(this.getTime() - this.getTimezoneOffset() * ETime.milliseconds.perMinute);
  };
}

// Set time to UTC time
if (!Date.prototype.fromUTC) {
  Date.prototype.fromUTC = function () {
    return new Date(this.getTime() + this.getTimezoneOffset() * ETime.milliseconds.perMinute);
  };
}

// Get quarter
if (!Date.prototype.getQuarter) {
  Date.prototype.getQuarter = function () {
    return Math.ceil((this.getMonth() + 1) / ETime.months.perQuarter);
  };
}

// Get fiscal period
if (!Date.prototype.getFiscalPeriod) {
  Date.prototype.getFiscalPeriod = function (fiscalYearEndMonth) {
    const quartersPerYear = 4;
    const fiscalYearEndDate = new Date(this.getFullYear(), fiscalYearEndMonth + 1, 0);

    let quarter = (quartersPerYear + this.getQuarter() - fiscalYearEndDate.getQuarter()) % quartersPerYear;
    quarter = quarter === 0 ? 4 : quarter;

    const year = new Date(this.setMonth(this.getMonth() + ETime.months.perQuarter * (ETime.quarters.perYear - quarter))).getFullYear();

    return { quarter, year };
  };
}

// Closest
if (!Date.prototype.closest) {
  Date.prototype.closest = function (arr = [], key) {
    const arrCopy = [...arr];
    return arrCopy.sort((a, b) => Math.abs(a[key].getTime() - this.getTime()) - Math.abs(b[key].getTime() - this.getTime()))?.first();
  };
}

/** ************ */
/** ** ARRAY *** */
/** ************ */

// Gets first element from array
if (!Array.prototype.first) {
  Array.prototype.first = function () {
    return this?.[0];
  };
}

// Gets last element from array
if (!Array.prototype.last) {
  Array.prototype.last = function () {
    return this?.slice(-1)?.[0];
  };
}

// Filters an array to only contain unique values
if (!Array.prototype.distinct) {
  Array.prototype.distinct = function (key = null) {
    const unique = [];
    this.forEach((item) => {
      if (typeof item === typeof Object()) {
        if (!unique.find((uniqueItem) => uniqueItem[key] === item[key])) {
          unique.push(item);
        }
      } else if (!unique.includes(item)) {
        unique.push(item);
      }
    });
    return unique;
  };
}

// Calculates sum of values
if (!Array.prototype.sum) {
  Array.prototype.sum = function () {
    if (!this.length) return 0;
    return this.reduce((a, b) => a + b);
  };
}

// Calculates mean of values
if (!Array.prototype.mean) {
  Array.prototype.mean = function () {
    return this.sum() / this.length;
  };
}

// Calculates CAGR
if (!Array.prototype.cagr) {
  Array.prototype.cagr = function () {
    const first = this.first();
    const last = this.last();
    if (first > 0 && last > 0) {
      return ((last / first) ** (1 / (this.length - 1)) - 1) * 100;
    }
    if (first < 0 && last < 0) {
      return -1 * ((Math.abs(last) / Math.abs(first)) ** (1 / (this.length - 1)) - 1) * 100;
    }
    if (first < 0 && last > 0) {
      return (((last + 2 * Math.abs(first)) / Math.abs(first)) ** (1 / (this.length - 1)) - 1) * 100;
    }
    if (first > 0 && last < 0) {
      return -1 * (((Math.abs(last) + 2 * first) / first) ** (1 / (this.length - 1)) - 1) * 100;
    }
    return 0;
  };
}

// Gets highest value of array
if (!Array.prototype.max) {
  Array.prototype.max = function () {
    return !this?.length ? null : this.reduce((a, b) => (a > b ? a : b));
  };
}

// Gets lowest value of array
if (!Array.prototype.min) {
  Array.prototype.min = function () {
    return !this?.length ? null : this.reduce((a, b) => (a > b ? b : a));
  };
}

// Calculates the % change
if (!Array.prototype.pctChange) {
  Array.prototype.pctChange = function () {
    if (this.length >= 2) {
      return (this.length - 1).map((i) => (this[i + 1] / this[i]) * 100 - 100);
    }
    return null;
  };
}

// Calculates the nominal change
if (!Array.prototype.change) {
  Array.prototype.change = function () {
    if (this.length >= 2) {
      return (this.length - 1).map((i) => this[i + 1] - this[i]);
    }
    return null;
  };
}

// Forward fill array
if (!Array.prototype.ffill) {
  Array.prototype.ffill = function () {
    return this.map((value, index) => value || this?.[index - 1] || null);
  };
}

// Backfill array
if (!Array.prototype.bfill) {
  Array.prototype.bfill = function () {
    return this.reverse().ffill().reverse();
  };
}

// Avg fill array
if (!Array.prototype.avgFill) {
  Array.prototype.avgFill = function () {
    const newArray = [];
    for (let i = 0; i < this.length; i++) {
      if (this[i] === null && this[i - 1]) {
        const nullRangeStart = i;
        let nullRangeEnd = null;
        for (let y = i; y < this.length; y++) {
          if (this[y] !== null) {
            nullRangeEnd = y - 1;
            break;
          }
        }
        if (nullRangeEnd === null) {
          for (let y = nullRangeStart; y < this.length; y++) {
            newArray.push(null);
          }
          break;
        }
        const firstNumber = this[nullRangeStart - 1];
        const lastNumber = this[nullRangeEnd + 1];
        const nullAmount = nullRangeEnd - nullRangeStart;
        const stepSize = (lastNumber - firstNumber) / (nullAmount + 2);
        for (let y = 0; y <= nullAmount; y++) {
          newArray.push(firstNumber + stepSize * (y + 1));
        }
        i += nullAmount;
      } else {
        newArray.push(this[i]);
      }
    }
    return newArray;
  };
}

// Filter outliers
if (!Array.prototype.filterOutliers) {
  Array.prototype.filterOutliers = function (percentile = 0.9, fillNull = false) {
    // Copy the values, rather than operating on references to existing values
    const values = this.concat();

    // Then sort
    const sortedValues = values.concat().sort((a, b) => {
      return a - b;
    });

    /* Then find a generous IQR. This is generous because if (values.length / 4)
     * is not an int, then really you should average the two elements on either
     * side to find q1.
     */
    const q1 = sortedValues[Math.floor(sortedValues.length * (1 - percentile))];
    // Likewise for q3.
    const q3 = sortedValues[Math.ceil(sortedValues.length * percentile)];
    const iqr = q3 - q1;

    // Then find min and max values
    const maxValue = q3 + iqr * 1.5;
    const minValue = q1 - iqr * 1.5;

    // Then filter anything beyond or beneath these values.
    let filteredValues;
    if (fillNull) {
      filteredValues = values.map((x) => (x <= maxValue && x >= minValue ? x : null));
    } else {
      filteredValues = values.filter((x) => x <= maxValue && x >= minValue);
    }

    // Then return
    return filteredValues;
  };
}
