<template src="./template.vue"></template>
<script>
import Component from 'vue-class-component';
import Vue from 'vue';
import jwt from 'jsonwebtoken';
import { ERoles } from '../../../shared/models/user/index.js';

const IS_DEVELOPMENT = process?.env?.NODE_ENV === 'development';

@Component({
  name: 'dev-tools',
  props: {},
  data: () => ({
    IS_DEVELOPMENT,
    ERoles
  })
})
class DevTools extends Vue {
  createTest() {
    this.$set(this.store.tests, this.$refs.inputNewTest.localValue.kebabToCamel(), true);
    this.$refs.inputNewTest.localValue = '';
  }

  updateTestName(oldName, newName) {
    this.$set(this.store.tests, newName.kebabToCamel(), this.store.tests[oldName.kebabToCamel()].toString());
    this.$delete(this.store.tests, oldName.kebabToCamel());
    this.$forceUpdate();
  }

  handleRoleCheckboxChange(role) {
    this.store.rolesManipulated = true;
    const cookie = jwt.decode(this.$cookie.get(process.env.JWT_TOKEN_NAME));
    if (cookie) {
      if (this.store.user.hasRole(role)) {
        const userRoleIndex = this.store.user.roles.findIndex((userRole) => userRole.id === role.id);
        this.store.user.roles.splice(userRoleIndex, 1);
        const cookieRoleIndex = cookie.roles.find((cookieRole) => cookieRole === role.id);
        cookie.roles.splice(cookieRoleIndex, 1);
      } else {
        this.store.user.roles.push(role);
        cookie.roles.push(role.id);
      }
      this.$cookie.set(process.env.JWT_TOKEN_NAME, jwt.sign(cookie, 'notsecret'), {
        expires: new Date(cookie.exp * 1000),
        path: '/',
        domain: '.fundamentl.io',
        sameSite: 'Lax'
      });
    }
  }
}
export default DevTools;
</script>
<style lang="scss" scoped>
@import './style.scss';
</style>
