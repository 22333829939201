var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid px-4 py-3",attrs:{"id":"company-details-share-summary"}},[_c('b-row',[_c('b-col',{attrs:{"cols":12}},[_c('c-table',{ref:"table",staticClass:"mb-4",attrs:{"small":"","sticky-header":"","sticky-column":"","responsive":"","variant":"financial"}},[(_vm.filteredSharePriceYearly)?_c('thead',[_c('tr',[_c('th',{staticClass:"text-nowrap",attrs:{"sticky-column":""}},[_vm._v(" Share prices "),(_vm.company.currencies.sharePrices.iso())?_c('span',[_vm._v("("+_vm._s(_vm.company.currencies.sharePrices.iso())+")")]):_vm._e()]),_vm._l((_vm.filteredSharePriceYearly.map((candlestick) => new Date(candlestick.date).getFullYear())),function(year,index){return _c('th',{key:index},[_vm._v(" "+_vm._s(year)+" ")])}),_c('th',{staticClass:"text-nowrap sticky-right sticky-right-0"},[_vm._v("Avg. 5 years")]),_c('th',{staticClass:"text-nowrap sticky-right sticky-right-1"},[_vm._v("Avg. 10 years")]),_c('th',{staticClass:"text-nowrap sticky-right sticky-right-2"},[_vm._v("Avg. all time")])],2)]):_vm._e(),(_vm.filteredSharePriceYearly)?_c('tbody',[_c('tr',[_c('th',[_vm._v("Close")]),_vm._l((_vm.filteredSharePriceYearly),function(candlestick,index){return _c('td',{key:index},[_c('div',{staticClass:"d-inline-flex flex-column"},[_vm._v(" "+_vm._s(_vm._f("financial")(candlestick.close))+" "),(index !== 0)?_c('small',{class:[candlestick.close > _vm.filteredSharePriceYearly[index - 1].close ? 'text-success' : 'text-danger']},[_vm._v(" "+_vm._s(_vm._f("percentage")([_vm.filteredSharePriceYearly[index - 1].close, candlestick.close].pctChange().first()))+" ")]):_vm._e()])])}),_vm._l((_vm.AVERAGE_YEARS),function(years,index){return _c('td',{key:`close-${years}`,class:`sticky-right sticky-right-${index}`},[_c('span',{class:[
                  _vm.filteredSharePriceYearly
                    .map((ohlc) => ohlc.close)
                    .slice(0, years)
                    .pctChange()
                    .mean() > 0
                    ? 'text-success'
                    : 'text-danger'
                ]},[_vm._v(" "+_vm._s(_vm._f("percentage")(_vm.filteredSharePriceYearly .map((ohlc) => ohlc.close) .slice(0, years) .pctChange() .mean()))+" ")])])})],2),_c('tr',[_c('th',[_vm._v("High")]),_vm._l((_vm.filteredSharePriceYearly),function(candlestick,index){return _c('td',{key:index},[_c('div',{staticClass:"d-inline-flex flex-column"},[_vm._v(" "+_vm._s(_vm._f("financial")(candlestick.high))+" "),(index !== 0)?_c('small',{class:[candlestick.high > _vm.filteredSharePriceYearly[index - 1].high ? 'text-success' : 'text-danger']},[_vm._v(" "+_vm._s(_vm._f("percentage")([_vm.filteredSharePriceYearly[index - 1].high, candlestick.high].pctChange().first()))+" ")]):_vm._e()])])}),_vm._l((_vm.AVERAGE_YEARS),function(years,index){return _c('td',{key:`high-${years}`,class:`sticky-right sticky-right-${index}`},[_c('span',{class:[
                  _vm.filteredSharePriceYearly
                    .map((ohlc) => ohlc.high)
                    .slice(0, years)
                    .pctChange()
                    .mean() > 0
                    ? 'text-success'
                    : 'text-danger'
                ]},[_vm._v(" "+_vm._s(_vm._f("percentage")(_vm.filteredSharePriceYearly .map((ohlc) => ohlc.high) .slice(0, years) .pctChange() .mean()))+" ")])])})],2),_c('tr',[_c('th',[_vm._v("Low")]),_vm._l((_vm.filteredSharePriceYearly),function(candlestick,index){return _c('td',{key:index},[_c('div',{staticClass:"d-inline-flex flex-column"},[_vm._v(" "+_vm._s(_vm._f("financial")(candlestick.low))+" "),(index !== 0)?_c('small',{class:[candlestick.low > _vm.filteredSharePriceYearly[index - 1].low ? 'text-success' : 'text-danger']},[_vm._v(" "+_vm._s(_vm._f("percentage")([_vm.filteredSharePriceYearly[index - 1].low, candlestick.low].pctChange().first()))+" ")]):_vm._e()])])}),_vm._l((_vm.AVERAGE_YEARS),function(years,index){return _c('td',{key:`low-${years}`,class:`sticky-right sticky-right-${index}`},[_c('span',{class:[
                  _vm.filteredSharePriceYearly
                    .map((ohlc) => ohlc.low)
                    .slice(0, years)
                    .pctChange()
                    .mean() > 0
                    ? 'text-success'
                    : 'text-danger'
                ]},[_vm._v(" "+_vm._s(_vm._f("percentage")(_vm.filteredSharePriceYearly .map((ohlc) => ohlc.low) .slice(0, years) .pctChange() .mean()))+" ")])])})],2),_c('tr',[_c('th',[_vm._v("Mean")]),_vm._l((_vm.filteredSharePriceYearly),function(candlestick,index){return _c('td',{key:index},[_c('div',{staticClass:"d-inline-flex flex-column"},[_vm._v(" "+_vm._s(_vm._f("financial")(candlestick.mean))+" "),(index !== 0)?_c('small',{class:[candlestick.mean > _vm.filteredSharePriceYearly[index - 1].mean ? 'text-success' : 'text-danger']},[_vm._v(" "+_vm._s(_vm._f("percentage")([_vm.filteredSharePriceYearly[index - 1].mean, candlestick.mean].pctChange().first()))+" ")]):_vm._e()])])}),_vm._l((_vm.AVERAGE_YEARS),function(years,index){return _c('td',{key:`mean-${years}`,class:`sticky-right sticky-right-${index}`},[_c('span',{class:[
                  _vm.filteredSharePriceYearly
                    .map((ohlc) => ohlc.mean)
                    .slice(0, years)
                    .pctChange()
                    .mean() > 0
                    ? 'text-success'
                    : 'text-danger'
                ]},[_vm._v(" "+_vm._s(_vm._f("percentage")(_vm.filteredSharePriceYearly .map((ohlc) => ohlc.mean) .slice(0, years) .pctChange() .mean()))+" ")])])})],2)]):_vm._e()])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }