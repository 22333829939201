/**
 * @name CompanyScreenerItem
 * @param {object} obj The filter
 * @param {string} obj.key The key used in the database
 * @param {string} obj.collection The MongoDB collection where the value can be found
 * @param {boolean} obj.sortable=true Whether or not the field is sortable (both frontend and supported in the backend)
 * @param {any} obj.value=null Whether or not the field is sortable (both frontend and supported in the backend)
 * <br/><strong>Name</strong>
 * @param {object} obj.name The names/labels of the filter
 * @param {string} obj.name.default Default name
 * @param {string} obj.name.filterModal=undefined Name used in the filter modal
 * @param {string} obj.name.columnsModal=undefined Name used in the columns modal
 * <br/><strong>Column</strong>
 */
class CompanyScreenerItem {
  static CATEGORY = {
    GENERAL: 'General',
    VALUATION: 'Valuation',
    RATIOS: 'Ratios',
    DIVIDEND: 'Dividend',
    SHARE: 'Share',
    GROWTH: {
      label: 'Growth',
      sub: {
        REVENUE_GROWTH: 'Avg. revenue growth',
        NET_INCOME_GROWTH: 'Avg. net income growth',
        EBITDA_GROWTH: 'Avg. EBITDA growth',
        EBIT_GROWTH: 'Avg. EBIT growth',
        CASH_AND_EQUIVALENTS_GROWTH: 'Avg. cash & equivalents growth',
        GROSS_PROFIT_GROWTH: 'Avg. gross profit growth',
        TOTAL_ASSETS_GROWTH: 'Avg. total assets growth',
        TOTAL_DEBT_GROWTH: 'Avg. total debt growth',
        TOTAL_EQUITY_GROWTH: 'Avg. total equity growth',
        EPS_GROWTH: 'Avg. EPS growth',
        DIVIDEND_YIELD_GROWTH: 'Avg. dividend yield growth'
      }
    }
  };

  constructor({ key, name, category, column = null, filter = null }) {
    this.key = key;
    this.name = {
      default: name?.default || name,
      filterModal: name?.filterModal || name?.default || name,
      columnsModal: name?.columnsModal || name?.default || name,
      table: name?.table || name?.default || name,
      visualizer: name?.visualizer || name?.default || name,
      long: name?.long || null
    };
    this.category = category;

    if (column) {
      this.column = column;
      if (!this.column?.keys?.length) {
        this.column.keys.push(this.key);
      }
    }

    if (filter) {
      if (Object.keys(filter).includes('key')) {
        // Has one filter in root level
        this.filter = filter;
        this.filter.name = name;
        this.filter.key = filter?.key || key;
      } else if (filter) {
        // Has multiple filters in the second level
        this.filter = {};
        Object.keys(filter).forEach((filterKey) => {
          this.filter[filterKey] = filter?.[filterKey];
          this.filter[filterKey].name = name;
          this.filter[filterKey].key = filter?.[filterKey]?.key || key;
        });
      }
    }

    const configError = this.validateConfig();
    if (configError) {
      console.error(configError);
    }
  }

  validateConfig() {
    return null;
  }

  get isValid() {
    return !!this.errors.length;
  }

  get errors() {
    let errors = [];
    if (this.filter?.key) {
      errors = errors.concat(this.filter.errors);
    } else {
      Object.values(this.filter).forEach((filter) => {
        errors = errors.concat(filter.errors);
      });
    }
    return errors || [];
  }

  mongoQuery(includeCollectionName = true) {
    let mongoQueries = [];
    if (this.filter?.key) {
      mongoQueries = mongoQueries.concat(this.filter.mongoQuery(includeCollectionName));
    } else {
      Object.values(this.filter).forEach((filter) => {
        mongoQueries = mongoQueries.concat(filter.mongoQuery(includeCollectionName));
      });
    }
    return mongoQueries || [];
  }
}

export { CompanyScreenerItem };
