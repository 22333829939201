export const ERoles = {};

class Role {
  constructor({ id = null, name = null }) {
    this.id = id;
    this.name = name;
  }
}

export class RoleGuest extends Role {
  static id = 'guest';
  static name = 'Guest';
}
ERoles.guest = RoleGuest;

export class RoleUser extends Role {
  static id = 'user';
  static name = 'User';
}
ERoles.user = RoleUser;

export class RolePackageTier1 extends Role {
  static id = 'packageTier1';
  static name = 'Basic';
  static isSubscription = true;
}
ERoles.packageTier1 = RolePackageTier1;

export class RolePackageTier2 extends Role {
  static id = 'packageTier2';
  static name = 'Advanced';
  static isSubscription = true;
}
ERoles.packageTier2 = RolePackageTier2;

export class RolePackageTier3 extends Role {
  static id = 'packageTier3';
  static name = 'Pro';
  static isSubscription = true;
}
ERoles.packageTier3 = RolePackageTier3;

export class RoleBetatester extends Role {
  static id = 'betatester';
  static name = 'Betatester';
}
ERoles.betatester = RoleBetatester;

export class RoleAdmin extends Role {
  static id = 'admin';
  static name = 'Admin';
}
ERoles.admin = RoleAdmin;

export class RoleLegacy20220831 extends Role {
  static id = 'legacy20220831';
  static name = 'Legacy package (EOL: 2022-08-31)';
  static isSubscription = true;
}
ERoles.legacy20220831 = RoleLegacy20220831;

const legacyRoles = [RoleLegacy20220831];
const ERoleGroups = {
  ALL: [RoleGuest, RoleAdmin, RoleUser, RolePackageTier1, RolePackageTier2, RolePackageTier3, ...legacyRoles],
  LOGGED_IN: [RoleAdmin, RoleUser, RolePackageTier1, RolePackageTier2, RolePackageTier3, ...legacyRoles],
  SUBSCRIBED: [RoleAdmin, RolePackageTier1, RolePackageTier2, RolePackageTier3, ...legacyRoles],
  LEGACY_ROLES: legacyRoles
};

export const EPermissions = {
  ADMIN_MODE: [RoleAdmin],
  COMPANIES: {
    UI: ERoleGroups.ALL,
    LIST: ERoleGroups.ALL,
    READ: ERoleGroups.ALL
  },
  HISTORIC_VALUATION: {
    UI: ERoleGroups.ALL,
    LIST: ERoleGroups.LOGGED_IN,
    READ: ERoleGroups.LOGGED_IN,
    UPDATE: [RoleAdmin, RolePackageTier2, RolePackageTier3],
    DELETE: [RoleAdmin, RolePackageTier2, RolePackageTier3],
    CREATE: [RoleAdmin, RolePackageTier2, RolePackageTier3]
  },
  DCF_VALUATION: {
    UI: [RoleAdmin, RolePackageTier3, RoleLegacy20220831],
    LIST: ERoleGroups.LOGGED_IN,
    READ: ERoleGroups.LOGGED_IN,
    UPDATE: [RoleAdmin, RolePackageTier3, RoleLegacy20220831],
    DELETE: [RoleAdmin, RolePackageTier3, RoleLegacy20220831],
    CREATE: [RoleAdmin, RolePackageTier3, RoleLegacy20220831]
  },
  COMPANY_MANAGEMENT: {
    READ: [RolePackageTier2, RolePackageTier3, RoleAdmin]
  },
  COMPANY_HOLDERS: {
    READ: [RolePackageTier3, RoleAdmin]
  },
  COMPANY_ANALYST_RATINGS: {
    READ: [RolePackageTier2, RolePackageTier3, RoleAdmin]
  },
  COMPANY_COMPETITORS: {
    READ: [RolePackageTier2, RolePackageTier3, RoleAdmin]
  },
  COMPANY_INSIDER_TRADING: {
    READ: [RolePackageTier2, RolePackageTier3, RoleAdmin]
  },
  COMPANY_ESG: {
    READ: [RolePackageTier3, RoleAdmin]
  },
  FUNDAMENTL_VALUATION: {
    UI: ERoleGroups.ALL,
    READ: [RolePackageTier2, RolePackageTier3, RoleAdmin, RoleLegacy20220831]
  },
  VALUATIONS: {
    UI: ERoleGroups.ALL,
    LIST: ERoleGroups.LOGGED_IN,
    DELETE: ERoleGroups.LOGGED_IN,
    CREATE: [RolePackageTier2, RolePackageTier3, RoleAdmin]
  },
  TAGS: {
    UI: ERoleGroups.LOGGED_IN,
    LIST: ERoleGroups.LOGGED_IN,
    DELETE: ERoleGroups.LOGGED_IN,
    CREATE: ERoleGroups.LOGGED_IN
  },
  DATA_TAGGING: {
    LIST: [RolePackageTier2, RolePackageTier3, RoleAdmin],
    CREATE: [RolePackageTier2, RolePackageTier3, RoleAdmin],
    DELETE: [RolePackageTier2, RolePackageTier3, RoleAdmin],
    UPDATE: [RolePackageTier2, RolePackageTier3, RoleAdmin]
  },
  COMPARISONS: {
    UI: [RoleAdmin],
    LIST: ERoleGroups.SUBSCRIBED,
    UPDATE: ERoleGroups.SUBSCRIBED,
    DELETE: ERoleGroups.SUBSCRIBED,
    CREATE: ERoleGroups.SUBSCRIBED
  },
  PORTFOLIOS: {
    UI: [RoleAdmin],
    LIST: ERoleGroups.LOGGED_IN,
    READ: ERoleGroups.LOGGED_IN,
    UPDATE: ERoleGroups.LOGGED_IN,
    DELETE: ERoleGroups.LOGGED_IN,
    CREATE: ERoleGroups.LOGGED_IN,
    TRANSACTION: {
      DELETE: ERoleGroups.LOGGED_IN,
      CREATE: ERoleGroups.LOGGED_IN
    }
  },
  CURRENCY_EXCHANGE_RATE_HISTORY: {
    READ: ERoleGroups.ALL
  },
  FUNCTIONS: {
    COMPANIES_PRICE: {
      READ: ERoleGroups.ALL
    },
    SECTOR_LIST: {
      READ: ERoleGroups.ALL
    },
    COUNTRY_LIST: {
      READ: ERoleGroups.ALL
    },
    EXCHANGE_LIST: {
      READ: ERoleGroups.ALL
    }
  },
  DATA_MANAGER: {
    UI: [RoleAdmin],
    READ: [RoleAdmin]
  },
  USERS: {
    LIST: ERoleGroups.LOGGED_IN,
    READ: [RoleAdmin]
  },
  PAYMENT: {
    PAYMENT_METHOD: {
      CREDIT_CARD: {
        LIST: ERoleGroups.LOGGED_IN,
        CREATE: ERoleGroups.LOGGED_IN,
        DELETE: ERoleGroups.LOGGED_IN
      }
    },
    SUBSCRIPTION: {
      READ: ERoleGroups.SUBSCRIBED,
      LIST: ERoleGroups.ALL,
      CREATE: ERoleGroups.LOGGED_IN,
      DELETE: ERoleGroups.SUBSCRIBED,
      UPDATE: ERoleGroups.SUBSCRIBED,
      CALCULATE_TAX: ERoleGroups.LOGGED_IN
    },
    ACCOUNTANCY: {
      INVOICES: {
        LIST: ERoleGroups.SUBSCRIBED
      },
      CREDIT_NOTES: {
        LIST: ERoleGroups.SUBSCRIBED
      }
    },
    CUSTOMER: {
      READ: ERoleGroups.LOGGED_IN,
      UPDATE: ERoleGroups.LOGGED_IN
    }
  },
  PROFILE: {
    READ: ERoleGroups.LOGGED_IN,
    UPDATE: ERoleGroups.LOGGED_IN
  },
  DASHBOARD: {
    READ: ERoleGroups.ALL
  },
  SITEMAP: {
    READ: ERoleGroups.ALL
  },
  WORKSPACE: {
    UI: ERoleGroups.LOGGED_IN,
    READ: ERoleGroups.LOGGED_IN,
    CREATE: ERoleGroups.LOGGED_IN,
    DELETE: ERoleGroups.LOGGED_IN
  },
  VISUALIZER: {
    UI: ERoleGroups.ALL,
    READ: ERoleGroups.ALL
  },
  EXPORT: {
    FINANCIAL_STATEMENTS: ERoleGroups.SUBSCRIBED,
    SHARE_DATA: ERoleGroups.SUBSCRIBED,
    RATIOS: [ERoles.admin, ERoles.packageTier2, ERoles.packageTier3],
    COMPETITORS: [ERoles.admin, ERoles.packageTier2, ERoles.packageTier3],
    STOCK_SCREENER: [ERoles.admin, ERoles.packageTier2, ERoles.packageTier3]
  },
  FEATURES: {
    UI: ERoleGroups.ALL
  }
};
