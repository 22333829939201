<template src="./template.vue" />

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'company-listing-comparison',
  props: {
    companyCode: {
      type: String,
      required: true
    }
  }
})
class CompanyListingComparison extends Vue {
  listings = [];
  loading = false;

  async handleBlacklistClick() {
    this.loading = true;
    try {
      await this.StockInvestingApi.adminCompanyBlacklist({ companyCode: this.companyCode });
      this.ToastSuccess(`${this.companyCode} is now blacklisted.`);
      await this.loadListings();
    } catch (err) {
      this.ToastError(`Something went wrong`);
      console.error(err);
    }
    this.loading = false;
  }

  async handleMarkPrimaryListingClick(companyCode) {
    this.loading = true;
    try {
      await this.StockInvestingApi.adminCompanyMarkPrimaryListing({
        companyCodes: this.listings.map((company) => company.companyCode),
        primaryCompanyCode: companyCode
      });
      this.ToastSuccess(`${companyCode} is now the primary listing`);
      await this.loadListings();
    } catch (err) {
      this.ToastError(`Something went wrong`);
      console.error(err);
    }
    this.loading = false;
  }

  async loadListings() {
    try {
      this.listings = await this.StockInvestingApi.adminCompanyListings({ companyCode: this.companyCode });
    } catch (err) {
      console.error(err);
    }
  }

  async mounted() {
    await this.loadListings();
  }
}
export default CompanyListingComparison;
</script>
