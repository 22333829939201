class CompanyCalculated {
  constructor({ marketCap = null, marketCapUsd = null, growth = null, dividend = null, fiftyTwoWeek = null, rating = null }) {
    this.marketCap = marketCap;
    this.marketCapUsd = marketCapUsd;
    this.growth = growth;
    this.dividend = dividend;
    this.fiftyTwoWeek = fiftyTwoWeek;
    this.rating = rating;
  }
}

export { CompanyCalculated };
