class TagSubject {
  static company = 'company';
}

class Tag {
  constructor({ _id = null, name = null, subject = null }) {
    this._id = _id;
    this.name = name;
    this.subject = subject;
  }
}

export { Tag, TagSubject };
