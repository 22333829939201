<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import jwt from 'jsonwebtoken';
import { User } from '../../../../shared/models/user/User.js';

@Component({
  name: 'success',
  data: () => ({})
})
class Success extends Vue {
  get isDowngraded() {
    return this.$route.query.action === 'downgrade';
  }

  async mounted() {
    this.setTitle('Success - Upgrade');
    if (!this.isDowngraded) {
      this.$gtag.event('upgrade_success');
    }

    try {
      await this.StockInvestingApi.authCheck({ roleCheck: true });
      this.store.user = new User(jwt.decode(this.$cookie.get(process.env.JWT_TOKEN_NAME)));
    } catch (err) {
      console.error(err);
    }
  }
}
export default Success;
</script>
<style lang="scss" scoped>
@import './style.scss';
</style>
