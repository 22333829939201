<template src="./template.vue"></template>
<script>
import Component from 'vue-class-component';
import Vue from 'vue';

@Component({
  name: 'formula'
})
class Formula extends Vue {
  parts = [];

  explanation = null;
  nominal = null;
  legend = null;

  splitFormula(formula = null) {
    if (!formula) return null;

    return formula
      .replaceAll(')', '|)|')
      .replaceAll('(', '|(|')
      .replaceAll(' * ', '| x |')
      .replaceAll(' / ', '| / |')
      .replaceAll(' + ', '| + |')
      .replaceAll(' - ', '| - |')
      .replaceAll(' = ', '| = |')
      .replaceAll(' ^ ', '| ^ |')
      .split('|');
  }

  splitLegendFormula(formula = null) {
    if (!formula) return null;

    return formula.replaceAll(' = ', '| = |').split('|');
  }

  prepare() {
    try {
      this.explanation = this.splitFormula(this.$scopedSlots?.explanation()?.first()?.text) || null;
    } catch (err) {
      /* Do nothing */
    }

    try {
      this.nominal = this.splitFormula(this.$scopedSlots?.nominal()?.first()?.text) || null;
    } catch (err) {
      /* Do nothing */
    }

    try {
      this.legend =
        this?.$scopedSlots
          ?.legend()
          ?.map((el) => el?.text || el?.children?.first()?.text)
          ?.map(this.splitLegendFormula) || null;
    } catch (err) {
      /* Do nothing */
    }
  }

  mounted() {
    this.prepare();
  }
}
export default Formula;
</script>
<style lang="scss" scoped>
@import './style.scss';
</style>
