<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

const COLORS = ['#ffc800', '#00ffdc', '#ff0000', '#ff7c00', '#5cd200', '#ca00ff'];

/* eslint-disable prettier/prettier */
const balanceSheet = ['date', 'filingDate', 'currency', 'totalAssets', 'intangibleAssets', 'earningAssets', 'otherCurrentAssets', 'totalLiabilities', 'totalStockholderEquity', 'deferredLongTermLiabilities',
  'otherCurrentLiabilities', 'commonStock', 'retainedEarnings', 'otherLiabilities', 'goodwill', 'otherAssets', 'cash', 'totalCurrentLiabilities', 'netDebt', 'shortTermDebt', 'shortLongTermDebt',
  'shortLongTermDebtTotal', 'otherStockholderEquity', 'propertyPlantEquipment', 'totalCurrentAssets', 'longTermInvestments', 'netTangibleAssets', 'shortTermInvestments', 'netReceivables',
  'longTermDebt', 'inventory', 'accountsPayable', 'totalPermanentEquity', 'nonControllingInterestInConsolidatedEntity', 'temporaryEquityRedeemableNonControllingInterests',
  'accumulatedOtherComprehensiveIncome', 'additionalPaidInCapital', 'commonStockTotalEquity', 'preferredStockTotalEquity', 'retainedEarningsTotalEquity', 'treasuryStock', 'accumulatedAmortization',
  'nonCurrentAssetsOther', 'deferredLongTermAssetCharges', 'nonCurrentAssetsTotal', 'capitalLeaseObligations', 'longTermDebtTotal', 'nonCurrentLiabilitiesOther', 'nonCurrentLiabilitiesTotal',
  'negativeGoodwill', 'warrants', 'preferredStockRedeemable', 'capitalSurplus', 'liabilitiesAndStockholdersEquity', 'cashAndShortTermInvestments', 'propertyPlantAndEquipmentGross',
  'accumulatedDepreciation', 'netWorkingCapital', 'netInvestedCapital', 'commonStockSharesOutstanding'];

const cashFlow = ['date', 'filingDate', 'currency', 'investments', 'changeToLiabilities', 'totalCashFlowFromInvestingActivities', 'netBorrowings', 'totalCashFromFinancingActivities',
  'changeToOperatingActivities', 'netIncome', 'changeInCash', 'beginPeriodCashFlow', 'endPeriodCashFlow', 'totalCashFromOperatingActivities', 'depreciation', 'otherCashFlowFromInvestingActivities',
  'dividendsPaid', 'changeToInventory', 'changeToAccountReceivables', 'salePurchaseOfStock', 'otherCashFlowFromFinancingActivities', 'changeToNetIncome', 'capitalExpenditures', 'changeReceivables',
  'cashFlowsOtherOperating', 'exchangeRateChanges', 'cashAndCashEquivalentsChanges']

const incomeStatement = ['date', 'filingDate', 'currency', 'researchDevelopment', 'effectOfAccountingCharges', 'incomeBeforeTax', 'minorityInterest', 'netIncome', 'sellingGeneralAdministrative',
  'grossProfit', 'ebit', 'nonOperatingIncomeNetOther', 'operatingIncome', 'otherOperatingExpenses', 'interestExpense', 'taxProvision', 'interestIncome', 'netInterestIncome', 'extraordinaryItems',
  'nonRecurring', 'otherItems', 'incomeTaxExpense', 'totalRevenue', 'totalOperatingExpenses', 'costOfRevenue', 'totalOtherIncomeExpenseNet', 'discontinuedOperations', 'netIncomeFromContinuingOps',
  'netIncomeApplicableToCommonShares', 'preferredStockAndOtherAdjustments']

const sharesOutstanding = ['date', 'shares'];

const sharePrice = ['date', 'currency', 'open', 'high', 'low', 'close', 'volume'];

const sharePricePeriodically = ['date', 'currency', 'high', 'low', 'close', 'mean'];
/* eslint-enable prettier/prettier */

const bs = {};
const cf = {};
const is = {};
const shares = {};
const price = {};
const pricePeriod = {};

class Formula {
  constructor({ t = '', type = '', formula = [] }) {
    this.t = t;
    this.type = type;
    this.formula = formula;
  }
}

balanceSheet.forEach((item) => {
  bs[item] = new Formula({ t: item, type: 'bs' });
});
cashFlow.forEach((item) => {
  cf[item] = new Formula({ t: item, type: 'cf' });
});
incomeStatement.forEach((item) => {
  is[item] = new Formula({ t: item, type: 'is' });
});
sharesOutstanding.forEach((item) => {
  shares[item] = new Formula({ t: item, type: 'shares' });
});
sharePrice.forEach((item) => {
  price[item] = new Formula({ t: item, type: 'price' });
});
sharePricePeriodically.forEach((item) => {
  pricePeriod[item] = new Formula({ t: item, type: 'pricePeriod' });
});

@Component({
  name: 'formulas',
  data: () => ({
    bs,
    cf,
    is,
    shares,
    price,
    pricePeriod,
    ratios: {},
    historicValuation: {},
    fundamentlValuation: {},
    colors: {},
    colorsLocked: false,
    colorsLockedUpdate: Date.now()
  })
})
class Formulas extends Vue {
  setFundamentlValuation() {
    const type = 'fundamentlValuation';
    const fv = {};

    // TTM
    fv.ttm = 'Trailing 12 months (TTM)';
    fv.ttmNetIncome = new Formula({
      t: 'ttmNetIncome',
      type,
      formula: ["Sum of last 4 quarter's", is.netIncome]
    });
    fv.ttmRevenue = new Formula({
      t: 'ttmRevenue',
      type,
      formula: ["Sum of last 4 quarter's", is.totalRevenue]
    });
    fv.ttmBookValue = new Formula({
      t: 'ttmBookValue',
      type,
      formula: ["Last quarter's", this.ratios.bookValue]
    });
    fv.ttmOperationalCashFlow = new Formula({
      t: 'ttmOperationalCashFlow',
      type,
      formula: ["Sum of last 4 quarter's", cf.totalCashFromOperatingActivities]
    });

    // Growth
    fv.growth = 'Growth';
    fv.growthNetIncome = new Formula({
      t: 'growthNetIncome',
      type,
      formula: ['pctChange(', fv.ttmNetIncome, ')']
    });
    fv.growthRevenue = new Formula({
      t: 'growthRevenue',
      type,
      formula: ['pctChange(', fv.ttmRevenue, ')']
    });
    fv.growthBookValue = new Formula({
      t: 'growthBookValue',
      type,
      formula: ['pctChange(', fv.ttmBookValue, ')']
    });
    fv.growthOperationalCashFlow = new Formula({
      t: 'growthOperationalCashFlow',
      type,
      formula: ['pctChange(', fv.ttmOperationalCashFlow, ')']
    });

    // Multiples
    fv.ttmMultiples = 'TTM Multiples';
    fv.ttmMultiplesNetIncome = new Formula({
      t: 'ttmMultiplesNetIncome',
      type,
      formula: ["mean( sum( last 4 quarter's", this.ratios.priceOverEarningsMean, ') )']
    });
    fv.ttmMultiplesRevenue = new Formula({
      t: 'ttmMultiplesRevenue',
      type,
      formula: ["mean( sum( last 4 quarter's", this.ratios.priceOverSalesMean, ') )']
    });
    fv.ttmMultiplesBookValue = new Formula({
      t: 'ttmMultiplesBookValue',
      type,
      formula: ["mean( sum( last 4 quarter's", this.ratios.priceOverBookMean, ') )']
    });
    fv.ttmMultiplesOperationalCashFlow = new Formula({
      t: 'ttmMultiplesOperationalCashFlow',
      type,
      formula: ["mean( sum( last 4 quarter's", this.ratios.priceOverCashFlowMean, ') )']
    });

    // Variables and functions
    fv.varsAndFuncs = 'Variables and functions';
    fv.lookBackPeriod = new Formula({
      t: 'lookBackPeriod',
      type,
      formula: ['4']
    });
    fv.predictNext = new Formula({
      t: 'predictNext',
      type,
      formula: ['mean( last', fv.lookBackPeriod, 'periods )']
    });

    // Predicted TTM
    fv.predictedTtm = 'Predicted TTM (next period)';
    fv.predictedTtmNetIncome = new Formula({
      t: 'predictedTtmNetIncome',
      type,
      formula: ['predictNext( last', fv.lookBackPeriod, '', fv.ttmMultiplesNetIncome, 'values )']
    });
    fv.predictedTtmRevenue = new Formula({
      t: 'predictedTtmRevenue',
      type,
      formula: ['predictNext( last', fv.lookBackPeriod, '', fv.ttmMultiplesRevenue, 'values )']
    });
    fv.predictedTtmBookValue = new Formula({
      t: 'predictedTtmBookValue',
      type,
      formula: ['predictNext( last', fv.lookBackPeriod, '', fv.ttmMultiplesBookValue, 'values )']
    });
    fv.predictedTtmOperationalCashFlow = new Formula({
      t: 'predictedTtmOperationalCashFlow',
      type,
      formula: ['predictNext( last', fv.lookBackPeriod, '', fv.ttmMultiplesOperationalCashFlow, 'values )']
    });

    // Predicted Growth
    fv.predictedGrowth = 'Predicted growth';
    fv.predictedGrowthNetIncome = new Formula({
      t: 'predictedGrowthNetIncome',
      type,
      formula: ['predictNext( last', fv.lookBackPeriod, '', fv.growthNetIncome, 'values )']
    });
    fv.predictedGrowthRevenue = new Formula({
      t: 'predictedGrowthRevenue',
      type,
      formula: ['predictNext( last', fv.lookBackPeriod, '', fv.growthRevenue, 'values )']
    });
    fv.predictedGrowthBookValue = new Formula({
      t: 'predictedGrowthBookValue',
      type,
      formula: ['predictNext( last', fv.lookBackPeriod, '', fv.growthBookValue, 'values )']
    });
    fv.predictedGrowthOperationalCashFlow = new Formula({
      t: 'predictedGrowthOperationalCashFlow',
      type,
      formula: ['predictNext( last', fv.lookBackPeriod, '', fv.growthOperationalCashFlow, 'values )']
    });

    // Predicted multiples
    fv.predictedMultiples = 'Predicted multiples';
    fv.predictedMultiplesNetIncome = new Formula({
      t: 'predictedGrowthNetIncome',
      type,
      formula: ['predictNext( last', fv.lookBackPeriod, '', fv.ttmMultiplesNetIncome, 'values )']
    });
    fv.predictedMultiplesRevenue = new Formula({
      t: 'predictedGrowthRevenue',
      type,
      formula: ['predictNext( last', fv.lookBackPeriod, '', fv.ttmMultiplesRevenue, 'values )']
    });
    fv.predictedMultiplesBookValue = new Formula({
      t: 'predictedGrowthBookValue',
      type,
      formula: ['predictNext( last', fv.lookBackPeriod, '', fv.ttmMultiplesBookValue, 'values )']
    });
    fv.predictedMultiplesOperationalCashFlow = new Formula({
      t: 'predictedGrowthOperationalCashFlow',
      type,
      formula: ['predictNext( last', fv.lookBackPeriod, '', fv.ttmMultiplesOperationalCashFlow, 'values )']
    });

    // Predicted shares outstanding
    fv.predictedSharesOutstandingSubtitle = 'Predicted shares outstanding';
    fv.predictedSharesOutstanding = new Formula({
      t: 'predictedSharesOutstanding',
      type,
      formula: ['predictNext( last', fv.lookBackPeriod, '', shares.shares, 'values )']
    });

    // Valuations
    fv.valuations = 'Valuations';
    fv.valuationNetIncome = new Formula({
      t: 'valuationNetIncome',
      type,
      formula: ['(', fv.predictedTtmNetIncome, '/', fv.predictedSharesOutstanding, ') *', fv.predictedGrowthNetIncome, '*', fv.predictedMultiplesNetIncome] // eslint-disable-line prettier/prettier
    });
    fv.valuationRevenue = new Formula({
      t: 'valuationRevenue',
      type,
      formula: ['(', fv.predictedTtmRevenue, '/', fv.predictedSharesOutstanding, ') *', fv.predictedGrowthRevenue, '*', fv.predictedMultiplesRevenue] // eslint-disable-line prettier/prettier
    });
    fv.valuationBookValue = new Formula({
      t: 'valuationBookValue',
      type,
      formula: ['(', fv.predictedTtmBookValue, '/', fv.predictedSharesOutstanding, ') *', fv.predictedGrowthBookValue, '*', fv.predictedMultiplesBookValue] // eslint-disable-line prettier/prettier
    });
    fv.valuationOperationalCashFlow = new Formula({
      t: 'valuationOperationalCashFlow',
      type,
      formula: ['(', fv.predictedTtmOperationalCashFlow, '/', fv.predictedSharesOutstanding, ') *', fv.predictedGrowthOperationalCashFlow, '*', fv.predictedMultiplesOperationalCashFlow] // eslint-disable-line prettier/prettier
    });

    // Valuations
    fv.fundamentlValuationSubtitle = 'Fundamentl Valuation';
    fv.fundamentlValuation = new Formula({
      t: 'fundamentlValuation',
      type,
      formula: ['(', fv.valuationNetIncome, '+', fv.valuationRevenue, '+', fv.valuationBookValue, '+', fv.valuationOperationalCashFlow, ') / 4'] // eslint-disable-line prettier/prettier
    });

    this.fundamentlValuation = fv;
  }

  setHistoricValuation() {
    const type = 'historicValuation';
    const hv = {};

    hv.valuationMethods = new Formula({
      t: 'valuationMethods',
      type,
      formula: ['4']
    });

    // Per share
    hv.perShare = 'Per share';
    hv.netProfitPerShare = new Formula({
      t: 'netProfitPerShare',
      type,
      formula: [is.netIncome, '/', shares.shares]
    });
    hv.revenuePerShare = new Formula({
      t: 'revenuePerShare',
      type,
      formula: [is.totalRevenue, '/', shares.shares]
    });
    hv.bookValuePerShare = new Formula({
      t: 'bookValuePerShare',
      type,
      formula: [this.ratios.bookValue, '/', shares.shares]
    });
    hv.operationalCashFlowPerShare = new Formula({
      t: 'operationalCashFlowPerShare',
      type,
      formula: [cf.totalCashFromOperatingActivities, '/', shares.shares]
    });

    // Valuations
    hv.valuations = 'Valuations';
    hv.netProfitValuation = new Formula({
      t: 'netProfitValuation',
      type,
      formula: [hv.netProfitPerShare, '* ( user input % growth / 100 + 1 ) * user input multiple']
    });
    hv.revenueValuation = new Formula({
      t: 'revenueValuation',
      type,
      formula: [hv.revenuePerShare, '* ( user input % growth / 100 + 1 ) * user input multiple']
    });
    hv.bookValueValuation = new Formula({
      t: 'bookValueValuation',
      type,
      formula: [hv.bookValuePerShare, '* ( user input % growth / 100 + 1 ) * user input multiple']
    });
    hv.operationalCashFlowValuation = new Formula({
      t: 'operationalCashFlowValuation',
      type,
      formula: [hv.operationalCashFlowPerShare, '* ( user input % growth / 100 + 1 ) * user input multiple']
    });

    // Valuations
    hv.totalValuation = 'Total valuation';
    hv.historicValuation = new Formula({
      t: 'historicValuation',
      type,
      formula: ['(', hv.netProfitValuation, '+', hv.revenueValuation, '+', hv.bookValueValuation, '+', hv.operationalCashFlowValuation, ') /', hv.valuationMethods] // eslint-disable-line prettier/prettier
    });

    this.historicValuation = hv;
  }

  setRatios() {
    const type = 'ratios';
    const ratios = {};

    // Ratios pre
    ratios.totalDebt = new Formula({
      t: 'totalDebt',
      type,
      formula: [bs.longTermDebt, '+', bs.shortLongTermDebt, '+', bs.shortTermDebt]
    });
    ratios.netFinancialDebt = new Formula({
      t: 'netFinancialDebt',
      type,
      formula: [ratios.totalDebt, '- (', bs.cash, '+', bs.shortTermInvestments, ')']
    });
    ratios.netWorkingCapital = new Formula({
      t: 'netWorkingCapital',
      type,
      formula: [bs.totalCurrentAssets, '-', bs.totalCurrentLiabilities]
    });
    ratios.changeInNetWorkingCapital = new Formula({
      t: 'changeInNetWorkingCapital',
      type,
      formula: [ratios.netWorkingCapital, "- previous period's", ratios.netWorkingCapital]
    });
    ratios.eps = new Formula({
      t: 'eps',
      type,
      formula: ['(', is.netIncome, '-', cf.dividendsPaid, ') /', shares.shares]
    });
    ratios.equity = new Formula({
      t: 'equity',
      type,
      formula: [bs.totalAssets, '-', bs.totalLiabilities]
    });
    ratios.bookValue = new Formula({
      t: 'bookValue',
      type,
      formula: [bs.totalAssets, '-', bs.totalLiabilities]
    });
    ratios.ebitda = new Formula({
      t: 'ebitda',
      type,
      formula: ['(', is.ebit, '+', cf.depreciation, ')']
    });

    // General
    ratios.general = 'General';
    ratios.marketCap = new Formula({
      t: 'marketCap',
      type,
      formula: [shares.shares, '*', pricePeriod.close]
    });
    ratios.enterpriseValue = new Formula({
      t: 'enterpriseValue',
      type,
      formula: [ratios.marketCap, '+', bs.totalLiabilities, '-', bs.cash, '-', bs.shortTermInvestments]
    });
    ratios.balanceTotal = new Formula({
      t: 'balanceTotal',
      type,
      formula: [bs.totalAssets]
    });
    ratios.totalEquity = new Formula({
      t: 'totalEquity',
      type,
      formula: [ratios.equity]
    });

    // Assets
    ratios.assets = 'Assets';
    ratios.goodwill = new Formula({
      t: 'goodwill',
      type,
      formula: ['(', bs.goodwill, '/', bs.totalAssets, ') * 100']
    });
    ratios.otherIntangibleAssets = new Formula({
      t: 'otherIntangibleAssets',
      type,
      formula: ['( (', bs.intangibleAssets, '-', bs.goodwill, ') /', bs.totalAssets, ') * 100']
    });
    ratios.totalIntangibleAssets = new Formula({
      t: 'totalIntangibleAssets',
      type,
      formula: ['(', bs.intangibleAssets, '/', bs.totalAssets, ') * 100']
    });
    ratios.cash = new Formula({
      t: 'cash',
      type,
      formula: ['(', bs.cash, '/', bs.totalAssets, ') * 100']
    });
    ratios.shortTermInvestments = new Formula({
      t: 'shortTermInvestments',
      type,
      formula: ['(', bs.shortTermInvestments, '/', bs.totalAssets, ') * 100']
    });
    ratios.cashAndShortTermInvestments = new Formula({
      t: 'cashAndShortTermInvestments',
      type,
      formula: ['( (', bs.cash, '+', bs.shortTermInvestments, ') /', bs.totalAssets, ') * 100']
    });
    ratios.currentRatio = new Formula({
      t: 'currentRatio',
      type,
      formula: [bs.totalCurrentAssets, '/', bs.totalCurrentLiabilities]
    });
    ratios.quickRatio = new Formula({
      t: 'quickRatio',
      type,
      formula: ['(', bs.cash, '+', bs.shortTermInvestments, '+', bs.netReceivables, ') /', bs.totalCurrentLiabilities]
    });
    ratios.netWorkingCapitalOverSales = new Formula({
      t: 'netWorkingCapitalOverSales',
      type,
      formula: [ratios.netWorkingCapital, '/', is.totalRevenue]
    });

    // Equity and liabilities
    ratios.equityAndLiabilities = 'Equity and liabilities';
    ratios.equityRatio = new Formula({
      t: 'equityRatio',
      type,
      formula: ['(', ratios.equity, '/', bs.totalAssets, ') * 100']
    });
    ratios.liabilitiesRatio = new Formula({
      t: 'liabilitiesRatio',
      type,
      formula: ['(', bs.totalLiabilities, '/', bs.totalAssets, ') * 100']
    });
    ratios.debtRatio = new Formula({
      t: 'debtRatio',
      type,
      formula: ['(', ratios.totalDebt, '/', bs.nonCurrentAssetsTotal, ') * 100']
    });
    ratios.debtOverEquityRatio = new Formula({
      t: 'debtOverEquityRatio',
      type,
      formula: ['(', ratios.totalDebt, '/', ratios.equity, ') * 100']
    });
    ratios.longTermDebtOverEquityRatio = new Formula({
      t: 'longTermDebtOverEquityRatio',
      type,
      formula: ['(', bs.longTermDebt, '/', ratios.equity, ') * 100']
    });
    ratios.debtOverCashRatio = new Formula({
      t: 'debtOverCashRatio',
      type,
      formula: ['(', bs.totalDebt, '/', bs.cash, ') * 100']
    });
    ratios.operatingCashFlowOverDebt = new Formula({
      t: 'operatingCashFlowOverDebt',
      type,
      formula: ['(', cf.totalCashFromOperatingActivities, '/', ratios.totalDebt, ') * 100']
    });
    ratios.equityOverNonCurrentAssetsRatio = new Formula({
      t: 'equityOverNonCurrentAssetsRatio',
      type,
      formula: ['(', ratios.equity, '/', bs.nonCurrentAssetsTotal, ') * 100']
    });
    ratios.equityPlusNonCurrentLiabilitiesOverNonCurrentAssets = new Formula({
      t: 'equityPlusNonCurrentLiabilitiesOverNonCurrentAssets',
      type,
      formula: ['( (', ratios.equity, '+', bs.nonCurrentLiabilitiesTotal, ') /', bs.nonCurrentAssetsTotal, ') * 100']
    });
    ratios.gearing = new Formula({
      t: 'gearing',
      type,
      formula: ['(', ratios.totalDebt, '/', ratios.equity, ') * 100']
    });
    ratios.netFinancialDebtOverEquity = new Formula({
      t: 'netFinancialDebtOverEquity',
      type,
      formula: ['(', ratios.netFinancialDebt, '/', ratios.equity, ') * 100']
    });
    ratios.netFinancialDebtOverEbitda = new Formula({
      t: 'netFinancialDebtOverEbitda',
      type,
      formula: ['(', ratios.netFinancialDebt, '/', ratios.ebitda, ') * 100']
    });

    // Income statement
    ratios.incomeStatement = 'Income statement';
    ratios.ebitda2 = new Formula({
      t: 'ebitda',
      type,
      formula: [ratios.ebitda]
    });
    ratios.ebit = new Formula({
      t: 'ebit',
      type,
      formula: [is.ebit]
    });
    ratios.ebt = new Formula({
      t: 'ebt',
      type,
      formula: [is.ebit, '-', is.interestExpense]
    });
    ratios.netIncome = new Formula({
      t: 'netIncome',
      type,
      formula: [is.netIncome]
    });

    // Cash flow
    ratios.cashflow = 'Cash flow';
    ratios.leveredFreeCashFlow = new Formula({
      t: 'leveredFreeCashFlow',
      type,
      formula: [ratios.ebitda, '-', ratios.changeInNetWorkingCapital, '-', cf.capitalExpenditures, '-', cf.changeToLiabilities]
    });
    ratios.unleveredFreeCashFlow = new Formula({
      t: 'unleveredFreeCashFlow',
      type,
      formula: [ratios.ebitda, '-', ratios.changeInNetWorkingCapital, '-', cf.capitalExpenditures]
    });
    ratios.operatingCashFlowOverSales = new Formula({
      t: 'operatingCashFlowOverSales',
      type,
      formula: ['(', cf.totalCashFromOperatingActivities, '/', is.totalRevenue, ') * 100']
    });

    // Growth
    ratios.growth = 'Growth';
    ratios.revenueGrowth = new Formula({
      t: 'revenueGrowth',
      type,
      formula: ['(', is.totalRevenue, "/ previous period's", is.interestExpense, '- 1 ) * 100']
    });
    ratios.grossProfitGrowth = new Formula({
      t: 'grossProfitGrowth',
      type,
      formula: ['(', is.grossProfit, "/ previous period's", is.grossProfit, '- 1 ) * 100']
    });
    ratios.ebitGrowth = new Formula({
      t: 'ebitGrowth',
      type,
      formula: ['(', ratios.ebit, "/ previous period's", ratios.ebit, '- 1 ) * 100']
    });
    ratios.netIncomeGrowth = new Formula({
      t: 'netIncomeGrowth',
      type,
      formula: ['(', is.netIncome, "/ previous period's", is.netIncome, '- 1 ) * 100']
    });
    ratios.epsGrowth = new Formula({
      t: 'epsGrowth',
      type,
      formula: ['(', ratios.eps, "/ previous period's", ratios.eps, '- 1 ) * 100']
    });
    ratios.cashFromOperatingActivitiesGrowth = new Formula({
      t: 'cashFromOperatingActivitiesGrowth',
      type,
      formula: ['(', cf.totalCashFromOperatingActivities, "/ previous period's", cf.totalCashFromOperatingActivities, '- 1 ) * 100']
    });

    // Margins
    ratios.margins = 'Margins';
    ratios.grossProfitMargin = new Formula({
      t: 'grossProfitMargin',
      type,
      formula: ['(', is.grossProfit, '/', is.totalRevenue, ') * 100']
    });
    ratios.ebitdaMargin = new Formula({
      t: 'ebitdaMargin',
      type,
      formula: ['(', ratios.ebitda, '/', is.totalRevenue, ') * 100']
    });
    ratios.ebitMargin = new Formula({
      t: 'ebitMargin',
      type,
      formula: ['(', ratios.ebit, '/', is.totalRevenue, ') * 100']
    });
    ratios.netIncomeMargin = new Formula({
      t: 'netIncomeMargin',
      type,
      formula: ['(', is.netIncome, '/', is.totalRevenue, ') * 100']
    });

    // Productivity
    ratios.productivity = 'Productivity';
    ratios.revenuePer100Equity = new Formula({
      t: 'revenuePer100Equity',
      type,
      formula: ['( (', is.totalRevenue, '/', shares.shares, ') * 100 ) /', ratios.equity, '/', shares.shares]
    });
    ratios.revenuePer100Assets = new Formula({
      t: 'revenuePer100Assets',
      type,
      formula: ['( (', is.totalRevenue, '/', shares.shares, ') * 100 ) /', bs.totalAssets, '/', shares.shares]
    });

    // Value creation
    ratios.valueCreation = 'Value creation';
    ratios.returnOnInvestment = new Formula({
      t: 'returnOnInvestment',
      type,
      formula: ['(', is.netIncome, '/', bs.totalAssets, ') * 100']
    });
    ratios.returnOnCapitalEmployed = new Formula({
      t: 'returnOnCapitalEmployed',
      type,
      formula: ['(', ratios.ebit, '/ (', ratios.equity, '+', bs.nonCurrentLiabilitiesTotal, ') ) * 100']
    });
    ratios.returnOnEquityBeforeTax = new Formula({
      t: 'returnOnEquityBeforeTax',
      type,
      formula: ['(', is.incomeBeforeTax, '/', ratios.equity, ') * 100']
    });
    ratios.returnOnEquityAfterTax = new Formula({
      t: 'returnOnEquityAfterTax',
      type,
      formula: ['(', is.netIncome, '/', ratios.equity, ') * 100']
    });
    ratios.bookValuePerShare = new Formula({
      t: 'bookValuePerShare',
      type,
      formula: [ratios.bookValue, '/', shares.shares]
    });
    ratios.bookValueChange = new Formula({
      t: 'bookValueChange',
      type,
      formula: ['(', ratios.bookValue, "/ previous period's", ratios.bookValue, ' - 1 ) * 100']
    });

    // Valuation - Price/Earnings
    ratios.valuationPriceEarnings = 'Valuation - Price/earnings';
    ratios.priceOverEarningsHigh = new Formula({
      t: 'priceOverEarningsHigh',
      type,
      formula: ['(', pricePeriod.high, '*', shares.shares, ') /', is.netIncome]
    });
    ratios.priceOverEarningsMean = new Formula({
      t: 'priceOverEarningsMean',
      type,
      formula: ['(', pricePeriod.mean, '*', shares.shares, ') /', is.netIncome]
    });
    ratios.priceOverEarningsLow = new Formula({
      t: 'priceOverEarningsLow',
      type,
      formula: ['(', pricePeriod.low, '*', shares.shares, ') /', is.netIncome]
    });

    // Valuation - Price/Earnings
    ratios.valuationPriceBook = 'Valuation - Price/book';
    ratios.priceOverBookHigh = new Formula({
      t: 'priceOverBookHigh',
      type,
      formula: [pricePeriod.high, '/ (', ratios.bookValue, '/', shares.shares, ')']
    });
    ratios.priceOverBookMean = new Formula({
      t: 'priceOverBookMean',
      type,
      formula: [pricePeriod.mean, '/ (', ratios.bookValue, '/', shares.shares, ')']
    });
    ratios.priceOverBookLow = new Formula({
      t: 'priceOverBookLow',
      type,
      formula: [pricePeriod.low, '/ (', ratios.bookValue, '/', shares.shares, ')']
    });

    // Valuation - Price/Cashflow
    ratios.valuationPriceCashFlow = 'Valuation - Price/cashflow';
    ratios.priceOverCashFlowHigh = new Formula({
      t: 'priceOverCashFlowHigh',
      type,
      formula: [pricePeriod.high, '/ (', cf.totalCashFromOperatingActivities, '/', shares.shares, ')']
    });
    ratios.priceOverCashFlowMean = new Formula({
      t: 'priceOverCashFlowMean',
      type,
      formula: [pricePeriod.mean, '/ (', cf.totalCashFromOperatingActivities, '/', shares.shares, ')']
    });
    ratios.priceOverCashFlowLow = new Formula({
      t: 'priceOverCashFlowLow',
      type,
      formula: [pricePeriod.low, '/ (', cf.totalCashFromOperatingActivities, '/', shares.shares, ')']
    });

    // Valuation - Price/Sales
    ratios.valuationPriceSales = 'Valuation - Price/sales';
    ratios.priceOverSalesHigh = new Formula({
      t: 'priceOverSalesHigh',
      type,
      formula: ['(', pricePeriod.high, '*', shares.shares, ') /', is.totalRevenue]
    });
    ratios.priceOverSalesMean = new Formula({
      t: 'priceOverSalesMean',
      type,
      formula: ['(', pricePeriod.mean, '*', shares.shares, ') /', is.totalRevenue]
    });
    ratios.priceOverSalesLow = new Formula({
      t: 'priceOverSalesLow',
      type,
      formula: ['(', pricePeriod.low, '*', shares.shares, ') /', is.totalRevenue]
    });

    // Other ratios
    ratios.other = 'Other ratios';
    ratios.ebitdaOverMarketCapHigh = new Formula({
      t: 'ebitdaOverMarketCapHigh',
      type,
      formula: [ratios.ebitda, '/ (', pricePeriod.high, '*', shares.shares, ')']
    });
    ratios.ebitdaOverMarketCapMean = new Formula({
      t: 'ebitdaOverMarketCapMean',
      type,
      formula: [ratios.ebitda, '/ (', pricePeriod.mean, '*', shares.shares, ')']
    });
    ratios.ebitdaOverMarketCapLow = new Formula({
      t: 'ebitdaOverMarketCapLow',
      type,
      formula: [ratios.ebitda, '/ (', pricePeriod.low, '*', shares.shares, ')']
    });

    ratios.space1 = '';

    ratios.netIncomeOverMarketCapHigh = new Formula({
      t: 'netIncomeOverMarketCapHigh',
      type,
      formula: [is.netIncome, '/ (', pricePeriod.high, '*', shares.shares, ')']
    });
    ratios.netIncomeOverMarketCapMean = new Formula({
      t: 'netIncomeOverMarketCapMean',
      type,
      formula: [is.netIncome, '/ (', pricePeriod.mean, '*', shares.shares, ')']
    });
    ratios.netIncomeOverMarketCapLow = new Formula({
      t: 'netIncomeOverMarketCapLow',
      type,
      formula: [is.netIncome, '/ (', pricePeriod.low, '*', shares.shares, ')']
    });

    ratios.space2 = '';

    ratios.enterpriseValueHigh = new Formula({
      t: 'enterpriseValueHigh',
      type,
      formula: [pricePeriod.high, '*', shares.shares, '+', bs.totalLiabilities, '-', bs.cash, '-', bs.shortTermInvestments]
    });
    ratios.enterpriseValueMean = new Formula({
      t: 'enterpriseValueMean',
      type,
      formula: [pricePeriod.mean, '*', shares.shares, '+', bs.totalLiabilities, '-', bs.cash, '-', bs.shortTermInvestments]
    });
    ratios.enterpriseValueLow = new Formula({
      t: 'enterpriseValueLow',
      type,
      formula: [pricePeriod.low, '*', shares.shares, '+', bs.totalLiabilities, '-', bs.cash, '-', bs.shortTermInvestments]
    });

    ratios.space3 = '';

    ratios.enterpriseValueOverEbitdaHigh = new Formula({
      t: 'enterpriseValueOverEbitdaHigh',
      type,
      formula: [ratios.enterpriseValueHigh, '/', ratios.ebitda]
    });
    ratios.enterpriseValueOverEbitdaMean = new Formula({
      t: 'enterpriseValueOverEbitdaMean',
      type,
      formula: [ratios.enterpriseValueMean, '/', ratios.ebitda]
    });
    ratios.enterpriseValueOverEbitdaLow = new Formula({
      t: 'enterpriseValueOverEbitdaLow',
      type,
      formula: [ratios.enterpriseValueLow, '/', ratios.ebitda]
    });

    ratios.space4 = '';

    ratios.enterpriseValueOverSalesHigh = new Formula({
      t: 'enterpriseValueOverSalesHigh',
      type,
      formula: [ratios.enterpriseValueHigh, '/', is.totalRevenue]
    });
    ratios.enterpriseValueOverSalesMean = new Formula({
      t: 'enterpriseValueOverSalesMean',
      type,
      formula: [ratios.enterpriseValueMean, '/', is.totalRevenue]
    });
    ratios.enterpriseValueOverSalesLow = new Formula({
      t: 'enterpriseValueOverSalesLow',
      type,
      formula: [ratios.enterpriseValueLow, '/', is.totalRevenue]
    });

    ratios.space5 = '';

    ratios.pegRatioHigh = new Formula({
      t: 'pegRatioHigh',
      type,
      formula: [pricePeriod.high, '/', ratios.eps, '/ (', ratios.eps, "- previous period's", ratios.eps, ')']
    });
    ratios.pegRatioMean = new Formula({
      t: 'pegRatioMean',
      type,
      formula: [pricePeriod.mean, '/', ratios.eps, '/ (', ratios.eps, "- previous period's", ratios.eps, ')']
    });
    ratios.pegRatioLow = new Formula({
      t: 'pegRatioLow',
      type,
      formula: [pricePeriod.low, '/', ratios.eps, '/ (', ratios.eps, "- previous period's", ratios.eps, ')']
    });

    this.ratios = ratios;
  }

  unlockColors() {
    const timeout = 50;
    if (this.colorsLocked && Date.now() - this.colorsLockedUpdate > timeout) {
      this.colorsLocked = false;
      this.colorsLockedUpdate = Date.now();
      this.resetColors();
    }
  }

  toggleColorsLocked() {
    const timeout = 50;
    if (this.colorsLocked && Date.now() - this.colorsLockedUpdate > timeout) {
      this.colorsLocked = false;
      this.colorsLockedUpdate = Date.now();
      this.resetColors();
    } else if (!this.colorsLocked && Date.now() - this.colorsLockedUpdate > timeout) {
      this.colorsLocked = true;
      this.colorsLockedUpdate = Date.now();
    }
  }

  resetColors() {
    if (!this.colorsLocked) {
      this.colors = {};
    }
  }

  handleClickFormula(formula) {
    this.handleHoverFormula(formula);
    this.toggleColorsLocked();
  }

  handleClickFormulaPart(part) {
    this.handleHoverFormulaPart(part);
    this.toggleColorsLocked();
  }

  handleHoverFormula(formula) {
    if (!this.colorsLocked) {
      const colors = { time: Date.now() };
      let colorCount = 0;
      for (let i = 0; i < formula.length; i++) {
        if (typeof formula[i] === typeof Object()) {
          colors[formula[i].type] ||= {};
          colors[formula[i].type][formula[i].t] = COLORS[colorCount];
          colorCount++;
        }
      }
      this.colors = colors;
    }
  }

  handleHoverFormulaPart(part) {
    if (!this.colorsLocked) {
      this.colors = {
        time: Date.now(),
        [part.type]: {
          [part.t]: 'orange'
        }
      };
    }
  }

  getColor(statement, key) {
    return this.colors?.[statement]?.[key] || 'transparent';
  }

  mounted() {
    if (!this.store.user?.roles?.includes('administrator') && !this.store.user?.roles?.includes('formulas-checker')) {
      this.$router.replace('/');
    }

    this.setRatios();
    this.setHistoricValuation();
    this.setFundamentlValuation();
  }
}
export default Formulas;
</script>
<style lang="scss" scoped>
@import './style.scss';
</style>
