<template>
  <div id="app" class="d-flex flex-column justify-content-between min-vh-100">
    <div id="wrapper d-flex flex-row">
      <Topbar />
      <div class="d-flex mt-4">
        <div class="content-container">
          <router-view />
        </div>
      </div>
    </div>
    <div class="d-lg-none pt-5 px-2 no-mobile-notice">
      <div class="logo d-flex justify-content-center align-items-end mb-3">
        <span class="text-white pr-1">Fundamentl</span>
        <small class="text-uppercase beta">BETA</small>
      </div>
      <p class="text-center d-block">
        Fundamentl currently isn't available on mobile yet. <br class="d-none d-sm-block" />
        Please, surf to <a href="https://www.fundamentl.io">fundamentl.io</a> on your desktop.
      </p>
      <p class="text-center d-block">
        <span class="text-white">Already on a desktop machine and still seeing this message?</span><br class="d-none d-sm-block" />
        Simply enlarge your window to start using Fundamentl.
      </p>
    </div>
    <div class="text-left container-fluid pb-3 pt-5">
      <small class="text-light">
        <a href="public/legal/cookie-policy.pdf" target="_blank" class="text-light text-decoration-none">Cookie Policy</a> -
        <a href="public/legal/privacy-policy.pdf" target="_blank" class="text-light text-decoration-none">Privacy Policy</a> -
        <a href="public/legal/terms-of-use.pdf" target="_blank" class="text-light text-decoration-none">Terms of use</a>
      </small>
    </div>
    <dev-tools />
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import jwt from 'jsonwebtoken';
import Hotjar from 'vue-hotjar';

import { User } from '../shared/models/user/index.js';
import { Topbar, DevTools } from './components/index.js';
import { ETime } from '../shared/enums.js';

@Component({
  name: 'App',
  components: { Topbar, DevTools },
  data: () => ({})
})
class App extends Vue {
  calculateScrollbarWidth() {
    document.documentElement.style.setProperty('--scrollbar-width', `${window.innerWidth - document.documentElement.clientWidth}px`);
  }

  async authCheck(performRoleCheck = false) {
    if (!this.store?.rolesManipulated && this.$cookie.get(process.env.JWT_TOKEN_NAME)) {
      try {
        await this.StockInvestingApi.authCheck({ roleCheck: performRoleCheck });
        this.store.user = new User(jwt.decode(this.$cookie.get(process.env.JWT_TOKEN_NAME)));

        if (!this.store.hotjarEnabled) {
          this.store.hotjarEnabled = true;
          Vue.use(Hotjar, {
            id: process.env.TRACKING_HOTJAR_ID,
            isProduction: process.env.ENV === 'prd'
          });
        }
      } catch (err) {
        console.error(err);
      }
    }

    if (this?.store?.user?._id) {
      this.$gtag.set('user_properties', { crm_id: this?.store?.user?._id });
      window.$crisp.push(['set', 'session:data', [[['fundamentl_user_id', this?.store?.user?._id]]]]);
    }
    if (this?.store?.user?.personal?.displayName) {
      window.$crisp.push(['set', 'user:nickname', [this.store.user.personal.displayName]]);
    }
    if (this?.store?.user?.personal?.email) {
      window.$crisp.push(['set', 'user:email', [this.store.user.personal.email]]);
    }
    if (this?.store?.user?.personal?.photoUrl) {
      window.$crisp.push(['set', 'user:avatar', [this.store.user.personal.photoUrl]]);
    }
  }

  mounted() {
    const resizeObserver = new ResizeObserver(this.calculateScrollbarWidth);
    resizeObserver.observe(document.body);
    this.calculateScrollbarWidth();

    // Recheck authentication
    setInterval(this.authCheck, ETime.milliseconds.perSecond * 30);
    this.authCheck(true);

    // Check if tests have been added
    const testsUpdateTrigger = setInterval(() => {
      const succeeded = this.updateTests();
      if (succeeded) {
        clearInterval(testsUpdateTrigger);
      }
    }, ETime.milliseconds.perSecond);
    this.updateTests();

    // Check if returning user
    const lastVisitCookie = 'fundamentl-last-visit';
    const returningVisitorCookie = this.$cookie.get(lastVisitCookie);
    if (!returningVisitorCookie) {
      // First time user
      this.$cookie.set(lastVisitCookie, new Date().removeTime().getTime(), { expires: ETime.days.perYear });
    } else if (returningVisitorCookie < new Date().removeTime().getTime()) {
      // Returning user
      this.$gtag.event('returning_user');
      this.$cookie.set(lastVisitCookie, new Date().removeTime().getTime(), { expires: ETime.days.perYear });
    }
  }
}
export default App;
</script>

<style lang="scss">
@import './style/variables.scss';

.logo {
  font-family: Cabin;
  color: $white;
  font-weight: 600;
  font-size: 30px;
  letter-spacing: 0.04em;
  text-decoration: none;
  .beta {
    font-size: 14px;
    font-weight: bold;
    color: $link-color;
  }
}

.no-mobile-notice {
  background: $body-bg;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 30;
}
</style>
