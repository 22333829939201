class ESharingPermission {
  static private = 'private';
  static group = 'group';
  static public = 'public';
}

class ETagSubject {
  static company = 'company';
}

class EPlans {
  static user = {
    key: 'user',
    name: 'Free',
    show: true,
    stripeProductId: null,
    taxInclusive: true,
    price: {
      monthly: {
        eur: null,
        usd: null
      },
      yearly: {
        eur: null,
        usd: null
      }
    },
    description: "Great if you're a beginner who wants to get their feet wet."
  };

  static legacy20220831 = {
    key: 'legacy20220831',
    name: 'Premium (Legacy)',
    show: false,
    stripeProductId: process.env.PKT_2022_08_31_STRIPE_PRODUCT_ID,
    taxInclusive: true,
    price: {
      monthly: {
        eur: process.env.PKT_2022_08_31_STRIPE_PRICE_ID_MONTH_EUR,
        usd: process.env.PKT_2022_08_31_STRIPE_PRICE_ID_MONTH_USD
      },
      yearly: {
        eur: process.env.PKT_2022_08_31_STRIPE_PRICE_ID_YEAR_EUR,
        usd: process.env.PKT_2022_08_31_STRIPE_PRICE_ID_YEAR_USD
      }
    }
  };

  static packageTier1 = {
    key: 'packageTier1',
    name: 'Basic',
    show: true,
    stripeProductId: process.env.PKT1_STRIPE_PRODUCT_ID,
    taxInclusive: false,
    price: {
      monthly: {
        eur: process.env.PKT1_STRIPE_PRICE_ID_MONTH_EUR,
        usd: process.env.PKT1_STRIPE_PRICE_ID_MONTH_USD
      },
      yearly: {
        eur: process.env.PKT1_STRIPE_PRICE_ID_YEAR_EUR,
        usd: process.env.PKT1_STRIPE_PRICE_ID_YEAR_USD
      }
    },
    description: 'The perfect plan for analysts and private investors.'
  };

  static packageTier2 = {
    key: 'packageTier2',
    name: 'Advanced',
    show: true,
    stripeProductId: process.env.PKT2_STRIPE_PRODUCT_ID,
    taxInclusive: true,
    price: {
      monthly: {
        eur: process.env.PKT2_STRIPE_PRICE_ID_MONTH_EUR,
        usd: process.env.PKT2_STRIPE_PRICE_ID_MONTH_USD
      },
      yearly: {
        eur: process.env.PKT2_STRIPE_PRICE_ID_YEAR_EUR,
        usd: process.env.PKT2_STRIPE_PRICE_ID_YEAR_USD
      }
    },
    description: 'The perfect plan for analysts and private investors.'
  };

  static packageTier3 = {
    key: 'packageTier3',
    name: 'Pro',
    show: true,
    stripeProductId: process.env.PKT3_STRIPE_PRODUCT_ID,
    taxInclusive: true,
    price: {
      monthly: {
        eur: process.env.PKT3_STRIPE_PRICE_ID_MONTH_EUR,
        usd: process.env.PKT3_STRIPE_PRICE_ID_MONTH_USD
      },
      yearly: {
        eur: process.env.PKT3_STRIPE_PRICE_ID_YEAR_EUR,
        usd: process.env.PKT3_STRIPE_PRICE_ID_YEAR_USD
      }
    },
    description: 'The perfect plan for analysts and private investors.'
  };
}

const EPlanFeatures = [
  {
    title: 'Financial and non-financial data',
    features: {
      Stocks: '+25k worldwide',
      'Historic financial data': {
        [EPlans.user.key]: '5 years',
        [EPlans.legacy20220831.key]: '+35 years',
        [EPlans.packageTier1.key]: '10 years',
        [EPlans.packageTier2.key]: '+35 years',
        [EPlans.packageTier3.key]: '+35 years'
      },
      'Analyst ratings': {
        [EPlans.user.key]: false,
        [EPlans.legacy20220831.key]: false,
        [EPlans.packageTier1.key]: false,
        [EPlans.packageTier2.key]: true,
        [EPlans.packageTier3.key]: true
      },
      'Insider trading': {
        note: 'Only available for companies located in the United States',
        [EPlans.user.key]: false,
        [EPlans.legacy20220831.key]: false,
        [EPlans.packageTier1.key]: false,
        [EPlans.packageTier2.key]: true,
        [EPlans.packageTier3.key]: true
      },
      'Company management': {
        note: `Information about who's managing the company.`,
        [EPlans.user.key]: false,
        [EPlans.legacy20220831.key]: false,
        [EPlans.packageTier1.key]: false,
        [EPlans.packageTier2.key]: true,
        [EPlans.packageTier3.key]: true
      },
      'Company ownership': {
        [EPlans.user.key]: false,
        [EPlans.legacy20220831.key]: false,
        [EPlans.packageTier1.key]: false,
        [EPlans.packageTier2.key]: false,
        [EPlans.packageTier3.key]: true
      },
      'ESG Activities': {
        [EPlans.user.key]: false,
        [EPlans.legacy20220831.key]: false,
        [EPlans.packageTier1.key]: false,
        [EPlans.packageTier2.key]: false,
        [EPlans.packageTier3.key]: true
      }
    }
  },
  {
    title: 'Analysis',
    features: {
      'Stock screener': {
        [EPlans.user.key]: true,
        [EPlans.legacy20220831.key]: true,
        [EPlans.packageTier1.key]: true,
        [EPlans.packageTier2.key]: true,
        [EPlans.packageTier3.key]: true
      },
      Visualizer: {
        note: 'Visual stock screener',
        [EPlans.user.key]: {
          note: 'Maximum 50 companies',
          value: 'Limited (50)'
        },
        [EPlans.legacy20220831.key]: {
          note: 'Maximum 50 companies',
          value: 'Limited (50)'
        },
        [EPlans.packageTier1.key]: {
          note: 'Maximum 50 companies',
          value: 'Limited (50)'
        },
        [EPlans.packageTier2.key]: {
          note: 'Maximum 1000 companies',
          value: true
        },
        [EPlans.packageTier3.key]: {
          note: 'Maximum 1000 companies',
          value: true
        }
      },
      'Competitor analysis tool': {
        [EPlans.user.key]: 'Limited',
        [EPlans.legacy20220831.key]: 'Limited',
        [EPlans.packageTier1.key]: 'Limited',
        [EPlans.packageTier2.key]: true,
        [EPlans.packageTier3.key]: true
      }
    }
  },
  {
    title: 'Valuations',
    features: {
      'Save your valuations': {
        [EPlans.user.key]: true,
        [EPlans.legacy20220831.key]: true,
        [EPlans.packageTier1.key]: true,
        [EPlans.packageTier2.key]: true,
        [EPlans.packageTier3.key]: true
      },
      'Historic valuations': {
        [EPlans.user.key]: {
          note: 'Valuation with up to 5 year of data is included',
          value: 'Limited (5Y)'
        },
        [EPlans.legacy20220831.key]: true,
        [EPlans.packageTier1.key]: {
          note: 'Valuation with up to 10 year of data is included',
          value: 'Limited (10Y)'
        },
        [EPlans.packageTier2.key]: {
          note: 'Valuation with custom data is included',
          value: true
        },
        [EPlans.packageTier3.key]: {
          note: 'Valuation with custom data is included',
          value: true
        }
      },
      'Fundamentl valuation': {
        [EPlans.user.key]: {
          note: 'Historic Fundamentl valuations are available up to 5 years.',
          value: 'Limited (5Y)'
        },
        [EPlans.legacy20220831.key]: true,
        [EPlans.packageTier1.key]: {
          note: 'Historic Fundamentl valuations are available up to 10 years.',
          value: 'Limited (10Y)'
        },
        [EPlans.packageTier2.key]: true,
        [EPlans.packageTier3.key]: true
      },
      'Fundamentl valuation breakdown': {
        [EPlans.user.key]: false,
        [EPlans.legacy20220831.key]: true,
        [EPlans.packageTier1.key]: false,
        [EPlans.packageTier2.key]: true,
        [EPlans.packageTier3.key]: true
      },
      'Weighted average valuations': {
        note: 'A tool to get a weighted valuation based on the different valuation methods (historic valuation, DCF,...)',
        [EPlans.user.key]: false,
        [EPlans.legacy20220831.key]: true,
        [EPlans.packageTier1.key]: false,
        [EPlans.packageTier2.key]: true,
        [EPlans.packageTier3.key]: true
      },
      'Create DCF valuations': {
        [EPlans.user.key]: false,
        [EPlans.legacy20220831.key]: true,
        [EPlans.packageTier1.key]: false,
        [EPlans.packageTier2.key]: false,
        [EPlans.packageTier3.key]: true
      }
    }
  },
  {
    title: 'Export data to CSV/Excel',
    features: {
      'Financial statements export': {
        [EPlans.user.key]: false,
        [EPlans.legacy20220831.key]: false,
        [EPlans.packageTier1.key]: {
          value: 'Limited',
          note: 'Most recent 5 yearly statements and most recent 4 quarterly statements'
        },
        [EPlans.packageTier2.key]: {
          value: true,
          note: 'Most recent 10 yearly statements and most recent 8 quarterly statements'
        },
        [EPlans.packageTier3.key]: {
          value: true,
          note: 'Most recent 10 yearly statements and most recent 8 quarterly statements'
        }
      },
      'Ratios export': {
        [EPlans.user.key]: false,
        [EPlans.legacy20220831.key]: false,
        [EPlans.packageTier1.key]: false,
        [EPlans.packageTier2.key]: {
          value: true,
          note: 'Ratios derived from most recent 10 yearly statements and most recent 8 quarterly statements'
        },
        [EPlans.packageTier3.key]: {
          value: true,
          note: 'Ratios derived from most recent 10 yearly statements and most recent 8 quarterly statements'
        }
      },
      'Competitors data export': {
        [EPlans.user.key]: false,
        [EPlans.legacy20220831.key]: false,
        [EPlans.packageTier1.key]: false,
        [EPlans.packageTier2.key]: true,
        [EPlans.packageTier3.key]: true
      },
      'Stock screener export': {
        note: 'Maximum 200 companies',
        [EPlans.user.key]: false,
        [EPlans.legacy20220831.key]: false,
        [EPlans.packageTier1.key]: false,
        [EPlans.packageTier2.key]: true,
        [EPlans.packageTier3.key]: true
      },
      'EOD share prices export': {
        [EPlans.user.key]: false,
        [EPlans.legacy20220831.key]: false,
        [EPlans.packageTier1.key]: false,
        [EPlans.packageTier2.key]: true,
        [EPlans.packageTier3.key]: true
      }
    }
  },
  {
    title: 'Other',
    features: {
      Workspace: {
        [EPlans.user.key]: true,
        [EPlans.legacy20220831.key]: true,
        [EPlans.packageTier1.key]: true,
        [EPlans.packageTier2.key]: true,
        [EPlans.packageTier3.key]: true
      },
      'Ad-free experience': {
        [EPlans.user.key]: false,
        [EPlans.legacy20220831.key]: true,
        [EPlans.packageTier1.key]: true,
        [EPlans.packageTier2.key]: true,
        [EPlans.packageTier3.key]: true
      },
      'Data tagging': {
        note: 'Tag data within financial statements, ratios,... by adding notes',
        [EPlans.user.key]: false,
        [EPlans.legacy20220831.key]: false,
        [EPlans.packageTier1.key]: false,
        [EPlans.packageTier2.key]: true,
        [EPlans.packageTier3.key]: true
      }
    }
  }
];

class ECurrency {
  static AUD = 'AUD';
  static CAD = 'CAD';
  static CHF = 'CHF';
  static EUR = 'EUR';
  static GBP = 'GBP';
  static NZD = 'NZD';
  static JPY = 'JPY';
  static USD = 'USD';
}

class ECurrencyPair {
  static AUDCAD = 'AUD/CAD';
  static AUDCHF = 'AUD/CHF';
  static AUDJPY = 'AUD/JPY';
  static AUDNZD = 'AUD/NZD';
  static AUDUSD = 'AUD/USD';
  static EURAUD = 'EUR/AUD';
  static GBPAUD = 'GBP/AUD';
  static CADCHF = 'CAD/CHF';
  static CADJPY = 'CAD/JPY';
  static EURCAD = 'EUR/CAD';
  static GBPCAD = 'GBP/CAD';
  static NZDCAD = 'NZD/CAD';
  static USDCAD = 'USD/CAD';
  static CHFJPY = 'CHF/JPY';
  static EURCHF = 'EUR/CHF';
  static GBPCHF = 'GBP/CHF';
  static NZDCHF = 'NZD/CHF';
  static USDCHF = 'USD/CHF';
  static EURGBP = 'EUR/GBP';
  static EURJPY = 'EUR/JPY';
  static EURNZD = 'EUR/NZD';
  static EURUSD = 'EUR/USD';
  static GBPJPU = 'GBP/JPU';
  static GBPNZD = 'GBP/NZD';
  static GBPUSD = 'GBP/USD';
  static NZDJPY = 'NZD/JPY';
  static NZDUSD = 'NZD/USD';
  static USDJPY = 'USD/JPY';
}

class EPortfolioTransactionType {
  static cash = 'cash';
  static shares = 'shares';
}

class ETime {
  static milliseconds = {
    perSecond: 1000,
    perMinute: 1000 * 60,
    perHour: 1000 * 60 * 60,
    perDay: 1000 * 60 * 60 * 24,
    perWeek: 1000 * 60 * 60 * 24 * 7,
    perMonth: 1000 * 60 * 60 * 24 * 30, // Shouldn't be used if accuracy matters
    perYear: 1000 * 60 * 60 * 24 * 365 // Shouldn't be used if accuracy matters
  };

  static hours = {
    perDay: 24,
    perWeek: 24 * 7
  };

  static days = {
    perWeek: 7,
    perMonth: 30, // Shouldn't be used if accuracy matters
    perYear: 365 // Shouldn't be used if accuracy matters
  };

  static months = {
    perQuarter: 3,
    perYear: 12
  };

  static quarters = {
    perYear: 4
  };
}

class EMonths {
  static january = 0;
  static february = 1;
  static march = 2;
  static april = 3;
  static may = 4;
  static june = 5;
  static july = 6;
  static august = 7;
  static september = 8;
  static october = 9;
  static november = 10;
  static december = 11;
}

class EBalanceSheet {
  static currency = 'Currency';
  static earningAssets = 'Earning Assets';
  static goodwill = 'Goodwill';
  static negativeGoodwill = 'Negative Goodwill';
  static intangibleAssets = 'Intangible Assets';
  static longTermInvestments = 'Long Term Investments';
  static nonCurrentAssetsOther = 'Non Current Assets Other';
  static nonCurrentAssetsTotal = 'Non Current Assets Total';
  static otherAssets = 'Other Assets';
  static propertyPlantAndEquipmentGross = 'Gross PP&E';
  static cash = 'Cash';
  static shortTermInvestments = 'Short Term Investments';
  static inventory = 'Inventory';
  static netReceivables = 'Net Receivables';
  static netTangibleAssets = 'Net Tangible Assets';
  static otherCurrentAssets = 'Other Current Assets';
  static shortTermDebt = 'Short Term Debt';
  static additionalPaidInCapital = 'Additional Paid In Capital';
  static commonStock = 'Common Stock';
  static liabilitiesAndStockholdersEquity = 'Total Equity and Liabilities';
  static otherStockholderEquity = 'Other Stockholder Equity';
  static preferredStockTotalEquity = 'Preferred Stock Total Equity';
  static retainedEarnings = 'Retained Earnings';
  static totalPermanentEquity = 'Total Permanent Equity';
  static totalStockholderEquity = 'Total Stockholder Equity';
  static treasuryStock = 'Treasury Stock';
  static capitalLeaseObligations = 'Capital Lease Obligations';
  static deferredLongTermAssetCharges = 'Prepaid expenses';
  static deferredLongTermLiabilities = 'Deferred Long Term Liabilities';
  static longTermDebt = 'Long Term Debt';
  static longTermDebtTotal = 'Long Term Debt Total';
  static nonCurrentLiabilitiesOther = 'Non Current Liabilities Other';
  static nonCurrentLiabilitiesTotal = 'Non Current Liabilities Total';
  static otherLiabilities = 'Other Liabilities';
  static shortLongTermDebt = 'Short Long Term Debt';
  static shortLongTermDebtTotal = 'Total debt';
  static accountsPayable = 'Accounts Payable';
  static otherCurrentLiabilities = 'Other Current Liabilities';
  static capitalSurplus = 'Capital Surplus';
  static nonControllingInterestInConsolidatedEntity = 'Non Controlling Interest In Consolidated Entity';
  static preferredStockRedeemable = 'Preferred Stock Redeemable';
  static retainedEarningsTotalEquity = 'Retained Earnings Total Equity';
  static temporaryEquityRedeemableNonControllingInterests = 'Temporary Equity Redeemable Non Controlling Interests';
  static warrants = 'Warrants';
  static accumulatedAmortization = 'Accumulated Amortization';
  static accumulatedDepreciation = 'Accumulated Depreciation';
  static accumulatedOtherComprehensiveIncome = 'Accumulated Other Comprehensive Income';
  static commonStockSharesOutstanding = 'Common Stock Shares Outstanding';
  static commonStockTotalEquity = 'Common Stock Total Equity';
  static propertyPlantEquipment = 'PP&E';
  static totalCurrentAssets = 'Total Current Assets';
  static totalCurrentLiabilities = 'Total Current Liabilities';
  static totalAssets = 'Total Assets';
  static totalLiabilities = 'Total Liabilities';
  static cashAndShortTermInvestments = 'Cash and equivalents';
  static otherNonCurrentAssets = 'Other Non Current Assets';
  static otherStockHolderEquity = 'Other Stockholder Equity';
  static otherNonCurrentLiabilities = 'Other Non Current Liabilities';
}

class EIncomeStatement {
  static currency = 'Currency';
  static researchDevelopment = 'Research Development';
  static sellingGeneralAdministrative = 'Selling General Administrative';
  static otherOperatingExpenses = 'Other Operating Expenses';
  static interestExpense = 'Interest Expense';
  static minorityInterest = 'Minority Interest';
  static nonOperatingIncomeNetOther = 'Non Operating Income Net Other';
  static interestIncome = 'Interest Income';
  static netInterestIncome = 'Net Interest Income';
  static nonRecurring = 'Non Recurring';
  static incomeTaxExpense = 'Income Tax Expense';
  static discontinuedOperations = 'Discontinued Operations';
  static extraordinaryItems = 'Extraordinary Items';
  static otherItems = 'Other Items';
  static netIncomeApplicableToCommonShares = 'Net Income Applicable To Common Shares';
  static effectOfAccountingCharges = 'Effect Of Accounting Charges';
  static taxProvision = 'Tax Provision';
  static preferredStockAndOtherAdjustments = 'Preferred Stock And Other Adjustments';
  static ebit = 'EBIT';
  static incomeBeforeTax = 'Income Before Tax';
  static grossProfit = 'Gross Profit';
  static netIncome = 'Net Income';
  static operatingIncome = 'Operating Income';
  static totalRevenue = 'Revenue';
  static totalOperatingExpenses = 'Total Operating Expenses';
  static costOfRevenue = 'Cost Of Revenue';
  static totalOtherIncomeExpenseNet = 'Total Other Income Expense Net';
  static netIncomeFromContinuingOps = 'Net Income From Continuing Ops';
  static otherOperatingIncome = 'Other Operating Income';
  static otherNetIncomeFromContinuingOps = 'Other Net Income From Continuing Operations';
}

class ECashFlowStatement {
  static currency = 'Currency';
  static changeToLiabilities = 'Change To Liabilities';
  static changeToOperatingActivities = 'Change To Operating Activities';
  static changeToInventory = 'Change To Inventory';
  static changeToAccountReceivables = 'Change To Account Receivables';
  static changeReceivables = 'Change Receivables';
  static changeToNetIncome = 'Change To Net Income';
  static depreciation = 'Depreciation and amortization';
  static cashFlowsOtherOperating = 'Cashflows Other Operating';
  static otherCashFlowFromInvestingActivities = 'Other Cashflow From Investing Activities';
  static capitalExpenditures = 'Capital Expenditures';
  static netBorrowings = 'Net Borrowings';
  static dividendsPaid = 'Dividends Paid';
  static salePurchaseOfStock = 'Sale Purchase Of Stock';
  static otherCashFlowFromFinancingActivities = 'Other Cashflow From Financing Activities';
  static exchangeRateChanges = 'Exchange Rate Changes';
  static cashAndCashEquivalentsChanges = 'Cash And Cash Equivalents Changes';
  static changeInCash = 'Change In Cash';
  static totalChangeInCash = 'Total Change In Cash';
  static beginPeriodCashFlow = 'Begin Period Cashflow';
  static endPeriodCashFlow = 'End Period Cashflow';
  static investments = 'Investments';
  static totalCashFlowFromInvestingActivities = 'Total Cashflow From Investing Activities';
  static totalCashFromFinancingActivities = 'Total Cash From Financing Activities';
  static totalCashFromOperatingActivities = 'Total Cash From Operating Activities';
  static netIncome = 'Net Income';
  static otherCashFromOperatingActivities = 'Other Cash From Operating Activities';
  static otherCashFromFinancingActivities = 'Other Cash From Financing Activities';
  static changeInCashWithoutExchangeRateChanges = 'Change In Cash Without Exchange Rate Changes';
}

class ERatios {
  static marketCap = 'Market Cap.';
  static enterpriseValue = 'Enterprise Value';
  static balanceTotal = 'Balance Total';
  static totalEquity = 'Total Equity';
  static goodwill = 'Goodwill';
  static otherIntangibleAssets = 'Other Intangible Assets';
  static totalIntangibleAssets = 'Total Intangible Assets';
  static cash = 'Cash';
  static shortTermInvestments = 'Short Term Investments';
  static cashAndShortTermInvestments = 'Cash And Short Term Investments';
  static currentRatio = 'Current Ratio';
  static quickRatio = 'Quick Ratio';
  static netWorkingCapitalOverSales = 'Net Working Capital/Sales';
  static operatingCashFlowOverSales = 'Operating Cashflow/Sales';
  static equityRatio = 'Equity Ratio';
  static equityOverNonCurrentAssetsRatio = 'Equity/Non Current Assets Ratio';
  static equityPlusNonCurrentLiabilitiesOverNonCurrentAssets = 'Equity + Non Current Liabilities/Non Current Assets';
  static debtRatio = 'Debt Ratio';
  static debtOverEquityRatio = 'Debt/Equity Ratio';
  static longTermDebtOverEquityRatio = 'Long Term Debt/Equity Ratio';
  static gearing = 'Gearing';
  static debtOverCashRatio = 'Debt/Cash Ratio';
  static netFinancialDebtOverEbitda = 'Net Financial Debt/EBITDA';
  static netFinancialDebtOverEquity = 'Net Financial Debt/Equity';
  static operatingCashFlowOverDebt = 'Operating Cashflow/Debt';
  static ebitda = 'EBITDA';
  static ebit = 'EBIT';
  static ebt = 'EBT';
  static leveredFreeCashFlow = 'Levered Free Cashflow';
  static unleveredFreeCashFlow = 'Unlevered Free Cashflow';
  static revenueGrowth = 'Revenue Growth';
  static grossProfitGrowth = 'Gross Profit Growth';
  static ebitdaGrowth = 'EBITDA Growth';
  static ebitGrowth = 'EBIT Growth';
  static netIncomeGrowth = 'Net Income Growth';
  static epsGrowth = 'EPS Growth';
  static cashFromOperatingActivitiesGrowth = 'Cash From Operating Activities Growth';
  static grossProfitMargin = 'Gross Profit Margin';
  static ebitdaMargin = 'EBITDA Margin';
  static ebitMargin = 'EBIT Margin';
  static netIncomeMargin = 'Net Income Margin';
  static returnOnInvestment = 'Return On Investment';
  static returnOnCapitalEmployed = 'Return On Capital Employed';
  static returnOnEquityBeforeTax = 'Return On Equity Before Tax';
  static returnOnEquityAfterTax = 'Return On Equity After Tax';
  static bookValuePerShare = 'Book Value Per Share';
  static bookValueChange = 'Book Value Change';
  static priceOverEarningsMean = 'Price/Earnings Mean';
  static priceOverEarningsHigh = 'Price/Earnings High';
  static priceOverEarningsLow = 'Price/Earnings Low';
  static priceOverBookMean = 'Price/Book value Mean';
  static priceOverBookHigh = 'Price/Book value High';
  static priceOverBookLow = 'Price/Book value Low';
  static priceOverCashFlowMean = 'Price/Cashflow Mean';
  static priceOverCashFlowHigh = 'Price/Cashflow High';
  static priceOverCashFlowLow = 'Price/Cashflow Low';
  static priceOverSalesMean = 'Price/Sales Mean';
  static priceOverSalesHigh = 'Price/Sales High';
  static priceOverSalesLow = 'Price/Sales Low';
  static ebitdaOverMarketCapMean = 'EBITDA/Market Cap Mean';
  static ebitdaOverMarketCapHigh = 'EBITDA/Market Cap High';
  static ebitdaOverMarketCapLow = 'EBITDA/Market Cap Low';
  static netIncomeOverMarketCapMean = 'Net Income/Market Cap. Mean';
  static netIncomeOverMarketCapHigh = 'Net Income/Market Cap. High';
  static netIncomeOverMarketCapLow = 'Net Income/Market Cap. Low';
  static enterpriseValueOverEbitdaMean = 'EV/EBITDA Mean';
  static enterpriseValueOverEbitdaHigh = 'EV/EBITDA High';
  static enterpriseValueOverEbitdaLow = 'EV/EBITDA Low';
  static enterpriseValueOverSalesMean = 'EV/Sales Mean';
  static enterpriseValueOverSalesHigh = 'EV/Sales High';
  static enterpriseValueOverSalesLow = 'EV/Sales Low';
  static pegRatioMean = 'PEG Ratio Mean';
  static pegRatioHigh = 'PEG Ratio High';
  static pegRatioLow = 'PEG Ratio Low';
  static liabilitiesRatio = 'Liabilities Ratio';
  static totalRevenue = 'Total Revenue';
  static grossProfit = 'Gross Profit';
  static netIncome = 'Net Income';
  static totalCashFromOperatingActivities = 'Total Cash from Operating Activities';
  static totalCashFlowFromInvestingActivities = 'Total Cash Flow from Investing Activities';
  static totalCashFromFinancingActivities = 'Total Cash from Financing Activities';
}

class EAuthStrategies {
  static GOOGLE = 'google';
  static MICROSOFT = 'microsoft';
  static TWITTER = 'twitter';
  static FACEBOOK = 'facebook';
}

class EVatIdTypes {
  static AE_TRN = { id: 'AE_TRN', stripeCode: 'ae_trn', name: 'United Arab Emirates TRN', example: '123456789012345' };
  static AU_ABN = { id: 'AU_ABN', stripeCode: 'au_abn', name: 'Australian Business Number (AU ABN)', example: '12345678912' };
  static AU_ARN = { id: 'AU_ARN', stripeCode: 'au_arn', name: 'Australian Taxation Office Reference Number', example: '123456789123' };
  static BR_CNPJ = { id: 'BR_CNPJ', stripeCode: 'br_cnpj', name: 'Brazilian CNPJ number', example: '01.234.456/5432-10' };
  static BR_CPF = { id: 'BR_CPF', stripeCode: 'br_cpf', name: 'Brazilian CPF number', example: '123.456.789-87' };
  static CA_BN = { id: 'CA_BN', stripeCode: 'ca_bn', name: 'Canadian BN', example: '123456789' };
  static CA_GST_HST = { id: 'CA_GST_HST', stripeCode: 'ca_gst_hst', name: 'Canadian GST/HST number', example: '123456789RT0002' };
  static CA_PST_BC = { id: 'CA_PST_BC', stripeCode: 'ca_pst_bc', name: 'Canadian PST number (British Columbia)', example: 'PST-1234-5678' };
  static CA_PST_MB = { id: 'CA_PST_MB', stripeCode: 'ca_pst_mb', name: 'Canadian PST number (Manitoba)', example: '123456-7' };
  static CA_PST_SK = { id: 'CA_PST_SK', stripeCode: 'ca_pst_sk', name: 'Canadian PST number (Saskatchewan)', example: '' };
  static CA_QST = { id: 'CA_QST', stripeCode: 'ca_qst', name: 'Canadian QST number (Québec)', example: '1234567' };
  static CH_VAT = { id: 'CH_VAT', stripeCode: 'ch_vat', name: 'Switzerland VAT number', example: 'CHE-123.456.789 MWST' };
  static CL_TIN = { id: 'CL_TIN', stripeCode: 'cl_tin', name: 'Chilean TIN', example: '12.345.678-K' };
  static ES_CIF = { id: 'ES_CIF', stripeCode: 'es_cif', name: 'Spanish CIF number', example: 'A12345678' };
  static EU_VAT = { id: 'EU_VAT', stripeCode: 'eu_vat', name: 'European VAT number', example: 'DE123456789' };
  static GB_VAT = { id: 'GB_VAT', stripeCode: 'gb_vat', name: 'United Kingdom VAT number', example: 'GB123456789' };
  static HK_BR = { id: 'HK_BR', stripeCode: 'hk_br', name: 'Hong Kong BR number', example: '12345678' };
  static ID_NPWP = { id: 'ID_NPWP', stripeCode: 'id_npwp', name: 'Indonesian NPWP number', example: '12.345.678.9-012.345' };
  static IL_VAT = { id: 'IL_VAT', stripeCode: 'il_vat', name: 'Israel VAT', example: '000012345' };
  static IN_GST = { id: 'IN_GST', stripeCode: 'in_gst', name: 'Indian GST number', example: '12ABCDE3456FGZH' };
  static KR_BRN = { id: 'KR_BRN', stripeCode: 'kr_brn', name: 'Korean BRN', example: '123-45-67890' };
  static LI_UID = { id: 'LI_UID', stripeCode: 'li_uid', name: 'Liechtensteinian UID number', example: 'CHE123456789' };
  static MX_RFC = { id: 'MX_RFC', stripeCode: 'mx_rfc', name: 'Mexican RFC number', example: 'ABC010203AB9' };
  static MY_FRP = { id: 'MY_FRP', stripeCode: 'my_frp', name: 'Malaysian FRP number', example: '12345678' };
  static MY_ITN = { id: 'MY_ITN', stripeCode: 'my_itn', name: 'Malaysian ITN', example: 'C 1234567890' };
  static MY_SST = { id: 'MY_SST', stripeCode: 'my_sst', name: 'Malaysian SST number', example: 'A12-3456-78912345' };
  static NO_VAT = { id: 'NO_VAT', stripeCode: 'no_vat', name: 'Norwegian VAT number', example: '123456789MVA' };
  static NZ_GST = { id: 'NZ_GST', stripeCode: 'nz_gst', name: 'New Zealand GST number', example: '123456789' };
  static RU_INN = { id: 'RU_INN', stripeCode: 'ru_inn', name: 'Russian INN', example: '1234567891' };
  static RU_KPP = { id: 'RU_KPP', stripeCode: 'ru_kpp', name: 'Russian KPP', example: '123456789' };
  static SA_VAT = { id: 'SA_VAT', stripeCode: 'sa_vat', name: 'Saudi Arabia VAT', example: '123456789012345' };
  static SG_GST = { id: 'SG_GST', stripeCode: 'sg_gst', name: 'Singaporean GST', example: 'M12345678X' };
  static SG_UEN = { id: 'SG_UEN', stripeCode: 'sg_uen', name: 'Singaporean UEN', example: '123456789F' };
  static TH_VAT = { id: 'TH_VAT', stripeCode: 'th_vat', name: 'Thai VAT', example: '1234567891234' };
  static TW_VAT = { id: 'TW_VAT', stripeCode: 'tw_vat', name: 'Taiwanese VAT', example: '12345678' };
  static US_EIN = { id: 'US_EIN', stripeCode: 'us_ein', name: 'United States EIN', example: '12-3456789' };
  static ZA_VAT = { id: 'ZA_VAT', stripeCode: 'za_vat', name: 'South African VAT number', example: '4123456789' };
  static JP_CN = { id: 'JP_CN', stripeCode: 'jp_cn', name: 'Japanese Corporate Number', example: '1234567891234' };
  static JP_RN = {
    id: 'JP_RN',
    stripeCode: 'jp_rn',
    name: "Japanese Registered Foreign Businesses' Registration Number",
    example: '12345'
  };
}

class EThousands {
  static 0 = {
    divider: 1,
    description: null
  };

  static 1 = {
    divider: 1000 ** 1,
    description: 'in thousands'
  };

  static 2 = {
    divider: 1000 ** 2,
    description: 'in millions'
  };

  static 3 = {
    divider: 1000 ** 3,
    description: 'in billions'
  };

  static 4 = {
    divider: 1000 ** 1,
    description: 'in trillions'
  };

  static DEFAULT = this[0];
  static THOUSAND = this[1];
  static MILLION = this[2];
  static BILLION = this[3];
  static TRILLION = this[4];
}

class EDbCollection {
  static companyData = 'company_data';
  static companySharePriceDaily = 'company_share_price_daily';
  static companyStockScreenerData01 = 'company_stock_screener_data_01';
  static companyStockScreenerData02 = 'company_stock_screener_data_02';
  static companyStockScreenerData03 = 'company_stock_screener_data_03';
  static companyHistoricValuations = 'company_historic_valuations';
  static companyDcfValuations = 'company_dcf_valuations';
  static companyValuations = 'company_valuations';
  static historicExchangeRates = 'historic_exchange_rates';
  static singleInstance = 'single_instance';
  static exchanges = 'exchanges';
  static earningsCalendar = 'earnings_calendar';
  static tags = 'tags';
  static users = 'users';
  static workspace = 'workspace';
  static dataTags = 'data_tags';
}

class EFinancialStatementType {
  static balanceSheet = 'bs';
  static cashFlow = 'cf';
  static incomeStatement = 'is';
}

class EUnicornDataSecurityType {
  static undefined = null;
  static commonStock = 'common stock';
  static fund = 'fund';
  static mutualFund = 'mutual fund';
  static etf = 'etf';
  static preferredShare = 'preferred share';
  static preferredStock = 'preferred stock';
  static bond = 'bond';
}

class EDataTaggingType {
  static BALANCE_SHEET = 'balance-sheet';
  static CASH_FLOW_STATEMENT = 'cash-flow-statement';
  static INCOME_STATEMENT = 'income-statement';
  static RATIOS = 'ratios';
  static SHARE_PRICE = 'share-price';
}

class EEmailTemplates {
  static NOTIFICATIONS_UPDATE = 'd-9030ab29dadb4faa902f68470732e1a6';
}

export {
  ESharingPermission,
  ETagSubject,
  EPlans,
  EPlanFeatures,
  ECurrency,
  ECurrencyPair,
  EPortfolioTransactionType,
  ETime,
  EMonths,
  EBalanceSheet,
  EIncomeStatement,
  ECashFlowStatement,
  ERatios,
  EAuthStrategies,
  EVatIdTypes,
  EThousands,
  EDbCollection,
  EFinancialStatementType,
  EUnicornDataSecurityType,
  EDataTaggingType,
  EEmailTemplates
};
