// prettier-ignore
const currencyList = {
  AED: { name: 'UAE Dirham', iso: 'AED', symbol: 'AED' },
  AFN: { name: 'Afghani', iso: 'AFN', symbol: '؋' },
  ALL: { name: 'Lek', iso: 'ALL', symbol: 'Lek' },
  AMD: { name: 'Armenian Dram', iso: 'AMD', symbol: 'AMD' },
  ARS: { name: 'Argentine Peso', iso: 'ARS', symbol: '$' },
  AUD: { name: 'Australian Dollar', iso: 'AUD', symbol: '$' },
  BAM: { name: 'Convertible Mark', iso: 'BAM', symbol: 'KM' },
  BBD: { name: 'Barbados Dollar', iso: 'BBD', symbol: '$' },
  BDT: { name: 'Taka', iso: 'BDT', symbol: 'BDT' },
  BGN: { name: 'Bulgarian Lev', iso: 'BGN', symbol: 'лв' },
  BHD: { name: 'Bahraini Dinar', iso: 'BHD', symbol: 'BHD' },
  BIF: { name: 'Burundi Franc', iso: 'BIF', symbol: 'BIF' },
  BMD: { name: 'Bermudian Dollar', iso: 'BMD', symbol: '$' },
  BND: { name: 'Brunei Dollar', iso: 'BND', symbol: '$' },
  BOB: { name: 'Boliviano', iso: 'BOB', symbol: '$b' },
  BRL: { name: 'Brazilian Real', iso: 'BRL', symbol: 'R$' },
  BSD: { name: 'Bahamian Dollar', iso: 'BSD', symbol: '$' },
  BWP: { name: 'Pula', iso: 'BWP', symbol: 'P' },
  BZD: { name: 'Belize Dollar', iso: 'BZD', symbol: 'BZ$' },
  CAD: { name: 'Canadian Dollar', iso: 'CAD', symbol: '$' },
  CDF: { name: 'Congolese Franc', iso: 'CDF', symbol: 'CDF' },
  CHF: { name: 'Swiss Franc', iso: 'CHF', symbol: 'CHF' },
  CLP: { name: 'Chilean Peso', iso: 'CLP', symbol: '$' },
  CNH: { name: 'CNH', iso: 'CNH', symbol: 'CNH' },
  CNY: { name: 'Yuan Renminbi', iso: 'CNY', symbol: '¥' },
  COP: { name: 'Colombian Peso', iso: 'COP', symbol: '$' },
  CRC: { name: 'Costa Rican Colon', iso: 'CRC', symbol: '₡' },
  CUP: { name: 'Cuban Peso', iso: 'CUP', symbol: '₱' },
  CVE: { name: 'Cabo Verde Escudo', iso: 'CVE', symbol: 'CVE' },
  CZK: { name: 'Czech Koruna', iso: 'CZK', symbol: 'Kč' },
  DJF: { name: 'Djibouti Franc', iso: 'DJF', symbol: 'DJF' },
  DKK: { name: 'Danish Krone', iso: 'DKK', symbol: 'kr' },
  DOP: { name: 'Dominican Peso', iso: 'DOP', symbol: 'RD$' },
  DZD: { name: 'Algerian Dinar', iso: 'DZD', symbol: 'DZD' },
  EGP: { name: 'Egyptian Pound', iso: 'EGP', symbol: '£' },
  ETB: { name: 'Ethiopian Birr', iso: 'ETB', symbol: 'ETB' },
  EUR: { name: 'Euro', iso: 'EUR', symbol: '€' },
  FJD: { name: 'Fiji Dollar', iso: 'FJD', symbol: '$' },
  GBP: { name: 'Pound Sterling', iso: 'GBP', symbol: '£' },
  GBX: { name: 'Penny Sterling', iso: 'GBX', symbol: 'p' },
  GEL: { name: 'Lari', iso: 'GEL', symbol: 'GEL' },
  GHS: { name: 'Ghana Cedi', iso: 'GHS', symbol: '¢' },
  GMD: { name: 'Dalasi', iso: 'GMD', symbol: 'GMD' },
  GNF: { name: 'Guinea Franc', iso: 'GNF', symbol: 'GNF' },
  GTQ: { name: 'Quetzal', iso: 'GTQ', symbol: 'Q' },
  HKD: { name: 'Hong Kong Dollar', iso: 'HKD', symbol: '$' },
  HNL: { name: 'Lempira', iso: 'HNL', symbol: 'L' },
  HRK: { name: 'Kuna', iso: 'HRK', symbol: 'kn' },
  HTG: { name: 'Gourde', iso: 'HTG', symbol: 'HTG' },
  HUF: { name: 'Forint', iso: 'HUF', symbol: 'Ft' },
  IDR: { name: 'Rupiah', iso: 'IDR', symbol: 'Rp' },
  ILA: { name: 'Israeli agora', iso: 'ILA', symbol: 'ILA' },
  ILS: { name: 'New Israeli Sheqel', iso: 'ILS', symbol: '₪' },
  INR: { name: 'Indian Rupee', iso: 'INR', symbol: '' },
  IQD: { name: 'Iraqi Dinar', iso: 'IQD', symbol: 'IQD' },
  ISK: { name: 'Iceland Krona', iso: 'ISK', symbol: 'kr' },
  JMD: { name: 'Jamaican Dollar', iso: 'JMD', symbol: 'J$' },
  JOD: { name: 'Jordanian Dinar', iso: 'JOD', symbol: 'JOD' },
  JPY: { name: 'Yen', iso: 'JPY', symbol: '¥' },
  KES: { name: 'Kenyan Shilling', iso: 'KES', symbol: 'KES' },
  KHR: { name: 'Riel', iso: 'KHR', symbol: '៛' },
  KMF: { name: 'Comoro Franc', iso: 'KMF', symbol: 'KMF' },
  KRW: { name: 'Won', iso: 'KRW', symbol: '₩' },
  KWD: { name: 'Kuwaiti Dinar', iso: 'KWD', symbol: 'KWD' },
  KYD: { name: 'Cayman Islands Dollar', iso: 'KYD', symbol: '$' },
  KZT: { name: 'Tenge', iso: 'KZT', symbol: 'лв' },
  LAK: { name: 'Kip', iso: 'LAK', symbol: '₭' },
  LBP: { name: 'Lebanese Pound', iso: 'LBP', symbol: '£' },
  LKR: { name: 'Sri Lanka Rupee', iso: 'LKR', symbol: '₨' },
  LRD: { name: 'Liberian Dollar', iso: 'LRD', symbol: '$' },
  LSL: { name: 'Loti', iso: 'LSL', symbol: 'LSL' },
  LYD: { name: 'Libyan Dinar', iso: 'LYD', symbol: 'LYD' },
  MAD: { name: 'Moroccan Dirham', iso: 'MAD', symbol: 'MAD' },
  MDL: { name: 'Moldovan Leu', iso: 'MDL', symbol: 'MDL' },
  MKD: { name: 'Denar', iso: 'MKD', symbol: 'ден' },
  MMK: { name: 'Kyat', iso: 'MMK', symbol: 'MMK' },
  MOP: { name: 'Pataca', iso: 'MOP', symbol: 'MOP' },
  MUR: { name: 'Mauritius Rupee', iso: 'MUR', symbol: '₨' },
  MVR: { name: 'Rufiyaa', iso: 'MVR', symbol: 'MVR' },
  MWK: { name: 'Kwacha', iso: 'MWK', symbol: 'MWK' },
  MXN: { name: 'Mexican Peso', iso: 'MXN', symbol: '$' },
  MYR: { name: 'Malaysian Ringgit', iso: 'MYR', symbol: 'RM' },
  NAD: { name: 'Namibia Dollar', iso: 'NAD', symbol: '$' },
  NGN: { name: 'Naira', iso: 'NGN', symbol: '₦' },
  NIO: { name: 'Cordoba Oro', iso: 'NIO', symbol: 'C$' },
  NOK: { name: 'Norwegian Krone', iso: 'NOK', symbol: 'kr' },
  NPR: { name: 'Nepalese Rupee', iso: 'NPR', symbol: '₨' },
  NZD: { name: 'New Zealand Dollar', iso: 'NZD', symbol: '$' },
  OMR: { name: 'Rial Omani', iso: 'OMR', symbol: '﷼' },
  PAB: { name: 'Balboa', iso: 'PAB', symbol: 'B/.' },
  PEN: { name: 'Nuevo Sol', iso: 'PEN', symbol: 'S/.' },
  PGK: { name: 'Kina', iso: 'PGK', symbol: 'PGK' },
  PHP: { name: 'Philippine Peso', iso: 'PHP', symbol: '₱' },
  PKR: { name: 'Pakistan Rupee', iso: 'PKR', symbol: '₨' },
  PLN: { name: 'Zloty', iso: 'PLN', symbol: 'zł' },
  PYG: { name: 'Guarani', iso: 'PYG', symbol: 'Gs' },
  QAR: { name: 'Qatari Rial', iso: 'QAR', symbol: '﷼' },
  RON: { name: 'Romanian Leu', iso: 'RON', symbol: 'lei' },
  RSD: { name: 'Serbian Dinar', iso: 'RSD', symbol: 'Дин.' },
  RUB: { name: 'Russian Ruble', iso: 'RUB', symbol: '₽' },
  RWF: { name: 'Rwanda Franc', iso: 'RWF', symbol: 'RWF' },
  SAR: { name: 'Saudi Riyal', iso: 'SAR', symbol: '﷼' },
  SCR: { name: 'Seychelles Rupee', iso: 'SCR', symbol: '₨' },
  SEK: { name: 'Swedish Krona', iso: 'SEK', symbol: 'kr' },
  SGD: { name: 'Singapore Dollar', iso: 'SGD', symbol: '$' },
  SHP: { name: 'Saint Helena Pound', iso: 'SHP', symbol: '£' },
  SLL: { name: 'Leone', iso: 'SLL', symbol: 'SLL' },
  SOS: { name: 'Somali Shilling', iso: 'SOS', symbol: 'S' },
  STD: { name: 'STD', iso: 'STD', symbol: 'STD' },
  SVC: { name: 'El Salvador Colon', iso: 'SVC', symbol: '$' },
  SZL: { name: 'Lilangeni', iso: 'SZL', symbol: 'E' },
  THB: { name: 'Baht', iso: 'THB', symbol: '฿' },
  TND: { name: 'Tunisian Dinar', iso: 'TND', symbol: 'DT' },
  TRY: { name: 'Turkish Lira', iso: 'TRY', symbol: '₺' },
  TTD: { name: 'Trinidad and Tobago Dollar', iso: 'TTD', symbol: 'TT$' },
  TWD: { name: 'New Taiwan Dollar', iso: 'TWD', symbol: 'NT$' },
  TZS: { name: 'Tanzanian Shilling', iso: 'TZS', symbol: 'TSh' },
  UAH: { name: 'Hryvnia', iso: 'UAH', symbol: '₴' },
  UGX: { name: 'Uganda Shilling', iso: 'UGX', symbol: 'Ush' },
  USD: { name: 'US Dollar', iso: 'USD', symbol: '$' },
  UYU: { name: 'Peso Uruguayo', iso: 'UYU', symbol: '$U' },
  UZS: { name: 'Uzbekistan Sum', iso: 'UZS', symbol: 'лв' },
  VND: { name: 'Dong', iso: 'VND', symbol: '₫' },
  XAF: { name: 'CFA Franc BEAC', iso: 'XAF', symbol: 'FCFA' },
  XCD: { name: 'East Caribbean Dollar', iso: 'XCD', symbol: '$' },
  XOF: { name: 'CFA Franc BCEAO', iso: 'XOF', symbol: 'CFA' },
  XPF: { name: 'CFP Franc', iso: 'XPF', symbol: '₣' },
  YER: { name: 'Yemeni Rial', iso: 'YER', symbol: '﷼' },
  ZAR: { name: 'Rand', iso: 'ZAR', symbol: 'R' }
};

export class Currency {
  static list = currencyList;

  constructor(currency) {
    if (typeof currency === typeof String()) {
      this.selected = currencyList[currency?.toUpperCase()] || null;
    } else {
      this.selected = null;
    }
  }

  iso() {
    return this?.selected?.iso;
  }

  name() {
    return this?.selected?.name;
  }

  symbol() {
    return this?.selected?.symbol;
  }
}
