import { StockInvestingApi } from '../../../frontend/api/index.js';

const stockInvestingApi = new StockInvestingApi();

class ETransactionType {
  static CASH = 'cash';
  static SHARES = 'shares';
}

class PortfolioTransaction {
  constructor({ _id = null, date = new Date() }) {
    this._id = _id;
    this.date = new Date(date);
  }

  async delete(portfolio) {
    await stockInvestingApi.portfoliosDeleteTransaction({
      portfolioId: portfolio._id,
      transactionId: this._id
    });
  }
}

class PortfolioTransactionCash extends PortfolioTransaction {
  constructor({ _id = null, deposit = true, amount = 0, date = new Date() }) {
    super({ _id, date });
    this.type = ETransactionType.CASH;
    this.deposit = deposit;
    this.amount = amount;
    this.date = new Date(date);
  }

  async save(portfolio) {
    const transaction = await stockInvestingApi.portfoliosCreateCashTransaction({
      portfolioId: portfolio._id,
      date: this.date,
      deposit: this.deposit,
      amount: this.amount
    });
    this._id = transaction._id;
  }
}

class PortfolioTransactionShares extends PortfolioTransaction {
  constructor({ _id = null, buy = true, companyCode = null, sharePrice = 0, amount = 0, exchangeRate = 1, date = new Date() }) {
    super({ _id, date });
    this.type = ETransactionType.SHARES;
    this.buy = buy;
    this.companyCode = companyCode;
    this.sharePrice = sharePrice;
    this.amount = amount;
    this.exchangeRate = exchangeRate;
  }

  async save(portfolio) {
    const transaction = await stockInvestingApi.portfoliosCreateSharesTransaction({
      portfolioId: portfolio._id,
      date: this.date,
      deposit: this.deposit,
      amount: this.amount,
      companyCode: this.companyCode,
      sharePrice: this.sharePrice,
      exchangeRate: this.exchangeRate,
      buy: this.buy
    });
    this._id = transaction._id;
  }
}

export { ETransactionType, PortfolioTransactionCash, PortfolioTransactionShares };
