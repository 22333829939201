const filterValue = (filter, value) => {
  if (filter && Array.isArray(filter)) {
    return filter?.first()(value, ...filter.slice(1));
  }
  if (typeof filter === typeof Function) {
    return filter(value);
  }
  if (!filter) {
    return value;
  }
  return null;
};

/**
 * @name CompanyScreenerColumn
 * @param {object} obj The column data
 * @param {string[]} [obj.keys=[]] The keys in the screener database used for this column
 * @param {boolean} [obj.sticky=false] Whether or not the column is sticky
 * @param {boolean} [obj.hideTableHeader=false] Hide the table header's label (used for narrow table columns)
 * @param {boolean} [obj.shown=true] Whether or not to show the column
 * @param {boolean} [obj.sortable=true] Whether or not the column is sortable
 * @param {string[]} [obj.dataClasses=[]] Array of classes applied to the data-cell of this column
 * @param {string[]} [obj.thClasses=[]] Array of classes applied to the table header of this column
 */
class CompanyScreenerColumn {
  constructor({
    keys = [],
    sticky = false,
    hideTableHeader = false,
    shown = false,
    sortable = true,
    dataClasses = [],
    thClasses = [],
    colored = false,
    valueFilter = null,
    hrValue = null,
    hrVisualizerValue = null,
    exportValue = null
  }) {
    this.keys = keys;
    this.sticky = sticky;
    this.hideTableHeader = hideTableHeader;
    this.shown = shown;
    this.sortable = sortable;
    this.dataClasses = dataClasses;
    this.thClasses = thClasses;
    this.colored = colored;
    this.valueFilter = valueFilter;
    this.hrValue =
      hrValue ||
      ((company) => {
        if (keys.length === 1) {
          return filterValue(this.valueFilter, company?.[keys?.first()]);
        }
        if (keys.length > 1) {
          const obj = {};
          keys.forEach((key) => {
            obj[key] = filterValue(this.valueFilter, company?.[key]);
          });
          return obj;
        }
        return null;
      });
    this.exportValue = exportValue || this.hrValue;
    this.hrVisualizerValue = hrVisualizerValue || this.hrValue;
  }
}

export { CompanyScreenerColumn };
