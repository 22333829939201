class CompanyAnalystRatings {
  constructor({ rating = null, targetPrice = null, strongBuy = null, buy = null, hold = null, sell = null, strongSell = null }) {
    this.rating = rating;
    this.targetPrice = targetPrice;
    this.strongBuy = strongBuy;
    this.buy = buy;
    this.hold = hold;
    this.sell = sell;
    this.strongSell = strongSell;
  }

  get ratingCalc() {
    const nominator = this.strongBuy * 5 + this.buy * 4 + this.hold * 3 + this.sell * 2 + this.strongSell * 1;
    const denominator = (this.strongBuy + this.buy + this.hold + this.sell + this.strongSell) * 5;
    return (nominator / denominator) * 5;
  }
}

export { CompanyAnalystRatings };
