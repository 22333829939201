<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'steps',
  props: {
    steps: {
      type: Number,
      default: 2
    },
    step: {
      type: Number,
      default: 1
    }
  }
})
class Steps extends Vue {}
export default Steps;
</script>
<style lang="scss" scoped>
@import './style.scss';
</style>
