import { StockInvestingApi } from '../../../frontend/api/index.js';

import { Tag } from '../general/Tag.js';
import { Exchange } from '../exchange/Exchange.js';

import { CompanyGeneral } from './CompanyGeneral.js';
import { CompanyCurrencies } from './CompanyCurrencies.js';
import { CompanyListing } from './CompanyListing.js';
import { CompanyFinancialStatements } from './financial-statements/CompanyFinancialStatements.js';
import { CompanyShares } from './shares/CompanyShares.js';
import { CompanyCalculated } from './CompanyCalculated.js';
import { CompanyValuations } from './valuations/CompanyValuations.js';
import { CompanyManagement } from './management/CompanyManagement.js';
import { CompanyAnalystRatings } from './CompanyAnalystRatings.js';
import { CompanyRatios } from './ratios/CompanyRatios.js';
import { CompanyHolders } from './holders/CompanyHolders.js';
import { CompanyInsiderTransactions } from './insider-transactions/CompanyInsiderTransactions.js';
import { CompanyEsg } from './CompanyEsg.js';
import { CompanyDataTag } from './data-tagging/CompanyDataTag.js';

import { ETime } from '../../enums.js';

const stockInvestingApi = new StockInvestingApi();

class Company {
  constructor(args) {
    this.construct(args);
  }

  construct({
    companyCode,
    _id = null,
    general = {},
    listing = {},
    currencies = {},
    financialStatements = {},
    ratios = {},
    shares = {},
    calculated = {},
    valuations = {},
    management = [],
    analystRatings = {},
    holders = {},
    insiderTransactions = [],
    esg = {},

    exchange = {},
    sharePriceLive = null,
    liveSharePrice = null, // Legacy support
    tags = []
  }) {
    this.companyCode = companyCode;
    this._id = _id;
    this.general = general && Object.keys(general).length ? new CompanyGeneral(general) : this.general;
    this.listing = listing && Object.keys(listing).length ? new CompanyListing(listing) : this.listing;
    this.currencies = currencies && Object.keys(currencies).length ? new CompanyCurrencies(currencies) : this.currencies;
    this.financialStatements =
      financialStatements && Object.keys(financialStatements).length
        ? new CompanyFinancialStatements(financialStatements)
        : this.financialStatements;
    this.ratios = ratios && Object.keys(ratios).length ? new CompanyRatios(ratios) : this.ratios;
    this.shares = shares && Object.keys(shares).length ? new CompanyShares(shares) : this.shares;
    this.calculated = calculated && Object.keys(calculated).length ? new CompanyCalculated(calculated) : this.calculated;
    this.valuations = valuations && Object.keys(valuations).length ? new CompanyValuations(valuations) : this.valuations;
    this.management = management && management.length ? new CompanyManagement({ people: management }) : this.management;
    this.analystRatings =
      analystRatings && Object.keys(analystRatings).length ? new CompanyAnalystRatings(analystRatings) : this.analystRatings;
    this.holders = holders && Object.keys(holders).length ? new CompanyHolders(holders) : this.holders;
    this.insiderTransactions =
      insiderTransactions && insiderTransactions.length ? new CompanyInsiderTransactions(insiderTransactions) : this.insiderTransactions;
    this.esg = esg && Object.keys(esg).length ? new CompanyEsg(esg) : this.esg;

    this.exchange = exchange && Object.keys(exchange).length ? new Exchange(exchange) : this.exchange;
    this.sharePricesDaily = this.sharePricesDaily || [];
    this.sharePriceLive = sharePriceLive || liveSharePrice || this.sharePriceLive;
    this.tags = tags && tags?.length ? tags.map((tag) => new Tag(tag || {})) : this.tags;
  }

  get ticker() {
    return this.companyCode?.split('.')?.first();
  }

  get exchangeCode() {
    return this.companyCode?.split('.')?.last();
  }

  get lastQuarter() {
    return this.financialStatements?.balanceSheets?.quarterly?.last()?.date || null;
  }

  get lastYear() {
    return this.financialStatements?.balanceSheets?.yearly?.last()?.date || null;
  }

  get rating() {
    const ONE_YEAR = '1year';
    const FIVE_YEAR = '5year';

    try {
      const minimumRequirements = {
        totalRevenueGrowth: 8,
        netIncomeMargin: 10,
        profitability: 15,
        selfFinancingRate: 40,
        operatingCashFlowOverSales: 30
      };

      const detailedRating = {
        minimumRequirements,
        oneYearRating: {
          totalRevenueGrowth: this.calculated?.rating?.[ONE_YEAR]?.totalRevenueGrowth > minimumRequirements.totalRevenueGrowth,
          netIncomeMargin: this.calculated?.rating?.[ONE_YEAR]?.netIncomeMargin > minimumRequirements.netIncomeMargin,
          profitability: this.calculated?.rating?.[ONE_YEAR]?.profitability > minimumRequirements.profitability,
          selfFinancingRate: this.calculated?.rating?.[ONE_YEAR]?.selfFinancingRate > minimumRequirements.selfFinancingRate,
          operatingCashFlowOverSales:
            this.calculated?.rating?.[ONE_YEAR]?.operatingCashFlowOverSales > minimumRequirements.operatingCashFlowOverSales
        },
        oneYearData: {
          totalRevenueGrowth: this.calculated?.rating?.[ONE_YEAR]?.totalRevenueGrowth,
          netIncomeMargin: this.calculated?.rating?.[ONE_YEAR]?.netIncomeMargin,
          profitability: this.calculated?.rating?.[ONE_YEAR]?.profitability,
          selfFinancingRate: this.calculated?.rating?.[ONE_YEAR]?.selfFinancingRate,
          operatingCashFlowOverSales: this.calculated?.rating?.[ONE_YEAR]?.operatingCashFlowOverSales
        },
        fiveYearRating: {
          totalRevenueGrowth: this.calculated?.rating?.[FIVE_YEAR]?.totalRevenueGrowth > minimumRequirements.totalRevenueGrowth,
          netIncomeMargin: this.calculated?.rating?.[FIVE_YEAR]?.netIncomeMargin > minimumRequirements.netIncomeMargin,
          profitability: this.calculated?.rating?.[FIVE_YEAR]?.profitability > minimumRequirements.profitability,
          selfFinancingRate: this.calculated?.rating?.[FIVE_YEAR]?.selfFinancingRate > minimumRequirements.selfFinancingRate,
          operatingCashFlowOverSales:
            this.calculated?.rating?.[FIVE_YEAR]?.operatingCashFlowOverSales > minimumRequirements.operatingCashFlowOverSales
        },
        fiveYearData: {
          totalRevenueGrowth: this.calculated?.rating?.[FIVE_YEAR]?.totalRevenueGrowth,
          netIncomeMargin: this.calculated?.rating?.[FIVE_YEAR]?.netIncomeMargin,
          profitability: this.calculated?.rating?.[FIVE_YEAR]?.profitability,
          selfFinancingRate: this.calculated?.rating?.[FIVE_YEAR]?.selfFinancingRate,
          operatingCashFlowOverSales: this.calculated?.rating?.[FIVE_YEAR]?.operatingCashFlowOverSales
        }
      };

      detailedRating.isValid = {
        oneYear:
          Object.values(detailedRating.oneYearData)
            .map((val) => val !== null)
            .includes(true) && this.calculated?.rating?.[ONE_YEAR],
        fiveYear:
          Object.values(detailedRating.fiveYearData)
            .map((val) => val !== null)
            .includes(true) && this.calculated?.rating?.[FIVE_YEAR]
      };

      const scores = [
        ...(detailedRating.isValid.oneYear ? Object.values(detailedRating.oneYearRating) : []),
        ...(detailedRating.isValid.fiveYear ? Object.values(detailedRating.fiveYearRating) : [])
      ];

      detailedRating.score = scores?.length ? scores?.map((score) => (score ? 5 / scores?.length : 0))?.sum() : 0;

      return detailedRating;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  sharesOutstandingAtDate(date = new Date()) {
    const sortedSharesOutstanding = this.shares.outstanding
      .map((shares) => ({
        ...shares,
        dateDiff: shares.date.removeTime().toUTC().getTime() - date,
        dateDiffAbs: Math.abs(shares.date.removeTime().toUTC().getTime() - date)
      }))
      .sort((a, b) => a.dateDiffAbs - b.dateDiffAbs);

    return (sortedSharesOutstanding.filter((shares) => shares.dateDiff <= 0).first() || sortedSharesOutstanding.first())?.shares;
  }

  // Load historic share price data from a specified date till a specified date; this data will be saved in the object for later use
  async loadSharePriceHistory(from, till) {
    // Check if current data contains needed data, else fetch new data
    const currentDataDates = this.sharePricesDaily.map((candlestick) => candlestick.date.getTime()).sort();
    const currentDataStartDate = currentDataDates.first();
    const currentDataEndDate = currentDataDates.last();
    if (!this.sharePricesDaily?.length || currentDataStartDate > from.getTime() || currentDataEndDate < till.getTime()) {
      const sharePricesDailyResponse = await stockInvestingApi.companySharePriceData({ companyCode: this.companyCode, from, till });
      const sharePricesDaily = sharePricesDailyResponse?.candlesticks
        ?.map((candlestick) => ({
          ...candlestick,
          date: new Date(candlestick.date)
        }))
        .sort((a, b) => a.date.getTime() - b.date.getTime());

      // Forward fill share prices
      const days = (till.removeTime().toUTC().getTime() - from.removeTime().toUTC().getTime()) / ETime.milliseconds.perDay + 1; // +1 -> include the till date
      days.forEach((day) => {
        // Get dates
        const previousDate = new Date(from.removeTime().toUTC().getTime() + ETime.milliseconds.perDay * (day - 1)).getTime();
        const date = new Date(from.removeTime().toUTC().getTime() + ETime.milliseconds.perDay * day).getTime();

        // Get candlesticks
        const previousFoundCandlestick = sharePricesDaily.find(
          (candlestick) => new Date(candlestick.date).removeTime().toUTC().getTime() === previousDate
        );
        const foundCandlestick = sharePricesDaily.find((candlestick) => new Date(candlestick.date).removeTime().toUTC().getTime() === date);

        // Check if current date has matching candlestick
        if (!foundCandlestick) {
          // Push new candlestick if the current date doesn't have a matching candlestick
          sharePricesDaily.push({
            ...(previousFoundCandlestick || sharePricesDaily?.first()), // Use last known candlestick or use very first known candlestick (use case: first day could be a holiday/weekend)
            date: new Date(date)
          });
        }
      });

      // Sort share prices
      sharePricesDaily.sort((a, b) => a.date.getTime() - b.date.getTime());

      this.sharePricesDaily = sharePricesDaily;
    }
  }

  // Get historic share price data from a specified date till a specified date; first fetch (read: load) it if needed
  async getSharePriceHistory(from, till) {
    // Check if current data contains needed data, else fetch new data and return it
    await this.loadSharePriceHistory(from, till);
    return this.sharePricesDaily.filter(
      (candlestick) => new Date(candlestick.date).getTime() >= from.getTime() && new Date(candlestick.date).getTime() <= till.getTime()
    );
  }

  async getCandlestick(date = null) {
    let candlestick;
    if (date && date.removeTime().getTime() !== new Date().removeTime().getTime()) {
      // Requested date is not today -> history share price required
      if (this.sharePricesDaily?.length) {
        // Check for price in history
        // Share price can still be undefined (and thus null) if it's a holiday or a weekend day
        candlestick =
          this.sharePricesDaily.find(
            (dailyCandlestick) => new Date(dailyCandlestick.date).removeTime().getTime() === new Date(date).removeTime().getTime()
          ) || null;
      } else {
        // Date not found in historic daily share prices -> fetch data
        const sharePriceResponse = await stockInvestingApi.companySharePriceData({
          companyCode: this.companyCode,
          from: date.removeTime(),
          till: date.removeTime()
        });
        candlestick = sharePriceResponse?.first();
      }
    } else {
      // Requested date is today -> live share price required
      if (!this.sharePriceLive) {
        const sharePricesResponse = await stockInvestingApi.fnCompaniesPrice({ companyCodes: [this.companyCode] });
        this.sharePriceLive = sharePricesResponse?.[this.companyCode];
      }
      candlestick = this.sharePriceLive;
    }

    return candlestick;
  }

  getSharePrice(date = null) {
    return this.getCandlestick(date);
  }

  _scramble(val) {
    const letters = 'abcdefghijklmnopqrstuvwxyz';
    const capitals = letters.toUpperCase();
    let value = val.replace(/[A-Z]/g, () => capitals[Math.floor(Math.random() * 26)]);
    value = value.replace(/[a-z]/g, () => letters[Math.floor(Math.random() * 26)]);
    return value;
  }

  _mask(val) {
    if (val && typeof val === typeof Object() && !(val?.currency1 || val?.currency2 || val?.iso)) {
      Object.keys(val).forEach((key) => {
        if (typeof key === typeof String() && key?.[0] !== '_') {
          val[key] = this._mask(val[key]);
        }
      });
    }

    if (typeof val === typeof Number()) {
      val = parseFloat(val.toString().replace(/[0-9]/g, '9'));
    }

    if (typeof val === typeof String()) {
      val = this._scramble(val);
    }

    return val;
  }

  maskData(data = null) {
    if (!data) {
      this._mask(this);
    } else {
      Object.keys(data).forEach((key) => {
        this[key] = this._mask(this[key]);
      });
    }
  }

  async loadDataTags(dataType) {
    try {
      const dataTags = await stockInvestingApi.dataTaggingList({
        companyCode: this.companyCode,
        dataType
      });
      this.dataTags = dataTags && dataTags?.length ? dataTags?.map((dataTag) => new CompanyDataTag(dataTag)) : [];
    } catch (err) {
      console.error(err);
    }
  }

  async load(mongoDbProject = {}) {
    const companyData = await stockInvestingApi.companyDetails({
      companyCode: this.companyCode,
      mongoDbProject
    });

    this.construct(companyData);
  }
}

export { Company };
