<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'c-table',
  props: {
    stickyHeader: {
      type: Boolean,
      default: false
    },
    stickyColumn: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    responsive: {
      type: Boolean,
      default: false
    },
    hover: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      required: false,
      default: null
    },
    tableCategoryTop: {
      type: Number,
      require: false
    },
    tableHeadTop: {
      type: Number,
      require: false
    },
    striped: {
      type: Boolean,
      require: false,
      default: false
    },
    tableClass: {
      type: String,
      default: ''
    },
    autoWidth: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({}),
  watch: {
    tableCategoryTop() {
      this.setStickyTops();
    },
    tableHeadTop() {
      this.setStickyTops();
    }
  }
})
class CTable extends Vue {
  setStickyTops() {
    const styleElement = document.createElement('style');
    styleElement.innerHTML = this.style;
    this.$refs.table.append(styleElement);
  }

  get style() {
    const tableCategory = `
      .c-table-${this._uid} thead th {
        top: ${this.tableHeadTop}px !important;
      }
    `;
    const tableHead = `
      .c-table-${this._uid} .table-category,
      .c-table-${this._uid} .table-category th,
      .c-table-${this._uid} .table-category td {
        top: ${this.tableCategoryTop}px !important;
      }
    `;

    let css = '';
    css += this.tableCategoryTop ? tableCategory : '';
    css += this.tableHeadTop ? tableHead : '';

    return css;
  }

  mounted() {
    this.setStickyTops();
  }
}
export default CTable;
</script>
