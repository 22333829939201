<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'toggle-button',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  }
})
class ToggleButton extends Vue {}
export default ToggleButton;
</script>
<style lang="scss" scoped>
@import './style.scss';
</style>
