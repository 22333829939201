import { CompanySharePricePeriod } from './CompanySharePricePeriod.js';
import { CompanySharesOutstanding } from './CompanySharesOutstanding.js';
import { CompanySharesSplit } from './CompanySharesSplit.js';

class CompanyShares {
  constructor({ price = {}, splits = [], outstanding = [] }) {
    this.price = {
      yearly: price?.yearly?.map((sharePrice) => new CompanySharePricePeriod(sharePrice || {})),
      quarterly: price?.quarterly?.map((sharePrice) => new CompanySharePricePeriod(sharePrice || {}))
    };
    this.splits = splits.map((split) => new CompanySharesSplit(split));
    this.outstanding = outstanding.map((sharesOutstanding) => new CompanySharesOutstanding(sharesOutstanding || {}));
  }

  getSharesOutstanding(date = new Date()) {
    const sharesOutstanding = [...this.outstanding];
    sharesOutstanding.sort(
      (a, b) =>
        Math.abs(new Date(a.date).getTime() - new Date(date).getTime()) - Math.abs(new Date(b.date).getTime() - new Date(date).getTime())
    );
    return sharesOutstanding?.first();
  }
}

export { CompanyShares };
