<template src="./template.vue"></template>
<script>
import Component from 'vue-class-component';
import Vue from 'vue';

@Component({
  name: 'google-ad',
  data: () => ({}),
  components: {},
  props: {
    client: {
      type: String,
      default: 'ca-pub-5306594462399781'
    },
    adSlot: {
      type: [String, Number],
      default: '4919645416'
    },
    format: {
      type: String,
      default: 'auto'
    },
    align: {
      type: String,
      default: 'center'
    }
  }
})
class GoogleAd extends Vue {
  get userIsPaidAccount() {
    return this.store.user.isPaidAccount;
  }

  async mounted() {
    if (!this.userIsPaidAccount) {
      const interval = setInterval(() => {
        if (!this.$refs.ad?.attributes?.['data-adsbygoogle-status']) {
          // eslint-disable-next-line no-unused-expressions
          window.adsbygoogle?.push({});
          clearInterval(interval);
        }
      }, 500);
    }
  }
}
export default GoogleAd;
</script>
<style lang="scss" scoped>
@import './style.scss';
</style>
