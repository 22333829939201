<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import { Chart } from 'highcharts-vue';

import { EMonths } from '../../../../../shared/enums.js';

import { CTable, Lazyload } from '../../../../components/index.js';
import { Company } from '../../../../../shared/models/company/Company.js';

const ALL_YEARS = Number.MAX_SAFE_INTEGER;
const AVERAGE_YEARS = [5, 10, ALL_YEARS];

@Component({
  name: 'company-details-share',
  components: { Chart, CTable, Lazyload },
  data: () => ({
    AVERAGE_YEARS,
    chartSeries: {
      ohlc: [],
      volume: [],
      lowPrices: [],
      meanPrices: [],
      highPrices: []
    }
  })
})
class CompanyDetailsShareSummary extends Vue {
  historyPricingData = null;
  filteredSharePriceYearly = null;

  async loadCompany() {
    const companyCode = this?.$route?.params?.companyCode;
    this.company = new Company({ companyCode });
    await this.company.load({
      general: true,
      currencies: true,
      shares: {
        price: {
          yearly: true
        },
        splits: true
      }
    });
  }

  async mounted() {
    await this.loadCompany();
    this.setTitle(`${this?.company?.general?.name} - Share price summary`);

    this.filteredSharePriceYearly = this.company.shares.price.yearly
      .filter((candlestick) => new Date(candlestick.date).getMonth() === EMonths.december)
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    await this.$nextTick();
    this.$refs.table.$el.scrollLeft = this.$refs.table.$el.scrollWidth;
  }
}
export default CompanyDetailsShareSummary;
</script>
