import axios from 'axios';
import Vue from 'vue';

import { handleResponse } from './utils/index.js';

const endpoint = `${process.env.BACKEND_PROTOCOL}://${process.env.BACKEND_HOSTNAME}:${process.env.BACKEND_PORT}/api`;
class StockInvestingApi {
  async authCheck({ roleCheck = false }) {
    Vue.$gtag.event('api_authCheck');
    try {
      const response = await axios.head(`${endpoint}/auth/check?roleCheck=${roleCheck}`);
      Vue.$gtag.event('api_authCheck_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_authCheck_fail');
      return Promise.reject(err);
    }
  }

  async companiesList(body) {
    Vue.$gtag.event('api_companiesList');
    try {
      const response = await axios.post(`${endpoint}/companies`, body);
      Vue.$gtag.event('api_companiesList_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_companiesList_fail');
      return Promise.reject(err);
    }
  }

  async companyDetails({ companyCode, mongoDbProject }) {
    Vue.$gtag.event('api_companyDetails', { companyCode, mongoDbProject });
    const queryParams = [];
    if (mongoDbProject) queryParams.push(`mongoDbProject=${encodeURIComponent(JSON.stringify(mongoDbProject))}`);

    try {
      const response = await axios.get(`${endpoint}/companies/${companyCode}${queryParams.length ? `?${queryParams.join('&')}` : ''}`);
      Vue.$gtag.event('api_companyDetails_success', { companyCode, mongoDbProject });
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_companyDetails_fail', { companyCode, mongoDbProject });
      return Promise.reject(err);
    }
  }

  async companySearch({ query, sharePriceLive }) {
    Vue.$gtag.event('api_companySearch', { query, sharePriceLive });
    const queryParams = [];
    if (query) queryParams.push(`query=${encodeURIComponent(query)}`);
    if (sharePriceLive) queryParams.push(`sharePriceLive=${sharePriceLive}`);

    try {
      const response = await axios.get(`${endpoint}/companies/search${queryParams.length ? `?${queryParams.join('&')}` : ''}`);
      Vue.$gtag.event('api_companySearch_success', { query, sharePriceLive });
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_companySearch_fail', { query, sharePriceLive });
      return Promise.reject(err);
    }
  }

  async companySharePriceData({ companyCode, from, till }) {
    Vue.$gtag.event('api_companySharePriceData', { companyCode, from: from?.toISOString() || null, till: till?.toISOString() || null });
    const queryParams = [];
    if (from) queryParams.push(`from=${encodeURIComponent(from.toISOString())}`);
    if (till) queryParams.push(`till=${encodeURIComponent(till.toISOString())}`);

    try {
      const response = await axios.get(
        `${endpoint}/companies/${companyCode}/price-history${queryParams.length ? `?${queryParams.join('&')}` : ''}`
      );
      Vue.$gtag.event('api_companySharePriceData_success', { companyCode, from: from?.toISOString() || null, till: till?.toISOString() || null });
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_companySharePriceData_fail', { companyCode, from: from?.toISOString() || null, till: till?.toISOString() || null });
      return Promise.reject(err);
    }
  }

  async historicValuationsCreate({ companyCode, year, growth, multiple, valuation, included, sharingPermissions }) {
    Vue.$gtag.event('api_historicValuationsCreate', { companyCode, year });
    try {
      const response = await axios.post(`${endpoint}/historic-valuation`, {
        companyCode,
        year,
        growth,
        multiple,
        valuation,
        included,
        sharingPermissions
      });
      Vue.$gtag.event('api_historicValuationsCreate_success', { companyCode, year });
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_historicValuationsCreate_fail', { companyCode, year });
      return Promise.reject(err);
    }
  }

  async historicValuationsUpdate({ _id, companyCode, year, growth, multiple, valuation, included, sharingPermissions }) {
    Vue.$gtag.event('api_historicValuationsUpdate', { companyCode, year });
    try {
      const response = await axios.patch(`${endpoint}/historic-valuation/${_id}`, {
        companyCode,
        year,
        growth,
        multiple,
        valuation,
        included,
        sharingPermissions
      });
      Vue.$gtag.event('api_historicValuationsUpdate_success', { companyCode, year });
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_historicValuationsUpdate_fail', { companyCode, year });
      return Promise.reject(err);
    }
  }

  async dcfValuationsCreate({
    companyCode,
    year,
    operatingData,
    balanceSheetData,
    cashFlowBuildup,
    costOfCapital,
    wacc,
    terminalValue,
    valuation,
    sharingPermissions
  }) {
    Vue.$gtag.event('api_dcfValuationsCreate', { companyCode, year });
    try {
      const response = await axios.post(`${endpoint}/dcf-valuation`, {
        companyCode,
        year,
        operatingData,
        balanceSheetData,
        cashFlowBuildup,
        costOfCapital,
        wacc,
        terminalValue,
        valuation,
        sharingPermissions
      });
      Vue.$gtag.event('api_dcfValuationsCreate_success', { companyCode, year });
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_dcfValuationsCreate_fail', { companyCode, year });
      return Promise.reject(err);
    }
  }

  async dcfValuationsUpdate({
    _id,
    companyCode,
    year,
    operatingData,
    balanceSheetData,
    cashFlowBuildup,
    costOfCapital,
    wacc,
    terminalValue,
    valuation,
    sharingPermissions
  }) {
    Vue.$gtag.event('api_dcfValuationsUpdate', { companyCode, year });
    try {
      const response = await axios.patch(`${endpoint}/dcf-valuation/${_id}`, {
        companyCode,
        year,
        operatingData,
        balanceSheetData,
        cashFlowBuildup,
        costOfCapital,
        wacc,
        terminalValue,
        valuation,
        sharingPermissions
      });
      Vue.$gtag.event('api_dcfValuationsUpdate_success', { companyCode, year });
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_dcfValuationsUpdate_fail', { companyCode, year });
      return Promise.reject(err);
    }
  }

  async valuationsCreate({ _id, companyCode, date, valuation, adjustedValuation, comment, valuationMethods }) {
    Vue.$gtag.event('api_valuationsCreate', { companyCode, date: date.toISOString() });
    try {
      const response = await axios.post(`${endpoint}/valuations`, {
        _id,
        companyCode,
        date,
        valuation,
        adjustedValuation,
        comment,
        valuationMethods
      });
      Vue.$gtag.event('api_valuationsCreate_success', { companyCode, date: date.toISOString() });
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_valuationsCreate_fail', { companyCode, date: date.toISOString() });
      return Promise.reject(err);
    }
  }

  async valuationsDelete({ _id }) {
    Vue.$gtag.event('api_valuationsDelete');
    try {
      const response = await axios.delete(`${endpoint}/valuations/${_id}`);
      Vue.$gtag.event('api_valuationsDelete_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_valuationsDelete_fail');
      return Promise.reject(err);
    }
  }

  async tagsList() {
    Vue.$gtag.event('api_tagsList');
    try {
      const response = await axios.get(`${endpoint}/tags`);
      Vue.$gtag.event('api_tagsList_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_tagsList_fail');
      return Promise.reject(err);
    }
  }

  async tagsCreate({ name, subject, subjectId }) {
    Vue.$gtag.event('api_tagsCreate', { name, subject });
    try {
      const response = await axios.post(`${endpoint}/tags`, { name, subject, subjectId });
      Vue.$gtag.event('api_tagsCreate_success', { name, subject });
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_tagsCreate_fail', { name, subject });
      return Promise.reject(err);
    }
  }

  async tagsDelete({ tagId }) {
    Vue.$gtag.event('api_tagsDelete');
    try {
      const response = await axios.delete(`${endpoint}/tags/${tagId}`);
      Vue.$gtag.event('api_tagsDelete_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_tagsDelete_fail');
      return Promise.reject(err);
    }
  }

  async comparisonsList() {
    Vue.$gtag.event('api_comparisonsList');
    try {
      const response = await axios.get(`${endpoint}/comparisons`);
      Vue.$gtag.event('api_comparisonsList_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_comparisonsList_fail');
      return Promise.reject(err);
    }
  }

  async comparisonsCreate({ name, order, companyCodes }) {
    Vue.$gtag.event('api_comparisonsCreate');
    try {
      const response = await axios.post(`${endpoint}/comparisons`, { name, order, companyCodes });
      Vue.$gtag.event('api_comparisonsCreate_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_comparisonsCreate_fail');
      return Promise.reject(err);
    }
  }

  async comparisonsUpdate({ _id, name, order, companyCodes }) {
    Vue.$gtag.event('api_comparisonsUpdate');
    try {
      const response = await axios.patch(`${endpoint}/comparisons/${_id}`, { name, order, companyCodes });
      Vue.$gtag.event('api_comparisonsUpdate_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_comparisonsUpdate_fail');
      return Promise.reject(err);
    }
  }

  async comparisonsDelete({ _id }) {
    Vue.$gtag.event('api_comparisonsDelete');
    try {
      const response = await axios.delete(`${endpoint}/comparisons/${_id}`);
      Vue.$gtag.event('api_comparisonsDelete_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_comparisonsDelete_fail');
      return Promise.reject(err);
    }
  }

  async portfoliosList() {
    Vue.$gtag.event('api_portfoliosList');
    try {
      const response = await axios.get(`${endpoint}/portfolios`);
      Vue.$gtag.event('api_portfoliosList_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_portfoliosList_fail');
      return Promise.reject(err);
    }
  }

  async portfoliosCreate({ name, currency }) {
    Vue.$gtag.event('api_portfoliosCreate', { currency });
    try {
      const response = await axios.post(`${endpoint}/portfolios`, { name, currency });
      Vue.$gtag.event('api_portfoliosCreate_success', { currency });
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_portfoliosCreate_fail', { currency });
      return Promise.reject(err);
    }
  }

  async portfoliosUpdate({ _id, name }) {
    Vue.$gtag.event('api_portfoliosUpdate');
    try {
      const response = await axios.patch(`${endpoint}/portfolios/${_id}`, { name });
      Vue.$gtag.event('api_portfoliosUpdate_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_portfoliosUpdate_fail');
      return Promise.reject(err);
    }
  }

  async portfoliosDelete({ _id }) {
    Vue.$gtag.event('api_portfoliosDelete');
    try {
      const response = await axios.delete(`${endpoint}/portfolios/${_id}`);
      Vue.$gtag.event('api_portfoliosDelete_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_portfoliosDelete_fail');
      return Promise.reject(err);
    }
  }

  async portfoliosCreateCashTransaction({ portfolioId, deposit, amount, date }) {
    Vue.$gtag.event('api_portfoliosCreateCashTransaction');
    try {
      const response = await axios.post(`${endpoint}/portfolios/${portfolioId}/transaction/cash`, { deposit, amount, date });
      Vue.$gtag.event('api_portfoliosCreateCashTransaction_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_portfoliosCreateCashTransaction_fail');
      return Promise.reject(err);
    }
  }

  async portfoliosCreateSharesTransaction({ portfolioId, buy, companyCode, sharePrice, amount, exchangeRate, date }) {
    Vue.$gtag.event('api_portfoliosCreateSharesTransaction');
    try {
      const response = await axios.post(`${endpoint}/portfolios/${portfolioId}/transaction/shares`, {
        buy,
        companyCode,
        sharePrice,
        amount,
        exchangeRate,
        date
      });
      Vue.$gtag.event('api_portfoliosCreateSharesTransaction_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_portfoliosCreateSharesTransaction_fail');
      return Promise.reject(err);
    }
  }

  async portfoliosDeleteTransaction({ portfolioId, transactionId }) {
    Vue.$gtag.event('api_portfoliosDeleteTransaction');
    try {
      const response = await axios.delete(`${endpoint}/portfolios/${portfolioId}/transaction/${transactionId}`);
      Vue.$gtag.event('api_portfoliosDeleteTransaction_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_portfoliosDeleteTransaction_fail');
      return Promise.reject(err);
    }
  }

  async currencyExchangeRateHistory({ currencyPair, from, till }) {
    Vue.$gtag.event('api_currencyExchangeRateHistory', { currencyPair, from: from.toISOString(), till: till.toISOString() });
    const queryParams = [];
    if (from) queryParams.push(`from=${encodeURIComponent(from.toISOString())}`);
    if (till) queryParams.push(`till=${encodeURIComponent(till.toISOString())}`);

    try {
      const response = await axios.get(
        `${endpoint}/currency/${currencyPair}/exchange-rate-history${queryParams.length ? `?${queryParams.join('&')}` : ''}`
      );
      Vue.$gtag.event('api_currencyExchangeRateHistory_success', { currencyPair, from: from.toISOString(), till: till.toISOString() });
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_currencyExchangeRateHistory_fail', { currencyPair, from: from.toISOString(), till: till.toISOString() });
      return Promise.reject(err);
    }
  }

  async paymentMethodCreditCardList() {
    Vue.$gtag.event('api_paymentMethodCreditCardList');
    try {
      const response = await axios.get(`${endpoint}/payment/payment-method/credit-card`);
      Vue.$gtag.event('api_paymentMethodCreditCardList_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_paymentMethodCreditCardList_fail');
      return Promise.reject(err);
    }
  }

  async paymentMethodCreditCardCreate({ creditCard }) {
    Vue.$gtag.event('api_paymentMethodCreditCardCreate');
    try {
      const response = await axios.post(`${endpoint}/payment/payment-method/credit-card`, { creditCard });
      Vue.$gtag.event('api_paymentMethodCreditCardCreate_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_paymentMethodCreditCardCreate_fail');
      return Promise.reject(err);
    }
  }

  async paymentMethodCreditCardDelete({ stripeId }) {
    Vue.$gtag.event('api_paymentMethodCreditCardDelete');
    try {
      const response = await axios.delete(`${endpoint}/payment/payment-method/credit-card/${stripeId}`);
      Vue.$gtag.event('api_paymentMethodCreditCardDelete_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_paymentMethodCreditCardDelete_fail');
      return Promise.reject(err);
    }
  }

  async paymentSubscriptionList() {
    Vue.$gtag.event('api_paymentSubscriptionList');
    try {
      const response = await axios.get(`${endpoint}/payment/subscriptions`);
      Vue.$gtag.event('api_paymentSubscriptionList_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_paymentSubscriptionList_fail');
      return Promise.reject(err);
    }
  }

  async paymentSubscriptionGet() {
    Vue.$gtag.event('api_paymentSubscriptionGet');
    try {
      const response = await axios.get(`${endpoint}/payment/subscription`);
      Vue.$gtag.event('api_paymentSubscriptionGet_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_paymentSubscriptionGet_fail');
      return Promise.reject(err);
    }
  }

  async paymentSubscriptionCreate({ stripePriceId, trial }) {
    Vue.$gtag.event('api_paymentSubscriptionCreate', { stripePriceId, trial });
    try {
      const response = await axios.post(`${endpoint}/payment/subscription`, { stripePriceId, trial });
      Vue.$gtag.event('api_paymentSubscriptionCreate_success', { stripePriceId, trial });
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_paymentSubscriptionCreate_fail', { stripePriceId, trial });
      return Promise.reject(err);
    }
  }

  async paymentSubscriptionUpdate({ stripePriceId, upgrade }) {
    Vue.$gtag.event('api_paymentSubscriptionUpdate', { stripePriceId, direction: upgrade ? 'upgrade' : 'downgrade' });
    try {
      const response = await axios.patch(`${endpoint}/payment/subscription/${upgrade ? 'upgrade' : 'downgrade'}`, { stripePriceId });
      Vue.$gtag.event('api_paymentSubscriptionUpdate_success', { stripePriceId, direction: upgrade ? 'upgrade' : 'downgrade' });
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_paymentSubscriptionUpdate_fail', { stripePriceId, direction: upgrade ? 'upgrade' : 'downgrade' });
      return Promise.reject(err);
    }
  }

  async paymentSubscriptionDelete() {
    Vue.$gtag.event('api_paymentSubscriptionDelete');
    try {
      const response = await axios.delete(`${endpoint}/payment/subscription`);
      Vue.$gtag.event('api_paymentSubscriptionDelete_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_paymentSubscriptionDelete_fail');
      return Promise.reject(err);
    }
  }

  async paymentSubscriptionCalculateTax({ stripePriceId }) {
    Vue.$gtag.event('api_paymentSubscriptionCalculateTax', { stripePriceId });
    try {
      const response = await axios.get(`${endpoint}/payment/subscription/${stripePriceId}/calculate-tax`);
      Vue.$gtag.event('api_paymentSubscriptionCalculateTax_success', { stripePriceId });
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_paymentSubscriptionCalculateTax_fail', { stripePriceId });
      return Promise.reject(err);
    }
  }

  async paymentAccountancyInvoicesList() {
    Vue.$gtag.event('api_paymentAccountancyInvoicesList');
    try {
      const response = await axios.get(`${endpoint}/payment/accountancy/invoices`);
      Vue.$gtag.event('api_paymentAccountancyInvoicesList_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_paymentAccountancyInvoicesList_fail');
      return Promise.reject(err);
    }
  }

  async paymentAccountancyCreditNotesList() {
    Vue.$gtag.event('api_paymentAccountancyCreditNotesList');
    try {
      const response = await axios.get(`${endpoint}/payment/accountancy/credit-notes`);
      Vue.$gtag.event('api_paymentAccountancyCreditNotesList_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_paymentAccountancyCreditNotesList_fail');
      return Promise.reject(err);
    }
  }

  async paymentCustomerDetails() {
    Vue.$gtag.event('api_paymentCustomerDetails');
    try {
      const response = await axios.get(`${endpoint}/payment/customer`);
      Vue.$gtag.event('api_paymentCustomerDetails_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_paymentCustomerDetails_fail');
      return Promise.reject(err);
    }
  }

  async paymentCustomerUpdate({ customer }) {
    Vue.$gtag.event('api_paymentCustomerUpdate');
    try {
      const response = await axios.patch(`${endpoint}/payment/customer`, { customer });
      Vue.$gtag.event('api_paymentCustomerUpdate_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_paymentCustomerUpdate_fail');
      return Promise.reject(err);
    }
  }

  async fnCompaniesPrice({ companyCodes }) {
    Vue.$gtag.event('api_fnCompaniesPrice', { companyCodes });
    try {
      const response = await axios.post(`${endpoint}/fn/companies-price`, { companyCodes });
      Vue.$gtag.event('api_fnCompaniesPrice_success', { companyCodes });
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_fnCompaniesPrice_fail', { companyCodes });
      return Promise.reject(err);
    }
  }

  async fnSectorList() {
    Vue.$gtag.event('api_fnSectorList');
    try {
      const response = await axios.get(`${endpoint}/fn/sector-list`);
      Vue.$gtag.event('api_fnSectorList_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_fnSectorList_fail');
      return Promise.reject(err);
    }
  }

  async fnCountryList() {
    Vue.$gtag.event('api_fnCountryList');
    try {
      const response = await axios.get(`${endpoint}/fn/country-list`);
      Vue.$gtag.event('api_fnCountryList_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_fnCountryList_fail');
      return Promise.reject(err);
    }
  }

  async fnExchangeList() {
    Vue.$gtag.event('api_fnExchangeList');
    try {
      const response = await axios.get(`${endpoint}/fn/exchange-list`);
      Vue.$gtag.event('api_fnExchangeList_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_fnExchangeList_fail');
      return Promise.reject(err);
    }
  }

  async dataManagerStatus() {
    Vue.$gtag.event('api_dataManagerStatus');
    try {
      const response = await axios.get(`${endpoint}/data-manager/status`, { timeout: 5000 });
      Vue.$gtag.event('api_dataManagerStatus_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_dataManagerStatus_fail');
      return Promise.reject(err);
    }
  }

  async profileDetails() {
    Vue.$gtag.event('api_profileDetails');
    try {
      const response = await axios.get(`${endpoint}/profile`);
      Vue.$gtag.event('api_profileDetails_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_profileDetails_fail');
      return Promise.reject(err);
    }
  }

  async adminCompanyListings({ companyCode }) {
    Vue.$gtag.event('api_adminCompanyListings', { companyCode });
    try {
      const response = await axios.get(`${endpoint}/admin/companies/${companyCode}/listings`);
      Vue.$gtag.event('api_adminCompanyListings_success', { companyCode });
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_adminCompanyListings_fail', { companyCode });
      return Promise.reject(err);
    }
  }

  async adminCompanyMarkPrimaryListing({ companyCodes, primaryCompanyCode }) {
    Vue.$gtag.event('api_adminCompanyMarkPrimaryListing', { companyCodes, primaryCompanyCode });
    try {
      const response = await axios.post(`${endpoint}/admin/companies/mark-primary-listing`, { companyCodes, primaryCompanyCode });
      Vue.$gtag.event('api_adminCompanyMarkPrimaryListing_success', { companyCodes, primaryCompanyCode });
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_adminCompanyMarkPrimaryListing_fail', { companyCodes, primaryCompanyCode });
      return Promise.reject(err);
    }
  }

  async adminCompanyBlacklist({ companyCode }) {
    Vue.$gtag.event('api_adminCompanyBlacklist', { companyCode });
    try {
      const response = await axios.post(`${endpoint}/admin/companies/${companyCode}/blacklist`);
      Vue.$gtag.event('api_adminCompanyBlacklist_success', { companyCode });
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_adminCompanyBlacklist_fail', { companyCode });
      return Promise.reject(err);
    }
  }

  async workspaceGet() {
    Vue.$gtag.event('api_workspaceGet');
    try {
      const response = await axios.get(`${endpoint}/workspace`);
      Vue.$gtag.event('api_workspaceGet_success');
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_workspaceGet_fail');
      return Promise.reject(err);
    }
  }

  async workspaceCreate({ companyCode }) {
    Vue.$gtag.event('api_workspaceCreate', { companyCode });
    try {
      const response = await axios.post(`${endpoint}/workspace/${companyCode}`);
      Vue.$gtag.event('api_workspaceCreate_success', { companyCode });
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_workspaceCreate_fail', { companyCode });
      return Promise.reject(err);
    }
  }

  async workspaceDelete({ companyCode }) {
    Vue.$gtag.event('api_workspaceDelete', { companyCode });
    try {
      const response = await axios.delete(`${endpoint}/workspace/${companyCode}`);
      Vue.$gtag.event('api_workspaceDelete_success', { companyCode });
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      Vue.$gtag.event('api_workspaceDelete/delete', { companyCode });
      return Promise.reject(err);
    }
  }

  async dataTaggingCreate({ companyCode, comment, dataType, dataLocationDetails }) {
    try {
      const response = await axios.post(`${endpoint}/data-tagging`, { companyCode, comment, dataType, dataLocationDetails });
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  }

  async dataTaggingUpdate({ _id, comment }) {
    try {
      const response = await axios.patch(`${endpoint}/data-tagging/${_id}`, { comment });
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  }

  async dataTaggingDelete({ _id }) {
    try {
      const response = await axios.delete(`${endpoint}/data-tagging/${_id}`);
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  }

  async dataTaggingList({ companyCode, dataType }) {
    try {
      const response = await axios.get(`${endpoint}/data-tagging/${companyCode}/${dataType}`);
      return handleResponse(response);
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  }
}

export { StockInvestingApi };
