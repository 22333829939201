<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import { Chart } from 'highcharts-vue';
import { SignUpModal, LoginModal, GoogleAd, StarRating } from '../../components/index.js';

import { Company } from '../../../shared/models/index.js';
import { ETagSubject, ETime } from '../../../shared/enums.js';
import { EPermissions } from '../../../shared/models/user/index.js';
import { Workspace } from '../../../shared/models/workspace/Workspace.js';

@Component({
  name: 'company-details',
  components: {
    Chart,
    SignUpModal,
    LoginModal,
    GoogleAd,
    StarRating
  },
  watch: {
    '$route.params.companyCode': function loadOtherCompany() {
      this.loadNewCompany();
    }
  }
})
class CompanyDetails extends Vue {
  companyCode = null;
  company = null;
  logoAvgColor = null;

  tagEditMode = false;
  allTags = [];
  newTag = '';
  creatingTag = false;
  deletingTagsConfirmed = false;

  signUpModalVisible = false;
  loginModalVisible = false;

  allNavItems = [];
  navItems = {};
  tabs = [];

  hoverTab = null;
  enterTabTimeout = null;
  leaveTabTimeout = null;

  showAd = true;

  workspace = new Workspace({});

  async handleTabMouseOver(tab) {
    clearTimeout(this.leaveTabTimeout);
    if (!this.hoverTab) {
      clearTimeout(this.enterTabTimeout);
      this.enterTabTimeout = setTimeout(() => {
        this.hoverTab = tab;
      }, 50);
    } else {
      clearTimeout(this.enterTabTimeout);
      this.enterTabTimeout = setTimeout(() => {
        this.hoverTab = tab;
      }, 200);
    }
  }

  handleTabMouseLeave() {
    clearTimeout(this.enterTabTimeout);
    clearTimeout(this.leaveTabTimeout);
    this.leaveTabTimeout = setTimeout(() => {
      this.hoverTab = null;
    }, 200);
  }

  async fetchAllTags() {
    const tags = await this.StockInvestingApi.tagsList();
    this.allTags = tags.sort();
  }

  toggleTagEditMode() {
    this.tagEditMode = !this.tagEditMode;
  }

  get allTagsFiltered() {
    const companyTags = this.company.tags.map((tag) => tag.name);
    const allTags = this.allTags.map((tag) => tag.name);
    const unusedTags = allTags.filter((tag) => !companyTags.includes(tag));
    return unusedTags.sort();
  }

  async handleSaveTagClick(event) {
    event.preventDefault();
    const tagName = this.newTag;
    this.newTag = '';
    await this.createTag(tagName);
  }

  async handleAddTagClick() {
    const focusInterval = setInterval(() => {
      if (this.$refs.addTagInput) {
        this.$refs.addTagInput.focus();
        clearInterval(focusInterval);
      }
    }, 50);
  }

  async createTag(tagName) {
    this.creatingTag = true;
    try {
      this.$gtag.event('company_tag_create', { company_code: this.companyCode });
      const tag = await this.StockInvestingApi.tagsCreate({ name: tagName, subject: ETagSubject.company, subjectId: this.company._id });
      this.company.tags.push(tag);
    } catch (err) {
      console.error(err);
      if (err?.errors?.length) {
        this.ToastError(err?.errors[0]);
      }
    }
    this.creatingTag = false;
  }

  async handleDeleteTagClick(tagId) {
    if (this.deletingTagsConfirmed || window.confirm('Are you sure you want to delete tags?')) {
      this.deletingTagsConfirmed = true;
      try {
        this.$gtag.event('company_tag_delete', { company_code: this.companyCode });
        this.company.tags = this.company.tags.filter((companyTag) => !(companyTag._id === tagId));
        await this.StockInvestingApi.tagsDelete({ tagId });
      } catch (err) {
        console.error(err);
        if (err?.errors?.length) {
          this.ToastError(err?.errors[0]);
        }
      }
    }
  }

  get companyName() {
    return this.company?.general?.name || '';
  }

  async loadNewCompany() {
    this.companyCode = null;
    this.company = null;

    this.tagEditMode = false;
    this.allTags = [];
    this.newTag = '';
    this.creatingTag = false;
    this.deletingTagsConfirmed = false;

    this.signUpModalVisible = false;
    this.loginModalVisible = false;

    await this.$nextTick();
    this.initialLoad();
  }

  async initialLoad() {
    this.companyCode = this.$route.params.companyCode;

    const company = new Company({ companyCode: this.companyCode });

    await company.load({
      tags: true,
      currencies: true,
      sharePriceLive: true,
      general: true,
      calculated: {
        rating: true
      },
      valuations: {
        fundamentlValuations: true
      }
    });

    this.company = company;

    this.getAverageLogoColor(this.company?.general?.logoUrl);

    await this.company.loadSharePriceHistory(
      new Date(Date.now() - ETime.milliseconds.perWeek).removeTime().toUTC(),
      new Date(Date.now() - ETime.milliseconds.perDay).removeTime().toUTC()
    );

    this.company.candleStickLastDay = company.sharePricesDaily
      ?.filter((candlestick) => candlestick.close && candlestick.close !== company.sharePriceLive)
      ?.last();
    this.$forceUpdate();

    if (this.store.user) {
      await this.fetchAllTags();
    }

    const companyName = this.company?.general?.name;
    if (companyName) {
      this.setTitle(companyName || this.companyCode);
    }
  }

  get activeTab() {
    return this.allNavItems.find((navItem) => navItem.name === this?.$route?.name);
  }

  tabIsActive(navItems) {
    return navItems?.map((navItem) => navItem?.name).includes(this?.$route?.name);
  }

  async loadNewAd(clickedTabName) {
    if (clickedTabName !== this.$route.name) {
      this.showAd = false;
      await this.$nextTick();
      this.showAd = true;
    }
  }

  getAverageLogoColor(src) {
    if (src) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      if (typeof src === 'string') {
        const img = new Image();
        img.setAttribute('crossOrigin', '');
        img.src = src;
        img.onload = () => {
          context.imageSmoothingEnabled = true;
          context.drawImage(img, 0, 0, img.width, img.height, 0, 0, 150, (img.height / img.width) * 150);

          const imageData = [...context.getImageData(1, 1, canvas.width, canvas.height).data];
          const colors = [];
          for (let i = imageData.length - 1; i >= 0; i -= 4) {
            const color = imageData.splice(i - 3, 4);

            // If pixel's opacity is zero, then do not include the color
            if (imageData[i] !== 0) {
              colors.push(color.slice(0, 3).mean());
            }
          }

          this.logoAvgColor = colors.filter((color) => color || color === 0).mean() > 100 ? 'light' : 'dark';
        };
      }
    }
  }

  handleFundamentlValuationBadgeClick() {
    if (this.$route.name !== this.pageNames.stockInvesting.COMPANY_DETAILS.VALUATION_FUNDAMENTL_VALUATION) {
      this.$router.push({
        name: this.pageNames.stockInvesting.COMPANY_DETAILS.VALUATION_FUNDAMENTL_VALUATION,
        params: { companyCode: this.$route.params.companyCode }
      });
    }
  }

  created() {
    this.navItems = {
      general: [
        {
          label: 'General',
          title: 'General information',
          name: this.pageNames.stockInvesting.COMPANY_DETAILS.GENERAL,
          permission: EPermissions.COMPANIES.UI
        },
        {
          label: 'Competitors',
          title: 'Competitors',
          name: this.pageNames.stockInvesting.COMPANY_DETAILS.COMPETITORS,
          permission: EPermissions.COMPANIES.UI
        },
        {
          label: 'Analyst ratings',
          title: 'Analyst ratings',
          name: this.pageNames.stockInvesting.COMPANY_DETAILS.ANALYST_RATINGS,
          permission: EPermissions.COMPANIES.UI
        },
        {
          label: 'Management',
          title: 'Management',
          name: this.pageNames.stockInvesting.COMPANY_DETAILS.MANAGEMENT,
          permission: EPermissions.COMPANIES.UI
        },
        {
          label: 'Ownership',
          title: 'Ownership',
          name: this.pageNames.stockInvesting.COMPANY_DETAILS.OWNERSHIP,
          permission: EPermissions.COMPANIES.UI
        },
        {
          label: 'Insider transactions',
          title: 'Insider transactions',
          name: this.pageNames.stockInvesting.COMPANY_DETAILS.INSIDER_TRANSACTIONS,
          permission: EPermissions.COMPANIES.UI
        },
        {
          label: 'ESG',
          title: 'ESG',
          name: this.pageNames.stockInvesting.COMPANY_DETAILS.ESG,
          permission: EPermissions.COMPANIES.UI
        }
      ],
      shareData: [
        {
          label: 'Chart',
          title: 'Shareprice chart',
          name: this.pageNames.stockInvesting.COMPANY_DETAILS.SHARE_DATA_CHART,
          permission: EPermissions.COMPANIES.UI
        },
        {
          label: 'Summary',
          title: 'Shareprice summary',
          name: this.pageNames.stockInvesting.COMPANY_DETAILS.SHARE_DATA_SUMMARY,
          permission: EPermissions.COMPANIES.UI
        }
      ],
      financials: [
        {
          label: 'Balance sheet',
          title: 'Balance sheet',
          name: this.pageNames.stockInvesting.COMPANY_DETAILS.FINANCIALS_BALANCE_SHEET,
          permission: EPermissions.COMPANIES.UI
        },
        {
          label: 'Income statement',
          title: 'Income statement',
          name: this.pageNames.stockInvesting.COMPANY_DETAILS.FINANCIALS_INCOME_STATEMENT,
          permission: EPermissions.COMPANIES.UI
        },
        {
          label: 'Cash flow statement',
          title: 'Cash flow statement',
          name: this.pageNames.stockInvesting.COMPANY_DETAILS.FINANCIALS_CASH_FLOW_STATEMENT,
          permission: EPermissions.COMPANIES.UI
        },
        {
          label: 'Ratios',
          title: 'Ratios',
          name: this.pageNames.stockInvesting.COMPANY_DETAILS.FINANCIALS_RATIOS,
          permission: EPermissions.COMPANIES.UI
        }
      ],
      valuation: [
        {
          label: 'Valuation summary',
          title: 'Valuation summary',
          name: this.pageNames.stockInvesting.COMPANY_DETAILS.VALUATION_SUMMARY,
          permission: EPermissions.VALUATIONS.UI
        },
        {
          label: 'Historical multiples valuation',
          title: 'Historical multiples valuation',
          name: this.pageNames.stockInvesting.COMPANY_DETAILS.VALUATION_HISTORIC_VALUATION,
          permission: EPermissions.HISTORIC_VALUATION.UI
        },
        {
          label: 'DCF',
          title: 'Discounted Cash Flow',
          name: this.pageNames.stockInvesting.COMPANY_DETAILS.VALUATION_DCF,
          permission: null
        },
        {
          label: 'Fundamentl valuation',
          title: 'Fundamentl valuation',
          name: this.pageNames.stockInvesting.COMPANY_DETAILS.VALUATION_FUNDAMENTL_VALUATION,
          permission: EPermissions.FUNDAMENTL_VALUATION.UI
        }
      ]
    };

    this.allNavItems = Object.values(this.navItems).reduce((a, b) => a.concat(b));

    this.tabs = [
      { label: 'Company', navItems: this.navItems.general },
      { label: 'Share data', navItems: this.navItems.shareData },
      { label: 'Financials', navItems: this.navItems.financials },
      { label: 'Valuation', navItems: this.navItems.valuation }
    ];
  }

  handleWorkspaceIconClick() {
    if (this.workspace.companies.includes(this.company.companyCode)) {
      this.workspace.delete(this.company.companyCode);
    } else {
      this.workspace.add(this.company.companyCode);
    }
  }

  async mounted() {
    this.initialLoad();
    this.workspace.load();
  }
}
export default CompanyDetails;
</script>
