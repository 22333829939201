<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import Highcharts from 'highcharts';
import { Chart } from 'highcharts-vue';

import { Lazyload, UpgradeCtaModal, AuthenticateModal } from '../../../../components/index.js';
import { adjustForSplits } from '../../../../../shared/utils/index.js';
import { EPermissions } from '../../../../../shared/models/user/index.js';
import { Company } from '../../../../../shared/models/company/Company.js';
import { CompanyHistoricValuation } from '../../../../../shared/models/company/valuations/CompanyHistoricValuation.js';
import { EPlans, ETime } from '../../../../../shared/enums.js';

@Component({
  name: 'company-details-valuations-summary',
  components: { Chart, Lazyload, UpgradeCtaModal, AuthenticateModal },
  data: () => ({}),
  watch: {
    valuationTotal() {
      this.setCheckboxes();
    }
  }
})
class CompanyDetailsValuationSummary extends Vue {
  company = null;
  loaded = false;

  saving = false;
  deleting = null;
  editingWeightedValuation = false;

  triggerValuationNumbers = true;

  users = null;
  usersById = null;

  showAuthenticateModal = false;
  showUpgradeCtaModal = false;
  upgradeCtaModalPlan = EPlans.packageTier1;

  subValuations = {
    historicNetProfit: {
      enabled: null,
      category: 'Historical Multiples Valuation',
      name: 'Net profit',
      weight: 1,
      permission: EPermissions.HISTORIC_VALUATION.LIST,
      hasPermission: false,
      selectedAutoValuation: 'custom'
    },
    historicRevenue: {
      enabled: null,
      category: 'Historical Multiples Valuation',
      name: 'Revenue',
      weight: 1,
      permission: EPermissions.HISTORIC_VALUATION.LIST,
      hasPermission: false,
      selectedAutoValuation: 'custom'
    },
    historicBookValue: {
      enabled: null,
      category: 'Historical Multiples Valuation',
      name: 'Book value',
      weight: 1,
      permission: EPermissions.HISTORIC_VALUATION.LIST,
      hasPermission: false,
      selectedAutoValuation: 'custom'
    },
    historicCashFlow: {
      enabled: null,
      category: 'Historical Multiples Valuation',
      name: 'Operational cash flow',
      weight: 1,
      permission: EPermissions.HISTORIC_VALUATION.LIST,
      hasPermission: false,
      selectedAutoValuation: 'custom'
    },
    dcfPerpetuity: {
      enabled: null,
      category: 'Discounted Cash Flow (DCF)',
      name: 'Perpetuity method',
      weight: 1,
      permission: EPermissions.DCF_VALUATION.LIST,
      hasPermission: false
    },
    dcfEbitda: {
      enabled: null,
      category: 'Discounted Cash Flow (DCF)',
      name: 'EBITDA method',
      weight: 1,
      permission: EPermissions.DCF_VALUATION.LIST,
      hasPermission: false
    },
    fundamentlValuation: {
      enabled: null,
      category: 'Other',
      name: 'Fundamentl valuation',
      weight: 1,
      permission: EPermissions.FUNDAMENTL_VALUATION.READ
    },
    analystsValuation: {
      enabled: null,
      category: 'Other',
      name: 'Analyst valuation',
      weight: 1,
      permission: EPermissions.COMPANY_ANALYST_RATINGS.READ
    },
    other: {
      enabled: null,
      input: true,
      category: 'Other',
      name: 'Other',
      value: 0,
      weight: 1,
      permission: EPermissions.VALUATIONS.LIST,
      hasPermission: true
    }
  };

  form = {
    date: new Date(),
    valuation: null,
    adjustedValuation: null,
    comment: ''
  };

  valuationsHistory = [];
  historyPricingData = null;
  chartDataOhlc = null;
  chartOptions = null;

  updatingValuationIndex = null;

  commentVisible = null;

  editingValuationDate = false;

  // Used to detect changes in the historic valuation or DCF valuations in other tabs
  get valuationTotal() {
    const valuations = { ...this.valuationNumbers };
    delete valuations.other;
    return Object.values(valuations).sum();
  }

  get averageValuation() {
    try {
      const filteredValuations = Object.keys(this.subValuations)
        .filter((valuationKey) => this.subValuations[valuationKey].enabled && this.valuationNumbers[valuationKey])
        .map((valuationKey) => ({
          ...this.subValuations[valuationKey],
          value: this.valuationNumbers?.[valuationKey] || 0
        }));

      // Explicitly check for `null`, because `0` will also result in `false`
      if (this.subValuations.other.enabled && this.valuationNumbers.other !== null) {
        filteredValuations.push({
          ...this.subValuations.other,
          value: this.subValuations?.other?.value || 0
        });
      }

      if (!filteredValuations.length) {
        return null;
      }

      let weightedValuation = 0;
      for (let i = 0; i < filteredValuations.length; i++) {
        const valuation = filteredValuations[i];
        weightedValuation += (valuation.value / this.weightTotal) * valuation.weight;
      }

      this.form.valuation = parseFloat(weightedValuation).round(2);
      this.form.adjustedValuation = parseFloat(weightedValuation).round(2);

      return weightedValuation;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  get weightTotal() {
    let total = 0;
    const subValuations = Object.values(this.subValuations);
    for (let i = 0; i < subValuations.length; i++) {
      total += subValuations[i].enabled && !isNaN(parseFloat(subValuations[i].weight)) ? parseFloat(subValuations[i].weight) : 0;
    }
    return total;
  }

  async handleValuationSave() {
    if (!this.store.user.hasPermission(EPermissions.VALUATIONS.CREATE)) {
      this.showAuthenticateModal = true;
      return;
    }

    this.saving = true;

    const valuation = {
      companyCode: this.company.companyCode,
      date: this.form.date,
      adjustedValuation: parseFloat(this.form.adjustedValuation).round(2),
      valuation: this.form.valuation,
      comment: this.form.comment,
      valuationMethods: {
        historicNetProfit: {
          valuation: this.valuationNumbers.historicNetProfit || 0,
          weight: this.subValuations.historicNetProfit.enabled ? this.subValuations.historicNetProfit.weight : 0
        },
        historicRevenue: {
          valuation: this.valuationNumbers.historicRevenue || 0,
          weight: this.subValuations.historicRevenue.enabled ? this.subValuations.historicRevenue.weight : 0
        },
        historicBookValue: {
          valuation: this.valuationNumbers.historicBookValue || 0,
          weight: this.subValuations.historicBookValue.enabled ? this.subValuations.historicBookValue.weight : 0
        },
        historicCashFlow: {
          valuation: this.valuationNumbers.historicCashFlow || 0,
          weight: this.subValuations.historicCashFlow.enabled ? this.subValuations.historicCashFlow.weight : 0
        },
        dcfPerpetuity: {
          valuation: this.valuationNumbers.dcfPerpetuity || 0,
          weight: this.subValuations.dcfPerpetuity.enabled ? this.subValuations.dcfPerpetuity.weight : 0
        },
        dcfEbitda: {
          valuation: this.valuationNumbers.dcfEbitda || 0,
          weight: this.subValuations.dcfEbitda.enabled ? this.subValuations.dcfEbitda.weight : 0
        },
        fundamentlValuation: {
          valuation: this.valuationNumbers.fundamentlValuation || 0,
          weight: this.subValuations.fundamentlValuation.enabled ? this.subValuations.fundamentlValuation.weight : 0
        },
        analystsValuation: {
          valuation: this.valuationNumbers.analystsValuation || 0,
          weight: this.subValuations.analystsValuation.enabled ? this.subValuations.analystsValuation.weight : 0
        },
        other: {
          valuation: this.valuationNumbers.other || 0,
          weight: this.subValuations.other.enabled ? this.subValuations.other.weight : 0
        }
      }
    };

    try {
      this.$gtag.event('company_val_summary_create', {
        company_code: this.company?.companyCode
      });
      const newValuation = await this.StockInvestingApi.valuationsCreate(valuation);
      this.ToastSuccess('Added valuation');

      this.valuationsHistory.push({ ...newValuation, enabled: true });
      this.valuationsHistory = this.valuationsHistory.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
      this.form.date = new Date();
      this.form.comment = '';
      await this.updateChartData();
    } catch (err) {
      console.error(err);
      if (err?.errors?.length) {
        this.ToastError(err?.errors[0]);
      }
    }

    this.saving = false;
  }

  async handleValuationDelete(valuationId) {
    this.deleting = valuationId;

    try {
      this.$gtag.event('company_val_summary_delete', {
        company_code: this.company?.companyCode
      });
      await this.StockInvestingApi.valuationsDelete({ _id: valuationId });
      this.ToastSuccess('Deleted valuation');

      const deletedValuationIndex = this.valuationsHistory.findIndex((valuation) => valuation._id === valuationId);
      this.valuationsHistory.splice(deletedValuationIndex, 1);
      await this.updateChartData();
    } catch (err) {
      console.error(err);
      if (err?.errors?.length) {
        this.ToastError(err?.errors[0]);
      }
    }

    this.deleting = null;
  }

  get valuationNumbers() {
    this.triggerValuationNumbers = !this?.triggerValuationNumbers;

    const historicValuation = this.company?.valuations?.historicValuations.sort((a, b) => b.year - a.year)?.first();
    const dcfValuation = this.company?.valuations?.dcfValuations.sort((a, b) => b.year - a.year)?.first();
    const fundamentlValuation = this.company?.valuations?.fundamentlValuations?.last();
    const analystsValuation = this.company?.analystRatings?.targetPrice;

    const parseValue = (value) => (!isNaN(parseFloat(value)) ? parseFloat(value) : null);

    return {
      historicNetProfit: parseValue(this.subValuations?.historicNetProfit?.value || historicValuation?.valuation?.netProfit),
      historicRevenue: parseValue(this.subValuations?.historicRevenue?.value || historicValuation?.valuation?.revenues),
      historicBookValue: parseValue(this.subValuations?.historicBookValue?.value || historicValuation?.valuation?.bookValue),
      historicCashFlow: parseValue(this.subValuations?.historicCashFlow?.value || historicValuation?.valuation?.operationalCashFlow),
      dcfPerpetuity: parseValue(this.subValuations?.dcfPerpetuity?.value || dcfValuation?.valuation?.perpetuity),
      dcfEbitda: parseValue(this.subValuations?.dcfEbitda?.value || dcfValuation?.valuation?.ebitda),
      fundamentlValuation: parseValue(this.subValuations?.fundamentlValuation?.value || fundamentlValuation?.average),
      analystsValuation: this.store.user.hasPermission(EPermissions.COMPANY_ANALYST_RATINGS.READ)
        ? parseValue(this.subValuations?.analystsValuation?.value || analystsValuation)
        : null,
      other: parseValue(this.subValuations?.other?.value)
    };
  }

  toggleComment(commentId) {
    this.commentVisible[commentId] = !this.commentVisible[commentId];
    this.$forceUpdate();
  }

  async updateChartData() {
    this.chartOptions = null;
    await this.$nextTick();
    this.initChart();
  }

  setChartData() {
    this.chartOptions.series = [
      {
        type: 'line',
        id: `${this.company.companyCode}-ohlc`,
        name: `Share price`,
        data: this.chartDataOhlc,
        lineWidth: 1,
        color: '#bacbda',
        turboThreshold: 0
      },
      {
        type: 'spline',
        id: `${this.company.companyCode}-own-valuation`,
        name: 'My valuation',
        // tooltip: {
        //   pointFormat: 'Fecha: {point.prefix} {point.x:%Y-%m-%d} date, <br>Evento: {point.x}'
        // },
        data: this.valuationsHistory
          .filter((valuation) => valuation.enabled)
          .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
          .map((valuation) => [new Date(valuation.date).getTime(), parseFloat(valuation.valuation)]),
        marker: {
          enabled: true,
          symbol: 'circle',
          radius: 4
        }
      }
    ];
  }

  async initChart() {
    if (!this.historyPricingData) {
      const historyPricingData = (
        await this.StockInvestingApi.companySharePriceData({ companyCode: this.company.companyCode })
      ).candlesticks.sort((a, b) => new Date(a.date) - new Date(b.date));
      this.historyPricingData = adjustForSplits(historyPricingData, this.company?.shares?.splits);

      this.chartDataOhlc = [];

      for (let i = 0; i < this.historyPricingData.length; i++) {
        this.chartDataOhlc.push([
          new Date(this.historyPricingData[i].date).getTime(),
          parseFloat(this.historyPricingData[i].close).round(2)
        ]);
      }
    }

    this.chartOptions = {
      scrollbar: {
        enabled: false
      },
      stockTools: null,
      rangeSelector: {
        buttons: [
          { type: 'month', count: 1, text: '1M' },
          { type: 'year', count: 1, text: '1Y' },
          { type: 'year', count: 5, text: '5Y' },
          { type: 'all', count: 1, text: 'All' }
        ],
        selected: 2,
        inputEnabled: false,
        labelStyle: {
          display: 'none'
        },
        verticalAlign: 'top'
      },
      legend: {
        enabled: true,
        align: 'right',
        verticalAlign: 'top',
        y: -37
      },
      time: {
        timezoneOffset: new Date().getTimezoneOffset()
      },
      yAxis: [
        {
          labels: {
            align: 'left',
            x: 10,
            y: 3
          },
          height: '100%',
          resize: {
            enabled: true
          },
          lineWidth: 1,
          tickLength: 5,
          tickWidth: 1,
          tickPixelInterval: 30
        }
      ],
      xAxis: {
        tickLength: 5,
        overscroll: ETime.milliseconds.perMonth,
        tickPixelInterval: 50,
        labels: {
          formatter: (val) => {
            const prevTick =
              val?.axis?.tickPositions?.[val.axis.tickPositions.indexOf(val.value) - 1] ||
              val.value - (val.axis.tickPositions[1] - val.axis.tickPositions[0]);
            const prevMonth = new Date(prevTick).getMonth();
            const prevYear = new Date(prevTick).getFullYear();
            const thisMonth = new Date(val.value).getMonth();
            const thisYear = new Date(val.value).getFullYear();
            if (val?.tickPositionInfo?.unitName === 'month') {
              if (prevYear !== thisYear) {
                return new Date(val.value).getFullYear();
              }
              return `<span style="color: #888">${new Date(val.value).toLocaleDateString('en-US', { month: 'short' })}</span>`;
            }
            if (val?.tickPositionInfo?.unitName === 'week') {
              if (prevMonth === thisMonth && prevYear === thisYear) {
                return `<span style="color: #888">${new Date(val.value).toLocaleDateString('en-US', { day: 'numeric' })}</span>`;
              }
              return new Date(val.value).toLocaleDateString('en-US', { month: 'short' });
            }
            return Highcharts.dateFormat(val?.dateTimeLabelFormat, val.value);
          }
        }
      },
      navigator: {
        height: 40,
        maskFill: 'rgba(50,50,50,0.5)',
        maskInside: false,
        outlineWidth: 0.5,
        outlineColor: 'transparent',
        series: {
          color: 'transparent',
          lineColor: 'var(--white)'
        },
        xAxis: {
          overscroll: ETime.milliseconds.perMonth
        }
      },
      tooltip: {
        shape: 'square',
        headerShape: 'callout',
        borderWidth: 0,
        shadow: false,
        followPointer: true,
        followTouchMove: true,
        useHTML: true,
        padding: 5,
        currency: this?.company?.currencies?.sharePrices?.symbol(),
        formatter(val) {
          const hrDate = `${new Date(this.x).getFullYear()}-${
            new Date(this.x).getMonth() + 1 < 10 ? `0${new Date(this.x).getMonth() + 1}` : new Date(this.x).getMonth() + 1
          }-${new Date(this.x).getDate() < 10 ? `0${new Date(this.x).getDate()}` : new Date(this.x).getDate()}`;
          return `
            <div class="text-right text-body">
              ${val?.options?.currency}${this.y.toLocaleString(window.navigator.language, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}<br />
              <small class="text-light" style="font-size: 10px;">${hrDate}</small>
            </div>
          `;
        }
      },
      series: []
    };
    this.setChartData();
  }

  async handleValuationCheckboxChange(valuationIndex, value) {
    this.company.valuations.historicValuations[valuationIndex].enabled = value;

    this.updatingValuationIndex = valuationIndex;
    await this.$nextTick();

    this.updateChartData();
    this.updatingValuationIndex = null;
  }

  setCheckboxes() {
    Object.keys(this.subValuations).forEach((key) => {
      this.subValuations[key].enabled =
        typeof this.subValuations[key].enabled === typeof Boolean()
          ? this.subValuations[key].enabled
          : !!this.valuationNumbers[key] && this.valuationNumbers[key] > 0;
    });
  }

  setPermissions() {
    Object.keys(this.subValuations).forEach((key) => {
      this.subValuations[key].hasPermission = this?.store?.user?.hasPermission(this.subValuations[key].permission) || false;
    });
  }

  getUserProfilePicture(valuation) {
    return this.usersById?.[valuation?.userId]?.personal?.photoUrl;
  }

  getUserDisplayName(valuation) {
    return this.usersById?.[valuation?.userId]?.personal?.displayName;
  }

  handleFormInteraction() {
    this.$gtag.event('company_val_summary_formInteract', {
      company_code: this.company?.companyCode
    });
  }

  async loadCompany() {
    const companyCode = this?.$route?.params?.companyCode;
    this.company = new Company({ companyCode });
    await this.company.load({
      general: true,
      currencies: true,
      valuations: {
        historicValuations: true,
        dcfValuations: true,
        fundamentlValuations: true,
        valuationSummaries: true
      },
      financialStatements: {
        balanceSheets: {
          yearly: true
        },
        incomeStatements: {
          yearly: true
        },
        cashFlowStatements: {
          yearly: true
        },
        ratios: {
          yearly: true
        }
      },
      ratios: {
        yearly: true
      },
      shares: {
        outstanding: true,
        splits: true
      },
      sharePriceLive: true,
      analystRatings: true
    });
  }

  async mounted() {
    await this.loadCompany();
    this.setTitle(`${this?.company?.general?.name} - Valuation summary`);

    // Get users list
    try {
      this.users = await this.UsersApi.userList();

      const usersById = {};
      for (let i = 0; i < this.users.length; i++) {
        const user = this.users[i];
        usersById[user._id] = user;
      }
      this.usersById = usersById;
    } catch (err) {
      console.error(err);
    }

    // Set auto valuations
    this.subValuations.historicNetProfit.autoValuations = [
      ...[2, 5, 10].map((years) => ({
        name: `${years} year`,
        key: years,
        enabled: this.store.user.maxDataHistory >= years,
        visible: true,
        tooltip: `Auto valuation based on ${years} years of data`,
        fn: async () => {
          if (this.store.user.maxDataHistory >= years) {
            this.subValuations.historicNetProfit.value = CompanyHistoricValuation.autoValuation(this.company, years).valuation.netProfit;
            this.subValuations.historicNetProfit.enabled = null;
            this.triggerValuationNumbers = !this.triggerValuationNumbers;
            this.subValuations.historicNetProfit.selectedAutoValuation = years;
            this.setCheckboxes();
          } else if (years === 5) {
            this.showAuthenticateModal = true;
          } else if (years === 10) {
            this.upgradeCtaModalPlan = EPlans.packageTier1;
            this.showUpgradeCtaModal = true;
          }
        }
      })),
      {
        name: 'Custom',
        key: 'custom',
        enabled: null,
        visible: !!this.company?.valuations?.historicValuations?.first()?.valuation?.netProfit,
        tooltip: 'Your own valuation, saved in the "Historical multiples valuation"',
        fn: () => {
          this.subValuations.historicNetProfit.value = this.company?.valuations?.historicValuations?.first()?.valuation?.netProfit;
          this.subValuations.historicNetProfit.enabled = null;
          this.triggerValuationNumbers = !this.triggerValuationNumbers;
          this.subValuations.historicNetProfit.selectedAutoValuation = 'custom';
          this.setCheckboxes();
        }
      }
    ];

    this.subValuations.historicRevenue.autoValuations = [
      ...[2, 5, 10].map((years) => ({
        name: `${years} year`,
        key: years,
        enabled: this.store.user.maxDataHistory >= years,
        visible: true,
        tooltip: `Auto valuation based on ${years} years of data`,
        fn: async () => {
          if (this.store.user.maxDataHistory >= years) {
            this.subValuations.historicRevenue.value = CompanyHistoricValuation.autoValuation(this.company, years).valuation.revenues;
            this.subValuations.historicRevenue.enabled = null;
            this.triggerValuationNumbers = !this.triggerValuationNumbers;
            this.subValuations.historicRevenue.selectedAutoValuation = years;
            this.setCheckboxes();
          } else if (years === 5) {
            this.showAuthenticateModal = true;
          } else if (years === 10) {
            this.upgradeCtaModalPlan = EPlans.packageTier1;
            this.showUpgradeCtaModal = true;
          }
        }
      })),
      {
        name: 'Custom',
        key: 'custom',
        enabled: null,
        visible: !!this.company?.valuations?.historicValuations?.first()?.valuation?.revenues,
        tooltip: 'Your own valuation, saved in the "Historical multiples valuation"',
        fn: () => {
          this.subValuations.historicRevenue.value = this.company?.valuations?.historicValuations?.first()?.valuation?.revenues;
          this.subValuations.historicRevenue.enabled = null;
          this.triggerValuationNumbers = !this.triggerValuationNumbers;
          this.subValuations.historicRevenue.selectedAutoValuation = 'custom';
          this.setCheckboxes();
        }
      }
    ];

    this.subValuations.historicBookValue.autoValuations = [
      ...[2, 5, 10].map((years) => ({
        name: `${years} year`,
        key: years,
        enabled: this.store.user.maxDataHistory >= years,
        visible: true,
        tooltip: `Auto valuation based on ${years} years of data`,
        fn: async () => {
          if (this.store.user.maxDataHistory >= years) {
            this.subValuations.historicBookValue.value = CompanyHistoricValuation.autoValuation(this.company, years).valuation.bookValue;
            this.subValuations.historicBookValue.enabled = null;
            this.triggerValuationNumbers = !this.triggerValuationNumbers;
            this.subValuations.historicBookValue.selectedAutoValuation = years;
            this.setCheckboxes();
          } else if (years === 5) {
            this.showAuthenticateModal = true;
          } else if (years === 10) {
            this.upgradeCtaModalPlan = EPlans.packageTier1;
            this.showUpgradeCtaModal = true;
          }
        }
      })),
      {
        name: 'Custom',
        key: 'custom',
        enabled: null,
        visible: !!this.company?.valuations?.historicValuations?.first()?.valuation?.bookValue,
        tooltip: 'Your own valuation, saved in the "Historical multiples valuation"',
        fn: () => {
          this.subValuations.historicBookValue.value = this.company?.valuations?.historicValuations?.first()?.valuation?.bookValue;
          this.subValuations.historicBookValue.enabled = null;
          this.triggerValuationNumbers = !this.triggerValuationNumbers;
          this.subValuations.historicBookValue.selectedAutoValuation = 'custom';
          this.setCheckboxes();
        }
      }
    ];

    this.subValuations.historicCashFlow.autoValuations = [
      ...[2, 5, 10].map((years) => ({
        name: `${years} year`,
        key: years,
        enabled: this.store.user.maxDataHistory >= years,
        visible: true,
        tooltip: `Auto valuation based on ${years} years of data`,
        fn: async () => {
          if (this.store.user.maxDataHistory >= years) {
            this.subValuations.historicCashFlow.value = CompanyHistoricValuation.autoValuation(
              this.company,
              years
            ).valuation.operationalCashFlow;
            this.subValuations.historicCashFlow.enabled = null;
            this.triggerValuationNumbers = !this.triggerValuationNumbers;
            this.subValuations.historicCashFlow.selectedAutoValuation = years;
            this.setCheckboxes();
          } else if (years === 5) {
            this.showAuthenticateModal = true;
          } else if (years === 10) {
            this.upgradeCtaModalPlan = EPlans.packageTier1;
            this.showUpgradeCtaModal = true;
          }
        }
      })),
      {
        name: 'Custom',
        key: 'custom',
        enabled: null,
        visible: !!this.company?.valuations?.historicValuations?.first()?.valuation?.operationalCashFlow,
        tooltip: 'Your own valuation, saved in the "Historical multiples valuation"',
        fn: () => {
          this.subValuations.historicCashFlow.value = this.company?.valuations?.historicValuations?.first()?.valuation?.operationalCashFlow;
          this.subValuations.historicCashFlow.enabled = null;
          this.triggerValuationNumbers = !this.triggerValuationNumbers;
          this.subValuations.historicCashFlow.selectedAutoValuation = 'custom';
          this.setCheckboxes();
        }
      }
    ];

    // Get valuations
    this.valuationsHistory = (this.company?.valuations?.valuationSummaries || []).sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );

    // Set visibility status of comments and valuations
    const commentVisible = {};
    for (let i = 0; i < this.valuationsHistory.length; i++) {
      if (this.valuationsHistory[i].comment && this.valuationsHistory[i].comment !== '') {
        commentVisible[this.valuationsHistory[i]._id] = false;
      }
      this.valuationsHistory[i].enabled = true;
    }
    this.commentVisible = commentVisible;

    // Set permissions
    this.setPermissions();

    // Set checkboxes
    this.setCheckboxes();

    // Create chart
    this.initChart();

    this.loaded = true;
  }
}
export default CompanyDetailsValuationSummary;
</script>
