<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import draggable from 'vuedraggable';

import { Chart } from 'highcharts-vue';
import { CompanyScreener, CTable, LoginModal, SignUpModal, CompanySearch } from '../../components/index.js';
import { ECurrency, ECurrencyPair, EPortfolioTransactionType, ETime } from '../../../shared/enums.js';
import { Currency, CurrencyPair, Portfolio } from '../../../shared/models/index.js';
import { PortfolioTransactionCash, PortfolioTransactionShares } from '../../../shared/models/portfolio/PortfolioTransaction.js';

const MAIN_CURRENCY = 'EUR';
const PIE_CHART_BASE_COLOR = {
  r: 15,
  g: 72,
  b: 127
};

const NEW_PORTFOLIO_MODAL_ID = 'new-portfolio-modal';
const RENAME_PORTFOLIO_MODAL_ID = 'rename-portfolio-modal';
const NEW_TRANSACTION_MODAL_ID = 'new-transaction-modal';
const COMPANY_SEARCH_POPOVER_ID = 'company-search-popover';

const CASH_TRANSACTION_TYPES = {
  deposit: true,
  withdraw: false
};

const SHARES_TRANSACTION_TYPES = {
  buy: true,
  sell: false
};

@Component({
  name: 'portfolios',
  data: () => ({
    MAIN_CURRENCY,
    PIE_CHART_BASE_COLOR,
    NEW_PORTFOLIO_MODAL_ID,
    RENAME_PORTFOLIO_MODAL_ID,
    NEW_TRANSACTION_MODAL_ID,
    COMPANY_SEARCH_POPOVER_ID,
    CASH_TRANSACTION_TYPES,
    SHARES_TRANSACTION_TYPES,
    ECurrency,
    ECurrencyPair,
    EPortfolioTransactionType,
    Currency,
    CurrencyPair
  }),
  components: { CompanyScreener, CTable, Chart, LoginModal, SignUpModal, CompanySearch, draggable }
})
class Portfolios extends Vue {
  dataLoaded = false;
  portfolios = null;

  isProcessing = false;

  historicValuationLabels = {
    avg: 'Average',
    netProfit: 'Net profit',
    revenues: 'Revenue',
    bookValue: 'Book value',
    operationalCashFlow: 'Operational cashflow'
  };

  // Create new portfolio
  newPortfolio = new Portfolio({});

  // Portfolio update form
  updateForm = {
    name: null
  };

  // Add transaction
  newTransactionType = EPortfolioTransactionType.shares;

  newCashTransaction = new PortfolioTransactionCash({});
  newSharesTransaction = new PortfolioTransactionShares({});

  loginModalVisible = false;
  signUpModalVisible = false;

  /* Create portfolio */
  async handleAddNewPortfolioClick() {
    this.isProcessing = true;
    try {
      await this.newPortfolio.create();
      this.portfolios.push(this.newPortfolio);
      this.newPortfolio = new Portfolio({});
      this.ToastSuccess('Portfolio was added');
      this.$bvModal.hide(NEW_PORTFOLIO_MODAL_ID);
    } catch (err) {
      console.error(err);
      if (err?.errors?.length) {
        this.ToastError(err?.errors[0]);
      }
    }
    this.isProcessing = false;
  }

  /* Update portfolio */
  async handleUpdatePortfolioClick(portfolio) {
    this.isProcessing = true;

    portfolio.name = this.updateForm.name;

    try {
      await portfolio.save();
      this.ToastSuccess('Portfolio was updated');
      this.$bvModal.hide(RENAME_PORTFOLIO_MODAL_ID + portfolio._id);
    } catch (err) {
      console.error(err);
      this.ToastError(err?.errors?.first());
    }
    this.isProcessing = false;
  }

  /* Delete portfolio */
  async handleDeletePortfolioClick(portfolioToDelete) {
    if (!confirm(`Are you sure you want to delete ${portfolioToDelete.name}?`)) return;
    this.isProcessing = true;

    try {
      await portfolioToDelete.delete();
      this.ToastSuccess('Portfolio was deleted');
      this.portfolios = this.portfolios.filter((portfolio) => portfolio._id !== portfolioToDelete._id);
    } catch (err) {
      console.error(err);
      this.Toast.error(err?.errors?.first());
    }
    this.isProcessing = false;
  }

  /* Add transaction */
  handleAddNewTransactionModal(portfolio) {
    this.$bvModal.show(NEW_TRANSACTION_MODAL_ID + portfolio._id);
    this.newCashTransaction = new PortfolioTransactionCash({});
    this.newSharesTransaction = new PortfolioTransactionShares({});
  }

  async handleAddNewCashTransactionClick(portfolio) {
    try {
      await portfolio.addTransaction(this.newCashTransaction);
      this.ToastSuccess('Transaction was added');
      this.$bvModal.hide(NEW_TRANSACTION_MODAL_ID + portfolio._id);
    } catch (err) {
      console.error(err);
      this.ToastError('Something went wrong');
    }
  }

  async handleAddNewSharesTransactionClick(portfolio) {
    try {
      await portfolio.addTransaction(this.newSharesTransaction);
      this.ToastSuccess('Transaction was added');
      this.$bvModal.hide(NEW_TRANSACTION_MODAL_ID + portfolio._id);
    } catch (err) {
      console.error(err);
      this.ToastError('Something went wrong');
    }
  }

  handleSharesTransactionCompanySelect(company) {
    this.newSharesTransaction.company = company;
    this.newSharesTransaction.companyCode = company.companyCode;
  }

  handleSharesTransactionCompanyDeselect() {
    this.newSharesTransaction.company = null;
    this.newSharesTransaction.companyCode = null;
  }

  /* Delete transaction */
  async handleDeleteTransactionClick(portfolio, transaction) {
    if (!window.confirm('Are you sure you want to delete this transaction?')) return;

    try {
      await portfolio.removeTransaction(transaction);
      this.ToastSuccess('Transaction was deleted');
    } catch (err) {
      console.error(err);
      this.ToastError('Something went wrong');
    }
  }

  createPortfolioPieChart(portfolio) {
    const companyCodes = Object.keys(portfolio.state.shares).filter((companyCode) => portfolio.state.shares[companyCode].amount > 0);
    const chartOptions = {
      chart: {
        type: 'pie',
        margin: 0,
        marginTop: 40,
        events: {
          load() {
            const [series] = this.series;
            setTimeout(() => {
              series.points.forEach((point) => {
                if (point.percentage < 5) {
                  point.update({
                    dataLabels: {
                      distance: 30
                    }
                  });
                }
              });
            });
          }
        }
      },
      title: {
        text: 'Holdings'
      },
      plotOptions: {
        pie: {
          cursor: 'pointer',
          colors: new Array(companyCodes.length + 1).fill(null).map((companyCode, index) => {
            const companyCodesAmount = companyCodes.length;
            const r = this.PIE_CHART_BASE_COLOR.r + ((255 - this.PIE_CHART_BASE_COLOR.r) / (companyCodesAmount + 2)) * (index + 1);
            const g = this.PIE_CHART_BASE_COLOR.g + ((255 - this.PIE_CHART_BASE_COLOR.g) / (companyCodesAmount + 2)) * (index + 1);
            const b = this.PIE_CHART_BASE_COLOR.b + ((255 - this.PIE_CHART_BASE_COLOR.b) / (companyCodesAmount + 2)) * (index + 1);
            return `rgb(${r}, ${g}, ${b})`;
          }),
          dataLabels: {
            distance: '-20%'
          }
        }
      },
      stockTools: null,
      tooltip: {
        pointFormat: `
            <span style="color:{point.color}">●</span>
            {series.name}: <strong>{point.y}</strong> <br />
            <span style="color:{point.color}">●</span>
            Percentage: <strong>{point.percentage:.2f}%</strong>`
      },
      series: [
        {
          name: `Invested (${MAIN_CURRENCY})`,
          colorByPoint: true,
          data: [
            ...companyCodes
              .map((companyCode) => ({
                name: portfolio.state.companies[companyCode]?.ticker,
                ticker: portfolio.state.companies[companyCode]?.ticker,
                y: parseFloat(portfolio.state.shares[companyCode].value.toFixed(2))
              }))
              .sort((a, b) => b.y - a.y),
            {
              name: 'CASH',
              ticker: 'CASH',
              y: parseFloat(portfolio.state.cash.toFixed(2)),
              color: '#34c38f'
            }
          ]
        }
      ]
    };
    return chartOptions;
  }

  createPortfolioValueChart(portfolio) {
    const chartOptions = {
      chart: {
        type: 'line'
      },
      title: {
        text: 'Historic portfolio value'
      },
      xAxis: {
        type: 'datetime',
        minPadding: 0.03,
        maxPadding: 0.03
      },
      yAxis: {
        minPadding: 0.16,
        maxPadding: 0.115
      },
      stockTools: null,
      plotOptions: {
        flags: {
          useHTML: true
        }
      },
      series: [
        {
          type: 'flags',
          showInLegend: false,
          data: [
            {
              x: portfolio.state.date.removeTime().toUTC().getTime(),
              y:
                portfolio?.state?.timeline?.find((datapoint) => datapoint?.date?.getTime() === portfolio?.state.date.getTime())
                  ?.portfolioValue || 0,
              title: '<i class="fa fa-eye"></i>',
              text: 'Current portfolio state'
            }
          ],
          color: '#556ee6', // same as onSeries
          fillColor: '#556ee6',
          onSeries: 'dataseries',
          width: 15,
          height: 15,
          margin: 30,
          shape: 'circlepin',
          style: {
            color: 'white'
          },
          states: {
            hover: {
              fillColor: '#4457b5' // darker
            }
          }
        },
        {
          type: 'flags',
          showInLegend: false,
          data: portfolio.transactions
            .filter((transaction) => transaction.type === EPortfolioTransactionType.cash && transaction.deposit)
            .map((transaction) => ({
              x: new Date(transaction.date).removeTime().toUTC().getTime(),
              title: '<i class="fa fa-coins"></i>',
              text: `+${transaction.amount} ${portfolio.currency.iso()}`
            })),
          color: '#34c38f', // same as onSeries
          fillColor: '#34c38f',
          onSeries: 'dataseries',
          width: 15,
          height: 15,
          margin: 30,
          shape: 'circlepin',
          style: {
            color: 'white',
            textAlign: 'center'
          }
        },
        {
          type: 'flags',
          showInLegend: false,
          data: portfolio.transactions
            .filter((transaction) => transaction.type === EPortfolioTransactionType.cash && !transaction.deposit)
            .map((transaction) => ({
              x: new Date(transaction.date).removeTime().toUTC().getTime(),
              title: '<i class="fa fa-coins"></i>',
              text: `-${transaction.amount} ${portfolio.currency.iso()}`
            })),
          color: '#f46a6a', // same as onSeries
          fillColor: '#f46a6a',
          onSeries: 'dataseries',
          width: 15,
          height: 15,
          margin: 30,
          shape: 'circlepin',
          style: {
            color: 'white',
            textAlign: 'center'
          }
        },
        {
          type: 'flags',
          showInLegend: false,
          data: portfolio.transactions
            .filter((transaction) => transaction.type === EPortfolioTransactionType.shares && transaction.buy)
            .map((transaction) => ({
              x: new Date(transaction.date).removeTime().toUTC().getTime(),
              title: '<i class="fa fa-money-check-alt"></i>',
              text: `
                <strong>${portfolio.state.companies[transaction.companyCode]?.generalData?.name}</strong><br />
                +${transaction.amount} shares <br />
                ${transaction.sharePrice}
                ${portfolio.state.companies[transaction.companyCode]?.currencies?.sharePrices.iso()} per share`
            })),
          color: '#34c38f', // same as onSeries
          fillColor: '#34c38f',
          onSeries: 'dataseries',
          width: 15,
          height: 15,
          margin: 30,
          shape: 'circlepin',
          style: {
            color: 'white',
            textAlign: 'center'
          }
        },
        {
          type: 'flags',
          showInLegend: false,
          data: portfolio.transactions
            .filter((transaction) => transaction.type === EPortfolioTransactionType.shares && !transaction.buy)
            .map((transaction) => ({
              x: new Date(transaction.date).removeTime().toUTC().getTime(),
              title: '<i class="fa fa-money-check-alt"></i>',
              text: `
                <strong>${portfolio.state.companies[transaction.companyCode]?.generalData?.name}</strong><br />
                -${transaction.amount} shares <br />
                ${transaction.sharePrice}
                ${portfolio.state.companies[transaction.companyCode]?.currencies?.sharePrices.iso()} per share`
            })),
          color: '#f46a6a', // same as onSeries
          fillColor: '#f46a6a',
          onSeries: 'dataseries',
          width: 15,
          height: 15,
          margin: 30,
          shape: 'circlepin',
          style: {
            color: 'white',
            textAlign: 'center'
          }
        },
        {
          name: `Value (${MAIN_CURRENCY})`,
          data: portfolio.state.timeline.map((datapoint) => [
            datapoint.date.removeTime().toUTC().getTime(),
            parseFloat(datapoint.portfolioValue.toFixed(2))
          ]),
          marker: {
            enabled: false
          },
          cursor: 'pointer',
          events: {
            click: (event) => {
              const currentPortfolio = portfolio;
              currentPortfolio.state.date = new Date(event.point.x).removeTime().toUTC();
            }
          }
        }
      ]
    };

    return chartOptions;
  }

  getAiValuation(company, date) {
    const quarter = Math.ceil((date.getMonth() + 1) / ETime.months.perQuarter);
    const year = date.getFullYear();

    let avgAiValuation = company?.ai_valuations?.averages?.values?.[year]?.[`Q${quarter}`];
    if (!avgAiValuation) {
      const latestYear = Object.keys(company?.ai_valuations?.averages?.values || {})
        .sort()
        .last();
      const latestQuarter = Object.keys(company?.ai_valuations?.averages?.values?.[latestYear] || {})
        .sort()
        .last();
      avgAiValuation = company?.ai_valuations?.averages?.values?.[latestYear]?.[latestQuarter];
    }

    let hvAiValuation = company?.ai_valuations?.historic_valuation?.[year]?.[`Q${quarter}`]?.valuation;
    if (!hvAiValuation) {
      const latestYear = Object.keys(company?.ai_valuations?.historic_valuation || {})
        .sort()
        .last();
      const latestQuarter = Object.keys(company?.ai_valuations?.historic_valuation?.[latestYear] || {})
        .sort()
        .last();
      hvAiValuation = company?.ai_valuations?.historic_valuation?.[latestYear]?.[latestQuarter]?.valuation;
    }

    return {
      netProfit: hvAiValuation?.netProfit || null,
      revenues: hvAiValuation?.revenues || null,
      bookValue: hvAiValuation?.bookValue || null,
      operationalCashFlow: hvAiValuation?.operationalCashFlow || null,
      avg: avgAiValuation || null
    };
  }

  getAiDiscount(company, date) {
    const aiValuation = this.getAiValuation(company, date).avg;
    const marketPrice =
      company.sharePricesDaily.find((candle) => new Date(candle.date).getTime() === date.getTime())?.close || company.lastCandle?.close;
    const discount = (aiValuation / marketPrice - 1) * 100;
    return discount;
  }

  /* Misc */
  async loadPortfolios() {
    try {
      if (this.store.user) {
        const portfolios = await this.StockInvestingApi.portfoliosList();
        this.portfolios = portfolios.map((portfolio) => new Portfolio(portfolio));
      }
    } catch (err) {
      console.error(err);
    }
  }

  async mounted() {
    this.setTitle('My Portfolio');
    await this.loadPortfolios();
    this.dataLoaded = true;
  }
}
export default Portfolios;
</script>
