class CompanyDcfValuation {
  constructor({
    _id = null,
    year = null,
    operatingData = null,
    balanceSheetData = null,
    cashFlowBuildup = null,
    costOfCapital = null,
    wacc = null,
    terminalValue = null,
    valuation = null
  }) {
    this._id = _id;
    this.year = year;
    this.operatingData = operatingData;
    this.balanceSheetData = balanceSheetData;
    this.cashFlowBuildup = cashFlowBuildup;
    this.costOfCapital = costOfCapital;
    this.wacc = wacc;
    this.terminalValue = terminalValue;
    this.valuation = valuation;
  }
}

export { CompanyDcfValuation };
