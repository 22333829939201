<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import { CompanyComparisonTable } from '../../../components/index.js';
import { Company, CompanyScreener } from '../../../../shared/models/index.js';

@Component({
  name: 'company-details-competitors',
  components: { CompanyComparisonTable }
})
class CompanyDetailsCompetitors extends Vue {
  loaded = false;

  comparisonCompanyCodes = null;

  async findCompetitors() {
    const sector = this.company?.general?.sector;
    const industry = this.company?.general?.industry;
    const screener = new CompanyScreener({
      offset: 0,
      limit: 5,
      sortColumn: 'marketCapUsd',
      sortDirection: CompanyScreener.SORT_DIRECTION.DESC,
      filters: {
        sectorIndustry: {
          sector: [sector],
          industry: [industry]
        }
      }
    });
    await screener.loadCompanies();
    const comparisonCompanyCodes = screener.companies.map((company) => company.companyCode);
    if (comparisonCompanyCodes.includes(this.company.companyCode)) {
      comparisonCompanyCodes.splice(comparisonCompanyCodes.indexOf(this.company.companyCode), 1);
    }
    this.comparisonCompanyCodes = [this.company.companyCode, ...comparisonCompanyCodes].slice(0, 5);
  }

  async loadCompany() {
    const companyCode = this?.$route?.params?.companyCode;
    this.company = new Company({ companyCode });
    await this.company.load({ currencies: true, general: true });
  }

  async mounted() {
    await this.loadCompany();
    this.setTitle(`${this?.company?.general?.name} - Competitors`);

    await this.findCompetitors();

    this.currency = this.company?.currencies?.financialData;

    this.loaded = true;
  }
}
export default CompanyDetailsCompetitors;
</script>
