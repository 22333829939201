<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Parser } from 'json2csv/dist/json2csv.esm.js';

@Component({
  name: 'export-csv',
  props: {
    exportData: {
      type: Array,
      required: true
    },
    topLeftCell: {
      type: String,
      required: false
    },
    fileName: {
      type: String,
      required: true
    },
    disclaimer: {
      type: String,
      required: false
    }
  }
})
class ExportCsvBtn extends Vue {
  showAuthenticateModal = false;

  downloadData() {
    const parser = new Parser({ fields: Object.keys(this.exportData?.first() || {}), delimiter: '\t' });
    let csv = parser.parse(this.exportData);
    if (this.topLeftCell) {
      csv = `"${this.topLeftCell}"\t${csv.split('\t').slice(1).join('\t')}`;
    }

    if (this.disclaimer) {
      csv += `\r\n\r\n"${this.disclaimer}"`;
    }

    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csv}`);

    const link = document.createElement('a');
    link.download = this.fileName;
    link.href = encodedUri;
    link.click();
  }
}
export default ExportCsvBtn;
</script>
