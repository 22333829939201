<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';

import { EMonths, EPlans, ETime } from '../../../../../shared/enums.js';

import { CTable, Lazyload, ExportCsvBtn, UpgradeCtaModal } from '../../../../components/index.js';
import { adjustForSplits } from '../../../../../shared/utils/index.js';
import { Company } from '../../../../../shared/models/company/Company.js';

const ALL_YEARS = Number.MAX_SAFE_INTEGER;
const AVERAGE_YEARS = [5, 10, ALL_YEARS];

@Component({
  name: 'company-details-share',
  components: { Chart, CTable, Lazyload, ExportCsvBtn, UpgradeCtaModal },
  data: () => ({
    AVERAGE_YEARS,
    chartSeries: {
      ohlc: [],
      volume: [],
      lowPrices: [],
      meanPrices: [],
      highPrices: []
    },
    EPlans
  })
})
class CompanyDetailsShareChart extends Vue {
  loadingChart = true;
  sharePriceChartOptions = null;
  historyPricingData = null;
  currency = this?.company?.currencies?.sharePrices || null;
  filteredSharePriceYearly = null;
  exportDates = null;

  showExportDataUpgradeModal = false;

  async initChart() {
    if (!this.historyPricingData) {
      const response = await this.StockInvestingApi.companySharePriceData({ companyCode: this.company.companyCode });

      this.currency = response?.currency;
      const candlesticks = response.candlesticks.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

      this.historyPricingData = adjustForSplits(candlesticks, this.company.shares.splits);

      const ohlc = [];
      const volume = [];

      for (let i = 0; i < this.historyPricingData.length; i++) {
        ohlc.push([
          new Date(this.historyPricingData[i].date).getTime(), // the date
          parseFloat(this.historyPricingData[i].open?.toFixed(2)),
          parseFloat(this.historyPricingData[i].high?.toFixed(2)),
          parseFloat(this.historyPricingData[i].low?.toFixed(2)),
          parseFloat(this.historyPricingData[i].close?.toFixed(2))
        ]);

        volume.push([
          new Date(this.historyPricingData[i].date).getTime(), // the date
          parseFloat(this.historyPricingData[i].volume?.toFixed(2))
        ]);
      }

      const meanPrices = [];
      for (let i = 0; i < ohlc.length; i++) {
        const timestamp = ohlc[i][0];
        const year = new Date(timestamp).getFullYear();
        const meanPrice = this.filteredSharePriceYearly.find((candlestick) => new Date(candlestick.date).getFullYear() === year)?.mean;

        if (meanPrice) {
          meanPrices.push([
            timestamp, // the date
            parseFloat(meanPrice.toFixed(2))
          ]);
        }
      }

      const highPrices = [];
      for (let i = 0; i < ohlc.length; i++) {
        const timestamp = ohlc[i][0];
        const year = new Date(timestamp).getFullYear();
        const highPrice = this.filteredSharePriceYearly.find((candlestick) => new Date(candlestick.date).getFullYear() === year)?.high;

        if (highPrice) {
          highPrices.push([
            timestamp, // the date
            parseFloat(highPrice.toFixed(2))
          ]);
        }
      }

      const lowPrices = [];
      for (let i = 0; i < ohlc.length; i++) {
        const timestamp = ohlc[i][0];
        const year = new Date(timestamp).getFullYear();
        const lowPrice = this.filteredSharePriceYearly.find((candlestick) => new Date(candlestick.date).getFullYear() === year)?.low;

        if (lowPrice) {
          lowPrices.push([
            timestamp, // the date
            parseFloat(lowPrice.toFixed(2))
          ]);
        }
      }

      this.chartSeries = {
        ohlc,
        volume,
        lowPrices,
        meanPrices,
        highPrices
      };
    }
    this.loadingChart = false;
  }

  get chartOptions() {
    return {
      scrollbar: {
        enabled: false
      },
      rangeSelector: {
        buttons: [
          { type: 'month', count: 1, text: '1M' },
          { type: 'year', count: 1, text: '1Y' },
          { type: 'year', count: 5, text: '5Y' },
          { type: 'all', count: 1, text: 'All' }
        ],
        selected: 2
      },
      legend: {
        enabled: true,
        verticalAlign: 'top',
        itemMarginTop: -40
      },
      time: {
        timezoneOffset: new Date().getTimezoneOffset()
      },
      yAxis: [
        {
          labels: {
            align: 'left',
            x: 10,
            y: 3
          },
          height: '88%',
          resize: {
            enabled: true
          },
          lineWidth: 1,
          tickLength: 5,
          tickWidth: 1,
          tickPixelInterval: 30
        },
        {
          labels: {
            align: 'left'
          },
          top: '90%',
          height: '10%',
          offset: 0
        }
      ],
      xAxis: {
        tickLength: 5,
        overscroll: ETime.milliseconds.perMonth,
        tickPixelInterval: 50,
        labels: {
          formatter: (val) => {
            const prevTick =
              val?.axis?.tickPositions?.[val.axis.tickPositions.indexOf(val.value) - 1] ||
              val.value - (val.axis.tickPositions[1] - val.axis.tickPositions[0]);
            const prevMonth = new Date(prevTick).getMonth();
            const prevYear = new Date(prevTick).getFullYear();
            const thisMonth = new Date(val.value).getMonth();
            const thisYear = new Date(val.value).getFullYear();
            if (val.tickPositionInfo.unitName === 'month') {
              if (prevYear !== thisYear) {
                return new Date(val.value).getFullYear();
              }
              return `<span style="color: #888">${new Date(val.value).toLocaleDateString('en-US', { month: 'short' })}</span>`;
            }
            if (val.tickPositionInfo.unitName === 'week') {
              if (prevMonth === thisMonth && prevYear === thisYear) {
                return `<span style="color: #888">${new Date(val.value).toLocaleDateString('en-US', { day: 'numeric' })}</span>`;
              }
              return new Date(val.value).toLocaleDateString('en-US', { month: 'short' });
            }
            return Highcharts.dateFormat(val.dateTimeLabelFormat, val.value);
          }
        }
      },
      navigator: {
        height: 40,
        maskFill: 'rgba(50,50,50,0.5)',
        maskInside: false,
        outlineWidth: 0.5,
        outlineColor: 'transparent',
        series: {
          color: 'transparent',
          lineColor: 'var(--white)'
        },
        xAxis: {
          overscroll: ETime.milliseconds.perMonth
        }
      },
      tooltip: {
        shape: 'square',
        headerShape: 'callout',
        borderWidth: 0,
        shadow: false,
        followPointer: true,
        followTouchMove: true,
        useHTML: true,
        padding: 5,
        currency: this?.company?.currencies?.sharePrices?.symbol(),
        formatter(val) {
          const hrDate = `${new Date(this.x).getFullYear()}-${
            new Date(this.x).getMonth() + 1 < 10 ? `0${new Date(this.x).getMonth() + 1}` : new Date(this.x).getMonth() + 1
          }-${new Date(this.x).getDate() < 10 ? `0${new Date(this.x).getDate()}` : new Date(this.x).getDate()}`;
          return `
          <div class="text-right text-body">
            ${val?.options?.currency}${this.y.toLocaleString(window.navigator.language, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}<br />
            <small class="text-light" style="font-size: 10px;">${hrDate}</small>
          </div>
        `;
        }
      },
      series: [
        {
          type: 'candlestick',
          id: `${this.companyCode}-ohlc`,
          name: `Price`,
          data: this.chartSeries.ohlc
        },
        {
          type: 'line',
          id: `${this.companyCode}-low`,
          name: `Low price`,
          data: this.chartSeries.lowPrices,
          color: '#a2b8cd',
          lineWidth: 1,
          marker: false,
          dashStyle: 'Dash'
        },
        {
          type: 'line',
          id: `${this.companyCode}-mean`,
          name: `Mean price`,
          data: this.chartSeries.meanPrices,
          color: '#527ba3',
          lineWidth: 1,
          marker: false,
          dashStyle: 'Dash'
        },
        {
          type: 'line',
          id: `${this.companyCode}-high`,
          name: `High price`,
          data: this.chartSeries.highPrices,
          color: '#1c5286',
          lineWidth: 1,
          marker: false,
          dashStyle: 'Dash'
        },
        {
          type: 'column',
          id: `${this.companyCode}-volume`,
          name: `Volume`,
          data: this.chartSeries.volume,
          yAxis: 1,
          color: '#666'
        }
      ],
      plotOptions: {
        candlestick: {
          turboThreshold: 1000, // Default: 1000 (limits candlesticks to max 1000)
          dataGrouping: {
            groupPixelWidth: 10
          }
        }
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: '100%'
            },
            chartOptions: {
              rangeSelector: {
                inputEnabled: false
              }
            }
          }
        ]
      }
    };
  }

  async loadCompany() {
    const companyCode = this?.$route?.params?.companyCode;
    this.company = new Company({ companyCode });
    await this.company.load({
      shares: {
        price: { yearly: true },
        splits: true
      },
      currencies: true,
      general: true
    });
  }

  get exportData() {
    return this.historyPricingData
      .filter(
        (candlestick) =>
          new Date(candlestick.date).getTime() >= this.exportDates.startDate.getTime() &&
          new Date(candlestick.date).getTime() <= this.exportDates.endDate.getTime()
      )
      .map((candlestick) => ({
        date: new Date(candlestick?.date)?.toISOString()?.split('T')?.first(),
        high: candlestick.high.round(2),
        low: candlestick.low.round(2),
        open: candlestick.open.round(2),
        close: candlestick.close.round(2),
        volume: candlestick.volume.round(0)
      }));
  }

  async mounted() {
    await this.loadCompany();
    this.setTitle(`${this?.company?.general?.name} - Share price chart`);

    this.filteredSharePriceYearly = this.company.shares.price.yearly
      .filter((candlestick) => new Date(candlestick.date).getMonth() === EMonths.december)
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    this.initChart();

    setInterval(() => {
      const dates = [...document.querySelectorAll('.highcharts-range-input tspan')];

      if (dates?.length) {
        const startDate = new Date(dates?.first().textContent);
        const endDate = new Date(dates?.last().textContent);

        this.exportDates = {
          startDate,
          endDate
        };
      }
    }, 500);
  }
}
export default CompanyDetailsShareChart;
</script>
