<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import PaymentMethodAdd from '../payment-method-add/component.vue';
import { Country } from '../../../shared/models/index.js';

@Component({
  name: 'payment-method-list',
  components: { PaymentMethodAdd },
  data: () => ({
    addingPaymentMethod: false,
    paymentMethods: null
  })
})
class PaymentMethodList extends Vue {
  getCountryName(countryIso) {
    const country = new Country(countryIso);
    return country.name.toLowerCase();
  }

  async handleRemoveCard(stripeId) {
    if (window.confirm('Are you sure you want to delete this card?')) {
      try {
        this.paymentMethods = await this.StockInvestingApi.paymentMethodCreditCardDelete({ stripeId });
      } catch (err) {
        console.error(err);
      }
    }
    this.loadPaymentMethods();
  }

  async loadPaymentMethods() {
    try {
      this.paymentMethods = await this.StockInvestingApi.paymentMethodCreditCardList();
    } catch (err) {
      console.error(err);
    }
  }

  handleAddPaymentMethodModalHide() {
    this.addingPaymentMethod = false;
    this.loadPaymentMethods();
  }

  async mounted() {
    this.loadPaymentMethods();
  }
}
export default PaymentMethodList;
</script>
