class CompanyEsg {
  constructor({
    ratingDate = null,
    controversyLevel = null,
    total = null,
    environment = null,
    social = null,
    governance = null,
    activities = null
  }) {
    this.ratingDate = ratingDate;
    this.controversyLevel = controversyLevel;
    this.total = total;
    this.environment = environment;
    this.social = social;
    this.governance = governance;
    this.activities = activities;
  }
}

export { CompanyEsg };
