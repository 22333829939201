<template src="./template.vue"></template>
<script>
import Component from 'vue-class-component';
import Vue from 'vue';

import { Company } from '../../../shared/models/index.js';

@Component({
  name: 'star-rating',
  props: {
    ratingData: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    Company
  })
})
class StarRating extends Vue {
  ratingDetails = null;

  mounted() {
    const company = new Company({
      calculated: {
        rating: {
          '1year': this.ratingData?.['1year'] || this.ratingData?.oneYearData,
          '5year': this.ratingData?.['5year'] || this.ratingData?.fiveYearData
        }
      }
    });
    this.ratingDetails = company.rating;
  }
}
export default StarRating;
</script>
<style lang="scss" scoped>
@import './style.scss';

.b-popover {
  max-width: 100vw;
}
</style>
