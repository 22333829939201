class Phone {
  constructor({ phone = null }) {
    this.phone = phone;
  }

  get raw() {
    return this.phone;
  }

  get toString() {
    return this.phone;
  }
}

export { Phone };
