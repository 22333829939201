<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import { Chart } from 'highcharts-vue';
import { CompanyScreenerFilter } from '../../../shared/models/company/screener/CompanyScreenerFilter.js';
import { CompanyScreener } from '../../../shared/models/index.js';
import { CompanyScreenerItem } from '../../../shared/models/company/screener/CompanyScreenerItem.js';

import { CompanyScreenerFilterModal } from '../../components/index.js';

const OUTLIER_PERCENTILE = 0.95;

@Component({
  name: 'visualizer',
  components: { Chart, CompanyScreenerFilterModal },
  data: () => ({
    CompanyScreener,
    CompanyScreenerItem
  }),
  watch: {
    'screener.sortDirection': function () {
      this.handleFiltersChange();
    },
    'screener.limit': function () {
      this.handleFiltersChange();
    }
  }
})
class Visualizer extends Vue {
  screener = new CompanyScreener({ limit: 200 });
  companyData = null;

  chartOptions = null;

  filtersModalVisible = false;

  get categoricalOptions() {
    const { attributes } = this.screener;
    const filteredAttributes = [];
    const categoricalInputTypes = [
      CompanyScreenerFilter.INPUT_TYPES.STRING.MULTISELECT?.key,
      CompanyScreenerFilter.INPUT_TYPES.STRING.SELECT?.key,
      CompanyScreenerFilter.INPUT_TYPES.STRING.SLIDER_OPTION?.key
    ];
    Object.keys(attributes).forEach((key) => {
      let isCategoricalOption;
      if (attributes?.[key]?.filter?.key) {
        isCategoricalOption = categoricalInputTypes.includes(attributes?.[key]?.filter?.inputType?.key);
      } else {
        isCategoricalOption = !Object.values(attributes?.[key]?.filter || {})
          .map((filter) => categoricalInputTypes.includes(filter?.inputType?.key))
          .includes(false);
      }
      if (isCategoricalOption) {
        filteredAttributes.push(attributes[key]);
      }
    });

    return Object.values(filteredAttributes);
  }

  get numericOptions() {
    const { attributes } = this.screener;
    const filteredAttributes = [];
    Object.keys(attributes).forEach((key) => {
      const isNumericOption = [
        CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE.key,
        CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_OPTIONS.key
      ].includes(attributes?.[key]?.filter?.inputType?.key);
      if (isNumericOption) {
        filteredAttributes.push(attributes[key]);
      }
    });
    return filteredAttributes;
  }

  get sortOptions() {
    const { attributes } = this.screener;
    const filteredAttributes = [];
    Object.keys(attributes).forEach((key) => {
      if (attributes?.[key]?.column?.sortable) {
        filteredAttributes.push(attributes[key]);
      }
    });
    return filteredAttributes;
  }

  settings = {
    xAxis: this.screener.attributes.avgEbitdaGrowth10Year,
    yAxis: this.screener.attributes.avgRevenueGrowth10Year,
    bubbleSize: this.screener.attributes.marketCapUsd,
    bubbleColor: this.screener.attributes.sectorIndustry,
    sortColumn: this.screener.attributes.marketCapUsd
  };

  handleSettingsChanged() {
    this.screener.companies = [];
    this.loadCompanyData();
  }

  handleSwapAxisClick() {
    const x = this.settings.xAxis;
    const y = this.settings.yAxis;
    this.settings.xAxis = y;
    this.settings.yAxis = x;
    this.handleSettingsChanged();
  }

  async loadCompanyData() {
    try {
      Object.values(this.screener.columns).forEach((column) => {
        column.shown = false;
      });
      this.screener.columns[this.settings.xAxis.key].shown = true;
      this.screener.columns[this.settings.yAxis.key].shown = true;
      this.screener.columns[this.settings.bubbleColor.key].shown = true;
      this.screener.columns[this.settings.bubbleSize.key].shown = true;

      this.screener.columns.companyName.shown = true;

      this.screener.sortColumn = this.settings.sortColumn.key;
      await this.screener.loadCompanies();

      let companies = this.screener.companies.filter((company) => company[this.settings.xAxis.key] && company[this.settings.yAxis.key]);

      const xAxisMax = companies
        .map((company) => company[this.settings.xAxis.key])
        .filterOutliers(OUTLIER_PERCENTILE)
        .max();
      const xAxisMin = companies
        .map((company) => company[this.settings.xAxis.key])
        .filterOutliers(OUTLIER_PERCENTILE)
        .min();
      companies = companies.filter(
        (company) => company[this.settings.xAxis.key] <= xAxisMax && company[this.settings.xAxis.key] >= xAxisMin
      );

      const yAxisMax = companies
        .map((company) => company[this.settings.yAxis.key])
        .filterOutliers(OUTLIER_PERCENTILE)
        .max();
      const yAxisMin = companies
        .map((company) => company[this.settings.yAxis.key])
        .filterOutliers(OUTLIER_PERCENTILE)
        .min();
      companies = companies.filter(
        (company) => company[this.settings.yAxis.key] <= yAxisMax && company[this.settings.yAxis.key] >= yAxisMin
      );

      const colorIsCategoric = Object.values(this.categoricalOptions)
        ?.map((option) => option.key)
        ?.includes(this.settings.bubbleColor.key);
      if (!colorIsCategoric) {
        const colorMax = companies
          .map((company) => company[this.settings.bubbleColor.key])
          .filterOutliers(OUTLIER_PERCENTILE)
          .max();
        const colorMin = companies
          .map((company) => company[this.settings.bubbleColor.key])
          .filterOutliers(OUTLIER_PERCENTILE)
          .min();
        companies = companies.filter(
          (company) => company[this.settings.bubbleColor.key] <= colorMax && company[this.settings.bubbleColor.key] >= colorMin
        );
      }

      this.screener.companies = companies;

      this.setChartOptions();
    } catch (err) {
      console.error(err);
    }
  }

  lightenColor(color, lighten) {
    let r = Math.round(parseInt(color.slice(0, 2), 16) + (255 - parseInt(color.slice(0, 2), 16)) * (lighten * 0.8)).toString(16);
    let g = Math.round(parseInt(color.slice(2, 4), 16) + (255 - parseInt(color.slice(2, 4), 16)) * (lighten * 0.8)).toString(16);
    let b = Math.round(parseInt(color.slice(4, 6), 16) + (255 - parseInt(color.slice(4, 6), 16)) * (lighten * 0.8)).toString(16);

    r = r.length >= 3 ? 'ff' : r;
    g = g.length >= 3 ? 'ff' : g;
    b = b.length >= 3 ? 'ff' : b;

    r = r.length === 1 ? `0${r}` : r;
    g = g.length === 1 ? `0${g}` : g;
    b = b.length === 1 ? `0${b}` : b;

    return r + g + b + Math.ceil(255 - 100 * lighten).toString(16);
  }

  setChartOptions() {
    const series = {};
    for (let i = 0; i < this.screener.companies.length; i++) {
      const company = this.screener.companies[i];
      const category = company?.[this.settings.bubbleColor.key] || this.settings.bubbleColor.column?.hrVisualizerValue(company) || 'None';
      if (category) {
        series[category] = series[category] || [];
        series[category].push(company);
      }
    }
    const seriesArray = Object.keys(series).map((serieKey) => series[serieKey]);

    const colorIsCategoric = Object.values(this.categoricalOptions)
      ?.map((option) => option.key)
      ?.includes(this.settings.bubbleColor.key);
    const colorMaxValue = seriesArray
      .map((companies) => {
        return companies.map((company) => company[this.settings.bubbleColor.key]);
      })
      .reduce((a, b) => a.concat(b))
      .max();

    const seriesData = seriesArray.map((companies) => {
      const name =
        companies?.first()?.[this.settings.bubbleColor?.key] || this.settings.bubbleColor.column?.hrVisualizerValue(companies?.first());
      return {
        name: typeof name === typeof String() ? name : 'None',
        data: companies.map((company) => ({
          x: company?.[this.settings.xAxis.key],
          y: company?.[this.settings.yAxis.key],
          z: company?.[this.settings.bubbleSize.key],
          bubbleColorCategory:
            company?.[this.settings.bubbleColor.key] || this.settings.bubbleColor.column?.hrVisualizerValue(company) || 'None',
          color: !colorIsCategoric ? `#${this.lightenColor('005bf9', 1 - company?.[this.settings.bubbleColor.key] / colorMaxValue)}` : null,
          companyName: company?.companyName || company?.ticker,
          companyCode: company?.companyCode,
          readableValues: {
            x: this.settings.xAxis?.column?.hrVisualizerValue
              ? this.settings.xAxis.column?.hrVisualizerValue(company)
              : this.settings.xAxis.column.hrValue(company),
            y: this.settings.yAxis?.column?.hrVisualizerValue
              ? this.settings.yAxis.column?.hrVisualizerValue(company)
              : this.settings.yAxis.column.hrValue(company),
            z: this.settings.bubbleSize?.column?.hrVisualizerValue
              ? this.settings.bubbleSize.column?.hrVisualizerValue(company)
              : this.settings.bubbleSize.column.hrValue(company)
          }
        })),
        maxSize: this.settings.bubbleSize ? '10%' : '2%',
        marker: {
          fillOpacity: 0.9
        }
      };
    });

    this.chartOptions = {
      chart: {
        type: 'bubble',
        zoomType: 'xyz'
      },
      title: null,
      xAxis: {
        title: {
          text: this.settings.xAxis.name?.visualizer,
          style: {
            color: 'white',
            fontSize: '16px'
          }
        }
      },
      yAxis: {
        title: {
          text: this.settings.yAxis.name?.visualizer,
          style: {
            color: 'white',
            fontSize: '16px'
          }
        }
      },
      stockTools: null,
      legend: {
        enabled: colorIsCategoric
      },
      plotOptions: {
        flags: {
          useHTML: true
        },
        bubble: {
          cursor: 'pointer',
          events: {
            click: (event) => {
              const companyCode = event?.point?.companyCode;
              this.$router.push({ name: this.pageNames.stockInvesting.COMPANY_DETAILS.GENERAL, params: { companyCode } });
            }
          }
        }
      },
      tooltip: {
        useHTML: true,
        headerFormat: '<table>',
        pointFormat: `
          <h4 class="text-white m-0">{point.companyName}</h4>
          <div class="text-body">
            <small class="text-uppercase d-inline-block mt-3 lh-0" style="color: {point.color}">X-axis</small> <br />
            <span class="text-gray-200 lh-0">${this.settings.xAxis.name.default}:</span>
            <span class="lh-0">{point.readableValues.x}</span>
            <br />

            <small class="text-uppercase d-inline-block mt-3 lh-0" style="color: {point.color}">Y-axis</small> <br />
            <span class="text-gray-200 lh-0">${this.settings.yAxis.name.default}:</span>
            <span class="lh-0">{point.readableValues.y}</span>
            <br />

            <span class="${!this.settings?.bubbleSize ? 'd-none' : ''}">
              <small class="text-uppercase d-inline-block mt-3 lh-0" style="color: {point.color}">Bubble size</small> <br />
              <span class="text-gray-200 lh-0">${this.settings?.bubbleSize?.name?.default}:</span>
              <span class="lh-0">{point.readableValues.z}</span>
              <br />
            </span>

            <small class="text-uppercase d-inline-block mt-3 lh-0" style="color: {point.color}">Bubble color</small> <br />
            <span class="text-gray-200 lh-0">${this.settings.bubbleColor?.name?.default}:</span>
            <span class="lh-0">{point.bubbleColorCategory}</span>
          </div>`,
        footerFormat: '</table>',
        followPointer: true
      },
      series: seriesData
    };
  }

  categoryHasFilters(category, attributes) {
    if (category?.label) {
      return Object.values(category?.sub)
        .map((subCategory) => this.categoryHasFilters(subCategory, attributes))
        ?.includes(true);
    }
    return !!attributes?.filter((attr) => attr.category === category)?.length;
  }

  handleFiltersChange() {
    this.loadCompanyData();
  }

  async mounted() {
    this.setTitle('Visualizer');

    this.loadCompanyData();
  }
}
export default Visualizer;
</script>
