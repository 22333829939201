<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Chart } from 'highcharts-vue';

import { Company } from '../../../../../shared/models/index.js';
import { CTable, UpgradeCtaModal } from '../../../../components/index.js';
import { EPermissions } from '../../../../../shared/models/user/index.js';
import { EPlans } from '../../../../../shared/enums.js';

@Component({
  name: 'company-details-analyst-ratings',
  components: { Chart, CTable, UpgradeCtaModal },
  data: () => ({ EPlans })
})
class CompanyDetailsAnalystRatings extends Vue {
  loaded = false;
  company = null;

  mockedRating = null;
  showUpgradeModal = false;

  get isDataLimited() {
    return !this.store.user.hasPermission(EPermissions.COMPANY_ANALYST_RATINGS.READ);
  }

  get chartOptions() {
    return {
      chart: {
        type: 'solidgauge'
      },

      title: null,

      pane: {
        center: ['50%', '80%'],
        size: '140%',
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor: '#2a3042',
          innerRadius: '60%',
          outerRadius: '100%',
          shape: 'arc',
          borderWidth: 0,
          lineWidth: 0,
          shadow: 0
        }
      },

      exporting: {
        enabled: false
      },

      tooltip: {
        enabled: false
      },

      // the value axis
      yAxis: {
        min: 0,
        max: 1,
        title: null,
        stops: [
          [0.4, '#f46a6a'], // red
          [0.6, '#f1b44c'], // yellow
          [1.0, '#34c38f'] // green
        ],
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 0,
        labels: {
          y: 16
        },
        showFirstLabel: false,
        showLastLabel: false
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 5,
            borderWidth: 0,
            useHTML: true
          },
          border: 0
        }
      },

      series: [
        {
          name: 'RPM',
          data: [this.analystsRating / 5],
          dataLabels: {
            format: `<span class="gauge-number text-light">
              <span class="text-white ${this.isDataLimited ? 'blur-data-big' : ''}">${this.Filter.number(
              this.mockedRating || this.company.analystRatings.ratingCalc,
              2,
              2
            )}</span>
              <span class="text-light">/ ${this.Filter.number(5, 2, 2)}</span>
            </span>`,
            y: -50,
            style: {
              fontSize: '30px'
            }
          }
        }
      ]
    };
  }

  get analystsRating() {
    return this.mockedRating || this.company.analystRatings.ratingCalc;
  }

  mockRating() {
    this.mockedRating = Math.random() * 5;
  }

  handleDataClick() {
    if (this.isDataLimited) {
      this.showUpgradeModal = true;
    }
  }

  async loadCompany() {
    const companyCode = this?.$route?.params?.companyCode;
    this.company = new Company({ companyCode });
    await this.company.load({ general: true, currencies: true, analystRatings: true, sharePriceLive: true });
  }

  async mounted() {
    await this.loadCompany();
    this.setTitle(`${this?.company?.general?.name} - Analyst ratings`);

    if (this.isDataLimited) {
      this.mockRating();
      setInterval(() => {
        this.mockRating();
      }, 1500);
    }
  }
}
export default CompanyDetailsAnalystRatings;
</script>
