import { CompanyHolder } from './CompanyHolder.js';

class CompanyHolders {
  constructor({ funds = [], institutions = [] }) {
    this.funds = funds.map((fund) => new CompanyHolder(fund));
    this.institutions = institutions.map((institution) => new CompanyHolder(institution));
  }
}

export { CompanyHolders };
