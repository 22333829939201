import { Company } from './Company.js';

class CompanyList {
  constructor({ companyCodes }) {
    this._companyCodes = companyCodes;
    this.companies = companyCodes.map((companyCode) => new Company({ companyCode }));
  }

  get(companyCode) {
    return this.companies.find((company) => company?.companyCode === companyCode);
  }

  add(companyCode) {
    this._companyCodes.push(companyCode);
    this.companies.push(new Company({ companyCode }));
  }

  delete(companyCode) {
    this._companyCodes.splice(this._companyCodes.indexOf(companyCode), 1);
    this.companies.splice(
      this.companies.find((company) => company?.companyCode === companyCode),
      1
    );
  }

  async load(mongoDbProject) {
    const promises = this.companies.map((company) => company.load(mongoDbProject));
    return Promise.all(promises);
  }

  async getSharePrice(date) {
    const promises = this.companies.map((company) => company.getSharePrice(date));
    return Promise.all(promises);
  }

  async loadSharePriceHistory(from, till) {
    const promises = this.companies.map((company) => company.loadSharePriceHistory(from, till));
    return Promise.all(promises);
  }
}

export { CompanyList };
