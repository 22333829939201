import { CompanyInsiderTransaction } from './CompanyInsiderTransaction.js';

class CompanyInsiderTransactions {
  constructor(transactions) {
    this.transactions = transactions
      .map((transaction) => new CompanyInsiderTransaction(transaction))
      .sort((a, b) => b.date.getTime() - a.date.getTime());
  }

  get transactionsByName() {
    const transactions = {};
    this.transactions.forEach((transaction) => {
      const { name } = transaction;
      transactions[name] = transactions?.[name] || [];
      transactions[name].push(transaction);
    });
    Object.keys(transactions).forEach((name) => {
      transactions[name] = transactions[name].sort((a, b) => b.date.getTime() - a.date.getTime());
    });
    return transactions;
  }

  getTitle(name) {
    return this.transactions.find((transaction) => transaction.ownerName.replace(/ [A-Z]{1}[.]{0,1} /, ' ') === name)?.ownerTitle;
  }

  getTotalAmountInTransactions(name) {
    return this.transactions
      .filter((transaction) => transaction.ownerName.replace(/ [A-Z]{1}[.]{0,1} /, ' ') === name)
      .map((transaction) => transaction.transactionValue)
      .reduce((a, b) => a + b);
  }
}

export { CompanyInsiderTransactions };
