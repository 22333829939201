import { CompanyFundamentlValuation } from './CompanyFundamentlValuation.js';
import { CompanyDcfValuation } from './CompanyDcfValuation.js';
import { CompanyHistoricValuation } from './CompanyHistoricValuation.js';
import { CompanyValuationSummary } from './CompanyValuationSummary.js';

class CompanyValuations {
  constructor({ fundamentlValuations = [], dcfValuations = [], historicValuations = [], valuationSummaries = [] }) {
    this.fundamentlValuations = fundamentlValuations
      .map((valuation) => new CompanyFundamentlValuation(valuation))
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    this.dcfValuations = dcfValuations.map((valuation) => new CompanyDcfValuation(valuation)).sort((a, b) => a.year - b.year);
    this.historicValuations = historicValuations
      .map((valuation) => new CompanyHistoricValuation(valuation))
      .sort((a, b) => a.year - b.year);
    this.valuationSummaries = valuationSummaries
      .map((valuation) => new CompanyValuationSummary(valuation))
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  }
}

export { CompanyValuations };
