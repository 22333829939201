<template src="./template.vue" />

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'how-it-works',
  props: {
    placement: {
      type: String,
      default: 'left'
    }
  }
})
class HowItWorks extends Vue {}
export default HowItWorks;
</script>

<style lang="scss">
@import './style.scss';
</style>
