<template src="./template.vue"></template>
<script>
import Component from 'vue-class-component';
import Vue from 'vue';

import { EAuthStrategies } from '../../../shared/enums.js';

@Component({
  name: 'auth-login',
  data: () => ({
    EAuthStrategies
  }),
  props: {
    apiPrefix: {
      type: String,
      required: false,
      default: '/auth'
    },
    buttonTextPrefix: {
      type: String,
      required: false,
      default: 'Login with'
    },
    buttonVariant: {
      type: String,
      required: false,
      default: 'dark'
    },
    authStrategies: {
      type: Array,
      required: false,
      default: null
    }
  }
})
class AuthLogin extends Vue {
  async auth(authStrategy) {
    await this.$gtag.event('auth_login', { method: authStrategy });
    window.location.href = `${process.env.BACKEND_PROTOCOL}://${process.env.BACKEND_HOSTNAME}:${process.env.BACKEND_PORT}${
      this.apiPrefix
    }/login/${authStrategy}?redirect=${encodeURIComponent(location.href)}`;
  }
}
export default AuthLogin;
</script>
<style lang="scss" scoped>
@import './style.scss';
</style>
