import moment from 'moment';

export class Filter {
  static number(value, min = 0, max = 20) {
    return typeof value === typeof Number() && !isNaN(value) && Math.abs(value) !== Infinity
      ? value.toLocaleString(navigator.languages, { minimumFractionDigits: min, maximumFractionDigits: max })
      : '-';
  }

  static hrFinancial(value, digits = 3, minimumFractionDigits = undefined, maximumFractionDigits = 2, space = false) {
    if (typeof value !== typeof Number()) return '-';

    const isNegative = value < 0;

    const precisionValue = value?.precision(digits) * (isNegative ? -1 : 1);
    if (precisionValue / 1000 ** 4 > 1) {
      return `${((precisionValue / 1000 ** 4) * (isNegative ? -1 : 1)).toLocaleString(navigator.languages, {
        minimumFractionDigits,
        maximumFractionDigits
      })}${space ? ' ' : ''}T`;
    }
    if (precisionValue / 1000 ** 3 > 1) {
      return `${((precisionValue / 1000 ** 3) * (isNegative ? -1 : 1)).toLocaleString(navigator.languages, {
        minimumFractionDigits,
        maximumFractionDigits
      })}${space ? ' ' : ''}B`;
    }
    if (precisionValue / 1000 ** 2 > 1) {
      return `${((precisionValue / 1000 ** 2) * (isNegative ? -1 : 1)).toLocaleString(navigator.languages, {
        minimumFractionDigits,
        maximumFractionDigits
      })}${space ? ' ' : ''}M`;
    }
    if (precisionValue / 1000 ** 1 > 1) {
      return `${((precisionValue / 1000 ** 1) * (isNegative ? -1 : 1)).toLocaleString(navigator.languages, {
        minimumFractionDigits,
        maximumFractionDigits
      })}${space ? ' ' : ''}k`;
    }
    return (precisionValue * (isNegative ? -1 : 1)).toLocaleString(navigator.languages, {
      minimumFractionDigits,
      maximumFractionDigits
    });
  }

  static financial(value) {
    return typeof value === typeof Number() && !isNaN(value) && Math.abs(value) !== Infinity
      ? value.toLocaleString(navigator.languages, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      : '-';
  }

  static ratio(value) {
    return typeof value === typeof Number() && !isNaN(value) && Math.abs(value) !== Infinity
      ? value.toLocaleString(navigator.languages, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      : '-';
  }

  static percentage(value, fractionDigits = 2) {
    return typeof value === typeof Number() && !isNaN(value) && Math.abs(value) !== Infinity
      ? `${value.toLocaleString(navigator.languages, {
          minimumFractionDigits: fractionDigits,
          maximumFractionDigits: fractionDigits
        })}%`
      : '-';
  }

  static date(date) {
    return new Date(date).toLocaleDateString(navigator.languages, { day: 'numeric', month: 'short', year: 'numeric' });
  }

  static time(date) {
    return new Date(date).toLocaleTimeString(navigator.languages, { hour: '2-digit', minute: '2-digit' });
  }

  static moment(date) {
    return moment.duration(new Date(date) - new Date(), 'milliseconds').humanize(true);
  }

  static momentDate(date, format = 'YYYY-MM-DD') {
    return date ? moment(date).format(format) : '-';
  }
}
