import { EMonths } from '../../enums.js';
import { Currency } from '../currency/Currency.js';
import { Address } from '../general/Address.js';
import { Phone } from '../general/Phone.js';

class CompanyGeneral {
  constructor({
    name = null,
    currency = 'USD',
    exchangeName = '',
    ISIN = null,
    CUSIP = null,
    CIK = null,
    street = null,
    city = null,
    zip = null,
    country = null,
    isDelisted = false,
    phone = null,
    website = null,
    employees = null,
    sector = null,
    industry = null,
    description = null,
    logoUrl = null,
    fiscalYearEndMonth = EMonths.december
  }) {
    this.name = name;
    this.currency = new Currency(currency);
    this.exchangeName = exchangeName;
    this.ISIN = ISIN;
    this.CUSIP = CUSIP;
    this.CIK = CIK;
    this.address = new Address({ street, city, zip, country });
    this.isDelisted = isDelisted;
    this.phone = new Phone({ phone });
    this.website = website;
    this.sector = sector;
    this.industry = industry;
    this.employees = employees;
    this.description = description;
    this.logoUrl = logoUrl;
    this.fiscalYearEndMonth = fiscalYearEndMonth;
  }
}

export { CompanyGeneral };
