<template src="./template.vue" />

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'lazyload',
  props: {
    tag: {
      type: String,
      default: 'div'
    }
  }
})
class Lazyload extends Vue {
  show = false;

  observeCallback() {
    this.show = true;
    this.$emit('load');
  }

  mounted() {
    if (this.intersectionObserver) {
      this.intersectionObserver.observe(this.$refs.element, this.observeCallback);
    } else {
      // For unsupported browsers
      this.show = true;
      this.$emit('load');
    }
  }

  beforeDestroy() {
    const { element } = this.$refs;
    this.intersectionObserver.unobserve(element);
  }
}
export default Lazyload;
</script>
