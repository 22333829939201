<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'fifty-two-week-range',
  props: {
    low: {
      type: Number,
      required: true
    },
    high: {
      type: Number,
      required: true
    },
    current: {
      type: Number,
      required: true
    },
    prefix: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    }
  }
})
class FiftyTwoWeekRange extends Vue {
  get indicatorMarginLeft() {
    let marginLeftPct = ((this.current - this.low) / (this.high - this.low)) * 100;
    if (marginLeftPct > 99) {
      marginLeftPct = 99;
    }
    return marginLeftPct;
  }
}
export default FiftyTwoWeekRange;
</script>
<style lang="scss" scoped>
@import './style.scss';

.fifty-two-week-range .bar {
  min-width: 175px;
  box-shadow: inset 0 0 10px rgba($white, 0.2);
  height: 7px;
  width: 100%;
  border-radius: 7px;
  overflow: hidden;
  .indicator {
    width: 4px;
    height: 7px;
    background: lighten($primary, 5%);
  }
}
</style>
