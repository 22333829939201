import Homepage from './homepage/page.vue';
import MyAccount from './my-account/page.vue';
import Portfolios from './portfolios/page.vue';
import StockComparison from './stock-comparison/page.vue';
import StockScreener from './stock-screener/page.vue';
import Visualizer from './visualizer/page.vue';
import Workspace from './workspace/page.vue';
import DataManager from './data-manager/page.vue';
import Features from './features/page.vue';
import Formulas from './formulas/page.vue';
import CompanyDetails from './company-details/index.js';
import Upgrade from './upgrade/index.js';

export default {
  Homepage,
  MyAccount,
  Portfolios,
  StockScreener,
  StockComparison,
  Visualizer,
  Workspace,
  DataManager,
  Features,
  Formulas,
  CompanyDetails,
  Upgrade
};
