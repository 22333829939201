<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({ name: 'upgrade' })
class Upgrade extends Vue {}
export default Upgrade;
</script>
<style lang="scss" scoped>
@import './style.scss';
</style>
