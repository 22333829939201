import { CompanyManagementPerson } from './CompanyManagementPerson.js';

class CompanyManagement {
  constructor({ people = [] }) {
    this.people = people.map((person) => new CompanyManagementPerson(person));
  }

  get count() {
    return this.people.length;
  }
}

export { CompanyManagement };
