import { EVatIdTypes } from '../../enums.js';

const continents = {
  AFRICA: { name: 'Africa' },
  ANTARCTICA: { name: 'Antarctica' },
  ASIA: { name: 'Asia' },
  EUROPE: { name: 'Europe' },
  NORTH_AMERICA: { name: 'North America' },
  OCEANIA: { name: 'Oceania' },
  SOUTH_AMERICA: { name: 'South America' }
};

const countries = {
  AF: { iso: 'AF', name: 'Afghanistan', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  AX: { iso: 'AX', name: 'Aland Islands', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [] },
  AL: { iso: 'AL', name: 'Albania', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [] },
  DZ: { iso: 'DZ', name: 'Algeria', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  AS: { iso: 'AS', name: 'American Samoa', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [] },
  AD: { iso: 'AD', name: 'Andorra', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [] },
  AO: { iso: 'AO', name: 'Angola', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  AI: { iso: 'AI', name: 'Anguilla', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  AQ: { iso: 'AQ', name: 'Antarctica', continent: continents.ANTARCTICA.name, aliases: [], vatIdTypes: [] },
  AG: { iso: 'AG', name: 'Antigua And Barbuda', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  AR: { iso: 'AR', name: 'Argentina', continent: continents.SOUTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  AM: { iso: 'AM', name: 'Armenia', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  AW: { iso: 'AW', name: 'Aruba', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  AU: {
    iso: 'AU',
    name: 'Australia',
    continent: continents.OCEANIA.name,
    aliases: [],
    vatIdTypes: [EVatIdTypes.AU_ABN, EVatIdTypes.AU_ARN]
  },
  AT: { iso: 'AT', name: 'Austria', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.EU_VAT] },
  AZ: { iso: 'AZ', name: 'Azerbaijan', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  BS: { iso: 'BS', name: 'Bahamas', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  BH: { iso: 'BH', name: 'Bahrain', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  BD: { iso: 'BD', name: 'Bangladesh', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  BB: { iso: 'BB', name: 'Barbados', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  BY: { iso: 'BY', name: 'Belarus', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [] },
  BE: { iso: 'BE', name: 'Belgium', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.EU_VAT] },
  BZ: { iso: 'BZ', name: 'Belize', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  BJ: { iso: 'BJ', name: 'Benin', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  BM: { iso: 'BM', name: 'Bermuda', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  BT: { iso: 'BT', name: 'Bhutan', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  BO: { iso: 'BO', name: 'Bolivia', continent: continents.SOUTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  BA: { iso: 'BA', name: 'Bosnia And Herzegovina', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [] },
  BW: { iso: 'BW', name: 'Botswana', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  BV: { iso: 'BV', name: 'Bouvet Island', continent: continents.SOUTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  BR: {
    iso: 'BR',
    name: 'Brazil',
    continent: continents.SOUTH_AMERICA.name,
    aliases: [],
    vatIdTypes: [EVatIdTypes.BR_CNPJ, EVatIdTypes.BR_CPF]
  },
  IO: { iso: 'IO', name: 'British Indian Ocean Territory', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  BN: { iso: 'BN', name: 'Brunei Darussalam', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  BG: { iso: 'BG', name: 'Bulgaria', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.EU_VAT] },
  BF: { iso: 'BF', name: 'Burkina Faso', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  BI: { iso: 'BI', name: 'Burundi', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  KH: { iso: 'KH', name: 'Cambodia', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  CM: { iso: 'CM', name: 'Cameroon', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  CA: {
    iso: 'CA',
    name: 'Canada',
    continent: continents.NORTH_AMERICA.name,
    aliases: [],
    vatIdTypes: [
      EVatIdTypes.CA_BN,
      EVatIdTypes.CA_GST_HST,
      EVatIdTypes.CA_PST_BC,
      EVatIdTypes.CA_PST_MB,
      EVatIdTypes.CA_PST_SK,
      EVatIdTypes.CA_QST
    ]
  },
  CV: { iso: 'CV', name: 'Cape Verde', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  KY: { iso: 'KY', name: 'Cayman Islands', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  CF: { iso: 'CF', name: 'Central African Republic', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  TD: { iso: 'TD', name: 'Chad', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  CL: { iso: 'CL', name: 'Chile', continent: continents.SOUTH_AMERICA.name, aliases: [], vatIdTypes: [EVatIdTypes.CL_TIN] },
  CN: { iso: 'CN', name: 'China', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  CX: { iso: 'CX', name: 'Christmas Island', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [] },
  CC: { iso: 'CC', name: 'Cocos (Keeling) Islands', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [] },
  CO: { iso: 'CO', name: 'Colombia', continent: continents.SOUTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  KM: { iso: 'KM', name: 'Comoros', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  CG: { iso: 'CG', name: 'Congo', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  CD: { iso: 'CD', name: 'Congo, Democratic Republic', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  CK: { iso: 'CK', name: 'Cook Islands', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [] },
  CR: { iso: 'CR', name: 'Costa Rica', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  CI: { iso: 'CI', name: "Cote D'Ivoire", continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  HR: { iso: 'HR', name: 'Croatia', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.EU_VAT] },
  CU: { iso: 'CU', name: 'Cuba', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  CY: { iso: 'CY', name: 'Cyprus', continent: continents.ASIA.name, aliases: [], vatIdTypes: [EVatIdTypes.EU_VAT] },
  CZ: { iso: 'CZ', name: 'Czech Republic', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.EU_VAT] },
  DK: { iso: 'DK', name: 'Denmark', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.EU_VAT] },
  DJ: { iso: 'DJ', name: 'Djibouti', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  DM: { iso: 'DM', name: 'Dominica', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  DO: { iso: 'DO', name: 'Dominican Republic', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  EC: { iso: 'EC', name: 'Ecuador', continent: continents.SOUTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  EG: { iso: 'EG', name: 'Egypt', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  SV: { iso: 'SV', name: 'El Salvador', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  GQ: { iso: 'GQ', name: 'Equatorial Guinea', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  ER: { iso: 'ER', name: 'Eritrea', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  EE: { iso: 'EE', name: 'Estonia', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.EU_VAT] },
  ET: { iso: 'ET', name: 'Ethiopia', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  FK: { iso: 'FK', name: 'Falkland Islands (Malvinas)', continent: continents.SOUTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  FO: { iso: 'FO', name: 'Faroe Islands', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [] },
  FJ: { iso: 'FJ', name: 'Fiji', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [] },
  FI: { iso: 'FI', name: 'Finland', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.EU_VAT] },
  FR: { iso: 'FR', name: 'France', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.EU_VAT] },
  GF: { iso: 'GF', name: 'French Guiana', continent: continents.SOUTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  PF: { iso: 'PF', name: 'French Polynesia', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [] },
  TF: { iso: 'TF', name: 'French Southern Territories', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  GA: { iso: 'GA', name: 'Gabon', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  GM: { iso: 'GM', name: 'Gambia', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  GE: { iso: 'GE', name: 'Georgia', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  DE: { iso: 'DE', name: 'Germany', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.EU_VAT] },
  GH: { iso: 'GH', name: 'Ghana', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  GI: { iso: 'GI', name: 'Gibraltar', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [] },
  GR: { iso: 'GR', name: 'Greece', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.EU_VAT] },
  GL: { iso: 'GL', name: 'Greenland', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  GD: { iso: 'GD', name: 'Grenada', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  GP: { iso: 'GP', name: 'Guadeloupe', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  GU: { iso: 'GU', name: 'Guam', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [] },
  GT: { iso: 'GT', name: 'Guatemala', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  GG: { iso: 'GG', name: 'Guernsey', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [] },
  GN: { iso: 'GN', name: 'Guinea', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  GW: { iso: 'GW', name: 'Guinea-Bissau', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  GY: { iso: 'GY', name: 'Guyana', continent: continents.SOUTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  HT: { iso: 'HT', name: 'Haiti', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  HM: { iso: 'HM', name: 'Heard Island & Mcdonald Islands', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [] },
  VA: { iso: 'VA', name: 'Holy See (Vatican City State)', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [] },
  HN: { iso: 'HN', name: 'Honduras', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  HK: { iso: 'HK', name: 'Hong Kong', continent: continents.ASIA.name, aliases: [], vatIdTypes: [EVatIdTypes.HK_BR] },
  HU: { iso: 'HU', name: 'Hungary', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.EU_VAT] },
  IS: { iso: 'IS', name: 'Iceland', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [] },
  IN: { iso: 'IN', name: 'India', continent: continents.ASIA.name, aliases: [], vatIdTypes: [EVatIdTypes.IN_GST] },
  ID: { iso: 'ID', name: 'Indonesia', continent: continents.ASIA.name, aliases: [], vatIdTypes: [EVatIdTypes.ID_NPWP] },
  IR: { iso: 'IR', name: 'Iran, Islamic Republic Of', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  IQ: { iso: 'IQ', name: 'Iraq', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  IE: { iso: 'IE', name: 'Ireland', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.EU_VAT] },
  IM: { iso: 'IM', name: 'Isle Of Man', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [] },
  IL: { iso: 'IL', name: 'Israel', continent: continents.ASIA.name, aliases: [], vatIdTypes: [EVatIdTypes.IL_VAT] },
  IT: { iso: 'IT', name: 'Italy', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.EU_VAT] },
  JM: { iso: 'JM', name: 'Jamaica', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  JP: { iso: 'JP', name: 'Japan', continent: continents.ASIA.name, aliases: [], vatIdTypes: [EVatIdTypes.JP_CN, EVatIdTypes.JP_RN] },
  JE: { iso: 'JE', name: 'Jersey', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [] },
  JO: { iso: 'JO', name: 'Jordan', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  KZ: { iso: 'KZ', name: 'Kazakhstan', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  KE: { iso: 'KE', name: 'Kenya', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  KI: { iso: 'KI', name: 'Kiribati', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [] },
  KR: { iso: 'KR', name: 'Korea', continent: continents.ASIA.name, aliases: [], vatIdTypes: [EVatIdTypes.KR_BRN] },
  KP: { iso: 'KP', name: 'North Korea', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  KW: { iso: 'KW', name: 'Kuwait', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  KG: { iso: 'KG', name: 'Kyrgyzstan', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  LA: { iso: 'LA', name: "Lao People's Democratic Republic", continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  LV: { iso: 'LV', name: 'Latvia', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.EU_VAT] },
  LB: { iso: 'LB', name: 'Lebanon', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  LS: { iso: 'LS', name: 'Lesotho', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  LR: { iso: 'LR', name: 'Liberia', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  LY: { iso: 'LY', name: 'Libyan Arab Jamahiriya', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  LI: { iso: 'LI', name: 'Liechtenstein', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.LI_UID] },
  LT: { iso: 'LT', name: 'Lithuania', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.EU_VAT] },
  LU: { iso: 'LU', name: 'Luxembourg', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.EU_VAT] },
  MO: { iso: 'MO', name: 'Macao', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  MK: { iso: 'MK', name: 'Macedonia', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [] },
  MG: { iso: 'MG', name: 'Madagascar', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  MW: { iso: 'MW', name: 'Malawi', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  MY: {
    iso: 'MY',
    name: 'Malaysia',
    continent: continents.ASIA.name,
    aliases: [],
    vatIdTypes: [EVatIdTypes.MY_FRP, EVatIdTypes.MY_ITN, EVatIdTypes.MY_SST]
  },
  MV: { iso: 'MV', name: 'Maldives', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  ML: { iso: 'ML', name: 'Mali', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  MT: { iso: 'MT', name: 'Malta', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.EU_VAT] },
  MH: { iso: 'MH', name: 'Marshall Islands', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [] },
  MQ: { iso: 'MQ', name: 'Martinique', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  MR: { iso: 'MR', name: 'Mauritania', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  MU: { iso: 'MU', name: 'Mauritius', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  YT: { iso: 'YT', name: 'Mayotte', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  MX: { iso: 'MX', name: 'Mexico', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [EVatIdTypes.MX_RFC] },
  FM: { iso: 'FM', name: 'Micronesia, Federated States Of', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [] },
  MD: { iso: 'MD', name: 'Moldova', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [] },
  MC: { iso: 'MC', name: 'Monaco', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [] },
  MN: { iso: 'MN', name: 'Mongolia', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  ME: { iso: 'ME', name: 'Montenegro', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [] },
  MS: { iso: 'MS', name: 'Montserrat', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  MA: { iso: 'MA', name: 'Morocco', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  MZ: { iso: 'MZ', name: 'Mozambique', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  MM: { iso: 'MM', name: 'Myanmar', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  NA: { iso: 'NA', name: 'Namibia', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  NR: { iso: 'NR', name: 'Nauru', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [] },
  NP: { iso: 'NP', name: 'Nepal', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  NL: { iso: 'NL', name: 'Netherlands', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.EU_VAT] },
  AN: { iso: 'AN', name: 'Netherlands Antilles', continent: continents.SOUTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  NC: { iso: 'NC', name: 'New Caledonia', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [] },
  NZ: { iso: 'NZ', name: 'New Zealand', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [EVatIdTypes.NZ_GST] },
  NI: { iso: 'NI', name: 'Nicaragua', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  NE: { iso: 'NE', name: 'Niger', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  NG: { iso: 'NG', name: 'Nigeria', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  NU: { iso: 'NU', name: 'Niue', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [] },
  NF: { iso: 'NF', name: 'Norfolk Island', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [] },
  MP: { iso: 'MP', name: 'Northern Mariana Islands', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [] },
  NO: { iso: 'NO', name: 'Norway', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.NO_VAT] },
  OM: { iso: 'OM', name: 'Oman', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  PK: { iso: 'PK', name: 'Pakistan', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  PW: { iso: 'PW', name: 'Palau', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [] },
  PS: { iso: 'PS', name: 'Palestinian Territory, Occupied', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  PA: { iso: 'PA', name: 'Panama', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  PG: { iso: 'PG', name: 'Papua New Guinea', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [] },
  PY: { iso: 'PY', name: 'Paraguay', continent: continents.SOUTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  PE: { iso: 'PE', name: 'Peru', continent: continents.SOUTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  PH: { iso: 'PH', name: 'Philippines', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  PN: { iso: 'PN', name: 'Pitcairn', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [] },
  PL: { iso: 'PL', name: 'Poland', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.EU_VAT] },
  PT: { iso: 'PT', name: 'Portugal', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.EU_VAT] },
  PR: { iso: 'PR', name: 'Puerto Rico', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  QA: { iso: 'QA', name: 'Qatar', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  RE: { iso: 'RE', name: 'Reunion', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  RO: { iso: 'RO', name: 'Romania', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.EU_VAT] },
  RU: {
    iso: 'RU',
    name: 'Russian Federation',
    continent: continents.EUROPE.name,
    aliases: [],
    vatIdTypes: [EVatIdTypes.RU_INN, EVatIdTypes.RU_KPP]
  },
  RW: { iso: 'RW', name: 'Rwanda', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  BL: { iso: 'BL', name: 'Saint Barthelemy', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  SH: { iso: 'SH', name: 'Saint Helena', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  KN: { iso: 'KN', name: 'Saint Kitts And Nevis', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  LC: { iso: 'LC', name: 'Saint Lucia', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  MF: { iso: 'MF', name: 'Saint Martin', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  PM: { iso: 'PM', name: 'Saint Pierre And Miquelon', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  VC: { iso: 'VC', name: 'Saint Vincent And Grenadines', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  WS: { iso: 'WS', name: 'Samoa', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [] },
  SM: { iso: 'SM', name: 'San Marino', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [] },
  ST: { iso: 'ST', name: 'Sao Tome And Principe', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  SA: { iso: 'SA', name: 'Saudi Arabia', continent: continents.ASIA.name, aliases: [], vatIdTypes: [EVatIdTypes.SA_VAT] },
  SN: { iso: 'SN', name: 'Senegal', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  RS: { iso: 'RS', name: 'Serbia', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [] },
  SC: { iso: 'SC', name: 'Seychelles', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  SL: { iso: 'SL', name: 'Sierra Leone', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  SG: {
    iso: 'SG',
    name: 'Singapore',
    continent: continents.ASIA.name,
    aliases: [],
    vatIdTypes: [EVatIdTypes.SG_GST, EVatIdTypes.SG_UEN]
  },
  SK: { iso: 'SK', name: 'Slovakia', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.EU_VAT] },
  SI: { iso: 'SI', name: 'Slovenia', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.EU_VAT] },
  SB: { iso: 'SB', name: 'Solomon Islands', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [] },
  SO: { iso: 'SO', name: 'Somalia', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  ZA: { iso: 'ZA', name: 'South Africa', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [EVatIdTypes.ZA_VAT] },
  GS: { iso: 'GS', name: 'South Georgia And Sandwich Isl.', continent: continents.SOUTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  ES: { iso: 'ES', name: 'Spain', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.ES_CIF, EVatIdTypes.EU_VAT] },
  LK: { iso: 'LK', name: 'Sri Lanka', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  SD: { iso: 'SD', name: 'Sudan', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  SR: { iso: 'SR', name: 'Suriname', continent: continents.SOUTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  SJ: { iso: 'SJ', name: 'Svalbard And Jan Mayen', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [] },
  SZ: { iso: 'SZ', name: 'Swaziland', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  SE: { iso: 'SE', name: 'Sweden', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [] },
  CH: { iso: 'CH', name: 'Switzerland', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [EVatIdTypes.CH_VAT] },
  SY: { iso: 'SY', name: 'Syrian Arab Republic', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  TW: { iso: 'TW', name: 'Taiwan', continent: continents.ASIA.name, aliases: [], vatIdTypes: [EVatIdTypes.TW_VAT] },
  TJ: { iso: 'TJ', name: 'Tajikistan', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  TZ: { iso: 'TZ', name: 'Tanzania', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  TH: { iso: 'TH', name: 'Thailand', continent: continents.ASIA.name, aliases: [], vatIdTypes: [EVatIdTypes.TH_VAT] },
  TL: { iso: 'TL', name: 'Timor-Leste', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  TG: { iso: 'TG', name: 'Togo', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  TK: { iso: 'TK', name: 'Tokelau', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [] },
  TO: { iso: 'TO', name: 'Tonga', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [] },
  TT: { iso: 'TT', name: 'Trinidad And Tobago', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  TN: { iso: 'TN', name: 'Tunisia', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  TR: { iso: 'TR', name: 'Turkey', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  TM: { iso: 'TM', name: 'Turkmenistan', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  TC: { iso: 'TC', name: 'Turks And Caicos Islands', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  TV: { iso: 'TV', name: 'Tuvalu', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [] },
  UG: { iso: 'UG', name: 'Uganda', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  UA: { iso: 'UA', name: 'Ukraine', continent: continents.EUROPE.name, aliases: [], vatIdTypes: [] },
  AE: { iso: 'AE', name: 'United Arab Emirates', continent: continents.ASIA.name, aliases: [], vatIdTypes: [EVatIdTypes.AE_TRN] },
  GB: {
    iso: 'GB',
    name: 'United Kingdom',
    continent: continents.EUROPE.name,
    aliases: [],
    vatIdTypes: [EVatIdTypes.GB_VAT, EVatIdTypes.EU_VAT]
  },
  US: {
    iso: 'US',
    name: 'United States',
    continent: continents.NORTH_AMERICA.name,
    aliases: ['US', 'USA'],
    vatIdTypes: [EVatIdTypes.US_EIN]
  },
  UM: { iso: 'UM', name: 'United States Outlying Islands', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [] },
  UY: { iso: 'UY', name: 'Uruguay', continent: continents.SOUTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  UZ: { iso: 'UZ', name: 'Uzbekistan', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  VU: { iso: 'VU', name: 'Vanuatu', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [] },
  VE: { iso: 'VE', name: 'Venezuela', continent: continents.SOUTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  VN: { iso: 'VN', name: 'Vietnam', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  VG: { iso: 'VG', name: 'Virgin Islands, British', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  VI: { iso: 'VI', name: 'Virgin Islands, U.S.', continent: continents.NORTH_AMERICA.name, aliases: [], vatIdTypes: [] },
  WF: { iso: 'WF', name: 'Wallis And Futuna', continent: continents.OCEANIA.name, aliases: [], vatIdTypes: [] },
  EH: { iso: 'EH', name: 'Western Sahara', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  YE: { iso: 'YE', name: 'Yemen', continent: continents.ASIA.name, aliases: [], vatIdTypes: [] },
  ZM: { iso: 'ZM', name: 'Zambia', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] },
  ZW: { iso: 'ZW', name: 'Zimbabwe', continent: continents.AFRICA.name, aliases: [], vatIdTypes: [] }
};

class Country {
  static list = countries;

  static fromName = (name) => {
    const iso = Object.values(this.list).find((country) => [country.iso, country.name, ...country.aliases].includes(name))?.iso;
    return iso ? new Country(iso) : null;
  };

  constructor(data) {
    this._selected = countries?.[data?.iso || data]; // data?.iso to support Country json object as input

    this.iso = this?._selected?.iso || null;
    this.name = this?._selected?.name || null;
  }

  get aliases() {
    return [...([this?.iso] || []), ...([this?.name] || []), ...(countries[this.iso]?.aliases || [])];
  }

  get vatIdTypes() {
    return countries[this.iso].vatIdTypes;
  }
}

export { Country };
