<template src="./template.vue" />

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import AuthLogin from '../auth-login/component.vue';
import { EAuthStrategies } from '../../../shared/enums.js';

const AUTH_API_PREFIX = '/api/auth';

const AUTH_STRATEGIES = [EAuthStrategies.GOOGLE, EAuthStrategies.TWITTER, EAuthStrategies.FACEBOOK];

const MODAL_ID = 'login-modal';

@Component({
  name: 'login-modal',
  components: { AuthLogin },
  data: () => ({ AUTH_API_PREFIX, AUTH_STRATEGIES, MODAL_ID }),
  props: {
    value: { type: Boolean, default: false }
  },
  watch: {
    value(value) {
      if (value) {
        this.$bvModal.show(MODAL_ID + this._uid);
      } else {
        this.$bvModal.hide(MODAL_ID + this._uid);
      }
    }
  }
})
class LoginModal extends Vue {
  handleHideModal() {
    this.$emit('input', false);
  }

  handleShowModal() {
    this.$emit('input', true);
  }

  mounted() {
    if (this.value) {
      this.$bvModal.show(MODAL_ID + this._uid);
    }
  }
}
export default LoginModal;
</script>
