<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import { EPlans, EPlanFeatures } from '../../../../shared/enums.js';
import { Currency } from '../../../../shared/models/index.js';
import { AuthenticateModal } from '../../../components/index.js';
import { config } from '../../../../shared/config.js';

const PLAN_LEVELS = {
  user: 0,
  legacy20220831: 1,
  packageTier1: 2,
  packageTier2: 3,
  packageTier3: 4
};

@Component({
  name: 'plans',
  data: () => ({
    EPlans,
    EPlanFeatures
  }),
  components: {
    AuthenticateModal
  }
})
class Plans extends Vue {
  subscriptions = null;

  currencyOptions = ['USD', 'EUR'];
  billingFrequencyOptions = ['monthly', 'yearly'];

  selectedCurrency = null;
  selectedBillingFrequency = null;

  upgradeTo = null;
  cancelFrom = null;

  isChangingPackage = false;

  currentSubscription = null;
  currentSubscriptionFrequency = null;
  customerCurrency = null;

  showAuthenticateModal = false;

  get PLAN_SETTINGS() {
    return {
      CURRENT: {
        id: 'CURRENT',
        buttonText: 'Current plan',
        planVariant: null,
        buttonDisabled: true,
        buttonHidden: false,
        buttonVariant: 'dark',
        faded: true,
        isPlanUpgrade: false
      },
      CANCEL: {
        id: 'CANCEL',
        buttonText: this.store.user.trialStatus.isInTrial ? 'Terminate trial' : 'Terminate plan',
        planVariant: null,
        buttonDisabled: false,
        buttonHidden: false,
        buttonVariant: 'link',
        buttonClass: 'text-danger text-underline',
        faded: true,
        isPlanUpgrade: false
      },
      UPGRADE: {
        id: 'UPGRADE',
        buttonText: 'Upgrade now',
        planVariant: 'warning',
        buttonDisabled: false,
        buttonHidden: false,
        buttonVariant: 'primary',
        faded: false,
        isPlanUpgrade: true
      },
      START: {
        id: 'START',
        buttonText: 'Start now',
        planVariant: 'warning',
        buttonDisabled: false,
        buttonHidden: false,
        buttonVariant: 'primary',
        faded: false,
        isPlanUpgrade: true
      },
      TRIAL: {
        id: 'TRIAL',
        buttonText: `Start ${config?.trial?.duration?.days}-day trial`,
        planVariant: 'warning',
        buttonDisabled: false,
        buttonHidden: false,
        buttonVariant: 'primary',
        faded: false,
        isPlanUpgrade: true
      },
      DEFAULT: {
        id: 'DEFAULT',
        buttonText: null,
        planVariant: null,
        buttonDisabled: true,
        buttonHidden: true,
        buttonVariant: 'dark',
        faded: true,
        isPlanUpgrade: false
      }
    };
  }

  get showUpgradeConfirmModal() {
    return !!this.upgradeTo;
  }

  get showCancelConfirmModal() {
    return !!this.cancelFrom;
  }

  planSettings(planKey) {
    const highestPlanLevel = this.store?.user?.roles
      ?.map((role) => PLAN_LEVELS[role.id])
      ?.sort()
      ?.filter((lvl) => lvl !== null && lvl !== undefined)
      ?.last();
    if (!this.store.user.isLoggedIn && PLAN_LEVELS[planKey] === PLAN_LEVELS.user) {
      return this.PLAN_SETTINGS.START;
    }
    if (PLAN_LEVELS.user === highestPlanLevel && PLAN_LEVELS.user === PLAN_LEVELS[planKey]) {
      return this.PLAN_SETTINGS.CURRENT;
    }
    if (PLAN_LEVELS[planKey] === highestPlanLevel) {
      return this.PLAN_SETTINGS.CANCEL;
    }
    if (PLAN_LEVELS.user === highestPlanLevel) {
      if (this.store.user.trialStatus.isAllowed) {
        return this.PLAN_SETTINGS.TRIAL;
      }
      return this.PLAN_SETTINGS.START;
    }
    if (!this.store?.user?.isLoggedIn || (!this.store?.user?.isPaidAccount && this.store?.user?.trialStatus?.isAllowed)) {
      return this.PLAN_SETTINGS.TRIAL;
    }

    if (PLAN_LEVELS[planKey] > highestPlanLevel) {
      return this.PLAN_SETTINGS.UPGRADE;
    }
    return this.PLAN_SETTINGS.DEFAULT;
  }

  handlePlanSelection(plan, replace = false) {
    const planSettings = this.planSettings(plan.key);

    if (!this.store?.user?._id) {
      this.showAuthenticateModal = true;
    } else if (planSettings.id === this.PLAN_SETTINGS.START.id) {
      this.store.checkoutSession = this.store.checkoutSession || {};
      this.store.checkoutSession.plan = plan;
      this.store.checkoutSession.planPrice =
        this.subscriptions[plan.key][this.selectedBillingFrequency.toLowerCase()][this.selectedCurrency.toLowerCase()];
      this.store.checkoutSession.currency = this.selectedCurrency;
      this.store.checkoutSession.billingFrequency = this.selectedBillingFrequency;
      if (replace) {
        this.$router.replace({ name: this.pageNames.stockInvesting.UPGRADE.BILLING_DETAILS });
      } else {
        this.$router.push({ name: this.pageNames.stockInvesting.UPGRADE.BILLING_DETAILS });
      }
    } else if (planSettings.id === this.PLAN_SETTINGS.TRIAL.id) {
      this.store.checkoutSession = this.store.checkoutSession || {};
      this.store.checkoutSession.plan = plan;
      this.store.checkoutSession.planPrice =
        this.subscriptions[plan.key][this.selectedBillingFrequency.toLowerCase()][this.selectedCurrency.toLowerCase()];
      this.store.checkoutSession.currency = this.selectedCurrency;
      this.store.checkoutSession.billingFrequency = this.selectedBillingFrequency;
      this.store.checkoutSession.isTrial = true;
      if (replace) {
        this.$router.push({ name: this.pageNames.stockInvesting.UPGRADE.BILLING_DETAILS });
      } else {
        this.$router.push({ name: this.pageNames.stockInvesting.UPGRADE.BILLING_DETAILS });
      }
    } else if (planSettings.id === this.PLAN_SETTINGS.CANCEL.id) {
      this.cancelFrom = plan;
    } else if (planSettings.id === this.PLAN_SETTINGS.UPGRADE.id) {
      this.upgradeTo = plan;
    }
  }

  async handleChangePlan() {
    this.isChangingPackage = true;
    try {
      const newPlanKey = this?.upgradeTo?.key;
      const stripePriceId = EPlans[newPlanKey]?.price?.[this.selectedBillingFrequency.toLowerCase()][this.selectedCurrency.toLowerCase()];
      await this.StockInvestingApi.paymentSubscriptionUpdate({ stripePriceId, upgrade: !!this?.upgradeTo });
      await this.$router.push({ name: this.pageNames.stockInvesting.UPGRADE.SUCCESS });
    } catch (err) {
      console.error(err);
      this.ToastError('Something went wrong. Please try again.');
    }
    this.isChangingPackage = false;
  }

  async handleCancelPlan() {
    this.isChangingPackage = true;
    try {
      await this.StockInvestingApi.paymentSubscriptionDelete();
      await this.$router.push({ name: this.pageNames.stockInvesting.UPGRADE.SUCCESS, query: { action: 'downgrade' } });
    } catch (err) {
      console.error(err);
      this.ToastError('Something went wrong. Please try again.');
    }
    this.isChangingPackage = false;
  }

  async cancelChange() {
    this.upgradeTo = null;
    this.cancelFrom = null;
  }

  async loadSubscriptions() {
    try {
      this.subscriptions = await this.StockInvestingApi.paymentSubscriptionList();
    } catch (err) {
      console.error(err);
    }
  }

  get selectedBillingFrequencyString() {
    if (this.selectedBillingFrequency === 'monthly') {
      return 'per month';
    }
    return 'per year';
  }

  getPricingString(planKey) {
    const price = this.subscriptions[planKey][this.selectedBillingFrequency.toLowerCase()][this.selectedCurrency.toLowerCase()];

    const currency = new Currency(this.selectedCurrency);
    const hasDecimalPlaces = !(price % 1 === 0);
    return `${currency.symbol()}${price.toLocaleString(navigator.languages, {
      minimumFractionDigits: hasDecimalPlaces ? 2 : 0,
      maximumFractionDigits: hasDecimalPlaces ? 2 : 0
    })}`;
  }

  async mounted() {
    this.setTitle('Plans - Upgrade');
    this.$gtag.event('upgrade_step1_pv');

    this.selectedCurrency = this.store?.checkoutSession?.currency || this.currencyOptions.first();
    this.selectedBillingFrequency = this.store?.checkoutSession?.billingFrequency || this.billingFrequencyOptions.first();

    await this.loadSubscriptions();

    if (EPlans?.[this.$route.query.plan]) {
      this.selectedBillingFrequency = 'monthly';
      this.handlePlanSelection(EPlans[this.$route.query.plan], true);
    }

    try {
      this.currentSubscription = await this.StockInvestingApi.paymentSubscriptionGet();
      switch (this.currentSubscription?.plan?.interval) {
        case 'month':
          this.currentSubscriptionFrequency = null;
          break;
        case 'year':
          this.currentSubscriptionFrequency = this.billingFrequencyOptions.last();
          break;
        default:
          this.currentSubscriptionFrequency = null;
      }
      this.selectedBillingFrequency = this.currentSubscriptionFrequency || this.selectedBillingFrequency;
    } catch (err) {
      console.error(err);
    }

    try {
      const customer = await this.StockInvestingApi.paymentCustomerDetails();
      this.customerCurrency = customer?.currency || null;
      this.selectedCurrency = this.customerCurrency?.toUpperCase() || this.selectedCurrency;
    } catch (err) {
      console.error(err);
    }
  }
}
export default Plans;
</script>
<style lang="scss" scoped>
@import './style.scss';
</style>
