<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import { CTable } from '../../../../components/index.js';

@Component({
  name: 'company-details-dividends',
  components: { CTable },
  props: {
    companyCode: {
      type: String,
      required: true
    },
    companyData: {
      type: Object,
      required: true
    }
  },
  data: () => ({})
})
class CompanyDetailsDividends extends Vue {
  loaded = false;
  mounted() {
    this.loaded = true;
  }
}
export default CompanyDetailsDividends;
</script>
