var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"table",class:[
    `c-table-${_vm._uid}`,
    'c-table',
    { 'c-table--sticky-header': _vm.stickyHeader },
    { 'c-table--sticky-column': _vm.stickyColumn },
    { 'c-table--striped': _vm.striped },
    { 'c-table--responsive': _vm.responsive },
    { 'c-table--hover': _vm.hover },
    { 'c-table--financial': _vm.variant === 'financial' }
  ]},[_c('table',{class:[{ 'table-sm': _vm.small }, { 'w-100': !_vm.autoWidth }, { 'w-auto': _vm.autoWidth }, 'table', _vm.tableClass]},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }