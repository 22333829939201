<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import PaymentMethodAdd from '../payment-method-add/component.vue';
import { Country } from '../../../shared/models/index.js';

@Component({
  name: 'payment-method-select',
  components: {
    PaymentMethodAdd
  },
  props: {
    value: {
      type: String,
      default: null
    },
    selectFirst: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    addingPaymentMethod: false,
    paymentMethods: null
  })
})
class PaymentMethodSelect extends Vue {
  getCountryName(countryIso) {
    const country = new Country(countryIso);
    return country.name.toLowerCase();
  }

  async loadPaymentMethods() {
    try {
      this.paymentMethods = await this.StockInvestingApi.paymentMethodCreditCardList();
      if (!this.value && this.selectFirst) {
        this.handlePaymentMethodSelect(this.paymentMethods.first().stripeId);
      }
    } catch (err) {
      console.error(err);
    }
  }

  handleAddPaymentMethodModalHide() {
    this.addingPaymentMethod = false;
    this.loadPaymentMethods();
  }

  handlePaymentMethodSelect(stripeId) {
    this.$emit('input', stripeId);
  }

  async mounted() {
    this.loadPaymentMethods();
  }
}
export default PaymentMethodSelect;
</script>
