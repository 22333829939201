import { StockInvestingApi } from '../../../../frontend/api/index.js';

import { EDbCollection } from '../../../enums.js';
import { Filter } from '../../../utils/index.js';
import { CompanyScreenerItem } from './CompanyScreenerItem.js';
import { CompanyScreenerFilter } from './CompanyScreenerFilter.js';
import { Validator } from '../../general/Validator.js';
import { CompanyScreenerColumn } from './CompanyScreenerColumn.js';

const stockInvestingApi = new StockInvestingApi();

const DEFAULT_SORT_KEY = 'marketCapUsd';

const SORT_DIRECTION = {
  ASC: 1,
  DESC: -1
};

const MAX_COMPANIES_EXPORT = 200;

/**
 * The Screener object is a shared class which is used both in the backend and frontend. It is used to transfer the
 * screener data (filters and settings) and contains the configuration of all the screener filters.
 *
 * @class CompanyScreener
 * @param {object} this The screener
 * @param {number} this.offset How many companies to skip in the database (used for paging)
 * @param {number} this.limit How many companies to retrieve from the database (used for paging)
 * @param {string[]} [this.sortColumn=marketCapUsd] Column key of which the data should be sorted
 * @param {number} [this.sortDirection=1] Column sort direction (ASC = 1, DESC = -1)
 * @param {object} this.attributes Whether or not the field is sortable (both frontend and supported in the backend)
 */
class CompanyScreener {
  static SORT_DIRECTION = SORT_DIRECTION;

  constructor({ offset = 0, limit = Infinity, sortColumn = DEFAULT_SORT_KEY, sortDirection = SORT_DIRECTION.DESC, filters }) {
    this.companies = null;

    this.offset = offset;
    this.limit = limit;
    this.sortColumn = sortColumn;
    this.sortDirection = sortDirection;

    this.totalRecords = null;

    this.companyLoadRequestCount = 0;

    this.attributes = {
      country: new CompanyScreenerItem({
        key: 'country',
        name: 'Country',
        category: CompanyScreenerItem.CATEGORY.GENERAL,
        column: new CompanyScreenerColumn({
          hideTableHeader: true,
          shown: true,
          sortable: false,
          dataClasses: ['country-column'],
          thClasses: ['text-center'],
          hrValue: (company) => ({
            flag: company?.country ? `/public/img/flags/${company?.country?.replace(/ /g, '-')?.toLowerCase()}.png` : null,
            country: company?.country || null
          }),
          exportValue: (company) => company?.country || null
        }),
        filter: new CompanyScreenerFilter({
          value: filters?.country?.filter?.value || filters?.country?.value || filters?.country,
          collection: EDbCollection.companyStockScreenerData03,
          inputType: CompanyScreenerFilter.INPUT_TYPES.STRING.MULTISELECT,
          validator: (value, description) => new Validator(value, description).isString().isUndefined().isNull(),
          options: {
            items: [],
            itemName: 'countries'
          }
        })
      }),
      companyName: new CompanyScreenerItem({
        key: 'companyName',
        name: 'Company',
        category: CompanyScreenerItem.CATEGORY.GENERAL,
        column: new CompanyScreenerColumn({
          keys: ['companyName', 'ticker'],
          sticky: true,
          shown: true,
          sortable: false,
          dataClasses: ['ticker-column', 'text-left'],
          thClasses: ['text-left'],
          hrValue: (company) => ({
            ticker: company?.ticker || null,
            companyName: company?.companyName || null
          }),
          exportValue: (company) => company?.companyName || null
        })
        // filter: new CompanyScreenerFilter({
        //   collection: EDbCollection.companyStockScreenerData03,
        //   value: filters?.companyName?.filter?.value || filters?.companyName?.value || filters?.companyName || '',
        //   inputType: CompanyScreenerFilter.INPUT_TYPES.STRING.TEXT_SEARCH,
        //   validator: (value, description) => new Validator(value, description).isString().maxLength(256).isUndefined().isNull(),
        //   options: {
        //     placeholder: 'Ticker or company name'
        //   }
        // })
      }),
      sectorIndustry: new CompanyScreenerItem({
        key: 'sectorIndustry',
        name: {
          default: 'Sector/industry',
          visualizer: 'Sector'
        },
        category: CompanyScreenerItem.CATEGORY.GENERAL,
        column: new CompanyScreenerColumn({
          keys: ['sector', 'industry'],
          shown: true,
          sortable: false,
          dataClasses: ['text-left'],
          thClasses: ['text-left'],
          hrValue: (company) => ({
            sector: company?.sector || null,
            industry: company?.industry || null
          }),
          hrVisualizerValue: (company) => company?.sector || null,
          exportValue: (company) => (company?.sector && company?.industry ? `${company?.sector} - ${company?.industry}` : null)
        }),
        filter: {
          sector: new CompanyScreenerFilter({
            key: 'sector',
            value: filters?.sectorIndustry?.filter?.sector?.value || filters?.sectorIndustry?.sector,
            collection: EDbCollection.companyStockScreenerData03,
            inputType: CompanyScreenerFilter.INPUT_TYPES.STRING.MULTISELECT,
            validator: (value, description) => new Validator(value, description).isString().isUndefined().isNull(),
            options: {
              items: [],
              itemName: 'sector(s)'
            }
          }),
          industry: new CompanyScreenerFilter({
            key: 'industry',
            value: filters?.sectorIndustry?.filter?.industry?.value || filters?.sectorIndustry?.industry,
            collection: EDbCollection.companyStockScreenerData03,
            inputType: CompanyScreenerFilter.INPUT_TYPES.STRING.MULTISELECT,
            validator: (value, description) => new Validator(value, description).isString().isUndefined().isNull(),
            options: {
              disabled: true,
              items: [],
              itemName: 'industries'
            }
          })
        }
      }),
      marketCapUsd: new CompanyScreenerItem({
        key: 'marketCapUsd',
        name: 'Market cap. (USD)',
        category: CompanyScreenerItem.CATEGORY.GENERAL,
        column: new CompanyScreenerColumn({
          shown: true,
          dataClasses: ['monospace'],
          hrValue: (company) => `$${Filter.hrFinancial(company.marketCapUsd, 3)}`,
          exportValue: (company) => company?.marketCapUsd || null
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value: filters?.marketCapUsd?.filter?.value || filters?.marketCapUsd?.value || filters?.marketCapUsd,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_OPTIONS,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            items: [
              { label: '<50M', value: null },
              { label: '50M', value: 50 * 1000 ** 2 },
              { label: '300M', value: 300 * 1000 ** 2 },
              { label: '2B', value: 2 * 1000 ** 3 },
              { label: '10B', value: 10 * 1000 ** 3 },
              { label: '200B', value: 200 * 1000 ** 3 },
              { label: '1T', value: 1000 ** 4 },
              { label: '>1T', value: null }
            ]
          }
        })
      }),
      sharePrice: new CompanyScreenerItem({
        key: 'sharePrice',
        name: 'Share price',
        category: CompanyScreenerItem.CATEGORY.SHARE,
        column: new CompanyScreenerColumn({
          shown: true,
          sortable: false,
          dataClasses: ['monospace'],
          keys: ['sharePrice', 'currencies'],
          hrValue: (company) => `${Filter.financial(company.sharePrice, 2, 2)} ${company?.currencies?.sharePrices || '⠀⠀⠀'}`
        })
      }),
      avgCashAndEquivalentsGrowth1Year: new CompanyScreenerItem({
        key: 'avgCashAndEquivalentsGrowth1Year',
        name: {
          default: 'Avg. cash & equivalents growth (1 year)',
          filterModal: '1 year',
          columnsModal: '1 year',
          visualizer: '1 year',
          table: 'Avg. CCE growth 1Y'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.CASH_AND_EQUIVALENTS_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value:
            filters?.avgCashAndEquivalentsGrowth1Year?.filter?.value ||
            filters?.avgCashAndEquivalentsGrowth1Year?.value ||
            filters?.avgCashAndEquivalentsGrowth1Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgCashAndEquivalentsGrowth3Year: new CompanyScreenerItem({
        key: 'avgCashAndEquivalentsGrowth3Year',
        name: {
          default: 'Avg. cash & equivalents growth (3 year)',
          filterModal: '3 year',
          columnsModal: '3 year',
          visualizer: '3 year',
          table: 'Avg. CCE growth 3Y'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.CASH_AND_EQUIVALENTS_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value:
            filters?.avgCashAndEquivalentsGrowth3Year?.filter?.value ||
            filters?.avgCashAndEquivalentsGrowth3Year?.value ||
            filters?.avgCashAndEquivalentsGrowth3Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgCashAndEquivalentsGrowth5Year: new CompanyScreenerItem({
        key: 'avgCashAndEquivalentsGrowth5Year',
        name: {
          default: 'Avg. cash & equivalents growth (5 year)',
          filterModal: '5 year',
          columnsModal: '5 year',
          visualizer: '5 year',
          table: 'Avg. CCE growth 5Y'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.CASH_AND_EQUIVALENTS_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value:
            filters?.avgCashAndEquivalentsGrowth5Year?.filter?.value ||
            filters?.avgCashAndEquivalentsGrowth5Year?.value ||
            filters?.avgCashAndEquivalentsGrowth5Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgCashAndEquivalentsGrowth10Year: new CompanyScreenerItem({
        key: 'avgCashAndEquivalentsGrowth10Year',
        name: {
          default: 'Avg. cash & equivalents growth (10 year)',
          filterModal: '10 year',
          columnsModal: '10 year',
          visualizer: '10 year',
          table: 'Avg. CCE growth 10Y'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.CASH_AND_EQUIVALENTS_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value:
            filters?.avgCashAndEquivalentsGrowth10Year?.filter?.value ||
            filters?.avgCashAndEquivalentsGrowth10Year?.value ||
            filters?.avgCashAndEquivalentsGrowth10Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgCashAndEquivalentsGrowthAllTime: new CompanyScreenerItem({
        key: 'avgCashAndEquivalentsGrowthAllTime',
        name: {
          default: 'Avg. cash & equivalents growth (all time)',
          filterModal: 'All time',
          columnsModal: 'All time',
          visualizer: 'All time',
          table: 'Avg. CCE growth'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.CASH_AND_EQUIVALENTS_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value:
            filters?.avgCashAndEquivalentsGrowthAllTime?.filter?.value ||
            filters?.avgCashAndEquivalentsGrowthAllTime?.value ||
            filters?.avgCashAndEquivalentsGrowthAllTime,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgEbitGrowth1Year: new CompanyScreenerItem({
        key: 'avgEbitGrowth1Year',
        name: {
          default: 'Avg. EBIT growth (1 year)',
          filterModal: '1 year',
          columnsModal: '1 year',
          visualizer: '1 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.EBIT_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value: filters?.avgEbitGrowth1Year?.filter?.value || filters?.avgEbitGrowth1Year?.value || filters?.avgEbitGrowth1Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgEbitGrowth3Year: new CompanyScreenerItem({
        key: 'avgEbitGrowth3Year',
        name: {
          default: 'Avg. EBIT growth (3 year)',
          filterModal: '3 year',
          columnsModal: '3 year',
          visualizer: '3 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.EBIT_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value: filters?.avgEbitGrowth3Year?.filter?.value || filters?.avgEbitGrowth3Year?.value || filters?.avgEbitGrowth3Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgEbitGrowth5Year: new CompanyScreenerItem({
        key: 'avgEbitGrowth5Year',
        name: {
          default: 'Avg. EBIT growth (5 year)',
          filterModal: '5 year',
          columnsModal: '5 year',
          visualizer: '5 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.EBIT_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value: filters?.avgEbitGrowth5Year?.filter?.value || filters?.avgEbitGrowth5Year?.value || filters?.avgEbitGrowth5Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgEbitGrowth10Year: new CompanyScreenerItem({
        key: 'avgEbitGrowth10Year',
        name: {
          default: 'Avg. EBIT growth (10 year)',
          filterModal: '10 year',
          columnsModal: '10 year',
          visualizer: '10 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.EBIT_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value: filters?.avgEbitGrowth10Year?.filter?.value || filters?.avgEbitGrowth10Year?.value || filters?.avgEbitGrowth10Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgEbitGrowthAllTime: new CompanyScreenerItem({
        key: 'avgEbitGrowthAllTime',
        name: {
          default: 'Avg. EBIT growth (all time)',
          filterModal: 'All time',
          columnsModal: 'All time',
          visualizer: 'All time'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.EBIT_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value: filters?.avgEbitGrowthAllTime?.filter?.value || filters?.avgEbitGrowthAllTime?.value || filters?.avgEbitGrowthAllTime,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgEbitdaGrowth1Year: new CompanyScreenerItem({
        key: 'avgEbitdaGrowth1Year',
        name: {
          default: 'Avg. EBITDA growth (1 year)',
          filterModal: '1 year',
          columnsModal: '1 year',
          visualizer: '1 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.EBITDA_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value: filters?.avgEbitdaGrowth1Year?.filter?.value || filters?.avgEbitdaGrowth1Year?.value || filters?.avgEbitdaGrowth1Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgEbitdaGrowth3Year: new CompanyScreenerItem({
        key: 'avgEbitdaGrowth3Year',
        name: {
          default: 'Avg. EBITDA growth (3 year)',
          filterModal: '3 year',
          columnsModal: '3 year',
          visualizer: '3 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.EBITDA_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value: filters?.avgEbitdaGrowth3Year?.filter?.value || filters?.avgEbitdaGrowth3Year?.value || filters?.avgEbitdaGrowth3Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgEbitdaGrowth5Year: new CompanyScreenerItem({
        key: 'avgEbitdaGrowth5Year',
        name: {
          default: 'Avg. EBITDA growth (5 year)',
          filterModal: '5 year',
          columnsModal: '5 year',
          visualizer: '5 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.EBITDA_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value: filters?.avgEbitdaGrowth5Year?.filter?.value || filters?.avgEbitdaGrowth5Year?.value || filters?.avgEbitdaGrowth5Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgEbitdaGrowth10Year: new CompanyScreenerItem({
        key: 'avgEbitdaGrowth10Year',
        name: {
          default: 'Avg. EBITDA growth (10 year)',
          filterModal: '10 year',
          columnsModal: '10 year',
          visualizer: '10 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.EBITDA_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value: filters?.avgEbitdaGrowth10Year?.filter?.value || filters?.avgEbitdaGrowth10Year?.value || filters?.avgEbitdaGrowth10Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgEbitdaGrowthAllTime: new CompanyScreenerItem({
        key: 'avgEbitdaGrowthAllTime',
        name: {
          default: 'Avg. EBITDA growth (all time)',
          filterModal: 'All time',
          columnsModal: 'All time',
          visualizer: 'All time'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.EBITDA_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value:
            filters?.avgEbitdaGrowthAllTime?.filter?.value || filters?.avgEbitdaGrowthAllTime?.value || filters?.avgEbitdaGrowthAllTime,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgEpsGrowth1Year: new CompanyScreenerItem({
        key: 'avgEpsGrowth1Year',
        name: {
          default: 'Avg. EPS growth (1 year)',
          filterModal: '1 year',
          columnsModal: '1 year',
          visualizer: '1 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.EPS_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value: filters?.avgEpsGrowth1Year?.filter?.value || filters?.avgEpsGrowth1Year?.value || filters?.avgEpsGrowth1Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgEpsGrowth3Year: new CompanyScreenerItem({
        key: 'avgEpsGrowth3Year',
        name: {
          default: 'Avg. EPS growth (3 year)',
          filterModal: '3 year',
          columnsModal: '3 year',
          visualizer: '3 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.EPS_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value: filters?.avgEpsGrowth3Year?.filter?.value || filters?.avgEpsGrowth3Year?.value || filters?.avgEpsGrowth3Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgEpsGrowth5Year: new CompanyScreenerItem({
        key: 'avgEpsGrowth5Year',
        name: {
          default: 'Avg. EPS growth (5 year)',
          filterModal: '5 year',
          columnsModal: '5 year',
          visualizer: '5 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.EPS_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value: filters?.avgEpsGrowth5Year?.filter?.value || filters?.avgEpsGrowth5Year?.value || filters?.avgEpsGrowth5Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgEpsGrowth10Year: new CompanyScreenerItem({
        key: 'avgEpsGrowth10Year',
        name: {
          default: 'Avg. EPS growth (10 year)',
          filterModal: '10 year',
          columnsModal: '10 year',
          visualizer: '10 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.EPS_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value: filters?.avgEpsGrowth10Year?.filter?.value || filters?.avgEpsGrowth10Year?.value || filters?.avgEpsGrowth10Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgEpsGrowthAllTime: new CompanyScreenerItem({
        key: 'avgEpsGrowthAllTime',
        name: {
          default: 'Avg. EPS growth (all time)',
          filterModal: 'All time',
          columnsModal: 'All time',
          visualizer: 'All time'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.EPS_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value: filters?.avgEpsGrowthAllTime?.filter?.value || filters?.avgEpsGrowthAllTime?.value || filters?.avgEpsGrowthAllTime,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgDividendYieldGrowth1Year: new CompanyScreenerItem({
        key: 'avgDividendYieldGrowth1Year',
        name: {
          default: 'Avg. dividend yield growth (1 year)',
          filterModal: '1 year',
          columnsModal: '1 year',
          visualizer: '1 year',
          table: 'Avg. div. yield growth 1Y'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.DIVIDEND_YIELD_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value:
            filters?.avgDividendYieldGrowth1Year?.filter?.value ||
            filters?.avgDividendYieldGrowth1Year?.value ||
            filters?.avgDividendYieldGrowth1Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgDividendYieldGrowth3Year: new CompanyScreenerItem({
        key: 'avgDividendYieldGrowth3Year',
        name: {
          default: 'Avg. dividend yield growth (3 year)',
          filterModal: '3 year',
          columnsModal: '3 year',
          visualizer: '3 year',
          table: 'Avg. div. yield growth 3Y'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.DIVIDEND_YIELD_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value:
            filters?.avgDividendYieldGrowth3Year?.filter?.value ||
            filters?.avgDividendYieldGrowth3Year?.value ||
            filters?.avgDividendYieldGrowth3Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgDividendYieldGrowth5Year: new CompanyScreenerItem({
        key: 'avgDividendYieldGrowth5Year',
        name: {
          default: 'Avg. dividend yield growth (5 year)',
          filterModal: '5 year',
          columnsModal: '5 year',
          visualizer: '5 year',
          table: 'Avg. div. yield growth 5Y'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.DIVIDEND_YIELD_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value:
            filters?.avgDividendYieldGrowth5Year?.filter?.value ||
            filters?.avgDividendYieldGrowth5Year?.value ||
            filters?.avgDividendYieldGrowth5Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgDividendYieldGrowth10Year: new CompanyScreenerItem({
        key: 'avgDividendYieldGrowth10Year',
        name: {
          default: 'Avg. dividend yield growth (10 year)',
          filterModal: '10 year',
          columnsModal: '10 year',
          visualizer: '10 year',
          table: 'Avg. div. yield growth 10Y'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.DIVIDEND_YIELD_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value:
            filters?.avgDividendYieldGrowth10Year?.filter?.value ||
            filters?.avgDividendYieldGrowth10Year?.value ||
            filters?.avgDividendYieldGrowth10Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgDividendYieldGrowthAllTime: new CompanyScreenerItem({
        key: 'avgDividendYieldGrowthAllTime',
        name: {
          default: 'Avg. dividend yield growth (all time)',
          filterModal: 'All time',
          columnsModal: 'All time',
          visualizer: 'All time',
          table: 'Avg. div. yield growth'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.DIVIDEND_YIELD_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value:
            filters?.avgDividendYieldGrowthAllTime?.filter?.value ||
            filters?.avgDividendYieldGrowthAllTime?.value ||
            filters?.avgDividendYieldGrowthAllTime,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgGrossProfitGrowth1Year: new CompanyScreenerItem({
        key: 'avgGrossProfitGrowth1Year',
        name: {
          default: 'Avg. gross profit growth (1 year)',
          filterModal: '1 year',
          columnsModal: '1 year',
          visualizer: '1 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.GROSS_PROFIT_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value:
            filters?.avgGrossProfitGrowth1Year?.filter?.value ||
            filters?.avgGrossProfitGrowth1Year?.value ||
            filters?.avgGrossProfitGrowth1Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgGrossProfitGrowth3Year: new CompanyScreenerItem({
        key: 'avgGrossProfitGrowth3Year',
        name: {
          default: 'Avg. gross profit growth (3 year)',
          filterModal: '3 year',
          columnsModal: '3 year',
          visualizer: '3 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.GROSS_PROFIT_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value:
            filters?.avgGrossProfitGrowth3Year?.filter?.value ||
            filters?.avgGrossProfitGrowth3Year?.value ||
            filters?.avgGrossProfitGrowth3Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgGrossProfitGrowth5Year: new CompanyScreenerItem({
        key: 'avgGrossProfitGrowth5Year',
        name: {
          default: 'Avg. gross profit growth (5 year)',
          filterModal: '5 year',
          columnsModal: '5 year',
          visualizer: '5 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.GROSS_PROFIT_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value:
            filters?.avgGrossProfitGrowth5Year?.filter?.value ||
            filters?.avgGrossProfitGrowth5Year?.value ||
            filters?.avgGrossProfitGrowth5Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgGrossProfitGrowth10Year: new CompanyScreenerItem({
        key: 'avgGrossProfitGrowth10Year',
        name: {
          default: 'Avg. gross profit growth (10 year)',
          filterModal: '10 year',
          columnsModal: '10 year',
          visualizer: '10 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.GROSS_PROFIT_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value:
            filters?.avgGrossProfitGrowth10Year?.filter?.value ||
            filters?.avgGrossProfitGrowth10Year?.value ||
            filters?.avgGrossProfitGrowth10Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgGrossProfitGrowthAllTime: new CompanyScreenerItem({
        key: 'avgGrossProfitGrowthAllTime',
        name: {
          default: 'Avg. gross profit growth (all time)',
          filterModal: 'All time',
          columnsModal: 'All time',
          visualizer: 'All time'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.GROSS_PROFIT_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value:
            filters?.avgGrossProfitGrowthAllTime?.filter?.value ||
            filters?.avgGrossProfitGrowthAllTime?.value ||
            filters?.avgGrossProfitGrowthAllTime,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgNetIncomeGrowth1Year: new CompanyScreenerItem({
        key: 'avgNetIncomeGrowth1Year',
        name: {
          default: 'Avg. net income growth (1 year)',
          filterModal: '1 year',
          columnsModal: '1 year',
          visualizer: '1 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.NET_INCOME_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value:
            filters?.avgNetIncomeGrowth1Year?.filter?.value || filters?.avgNetIncomeGrowth1Year?.value || filters?.avgNetIncomeGrowth1Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgNetIncomeGrowth3Year: new CompanyScreenerItem({
        key: 'avgNetIncomeGrowth3Year',
        name: {
          default: 'Avg. net income growth (3 year)',
          filterModal: '3 year',
          columnsModal: '3 year',
          visualizer: '3 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.NET_INCOME_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value:
            filters?.avgNetIncomeGrowth3Year?.filter?.value || filters?.avgNetIncomeGrowth3Year?.value || filters?.avgNetIncomeGrowth3Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgNetIncomeGrowth5Year: new CompanyScreenerItem({
        key: 'avgNetIncomeGrowth5Year',
        name: {
          default: 'Avg. net income growth (5 year)',
          filterModal: '5 year',
          columnsModal: '5 year',
          visualizer: '5 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.NET_INCOME_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value:
            filters?.avgNetIncomeGrowth5Year?.filter?.value || filters?.avgNetIncomeGrowth5Year?.value || filters?.avgNetIncomeGrowth5Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgNetIncomeGrowth10Year: new CompanyScreenerItem({
        key: 'avgNetIncomeGrowth10Year',
        name: {
          default: 'Avg. net income growth (10 year)',
          filterModal: '10 year',
          columnsModal: '10 year',
          visualizer: '10 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.NET_INCOME_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value:
            filters?.avgNetIncomeGrowth10Year?.filter?.value ||
            filters?.avgNetIncomeGrowth10Year?.value ||
            filters?.avgNetIncomeGrowth10Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgNetIncomeGrowthAllTime: new CompanyScreenerItem({
        key: 'avgNetIncomeGrowthAllTime',
        name: {
          default: 'Avg. net income growth (all time)',
          filterModal: 'All time',
          columnsModal: 'All time',
          visualizer: 'All time'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.NET_INCOME_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value:
            filters?.avgNetIncomeGrowthAllTime?.filter?.value ||
            filters?.avgNetIncomeGrowthAllTime?.value ||
            filters?.avgNetIncomeGrowthAllTime,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgRevenueGrowth1Year: new CompanyScreenerItem({
        key: 'avgRevenueGrowth1Year',
        name: {
          default: 'Avg. revenue growth (1 year)',
          filterModal: '1 year',
          columnsModal: '1 year',
          visualizer: '1 year',
          table: 'Avg. rev. growth 1Y'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.REVENUE_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value: filters?.avgRevenueGrowth1Year?.filter?.value || filters?.avgRevenueGrowth1Year?.value || filters?.avgRevenueGrowth1Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgRevenueGrowth3Year: new CompanyScreenerItem({
        key: 'avgRevenueGrowth3Year',
        name: {
          default: 'Avg. revenue growth (3 year)',
          filterModal: '3 year',
          columnsModal: '3 year',
          visualizer: '3 year',
          table: 'Avg. rev. growth 3Y'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.REVENUE_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value: filters?.avgRevenueGrowth3Year?.filter?.value || filters?.avgRevenueGrowth3Year?.value || filters?.avgRevenueGrowth3Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgRevenueGrowth5Year: new CompanyScreenerItem({
        key: 'avgRevenueGrowth5Year',
        name: {
          default: 'Avg. revenue growth (5 year)',
          filterModal: '5 year',
          columnsModal: '5 year',
          visualizer: '5 year',
          table: 'Avg. rev. growth 5Y'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.REVENUE_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value: filters?.avgRevenueGrowth5Year?.filter?.value || filters?.avgRevenueGrowth5Year?.value || filters?.avgRevenueGrowth5Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgRevenueGrowth10Year: new CompanyScreenerItem({
        key: 'avgRevenueGrowth10Year',
        name: {
          default: 'Avg. revenue growth (10 year)',
          filterModal: '10 year',
          columnsModal: '10 year',
          visualizer: '10 year',
          table: 'Avg. rev. growth 10Y'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.REVENUE_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value:
            filters?.avgRevenueGrowth10Year?.filter?.value || filters?.avgRevenueGrowth10Year?.value || filters?.avgRevenueGrowth10Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgRevenueGrowthAllTime: new CompanyScreenerItem({
        key: 'avgRevenueGrowthAllTime',
        name: {
          default: 'Avg. revenue growth (all time)',
          filterModal: 'All time',
          columnsModal: 'All time',
          visualizer: 'All time',
          table: 'Avg. rev. growth'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.REVENUE_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData01,
          value:
            filters?.avgRevenueGrowthAllTime?.filter?.value || filters?.avgRevenueGrowthAllTime?.value || filters?.avgRevenueGrowthAllTime,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgTotalAssetsGrowth1Year: new CompanyScreenerItem({
        key: 'avgTotalAssetsGrowth1Year',
        name: {
          default: 'Avg. total assets growth (1 year)',
          filterModal: '1 year',
          columnsModal: '1 year',
          visualizer: '1 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.TOTAL_ASSETS_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value:
            filters?.avgTotalAssetsGrowth1Year?.filter?.value ||
            filters?.avgTotalAssetsGrowth1Year?.value ||
            filters?.avgTotalAssetsGrowth1Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgTotalAssetsGrowth3Year: new CompanyScreenerItem({
        key: 'avgTotalAssetsGrowth3Year',
        name: {
          default: 'Avg. total assets growth (3 year)',
          filterModal: '3 year',
          columnsModal: '3 year',
          visualizer: '3 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.TOTAL_ASSETS_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value:
            filters?.avgTotalAssetsGrowth3Year?.filter?.value ||
            filters?.avgTotalAssetsGrowth3Year?.value ||
            filters?.avgTotalAssetsGrowth3Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgTotalAssetsGrowth5Year: new CompanyScreenerItem({
        key: 'avgTotalAssetsGrowth5Year',
        name: {
          default: 'Avg. total assets growth (5 year)',
          filterModal: '5 year',
          columnsModal: '5 year',
          visualizer: '5 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.TOTAL_ASSETS_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value:
            filters?.avgTotalAssetsGrowth5Year?.filter?.value ||
            filters?.avgTotalAssetsGrowth5Year?.value ||
            filters?.avgTotalAssetsGrowth5Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgTotalAssetsGrowth10Year: new CompanyScreenerItem({
        key: 'avgTotalAssetsGrowth10Year',
        name: {
          default: 'Avg. total assets growth (10 year)',
          filterModal: '10 year',
          columnsModal: '10 year',
          visualizer: '10 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.TOTAL_ASSETS_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value:
            filters?.avgTotalAssetsGrowth10Year?.filter?.value ||
            filters?.avgTotalAssetsGrowth10Year?.value ||
            filters?.avgTotalAssetsGrowth10Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgTotalAssetsGrowthAllTime: new CompanyScreenerItem({
        key: 'avgTotalAssetsGrowthAllTime',
        name: {
          default: 'Avg. total assets growth (all time)',
          filterModal: 'All time',
          columnsModal: 'All time',
          visualizer: 'All time'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.TOTAL_ASSETS_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value:
            filters?.avgTotalAssetsGrowthAllTime?.filter?.value ||
            filters?.avgTotalAssetsGrowthAllTime?.value ||
            filters?.avgTotalAssetsGrowthAllTime,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgTotalDebtGrowth1Year: new CompanyScreenerItem({
        key: 'avgTotalDebtGrowth1Year',
        name: {
          default: 'Avg. total debt growth (1 year)',
          filterModal: '1 year',
          columnsModal: '1 year',
          visualizer: '1 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.TOTAL_DEBT_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value:
            filters?.avgTotalDebtGrowth1Year?.filter?.value || filters?.avgTotalDebtGrowth1Year?.value || filters?.avgTotalDebtGrowth1Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgTotalDebtGrowth3Year: new CompanyScreenerItem({
        key: 'avgTotalDebtGrowth3Year',
        name: {
          default: 'Avg. total debt growth (3 year)',
          filterModal: '3 year',
          columnsModal: '3 year',
          visualizer: '3 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.TOTAL_DEBT_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value:
            filters?.avgTotalDebtGrowth3Year?.filter?.value || filters?.avgTotalDebtGrowth3Year?.value || filters?.avgTotalDebtGrowth3Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgTotalDebtGrowth5Year: new CompanyScreenerItem({
        key: 'avgTotalDebtGrowth5Year',
        name: {
          default: 'Avg. total debt growth (5 year)',
          filterModal: '5 year',
          columnsModal: '5 year',
          visualizer: '5 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.TOTAL_DEBT_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value:
            filters?.avgTotalDebtGrowth5Year?.filter?.value || filters?.avgTotalDebtGrowth5Year?.value || filters?.avgTotalDebtGrowth5Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgTotalDebtGrowth10Year: new CompanyScreenerItem({
        key: 'avgTotalDebtGrowth10Year',
        name: {
          default: 'Avg. total debt growth (10 year)',
          filterModal: '10 year',
          columnsModal: '10 year',
          visualizer: '10 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.TOTAL_DEBT_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value:
            filters?.avgTotalDebtGrowth10Year?.filter?.value ||
            filters?.avgTotalDebtGrowth10Year?.value ||
            filters?.avgTotalDebtGrowth10Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgTotalDebtGrowthAllTime: new CompanyScreenerItem({
        key: 'avgTotalDebtGrowthAllTime',
        name: {
          default: 'Avg. total debt growth (all time)',
          filterModal: 'All time',
          columnsModal: 'All time',
          visualizer: 'All time'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.TOTAL_DEBT_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value:
            filters?.avgTotalDebtGrowthAllTime?.filter?.value ||
            filters?.avgTotalDebtGrowthAllTime?.value ||
            filters?.avgTotalDebtGrowthAllTime,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgTotalEquityGrowth1Year: new CompanyScreenerItem({
        key: 'avgTotalEquityGrowth1Year',
        name: {
          default: 'Avg. total equity growth (1 year)',
          filterModal: '1 year',
          columnsModal: '1 year',
          visualizer: '1 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.TOTAL_EQUITY_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value:
            filters?.avgTotalEquityGrowth1Year?.filter?.value ||
            filters?.avgTotalEquityGrowth1Year?.value ||
            filters?.avgTotalEquityGrowth1Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgTotalEquityGrowth3Year: new CompanyScreenerItem({
        key: 'avgTotalEquityGrowth3Year',
        name: {
          default: 'Avg. total equity growth (3 year)',
          filterModal: '3 year',
          columnsModal: '3 year',
          visualizer: '3 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.TOTAL_EQUITY_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value:
            filters?.avgTotalEquityGrowth3Year?.filter?.value ||
            filters?.avgTotalEquityGrowth3Year?.value ||
            filters?.avgTotalEquityGrowth3Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgTotalEquityGrowth5Year: new CompanyScreenerItem({
        key: 'avgTotalEquityGrowth5Year',
        name: {
          default: 'Avg. total equity growth (5 year)',
          filterModal: '5 year',
          columnsModal: '5 year',
          visualizer: '5 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.TOTAL_EQUITY_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value:
            filters?.avgTotalEquityGrowth5Year?.filter?.value ||
            filters?.avgTotalEquityGrowth5Year?.value ||
            filters?.avgTotalEquityGrowth5Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgTotalEquityGrowth10Year: new CompanyScreenerItem({
        key: 'avgTotalEquityGrowth10Year',
        name: {
          default: 'Avg. total equity growth (10 year)',
          filterModal: '10 year',
          columnsModal: '10 year',
          visualizer: '10 year'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.TOTAL_EQUITY_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value:
            filters?.avgTotalEquityGrowth10Year?.filter?.value ||
            filters?.avgTotalEquityGrowth10Year?.value ||
            filters?.avgTotalEquityGrowth10Year,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      avgTotalEquityGrowthAllTime: new CompanyScreenerItem({
        key: 'avgTotalEquityGrowthAllTime',
        name: {
          default: 'Avg. total equity growth (all time)',
          filterModal: 'All time',
          columnsModal: 'All time',
          visualizer: 'All time'
        },
        category: CompanyScreenerItem.CATEGORY.GROWTH.sub.TOTAL_EQUITY_GROWTH,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value:
            filters?.avgTotalEquityGrowthAllTime?.filter?.value ||
            filters?.avgTotalEquityGrowthAllTime?.value ||
            filters?.avgTotalEquityGrowthAllTime,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 100,
            rangeStepSize: 1
          }
        })
      }),
      dividendPayoutFrequency: new CompanyScreenerItem({
        key: 'dividendPayoutFrequency',
        name: {
          default: 'Dividend payout frequency',
          filterModal: 'Payout frequency',
          columnsModal: 'Payout frequency',
          table: 'Div. payout freq.'
        },
        category: CompanyScreenerItem.CATEGORY.DIVIDEND,
        column: new CompanyScreenerColumn({
          sortable: false,
          valueFilter: [Filter.number, 0, 0]
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value:
            filters?.dividendPayoutFrequency?.filter?.value || filters?.dividendPayoutFrequency?.value || filters?.dividendPayoutFrequency,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().min(0).max(4).isUndefined().isNull(),
          options: {
            rangeMin: 0,
            rangeMax: 4,
            rangeStepSize: 1
          }
        })
      }),
      dividendPayoutRatio: new CompanyScreenerItem({
        key: 'dividendPayoutRatio',
        name: {
          default: 'Dividend payout ratio %',
          filterModal: 'Payout ratio %',
          columnsModal: 'Payout ratio %'
        },
        category: CompanyScreenerItem.CATEGORY.DIVIDEND,
        column: new CompanyScreenerColumn({
          sortable: false,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value: filters?.dividendPayoutRatio?.filter?.value || filters?.dividendPayoutRatio?.value || filters?.dividendPayoutRatio,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().min(0).isUndefined().isNull(),
          options: {
            rangeMin: 0,
            rangeMax: 30,
            rangeStepSize: 0.1
          }
        })
      }),
      dividendYield: new CompanyScreenerItem({
        key: 'dividendYield',
        name: {
          default: 'Dividend yield %',
          table: 'Div. yield %'
        },
        category: CompanyScreenerItem.CATEGORY.DIVIDEND,
        column: new CompanyScreenerColumn({
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value: filters?.dividendYield?.filter?.value || filters?.dividendYield?.value || filters?.dividendYield,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().min(0).isUndefined().isNull(),
          options: {
            rangeMin: 0,
            rangeMax: 15,
            rangeStepSize: 0.1
          }
        })
      }),
      sharePrice52WeekChangePct: new CompanyScreenerItem({
        key: 'sharePrice52WeekChangePct',
        name: {
          default: 'Share price 52 week change %',
          filterModal: '52 week price change %',
          columnsModal: '52 week price change %',
          table: 'Share price 52wk change %'
        },
        category: CompanyScreenerItem.CATEGORY.SHARE,
        column: new CompanyScreenerColumn({
          colored: true,
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value:
            filters?.sharePrice52WeekChangePct?.filter?.value ||
            filters?.sharePrice52WeekChangePct?.value ||
            filters?.sharePrice52WeekChangePct,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: 0,
            rangeMax: 15,
            rangeStepSize: 0.1
          }
        })
      }),
      shareVolume52WeekAvg: new CompanyScreenerItem({
        key: 'shareVolume52WeekAvg',
        name: {
          default: 'Share volume 52 week (avg)',
          filterModal: 'Avg. 52wk volume change',
          columnsModal: 'Avg. 52wk volume change',
          table: 'Avg. 52wk vol. change'
        },
        category: CompanyScreenerItem.CATEGORY.SHARE,
        column: new CompanyScreenerColumn({
          dataClasses: ['monospace'],
          valueFilter: Filter.number
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value: filters?.shareVolume52WeekAvg?.filter?.value || filters?.shareVolume52WeekAvg?.value || filters?.shareVolume52WeekAvg,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: 0,
            rangeMax: 1000 ** 3,
            rangeStepSize: 10000000
          }
        })
      }),
      sharePrice52WeekHighPct: new CompanyScreenerItem({
        key: 'sharePrice52WeekHighPct',
        name: {
          default: 'Share price 52 week % from high',
          filterModal: '52wk price % from high',
          columnsModal: '52wk price % from high',
          table: '52wk price % from high'
        },
        category: CompanyScreenerItem.CATEGORY.SHARE,
        column: new CompanyScreenerColumn({
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData03,
          value:
            filters?.sharePrice52WeekHighPct?.filter?.value || filters?.sharePrice52WeekHighPct?.value || filters?.sharePrice52WeekHighPct,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -100,
            rangeMax: 0,
            rangeStepSize: 0.5
          }
        })
      }),
      sharePrice52WeekLowPct: new CompanyScreenerItem({
        key: 'sharePrice52WeekLowPct',
        name: {
          default: 'Share price 52 week % from low',
          filterModal: '52wk price % from low',
          columnsModal: '52wk price % from low',
          table: '52wk price % from low'
        },
        category: CompanyScreenerItem.CATEGORY.SHARE,
        column: new CompanyScreenerColumn({
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData03,
          value:
            filters?.sharePrice52WeekLowPct?.filter?.value || filters?.sharePrice52WeekLowPct?.value || filters?.sharePrice52WeekLowPct,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: 0,
            rangeMax: 200,
            rangeStepSize: 0.5
          }
        })
      }),
      priceOverSales: new CompanyScreenerItem({
        key: 'priceOverSales',
        name: {
          default: 'P/S',
          long: 'Price/sales',
          visualizer: 'P/S - Price/sales'
        },
        category: CompanyScreenerItem.CATEGORY.RATIOS,
        column: new CompanyScreenerColumn({
          dataClasses: ['monospace'],
          valueFilter: Filter.ratio
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData03,
          value: filters?.priceOverSales?.filter?.value || filters?.priceOverSales?.value || filters?.priceOverSales,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -10,
            rangeMax: 20,
            rangeStepSize: 0.1
          }
        })
      }),
      priceOverBook: new CompanyScreenerItem({
        key: 'priceOverBook',
        name: {
          default: 'P/B',
          long: 'Price/book',
          visualizer: 'P/B - Price/book'
        },
        category: CompanyScreenerItem.CATEGORY.RATIOS,
        column: new CompanyScreenerColumn({
          dataClasses: ['monospace'],
          valueFilter: Filter.ratio
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData03,
          value: filters?.priceOverBook?.filter?.value || filters?.priceOverBook?.value || filters?.priceOverBook,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: -10,
            rangeMax: 20,
            rangeStepSize: 0.5
          }
        })
      }),
      priceOverEarnings: new CompanyScreenerItem({
        key: 'priceOverEarnings',
        name: {
          default: 'P/E',
          long: 'Price/earnings',
          visualizer: 'P/E - Price/earnings'
        },
        category: CompanyScreenerItem.CATEGORY.RATIOS,
        column: new CompanyScreenerColumn({
          dataClasses: ['monospace'],
          valueFilter: Filter.ratio
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData03,
          value: filters?.priceOverEarnings?.filter?.value || filters?.priceOverEarnings?.value || filters?.priceOverEarnings,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: 0,
            rangeMax: 30,
            rangeStepSize: 1
          }
        })
      }),
      priceOverCashFlow: new CompanyScreenerItem({
        key: 'priceOverCashFlow',
        name: {
          default: 'P/CF',
          long: 'Price/Cash flow',
          visualizer: 'P/CF - Price/Cash flow'
        },
        category: CompanyScreenerItem.CATEGORY.RATIOS,
        column: new CompanyScreenerColumn({
          dataClasses: ['monospace'],
          valueFilter: Filter.ratio
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData03,
          value: filters?.priceOverCashFlow?.filter?.value || filters?.priceOverCashFlow?.value || filters?.priceOverCashFlow,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: 0,
            rangeMax: 40,
            rangeStepSize: 1
          }
        })
      }),
      peg: new CompanyScreenerItem({
        key: 'peg',
        name: {
          default: 'P/E/G',
          long: 'Price/earnings/growth',
          visualizer: 'P/E/G - Price/earnings/growth'
        },
        category: CompanyScreenerItem.CATEGORY.RATIOS,
        column: new CompanyScreenerColumn({
          dataClasses: ['monospace'],
          valueFilter: Filter.ratio
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData03,
          value: filters?.peg?.filter?.value || filters?.peg?.value || filters?.peg,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: 0,
            rangeMax: 5,
            rangeStepSize: 0.25
          }
        })
      }),
      enterpriseValueOverEbit: new CompanyScreenerItem({
        key: 'enterpriseValueOverEbit',
        name: {
          default: 'EV/EBIT',
          long: 'Enterprise value/EBIT',
          visualizer: 'EV/EBIT - Enterprise value/EBIT'
        },
        category: CompanyScreenerItem.CATEGORY.RATIOS,
        column: new CompanyScreenerColumn({
          dataClasses: ['monospace'],
          valueFilter: Filter.ratio
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData03,
          value:
            filters?.enterpriseValueOverEbit?.filter?.value || filters?.enterpriseValueOverEbit?.value || filters?.enterpriseValueOverEbit,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: 0,
            rangeMax: 80,
            rangeStepSize: 1
          }
        })
      }),
      enterpriseValueOverEbitda: new CompanyScreenerItem({
        key: 'enterpriseValueOverEbitda',
        name: {
          default: 'EV/EBITDA',
          long: 'Enterprise value/EBITDA',
          visualizer: 'EV/EBITDA - Enterprise value/EBITDA'
        },
        category: CompanyScreenerItem.CATEGORY.RATIOS,
        column: new CompanyScreenerColumn({
          dataClasses: ['monospace'],
          valueFilter: Filter.ratio
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData03,
          value:
            filters?.enterpriseValueOverEbitda?.filter?.value ||
            filters?.enterpriseValueOverEbitda?.value ||
            filters?.enterpriseValueOverEbitda,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: 0,
            rangeMax: 60,
            rangeStepSize: 1
          }
        })
      }),
      enterpriseValueOverSales: new CompanyScreenerItem({
        key: 'enterpriseValueOverSales',
        name: {
          default: 'EV/S',
          long: 'Enterprise value/sales',
          visualizer: 'EV/S - Enterprise value/sales'
        },
        category: CompanyScreenerItem.CATEGORY.RATIOS,
        column: new CompanyScreenerColumn({
          dataClasses: ['monospace'],
          valueFilter: Filter.ratio
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData03,
          value:
            filters?.enterpriseValueOverSales?.filter?.value ||
            filters?.enterpriseValueOverSales?.value ||
            filters?.enterpriseValueOverSales,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: 0,
            rangeMax: 25,
            rangeStepSize: 0.25
          }
        })
      }),
      returnOnEquityBeforeTax: new CompanyScreenerItem({
        key: 'returnOnEquityBeforeTax',
        name: {
          default: 'ROE',
          long: 'Return on equity (before tax)',
          visualizer: 'ROE - Return on equity before tax'
        },
        category: CompanyScreenerItem.CATEGORY.RATIOS,
        column: new CompanyScreenerColumn({
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData03,
          value:
            filters?.returnOnEquityBeforeTax?.filter?.value || filters?.returnOnEquityBeforeTax?.value || filters?.returnOnEquityBeforeTax,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: 0,
            rangeMax: 25,
            rangeStepSize: 0.25
          }
        })
      }),
      returnOnAssetsBeforeTax: new CompanyScreenerItem({
        key: 'returnOnAssetsBeforeTax',
        name: {
          default: 'ROA',
          long: 'Return on assets (before tax)',
          visualizer: 'ROA - Return on assets (before tax)'
        },
        category: CompanyScreenerItem.CATEGORY.RATIOS,
        column: new CompanyScreenerColumn({
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData03,
          value:
            filters?.returnOnAssetsBeforeTax?.filter?.value || filters?.returnOnAssetsBeforeTax?.value || filters?.returnOnAssetsBeforeTax,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: 0,
            rangeMax: 14,
            rangeStepSize: 0.25
          }
        })
      }),
      grossProfitMargin: new CompanyScreenerItem({
        key: 'grossProfitMargin',
        name: {
          default: 'Gross profit margin'
        },
        category: CompanyScreenerItem.CATEGORY.RATIOS,
        column: new CompanyScreenerColumn({
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData03,
          value: filters?.grossProfitMargin?.filter?.value || filters?.grossProfitMargin?.value || filters?.grossProfitMargin,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: 0,
            rangeMax: 120,
            rangeStepSize: 1
          }
        })
      }),
      ebitdaMargin: new CompanyScreenerItem({
        key: 'ebitdaMargin',
        name: {
          default: 'EBITDA margin'
        },
        category: CompanyScreenerItem.CATEGORY.RATIOS,
        column: new CompanyScreenerColumn({
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData03,
          value: filters?.ebitdaMargin?.filter?.value || filters?.ebitdaMargin?.value || filters?.ebitdaMargin,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: 0,
            rangeMax: 150,
            rangeStepSize: 0.5
          }
        })
      }),
      ebitMargin: new CompanyScreenerItem({
        key: 'ebitMargin',
        name: {
          default: 'EBIT margin'
        },
        category: CompanyScreenerItem.CATEGORY.RATIOS,
        column: new CompanyScreenerColumn({
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData03,
          value: filters?.ebitMargin?.filter?.value || filters?.ebitMargin?.value || filters?.ebitMargin,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: 0,
            rangeMax: 75,
            rangeStepSize: 0.5
          }
        })
      }),
      netIncomeMargin: new CompanyScreenerItem({
        key: 'netIncomeMargin',
        name: {
          default: 'Net income margin'
        },
        category: CompanyScreenerItem.CATEGORY.RATIOS,
        column: new CompanyScreenerColumn({
          dataClasses: ['monospace'],
          valueFilter: Filter.percentage
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData03,
          value: filters?.netIncomeMargin?.filter?.value || filters?.netIncomeMargin?.value || filters?.netIncomeMargin,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: 0,
            rangeMax: 30,
            rangeStepSize: 0.25
          }
        })
      }),
      rating: new CompanyScreenerItem({
        key: 'rating',
        name: {
          default: 'Rating'
        },
        category: CompanyScreenerItem.CATEGORY.GENERAL,
        column: new CompanyScreenerColumn({
          keys: ['rating', 'ratingDetails'],
          shown: true,
          hrValue: (company) => ({
            score: company?.rating,
            details: company?.ratingDetails
          })
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData02,
          value: filters?.rating?.filter?.value || filters?.rating?.value || filters?.rating,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isString(),
          options: {
            rangeMin: 0,
            rangeMax: 5,
            rangeStepSize: 0.5
          }
        })
      }),
      fundamentlValuation: new CompanyScreenerItem({
        key: 'fundamentlValuation',
        name: {
          default: 'Fundamentl Valuation'
        },
        category: CompanyScreenerItem.CATEGORY.VALUATION,
        column: new CompanyScreenerColumn({
          dataClasses: ['monospace'],
          sortable: false,
          shown: true,
          keys: ['fundamentlValuation', 'fundamentlValuations', 'currencies'],
          hrValue: (company) => ({
            fundamentlValuation: `${Filter.financial(company?.fundamentlValuation)} ${company?.currencies?.sharePrices || '⠀⠀⠀'}`,
            fundamentlValuations: {
              netIncome: `${Filter.hrFinancial(company?.fundamentlValuations?.netIncome?.precision(3))} ${
                company?.currencies?.sharePrices || ''
              }`,
              revenue: `${Filter.hrFinancial(company?.fundamentlValuations?.revenue?.precision(3))} ${
                company?.currencies?.sharePrices || ''
              }`,
              cashFlow: `${Filter.hrFinancial(company?.fundamentlValuations?.cashFlow?.precision(3))} ${
                company?.currencies?.sharePrices || ''
              }`,
              bookValue: `${Filter.hrFinancial(company?.fundamentlValuations?.bookValue?.precision(3))} ${
                company?.currencies?.sharePrices || ''
              }`
            }
          }),
          exportValue: (company) => company?.fundamentlValuation || null
        })
      }),
      currentRatio: new CompanyScreenerItem({
        key: 'currentRatio',
        name: {
          default: 'Current ratio'
        },
        category: CompanyScreenerItem.CATEGORY.RATIOS,
        column: new CompanyScreenerColumn({
          dataClasses: ['monospace'],
          valueFilter: Filter.ratio
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData03,
          value: filters?.currentRatio?.filter?.value || filters?.currentRatio?.value || filters?.currentRatio,
          inputType: CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE,
          validator: (value, description) => new Validator(value, description).isNumber().isUndefined().isNull(),
          options: {
            rangeMin: 0,
            rangeMax: 9,
            rangeStepSize: 0.1
          }
        })
      }),
      exchange: new CompanyScreenerItem({
        key: 'exchange',
        name: {
          default: 'Exchange'
        },
        category: CompanyScreenerItem.CATEGORY.GENERAL,
        column: new CompanyScreenerColumn({
          dataClasses: ['monospace']
        }),
        filter: new CompanyScreenerFilter({
          collection: EDbCollection.companyStockScreenerData03,
          value: filters?.exchange?.filter?.value || filters?.exchange?.value || filters?.exchange,
          inputType: CompanyScreenerFilter.INPUT_TYPES.STRING.MULTISELECT,
          validator: (value, description) => new Validator(value, description).isString(),
          options: {
            items: [],
            itemName: 'exchanges'
          }
        })
      })
      // currencies: new CompanyScreenerItem({
      //   key: 'currencies',
      //   collection: EDbCollection.companyStockScreenerData03,
      //   sortable: false,
      //   isInput: false,
      //   isOutput: true
      // })
    };
  }

  get isValid() {
    return !this?.errors?.length;
  }

  get errors() {
    let errors = [
      ...new Validator(this.offset, 'Offset').isNumber().isNull().isUndefined().errors,
      ...new Validator(this.limit, 'Limit').isNumber().isNull().isUndefined().errors,
      ...new Validator(this.sortColumn, 'Sort column').isString().isNull().isUndefined().isEmpty().errors,
      ...new Validator(this.sortDirection, 'Sort direction').isNumber().isNull().isUndefined().errors
    ];

    // Validate filters
    const filters = Object.keys(this.attributes)
      .map((key) => {
        if (this.attributes?.[key]?.filter) {
          if (this.attributes?.[key]?.filter?.key) {
            return [this.attributes?.[key]?.filter];
          }
          return Object.values(this.attributes?.[key]?.filter);
        }
        return [];
      })
      .reduce((a, b) => a.concat(b));
    for (let i = 0; i < filters.length; i++) {
      const filterErrors = filters?.[i]?.errors;
      errors = errors.concat(filterErrors);
    }

    return errors;
  }

  get columns() {
    const columns = {};
    Object.values(this.attributes)
      .filter((attribute) => attribute?.column)
      .forEach((attribute) => {
        columns[attribute.key] = attribute.column;
      });
    return columns;
  }

  get filters() {
    const filters = {};
    Object.values(this.attributes)
      .filter((attribute) => attribute?.filter)
      .forEach((attribute) => {
        if (attribute?.filter?.key) {
          filters[attribute.key] = attribute.filter;
        } else {
          Object.keys(attribute.filter).forEach((subFilterKey) => {
            filters[subFilterKey] = attribute.filter[subFilterKey];
          });
        }
      });
    return filters;
  }

  get sortDesc() {
    return this.sortDirection === SORT_DIRECTION.DESC;
  }

  get sortAsc() {
    return this.sortDirection !== SORT_DIRECTION.DESC;
  }

  resetFilters() {
    Object.values(this.filters).forEach((filter) => {
      filter.reset();
    });
  }

  exportData(keys = null) {
    const data = [];
    this.companies.slice(0, MAX_COMPANIES_EXPORT).forEach((company) => {
      const record = {};
      (keys || Object.keys(this.companies?.first() || {})).forEach((key) => {
        const attribute = this.attributes[key];
        record[attribute.name?.default || attribute?.name] = attribute.column.exportValue(company);
      });
      data.push(record);
    });
    return data;
  }

  async loadCompanies(options) {
    this.companyLoadRequestCount++;
    const loadId = Number(this.companyLoadRequestCount);

    const opts = {
      totalRecords: options?.totalRecords || false
    };

    const attributes = {};
    Object.keys(this.attributes).forEach((key) => {
      if (this.attributes?.[key]?.filter?.key) {
        if (this.attributes[key].filter?.value) {
          attributes[key] = {
            filter: {
              // Test value.value because multiselects have `{ label, value }` format
              value: null
            }
          };

          if (this.attributes[key].filter.inputType === CompanyScreenerFilter.INPUT_TYPES.STRING.MULTISELECT) {
            attributes[key].filter.value = this.attributes[key].filter?.value?.map((item) => item?.value);
            if (!this.attributes[key].filter?.value?.length) delete attributes[key];
          } else {
            attributes[key].filter.value = this.attributes[key].filter?.value;
          }
        }
      } else if (this.attributes?.[key]?.filter) {
        const filterKeys = Object.keys(this.attributes?.[key]?.filter);
        attributes[key] = { filter: {} };
        filterKeys.forEach((filterKey) => {
          if (this.attributes?.[key]?.filter?.[filterKey]?.value) {
            // Test value.value because multiselects have `{ label, value }` format
            if (this.attributes[key].filter[filterKey]?.inputType === CompanyScreenerFilter.INPUT_TYPES.STRING.MULTISELECT) {
              attributes[key].filter[filterKey] = {
                value: this.attributes[key].filter[filterKey]?.value?.length
                  ? this.attributes[key].filter[filterKey]?.value?.map((item) => item?.value || item)
                  : null
              };
            } else {
              attributes[key].filter[filterKey] = { value: this.attributes[key].filter[filterKey]?.value };
            }
          }
          if (!attributes?.[key]?.filter?.[filterKey]?.value) {
            delete attributes?.[key]?.filter?.[filterKey];
          }
        });
        if (!Object.keys(attributes[key].filter).length) {
          delete attributes[key];
        }
      }
    });

    if (this.offset === 0 && !opts.totalRecords) {
      this.companies = [];
    }

    let response = [];
    try {
      response = await stockInvestingApi.companiesList({
        limit: this.limit,
        offset: this.offset,
        filters: attributes,
        sortColumn: this.sortColumn,
        sortDirection: this.sortDirection,
        project: Object.values(this.attributes)
          .filter((attribute) => attribute?.column?.shown)
          .map((attribute) => attribute.column.keys)
          .reduce((a, b) => [...a, ...b])
          .distinct(),
        totalRecords: opts?.totalRecords
      });
    } catch (err) {
      console.error(err);
      return false;
    }

    if (loadId === this.companyLoadRequestCount) {
      if (opts.totalRecords) {
        this.totalRecords = response?.totalRecords || 0;
      } else {
        this.companies = (this.companies || []).concat(response?.companies || []);
      }
    }
    return true;
  }
}

export { CompanyScreener };
