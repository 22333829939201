import CompanyDetails from './page.vue';

import CompanyDetailsCompetitors from './competitors/component.vue';

import {
  CompanyDetailsBalanceSheet,
  CompanyDetailsCashFlowStatement,
  CompanyDetailsIncomeStatement,
  CompanyDetailsRatios
} from './financials/index.js';

import {
  CompanyDetailsAnalystRatings,
  CompanyDetailsGeneralData,
  CompanyDetailsDividends,
  CompanyDetailsInsiderTransactions,
  CompanyDetailsEsg,
  CompanyDetailsManagement,
  CompanyDetailsOwnership
} from './general/index.js';

import { CompanyDetailsShareChart, CompanyDetailsShareSummary } from './share-data/index.js';

import {
  CompanyDetailsDcf,
  CompanyDetailsHistoricValuation,
  CompanyDetailsFundamentlValuation,
  CompanyDetailsValuationSummary
} from './valuation/index.js';

export default {
  CompanyDetails,
  CompanyDetailsCompetitors,
  CompanyDetailsBalanceSheet,
  CompanyDetailsCashFlowStatement,
  CompanyDetailsIncomeStatement,
  CompanyDetailsRatios,
  CompanyDetailsAnalystRatings,
  CompanyDetailsGeneralData,
  CompanyDetailsDividends,
  CompanyDetailsInsiderTransactions,
  CompanyDetailsEsg,
  CompanyDetailsManagement,
  CompanyDetailsOwnership,
  CompanyDetailsShareChart,
  CompanyDetailsShareSummary,
  CompanyDetailsDcf,
  CompanyDetailsHistoricValuation,
  CompanyDetailsFundamentlValuation,
  CompanyDetailsValuationSummary
};
