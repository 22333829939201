import { CompanyRatioStatement } from './CompanyRatioStatement.js';
import { ERatios } from '../../../enums.js';
import { ERoles } from '../../user/index.js';

const ROLE_SPECIFIC_EXPORT_LIMITS = {
  default: {
    years: 10,
    quarters: 8
  }
};

class CompanyRatios {
  constructor({ yearly = [], quarterly = [] }) {
    this.yearly = yearly.map((ratioStatement) => new CompanyRatioStatement(ratioStatement));
    this.quarterly = quarterly.map((ratioStatement) => new CompanyRatioStatement(ratioStatement));
  }

  export(periodKey = 'yearly', maxStatements, user) {
    return this._export(periodKey, ERatios, maxStatements, user);
  }

  _export(periodKey, statementEnum, maxStatements = null, user) {
    if (periodKey !== 'yearly' && periodKey !== 'quarterly') {
      periodKey = 'yearly';
    }

    let maxExportYears = 0;
    let maxExportQuarters = 0;
    Object.keys(ROLE_SPECIFIC_EXPORT_LIMITS).forEach((roleId) => {
      const role = ERoles?.[roleId];
      if (role && user && user.hasRole(role)) {
        if (maxExportYears < ROLE_SPECIFIC_EXPORT_LIMITS[roleId].years) {
          maxExportYears = ROLE_SPECIFIC_EXPORT_LIMITS[roleId].years;
        }
        if (maxExportQuarters < ROLE_SPECIFIC_EXPORT_LIMITS[roleId].quarters) {
          maxExportQuarters = ROLE_SPECIFIC_EXPORT_LIMITS[roleId].quarters;
        }
      }
    });
    maxExportYears = maxExportYears || ROLE_SPECIFIC_EXPORT_LIMITS.default.years;
    maxExportQuarters = maxExportQuarters || ROLE_SPECIFIC_EXPORT_LIMITS.default.quarters;

    if (periodKey === 'yearly' && (!maxStatements || maxStatements > maxExportYears)) maxStatements = maxExportYears;
    if (periodKey === 'quarterly' && (!maxStatements || maxStatements > maxExportQuarters)) maxStatements = maxExportQuarters;

    const data = [];
    const dates = this[periodKey]
      .sort((a, b) => a.date - b.date)
      .map((bs) => new Date(bs.date))
      .slice(-maxStatements);

    const keys = Object.keys(statementEnum);
    keys.forEach((key) => {
      const periodData = {};
      periodData['0'] = statementEnum?.[key] || key;
      dates.forEach((date) => {
        const year = new Date(date).getFullYear();
        const month = (new Date(date).getMonth() + 1).toString();
        const periodString = `${year}-${month.length === 1 ? `0${month}` : month}`;
        const periodStatement = this[periodKey].find((statement) => new Date(statement.date).getTime() === date.getTime());
        periodData[periodString] = (periodStatement?.[key]?.iso ? periodStatement?.[key]?.iso() : null) || periodStatement?.[key];
      });
      data.push(periodData);
    });

    return data;
  }
}

export { CompanyRatios };
