<template src="./template.vue" />

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Company } from '../../../shared/models/company/Company.js';

const COMPANY_SEARCH_POPOVER_ID = 'company-search-popover';
const USER_SEARCH_DEBOUNCE = 500;
const MINIMUM_CHARACTERS_INPUT = 2;

@Component({
  name: 'company-search',
  data: () => ({
    COMPANY_SEARCH_POPOVER_ID
  }),
  props: {
    showSharePrice: {
      type: Boolean,
      default: false
    }
  }
})
class CompanySearch extends Vue {
  query = '';
  isSearchingCompanies = false;
  searchQueryInputTimeout = null;
  foundCompanies = [];
  dirty = false;

  handleCompanySearchInput(input) {
    this.dirty = true;
    clearTimeout(this.searchQueryInputTimeout);

    if (input.length > MINIMUM_CHARACTERS_INPUT) {
      this.searchQueryInputTimeout = setTimeout(async () => {
        this.isSearchingCompanies = true;
        try {
          this.$gtag.event('search', {
            search_term: input
          });
          const companies = await this.StockInvestingApi.companySearch({
            query: input,
            sharePriceLive: this.showSharePrice
          });
          this.foundCompanies = companies.map((company) => new Company(company));
        } catch (err) {
          console.error(err);
          this.foundCompanies = [];
        }
        this.isSearchingCompanies = false;
      }, USER_SEARCH_DEBOUNCE);
    }

    setTimeout(() => {
      if (input === '') {
        this.foundCompanies = [];
        return;
      }

      this.$root.$emit('bv::show::popover', this.COMPANY_SEARCH_POPOVER_ID + this._uid);
    }, USER_SEARCH_DEBOUNCE);
  }

  handleCompanyClick(event, company) {
    if (this.$listeners.click) {
      event.preventDefault();
      this.$emit('click', company);
    }
    this.$refs.popover.$emit('close');
    this.query = '';
    this.foundCompanies = [];
  }

  focus() {
    this.$refs.input.focus();
  }

  mounted() {}
}
export default CompanySearch;
</script>
