import { CompanyDataTagLocationDetails } from './CompanyDataTagLocationDetails.js';

class CompanyDataTag {
  constructor({ _id = null, userId, companyCode, comment = '', dataType, dataLocationDetails }) {
    this._id = _id;
    this.userId = userId;
    this.companyCode = companyCode;
    this.comment = comment;
    this.dataType = dataType;
    this.dataLocationDetails = new CompanyDataTagLocationDetails(dataLocationDetails || {});
  }
}

export { CompanyDataTag };
