class EExchangeHolidayType {
  static official = 'official';
  static bank = 'bank';
  static observance = 'observance';
  static other = 'other';
}

class ExchangeHoliday {
  constructor({ name = null, date = null, type = null }) {
    this.name = name;
    this.date = date;
    this.type = type;
  }
}

export { ExchangeHoliday, EExchangeHolidayType };
