class CompanyFundamentlValuation {
  constructor({ date = null, average = null, bookValue = null, cashFlow = null, netIncome = null, revenue = null, version = null }) {
    this.date = date;
    this.average = average;
    this.bookValue = bookValue;
    this.cashFlow = cashFlow;
    this.netIncome = netIncome;
    this.revenue = revenue;
    this.version = version;
  }
}

export { CompanyFundamentlValuation };
