import { Currency } from '../currency/Currency.js';

class CompanyCurrencies {
  constructor({ financialData = null, sharePrices = null }) {
    this.financialData = new Currency(financialData);
    this.sharePrices = new Currency(sharePrices);
  }
}

export { CompanyCurrencies };
