<template src="./template.vue" />

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import AuthLogin from '../auth-login/component.vue';

const AUTH_API_PREFIX = '/api/auth';

const MODAL_ID = 'upgrade-cta-modal';

@Component({
  name: 'upgrade-cta-modal',
  components: { AuthLogin },
  data: () => ({ AUTH_API_PREFIX, MODAL_ID }),
  props: {
    value: { type: Boolean, default: false },
    plan: { type: Object, required: true }
  },
  watch: {
    value(value) {
      if (value) {
        this.$bvModal.show(MODAL_ID + this._uid);
      } else {
        this.$bvModal.hide(MODAL_ID + this._uid);
      }
    }
  }
})
class UpgradeCtaModal extends Vue {
  legalCheckbox = false;

  handleHideModal() {
    this.$emit('input', false);
  }

  handleShowModal() {
    this.$emit('input', true);
  }

  mounted() {
    if (this.value) {
      this.$bvModal.show(MODAL_ID + this._uid);
    }
  }
}
export default UpgradeCtaModal;
</script>
