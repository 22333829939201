import { Currency } from '../currency/index.js';
import { ETransactionType, PortfolioTransactionCash, PortfolioTransactionShares } from './PortfolioTransaction.js';
import { PortfolioState } from './PortfolioState.js';
import { StockInvestingApi } from '../../../frontend/api/index.js';

const stockInvestingApi = new StockInvestingApi();

class Portfolio {
  constructor({ _id = null, name = 'Unnamed portfolio', currency = 'USD', transactions = [] }) {
    this._id = _id;
    this.name = name;
    this.currency = new Currency(currency);
    this.transactions = transactions
      .map((transaction) => {
        switch (transaction?.type) {
          case ETransactionType.CASH:
            return new PortfolioTransactionCash(transaction);
          case ETransactionType.SHARES:
            return new PortfolioTransactionShares(transaction);
          default:
            return null;
        }
      })
      .filter((transaction) => transaction);
    this.state = new PortfolioState({ portfolioCurrency: this.currency, transactions: this.transactions });
  }

  get currencyRaw() {
    return this.currency.iso();
  }

  set currencyRaw(currency) {
    this.currency = new Currency(currency);
  }

  async create() {
    const portfolio = await stockInvestingApi.portfoliosCreate({ name: this.name, currency: this.currencyRaw });
    this._id = portfolio._id;
  }

  async save() {
    await stockInvestingApi.portfoliosUpdate({ _id: this._id, name: this.name });
  }

  async delete() {
    await stockInvestingApi.portfoliosDelete({ _id: this._id });
  }

  async addTransaction(transaction) {
    await transaction.save(this);
    this.transactions.push(transaction);
    this.state.refresh();
  }

  async removeTransaction(transaction) {
    await transaction.delete(this);
    const transactionIndex = this.transactions.findIndex((tx) => tx._id === transaction._id);
    this.transactions.splice(transactionIndex, 1);
    this.state.refresh();
  }
}

export { Portfolio };
