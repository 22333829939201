import { ETime } from './enums.js';

export const config = {
  trial: {
    // The length of a trial period
    duration: {
      days: 30,
      milliseconds: ETime.milliseconds.perDay * 30
    },
    // When the trial period refreshes
    refreshTime: ETime.milliseconds.perMonth * 3
  }
};
