<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import { Chart } from 'highcharts-vue';

import { ERoles } from '../../../shared/models/user/index.js';

const CHART_COMPANY_NAMES = ['Apple', 'Amazon', 'China Mobile', 'Tencent', 'Google', 'Samsung', 'Tesla', 'NVIDIA', 'Walmart', 'LVMH'];

@Component({
  name: 'features',
  data: () => ({ ERoles }),
  components: { Chart }
})
class Features extends Vue {
  slideshow1 = {
    slides: 7,
    slide: 1,
    interval: 3000,
    setInterval: null,
    enlarged: false
  };

  slideshow2 = {
    slides: 4,
    slide: 1,
    interval: 3000,
    setInterval: null,
    enlarged: false
  };

  slideshow3 = {
    slides: 5,
    slide: 1,
    interval: 3000,
    setInterval: null,
    enlarged: false
  };

  chartOptions = {
    chart: {
      type: 'bar'
    },
    stockTools: null,
    title: {
      text: this.title
    },
    rangeSelector: {
      enabled: false
    },
    xAxis: {
      type: 'category',
      label: 'Years of financial statements',
      name: 'Years of financial statements',
      title: 'Years of financial statements',
      labels: {
        angle: 30,
        step: 1,
        overflow: 'justify',
        formatter() {
          return CHART_COMPANY_NAMES[this.pos];
        }
      }
    },
    series: [
      {
        pointWidth: 20,
        showInLegend: false,
        name: 'Years of financial statements',
        data: [
          { y: 37, name: 'Apple', color: '#16E3CE' },
          { y: 26, name: 'Amazon', color: '#2072FF' },
          { y: 25, name: 'China Mobile', color: '#F33A59' },
          { y: 19, name: 'Tencent', color: '#D1D9FD' },
          { y: 22, name: 'Google', color: '#3308AE' },
          { y: 22, name: 'Samsung', color: '#C7038C' },
          { y: 15, name: 'Tesla', color: '#01B5C2' },
          { y: 24, name: 'NVIDIA', color: '#00B0FF' },
          { y: 27, name: 'Walmart', color: '#9D189A' },
          { y: 21, name: 'LVMH', color: '#D1ABEE' }
        ]
      }
    ]
  };

  scrollUp() {
    document.querySelector('html').scrollTop = 0;
  }

  startSlideshow(slideshow) {
    if (!slideshow.setInterval) {
      slideshow.setInterval = setInterval(() => {
        if (slideshow.slide === slideshow.slides) {
          slideshow.slide = 1;
        } else {
          slideshow.slide++;
        }
      }, slideshow.interval);
    }
  }

  slideLeft(slideshow) {
    clearInterval(slideshow.setInterval);
    if (slideshow.slide === 1) {
      slideshow.slide = slideshow.slides;
    } else {
      slideshow.slide--;
    }
  }

  slideRight(slideshow) {
    clearInterval(slideshow.setInterval);
    if (slideshow.slide === slideshow.slides) {
      slideshow.slide = 1;
    } else {
      slideshow.slide++;
    }
  }

  handleSlideshowDotClick(slideshow, i) {
    clearInterval(slideshow.setInterval);
    slideshow.slide = i;
  }

  mounted() {
    this.setTitle('Fundamentl - Features overview', false);

    this.startSlideshow(this.slideshow1);
  }
}
export default Features;
</script>
<style lang="scss" scoped>
@import './style.scss';
</style>
