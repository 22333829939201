class CompanyInsiderTransaction {
  constructor({
    date = null,
    reportDate = null,
    ownerCik = null,
    ownerName = null,
    ownerTitle = null,
    transactionDate = null,
    transactionDirection = null,
    transactionAmount = null,
    transactionPostAmount = null,
    transactionPrice = null,
    transactionAcquiredDisposed = null,
    secLink = null
  }) {
    this.date = new Date(date);
    this.reportDate = new Date(reportDate);
    this.ownerCik = ownerCik;
    this.ownerName = ownerName;
    this.ownerTitle = ownerTitle;
    this.transactionDate = new Date(transactionDate);
    this.transactionDirection = transactionDirection;
    this.transactionAmount = transactionAmount;
    this.transactionPostAmount = transactionPostAmount;
    this.transactionPrice = transactionPrice;
    this.transactionValue = transactionPrice * transactionAmount;
    this.transactionAcquiredDisposed = transactionAcquiredDisposed;
    this.secLink = secLink;
  }

  get name() {
    return this.ownerName.replace(/ [A-Z]{1}[.]{0,1} /, ' ');
  }
}

export { CompanyInsiderTransaction };
