<template src="./template.vue" />

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import AuthLogin from '../auth-login/component.vue';
import { EAuthStrategies } from '../../../shared/enums.js';

import SignUpModal from '../sign-up-modal/component.vue';
import CompanySearch from '../company-search/component.vue';
import { ERoles } from '../../../shared/models/user/index.js';

const COOKIE_DOMAIN_SEPARATOR = '.';
const COOKIE_DOMAIN_PORT_SEPARATOR = ':';
const COOKIE_DOMAIN_LAST_PARTS_AMOUNT = 2;

const AUTH_API_PREFIX = '/api/auth';

const AUTH_STRATEGIES = [EAuthStrategies.GOOGLE, EAuthStrategies.TWITTER, EAuthStrategies.FACEBOOK];

@Component({
  name: 'topbar',
  components: { AuthLogin, SignUpModal, CompanySearch },
  data: () => ({ AUTH_API_PREFIX, AUTH_STRATEGIES, ERoles })
})
class Topbar extends Vue {
  signUpModalVisible = false;

  async logout() {
    this.$gtag.event('auth_logout');
    const [cookieDomain] = window.location.host
      .split(COOKIE_DOMAIN_SEPARATOR)
      .slice(-COOKIE_DOMAIN_LAST_PARTS_AMOUNT)
      .join(COOKIE_DOMAIN_SEPARATOR)
      .split(COOKIE_DOMAIN_PORT_SEPARATOR);

    this.$cookie.delete(process.env.JWT_TOKEN_NAME, { domain: cookieDomain });
    this.$router.go();
  }

  toggleAdminMode() {
    this.store.adminMode = !this?.store?.adminMode;
  }

  get profilePictureUrl() {
    return this.store?.user?.personal?.photoUrl || null;
  }

  get name() {
    return this.store?.user?.personal?.displayName || null;
  }

  get inFocusMode() {
    return this.$route.meta.mode === 'focus';
  }

  get isAdministrator() {
    return this?.store?.user?.roles?.includes('administrator');
  }

  get isBetaTester() {
    return this?.store?.user?.roles?.includes('beta-tester');
  }

  get isDataManager() {
    return this?.store?.user?.roles?.includes('data-manager');
  }
}
export default Topbar;
</script>
