import validator from 'validator';

const VALIDATOR_ERROR_MSG = {
  IS_REQUIRED: '{0} is required.',
  IS_STRING: '{0} is not a string.',
  IS_NUMBER: '{0} is not a number.',
  IS_BOOLEAN: '{0} is not a boolean.',
  IS_OBJECT: '{0} is not an object.',
  IS_DATE: '{0} is not a date.',
  IS_UUID: '{0} does not have a UUID format.',
  IS_BASE64: '{0} does not have a Base64 format.',
  IS_URL: '{0} does not have a URL format.',
  IS_MONGO_ID: '{0} is not a Mongo ID.',
  IS_ARRAY: '{0} is not an array.',
  IS_IN: '{1} is not in {0}, but is expected.',
  IS_LOCALE: '{0} is not a locale.',
  MIN_LENGTH: '{0} should have at least {1} character(s).',
  MAX_LENGTH: '{0} may only have a maximum of {1} character(s).',
  MIN_ARRAY_LENGTH: '{0} should have at least {1} array item(s).',
  MAX_ARRAY_LENGTH: '{0} may only have a maximum of {1} array item(s).',
  MIN: '{0} should be greater than {1}.',
  MAX: '{0} should be less than {1}.'
};

class Validator {
  constructor(value, description) {
    this.description = description;
    this.value = value;
    this.nullable = false;
    this.undefinable = false;
    this.allowEmpty = false;
    this.valid = true;
    this.errors = [];
  }

  isValid() {
    return (
      (this.value === null && this.nullable) ||
      (this.value === undefined && this.undefinable) ||
      (this.value === '' && this.allowEmpty) ||
      this.valid
    );
  }

  isEmpty() {
    this.allowEmpty = true;
    return this;
  }

  isNull() {
    this.nullable = true;
    return this;
  }

  isUndefined() {
    this.undefinable = true;
    return this;
  }

  isRequired() {
    try {
      if (!this.value) throw Error();
    } catch (err) {
      this.valid = false;
      this.errors.push(VALIDATOR_ERROR_MSG.IS_REQUIRED.format(this.description));
    }
    return this;
  }

  isString() {
    try {
      if (typeof this.value !== typeof String()) throw Error();
    } catch (err) {
      this.valid = false;
      this.errors.push(VALIDATOR_ERROR_MSG.IS_STRING.format(this.description));
    }
    return this;
  }

  isNumber() {
    try {
      if (typeof this.value !== typeof Number() || isNaN(this.value)) throw Error();
    } catch (err) {
      this.valid = false;
      this.errors.push(VALIDATOR_ERROR_MSG.IS_NUMBER.format(this.description));
    }
    return this;
  }

  isBoolean() {
    try {
      if (typeof this.value !== typeof Boolean()) throw Error();
    } catch (err) {
      this.valid = false;
      this.errors.push(VALIDATOR_ERROR_MSG.IS_BOOLEAN.format(this.description));
    }
    return this;
  }

  isObject() {
    try {
      if (typeof this.value !== typeof Object()) throw Error();
    } catch (err) {
      this.valid = false;
      this.errors.push(VALIDATOR_ERROR_MSG.IS_OBJECT.format(this.description));
    }
    return this;
  }

  isDate() {
    try {
      if (!validator.isDate(this.value)) throw Error();
    } catch (err) {
      this.valid = false;
      this.errors.push(VALIDATOR_ERROR_MSG.IS_DATE.format(this.description));
    }
    return this;
  }

  isUUID() {
    try {
      if (!validator.isUUID(this.value)) throw Error();
    } catch (err) {
      this.valid = false;
      this.errors.push(VALIDATOR_ERROR_MSG.IS_UUID.format(this.description));
    }
    return this;
  }

  isBase64() {
    try {
      if (!validator.isBase64(this.value)) throw Error();
    } catch (err) {
      this.valid = false;
      this.errors.push(VALIDATOR_ERROR_MSG.IS_BASE64.format(this.description));
    }
    return this;
  }

  isURL() {
    try {
      if (!validator.isURL(this.value)) throw Error();
    } catch (err) {
      this.valid = false;
      this.errors.push(VALIDATOR_ERROR_MSG.IS_URL.format(this.description));
    }
    return this;
  }

  isMongoId() {
    try {
      if (!validator.isMongoId(this.value)) throw Error();
    } catch (err) {
      this.valid = false;
      this.errors.push(VALIDATOR_ERROR_MSG.IS_MONGO_ID.format(this.description));
    }
    return this;
  }

  isArray() {
    try {
      if (!Array.isArray(this.value)) throw Error();
    } catch (err) {
      this.valid = false;
      this.errors.push(VALIDATOR_ERROR_MSG.IS_ARRAY.format(this.description));
    }
    return this;
  }

  isIn(values) {
    try {
      if (!validator.isIn(this.value, values)) throw Error();
    } catch (err) {
      this.valid = false;
      this.errors.push(VALIDATOR_ERROR_MSG.IS_IN.format(this.description, this.value));
    }
    return this;
  }

  isLocale() {
    try {
      if (!validator.isLocale(this.value)) throw Error();
    } catch (err) {
      this.valid = false;
      this.errors.push(VALIDATOR_ERROR_MSG.IS_LOCALE.format(this.description));
    }
    return this;
  }

  minLength(length) {
    try {
      if (this.value.length < length) throw Error();
    } catch (err) {
      this.valid = false;
      this.errors.push(VALIDATOR_ERROR_MSG.MIN_LENGTH.format(this.description, length));
    }
    return this;
  }

  maxLength(length) {
    try {
      if (this.value.length > length) throw Error();
    } catch (err) {
      this.valid = false;
      this.errors.push(VALIDATOR_ERROR_MSG.MAX_LENGTH.format(this.description, length));
    }
    return this;
  }

  minArrayLength(length) {
    try {
      if (this.value.length < length) throw Error();
    } catch (err) {
      this.valid = false;
      this.errors.push(VALIDATOR_ERROR_MSG.MIN_ARRAY_LENGTH.format(this.description, length));
    }
    return this;
  }

  maxArrayLength(length) {
    try {
      if (this.value.length > length) throw Error();
    } catch (err) {
      this.valid = false;
      this.errors.push(VALIDATOR_ERROR_MSG.MAX_ARRAY_LENGTH.format(this.description, length));
    }
    return this;
  }

  min(minimum) {
    try {
      if (this.value < minimum) throw Error();
    } catch (err) {
      this.valid = false;
      this.errors.push(VALIDATOR_ERROR_MSG.MIN.format(this.description, minimum));
    }
    return this;
  }

  max(maximum) {
    try {
      if (this.value > maximum) throw Error();
    } catch (err) {
      this.valid = false;
      this.errors.push(VALIDATOR_ERROR_MSG.MAX.format(this.description, maximum));
    }
    return this;
  }
}

export { Validator };
