<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Chart } from 'highcharts-vue';

import { Company } from '../../../../../shared/models/index.js';
import { CTable, UpgradeCtaModal } from '../../../../components/index.js';
import { EPermissions } from '../../../../../shared/models/user/index.js';

import { EPlans } from '../../../../../shared/enums.js';

const PIE_CHART_BASE_COLOR = {
  r: 15,
  g: 72,
  b: 127
};

@Component({
  name: 'company-details-ownership',
  components: { Chart, CTable, UpgradeCtaModal },
  data: () => ({ EPlans })
})
class CompanyDetailsOwnership extends Vue {
  loaded = false;
  company = null;

  showUpgradeModal = false;

  async loadCompany() {
    const companyCode = this?.$route?.params?.companyCode;
    this.company = new Company({ companyCode });
    await this.company.load({ general: true, holders: true });
  }

  get pieChartInstitutions() {
    const holders = [
      ...(this?.company?.holders?.institutions || []).map((institution) => ({
        type: 'Institution',
        ...institution
      })),
      ...(this?.company?.holders?.funds || []).map((fund) => ({
        type: 'Mutual Fund',
        ...fund
      }))
    ]?.sort((a, b) => b.currentSharesPct - a.currentSharesPct);
    const totalOwnership = holders?.map((holder) => holder.currentSharesPct)?.sum() || 0;
    const totalShares = holders?.map((holder) => holder.currentShares)?.sum() || 0;

    const chartOptions = {
      chart: {
        type: 'bar',
        margin: 0,
        height: 100,
        events: {
          load() {
            const [series] = this.series;
            setTimeout(() => {
              series.points.forEach((point) => {
                if (point.percentage < 5) {
                  point.update({
                    dataLabels: {
                      distance: 30
                    }
                  });
                }
              });
            });
          }
        },
        tickLength: 0,
        tickWidth: 0,
        lineWidth: 0,
        showAxes: false
      },
      title: null,
      legend: false,
      plotOptions: {
        column: {
          borderColor: 'transparent'
        },
        series: {
          stacking: 'normal',
          cursor: 'pointer',
          dataLabels: {
            distance: '20%'
          }
        }
      },
      xAxis: {
        height: 100,
        padding: 0,
        margin: 0,
        gridLineColor: '#F00',
        lineWidth: 1
      },
      yAxis: {
        max: 100,
        lineWidth: 1,
        tickInterval: 100
      },
      colors: [
        ...new Array(holders.length).fill(null).map((companyCode, index) => {
          const holdersAmount = holders.length;
          const r = PIE_CHART_BASE_COLOR.r + ((255 - PIE_CHART_BASE_COLOR.r) / (holdersAmount + 2)) * (index + 1);
          const g = PIE_CHART_BASE_COLOR.g + ((255 - PIE_CHART_BASE_COLOR.g) / (holdersAmount + 2)) * (index + 1);
          const b = PIE_CHART_BASE_COLOR.b + ((255 - PIE_CHART_BASE_COLOR.b) / (holdersAmount + 2)) * (index + 1);
          return `rgb(${r}, ${g}, ${b})`;
        }),
        '#777'
      ],
      stockTools: null,
      tooltip: {
        pointFormat: `
            <span style="color:{point.color}">●</span>
            Ownership %: <strong>{point.y:.2f}%</strong> <br />
            <span style="color:{point.color}">●</span>
            Shares: <strong>{point.shares}</strong> <br />
            <span style="color:{point.color}">●</span>
            Type: <strong>{point.type}</strong> <br />`
      },
      series: [
        ...holders
          .map((holder) => ({
            name: holder.name,
            data: [
              {
                name: this.isBlurred(holder) ? 'Name available after upgrade' : holder.name,
                shares: this.isBlurred(holder) ? 'Available after upgrade' : this.Filter.number(holder.currentShares),
                type: this.isBlurred(holder) ? 'Available after upgrade' : holder.type,
                y: holder.currentSharesPct.round(2)
              }
            ]
          }))
          .sort((a, b) => b.data[0] - a.data[0]),
        {
          name: 'Others',
          data: [
            {
              name: 'Others',
              shares: this.Filter.number((totalShares / totalOwnership) * 100 - totalShares, 0, 0),
              type: 'Other',
              data: [100 - totalOwnership],
              y: 100 - totalOwnership
            }
          ]
        }
      ]
    };
    return chartOptions;
  }

  handleDataClick(holder) {
    if (this.isBlurred(holder)) {
      this.showUpgradeModal = true;
    }
  }

  get hasBlurredData() {
    if (this?.company?.holders?.funds?.length || this?.company?.holders?.institutions?.length) {
      return (
        this?.company?.holders?.funds?.map((holder) => this.isBlurred(holder)).includes(true) ||
        this?.company?.holders?.institutions?.map((holder) => this.isBlurred(holder)).includes(true)
      );
    }
    return false;
  }

  isBlurred(holder) {
    if (!this.store.user.hasPermission(EPermissions.COMPANY_HOLDERS.READ)) {
      return !(
        holder.name === this.company?.holders?.institutions?.first()?.name || holder.name === this.company?.holders?.funds?.first()?.name
      );
    }
    return false;
  }

  async mounted() {
    await this.loadCompany();
    this.setTitle(`${this?.company?.general?.name} - Ownership`);
  }
}
export default CompanyDetailsOwnership;
</script>
