<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import { CTable, FiftyTwoWeekRange, CompanySearch } from '../../components/index.js';

import { Workspace } from '../../../shared/models/workspace/Workspace.js';
import { CompanyList } from '../../../shared/models/company/CompanyList.js';
import { CompanyHistoricValuation } from '../../../shared/models/company/valuations/CompanyHistoricValuation.js';
import { ETime } from '../../../shared/enums.js';
import { EPermissions } from '../../../shared/models/user/index.js';

@Component({
  name: 'workspace',
  components: { CTable, FiftyTwoWeekRange, CompanySearch },
  data: () => ({
    EPermissions,
    CompanyHistoricValuation,
    saving: false
  })
})
class WorkspacePage extends Vue {
  workspace = new Workspace({});
  companyList = null;

  async workspaceAdd(companyCode) {
    try {
      await this.workspace.add(companyCode);
      this.companyList.add(companyCode);
      this.loadCompanyData();
      this.ToastSuccess('The company has been added to your workspace!');
    } catch (err) {
      this.ToastError('Something went wrong, please try again.');
    }
  }

  async workspaceDelete(companyCode) {
    try {
      await this.workspace.delete(companyCode);
      this.companyList.delete(companyCode);
      this.ToastSuccess('The company has been removed from your workspace.');
    } catch (err) {
      this.ToastError('Something went wrong, please try again.');
    }
  }

  async loadCompanyData() {
    await this.companyList.load({
      general: true,
      sharePriceLive: true,
      currencies: true,
      analystRatings: true,
      calculated: {
        fiftyTwoWeek: true
      },
      shares: true,
      valuations: {
        fundamentlValuations: true,
        historicValuations: true,
        dcfValuations: true,
        valuationSummaries: true
      },
      financialStatements: {
        incomeStatements: {
          yearly: true
        },
        cashFlowStatements: {
          yearly: true
        }
      },
      ratios: {
        yearly: true
      }
    });

    await this.companyList.loadSharePriceHistory(
      new Date(Date.now() - ETime.milliseconds.perDay * ETime.days.perWeek).removeTime().toUTC(),
      new Date(Date.now() - ETime.milliseconds.perDay).removeTime().toUTC()
    );

    this.companyList.companies.forEach((company, index) => {
      Vue.set(this.companyList.companies[index], 'candleStickLastDay', company.sharePricesDaily.last());
    });
  }

  closePopover(ref) {
    if (this?.$refs?.[ref]?.[0]) {
      this.$refs[ref][0].doClose();
    }
  }

  async loadSharePrice() {
    await this.companyList.load({ sharePriceLive: true });
  }

  historicValuationResults(company) {
    const hv = company?.valuations?.historicValuations?.last();
    if (!hv) return null;

    const perShareData =  CompanyHistoricValuation.perShareData(company);

    const netProfit =
      perShareData?.netProfitPerShare.round(2) *
      ((hv?.growth?.netProfit) / 100 + 1) *
      (hv?.multiple?.netProfit);
    const revenues =
      perShareData?.revenuePerShare.round(2) *
      ((hv?.growth?.revenues) / 100 + 1) *
      (hv?.multiple?.revenues);
    const bookValue =
      perShareData?.bookValuePerShare.round(2) *
      ((hv?.growth?.bookValue) / 100 + 1) *
      (hv?.multiple?.bookValue);
    const operationalCashFlow =
      perShareData?.operationalCashFlowPerShare.round(2) *
      ((hv?.growth?.operationalCashFlow) / 100 + 1) *
      (hv?.multiple?.operationalCashFlow);

    const valuations = [];
    if (hv?.included?.netProfit) {
      valuations.push(netProfit);
    }
    if (hv?.included?.revenues) {
      valuations.push(revenues);
    }
    if (hv?.included?.bookValue) {
      valuations.push(bookValue);
    }
    if (hv?.operationalCashFlow) {
      valuations.push(operationalCashFlow);
    }

    const total = valuations.sum();
    const average = valuations.mean();
    const value = valuations.mean();

    return {
      netProfit,
      revenues,
      bookValue,
      operationalCashFlow,
      total,
      average,
      value
    };
  }

  async mounted() {
    await this.workspace.load();
    this.companyList = new CompanyList({ companyCodes: this.workspace.companies });

    this.loadCompanyData();

    setInterval(() => {
      this.loadSharePrice();
    }, ETime.milliseconds.perMinute * 20);
  }
}
export default WorkspacePage;
</script>
<style lang="scss" scoped>
@import './style.scss';
.company-search {
  max-width: 300px;
}
</style>
