import { Currency } from '../../currency/index.js';

class CompanySharePricePeriod {
  constructor({ currency = null, date = null, close = null, high = null, low = null, mean = null }) {
    this.currency = new Currency(currency);
    this.date = date;
    this.close = close;
    this.high = high;
    this.low = low;
    this.mean = mean;
  }
}

export { CompanySharePricePeriod };
