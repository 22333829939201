<template>
  <div>
    <b-form-input
      v-if="filter.inputType === CompanyScreenerFilter.INPUT_TYPES.STRING.TEXT_SEARCH"
      v-model="filter.value"
      :debounce="200"
      :placeholder="filter.options.placeholder"
      size="sm"
      @input="emitChange($event, filter.key)"
    ></b-form-input>
    <MultiSelectDropdown
      v-else-if="filter.inputType === CompanyScreenerFilter.INPUT_TYPES.STRING.MULTISELECT"
      v-model="filter.value"
      size="sm"
      :items="filter.options.items"
      :item-name="filter.options.itemName"
      :disabled="filter.options.disabled || false"
      class="w-100"
      @input="emitChange($event, filter.key)"
    ></MultiSelectDropdown>
    <InputSlider
      v-else-if="filter.inputType === CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_OPTIONS"
      v-model="filter.value"
      class="mx-1"
      type="options"
      :options="filter.options.items"
      :single="false"
      range-unlimited-min
      range-unlimited-max
      @input="emitChange($event, filter.key)"
    />
    <InputSlider
      v-else-if="filter.inputType === CompanyScreenerFilter.INPUT_TYPES.NUMBER.SLIDER_RANGE"
      v-model="filter.value"
      class="mx-1"
      type="range"
      :range-min="filter.options.rangeMin"
      :range-max="filter.options.rangeMax"
      :range-step-size="filter.options.rangeStepSize"
      :single="false"
      range-unlimited-min
      range-unlimited-max
      @input="emitChange($event, filter.key)"
    />
  </div>
</template>
<script>
import Component from 'vue-class-component';
import Vue from 'vue';
import { CompanyScreenerItem } from '../../../shared/models/company/screener/CompanyScreenerItem.js';
import { CompanyScreenerFilter } from '../../../shared/models/company/screener/CompanyScreenerFilter.js';
import MultiSelectDropdown from '../multi-select-dropdown/component.vue';
import Lazyload from '../lazyload/component.vue';
import InputSlider from '../input-slider/component.vue';

@Component({
  name: 'company-screener',
  data: () => ({ CompanyScreenerItem, CompanyScreenerFilter }),
  components: { MultiSelectDropdown, Lazyload, InputSlider },
  props: {
    attribute: {
      type: Object,
      required: true
    },
    subAttributeKey: {
      type: String,
      default: null
    }
  }
})
class CompanyScreenerFilterInputView extends Vue {
  debounceTimeout = null;

  get filter() {
    if (this.subAttributeKey) {
      return this.attribute.filter[this.subAttributeKey];
    }
    return this.attribute.filter;
  }

  debounce(fn, timeout) {
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => {
      fn();
    }, timeout);
  }

  emitChange(event, key) {
    this.debounce(() => {
      this.$emit('change', key);
    }, 200);
  }
}

export default CompanyScreenerFilterInputView;
</script>
