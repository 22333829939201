<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Chart } from 'highcharts-vue';

import { Company } from '../../../../../shared/models/index.js';
import { CTable, UpgradeCtaModal } from '../../../../components/index.js';
import { EPermissions } from '../../../../../shared/models/user/index.js';

import { EPlans } from '../../../../../shared/enums.js';

@Component({
  name: 'company-details-esg',
  components: { Chart, CTable, UpgradeCtaModal },
  data: () => ({ EPlans })
})
class CompanyDetailsEsg extends Vue {
  loaded = false;
  company = null;

  showUpgradeModal = false;

  async loadCompany() {
    const companyCode = this?.$route?.params?.companyCode;
    this.company = new Company({ companyCode });
    await this.company.load({ general: true, esg: true });
  }

  get chartOptions() {
    const mockedRating = 70;
    return {
      chart: {
        type: 'solidgauge'
      },

      title: null,

      pane: {
        center: ['50%', '50%'],
        size: '100%',
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor: '#2a3042',
          innerRadius: '60%',
          outerRadius: '100%',
          shape: 'arc',
          borderWidth: 0,
          lineWidth: 0,
          shadow: 0
        }
      },

      exporting: {
        enabled: false
      },

      tooltip: {
        enabled: false
      },

      // the value axis
      yAxis: {
        min: 0,
        max: 1,
        title: null,
        stops: this.isDataLimited
          ? [[1.0, '#74788d']]
          : [
              [0.3, '#f46a6a'], // red
              [0.7, '#f1b44c'], // yellow
              [1.0, '#34c38f'] // green
            ],
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 0,
        labels: {
          y: 16
        },
        showFirstLabel: false,
        showLastLabel: false
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 100,
            borderWidth: 0,
            useHTML: true
          },
          border: 0
        }
      },

      series: [
        {
          name: 'RPM',
          data: [(this.company.esg.total.score || mockedRating) / 100],
          dataLabels: {
            format: `<span class="gauge-number text-light">
              <span class="text-white ${this.isDataLimited ? 'blur-data-big' : ''}">${this.Filter.number(
              this.company?.esg?.total?.score || mockedRating,
              2,
              2
            )}</span>
              <span class="text-light">/ ${this.Filter.number(100, 0, 0)}</span>
            </span>`,
            y: -50,
            style: {
              fontSize: '30px'
            }
          }
        }
      ]
    };
  }

  progressBarVariant(value, max = 100) {
    if (this.isDataLimited) {
      return 'secondary';
    }

    if (value / max < 0.3) {
      return 'danger';
    }
    if (value / max < 0.7) {
      return 'warning';
    }
    return 'success';
  }

  handleDataClick(isBlurred = true) {
    if (isBlurred && this.isDataLimited) {
      this.showUpgradeModal = true;
    }
  }

  get isDataLimited() {
    return !this.store.user.hasPermission(EPermissions.COMPANY_ESG.READ);
  }

  async mounted() {
    await this.loadCompany();
    this.setTitle(`${this?.company?.general?.name} - ESG`);
  }
}
export default CompanyDetailsEsg;
</script>
