import { Currency } from '../../currency/index.js';

class CompanyBalanceSheet {
  constructor({
    date = null,
    filingDate = null,
    currency = null,

    accountsPayable = null,
    accumulatedAmortization = null,
    accumulatedDepreciation = null,
    accumulatedOtherComprehensiveIncome = null,
    additionalPaidInCapital = null,
    capitalLeaseObligations = null,
    capitalSurplus = null,
    cash = null,
    cashAndShortTermInvestments = null,
    commonStock = null,
    commonStockSharesOutstanding = null,
    commonStockTotalEquity = null,
    deferredLongTermAssetCharges = null,
    deferredLongTermLiabilities = null,
    earningAssets = null,
    goodwill = null,
    intangibleAssets = null,
    inventory = null,
    liabilitiesAndStockholdersEquity = null,
    longTermDebt = null,
    longTermDebtTotal = null,
    longTermInvestments = null,
    negativeGoodwill = null,
    netDebt = null,
    netInvestedCapital = null,
    netReceivables = null,
    netTangibleAssets = null,
    netWorkingCapital = null,
    nonControllingInterestInConsolidatedEntity = null,
    nonCurrentAssetsOther = null,
    nonCurrentAssetsTotal = null,
    nonCurrentLiabilitiesOther = null,
    nonCurrentLiabilitiesTotal = null,
    otherAssets = null,
    otherLiabilities = null,
    preferredStockRedeemable = null,
    preferredStockTotalEquity = null,
    propertyPlantAndEquipmentGross = null,
    propertyPlantEquipment = null,
    retainedEarnings = null,
    retainedEarningsTotalEquity = null,
    shortLongTermDebt = null,
    shortLongTermDebtTotal = null,
    shortTermDebt = null,
    shortTermInvestments = null,
    temporaryEquityRedeemableNonControllingInterests = null,
    totalAssets = null,
    totalCurrentAssets = null,
    totalCurrentLiabilities = null,
    totalLiabilities = null,
    totalPermanentEquity = null,
    totalStockholderEquity = null,
    treasuryStock = null,
    warrants = null
  }) {
    this.date = date;
    this.filingDate = filingDate;
    this.currency = new Currency(currency);

    this.accountsPayable = accountsPayable;
    this.accumulatedAmortization = accumulatedAmortization;
    this.accumulatedDepreciation = accumulatedDepreciation;
    this.accumulatedOtherComprehensiveIncome = accumulatedOtherComprehensiveIncome;
    this.additionalPaidInCapital = additionalPaidInCapital;
    this.capitalLeaseObligations = capitalLeaseObligations;
    this.capitalSurplus = capitalSurplus;
    this.cash = cash;
    this.cashAndShortTermInvestments = cashAndShortTermInvestments;
    this.commonStock = commonStock;
    this.commonStockSharesOutstanding = commonStockSharesOutstanding;
    this.commonStockTotalEquity = commonStockTotalEquity;
    this.deferredLongTermAssetCharges = deferredLongTermAssetCharges;
    this.deferredLongTermLiabilities = deferredLongTermLiabilities;
    this.earningAssets = earningAssets;
    this.goodwill = goodwill;
    this.intangibleAssets = intangibleAssets;
    this.inventory = inventory;
    this.liabilitiesAndStockholdersEquity = liabilitiesAndStockholdersEquity;
    this.longTermDebt = longTermDebt;
    this.longTermDebtTotal = longTermDebtTotal;
    this.longTermInvestments = longTermInvestments;
    this.negativeGoodwill = negativeGoodwill;
    this.netDebt = netDebt;
    this.netInvestedCapital = netInvestedCapital;
    this.netReceivables = netReceivables;
    this.netTangibleAssets = netTangibleAssets;
    this.netWorkingCapital = netWorkingCapital;
    this.nonControllingInterestInConsolidatedEntity = nonControllingInterestInConsolidatedEntity;
    this.nonCurrentAssetsOther = nonCurrentAssetsOther;
    this.nonCurrentAssetsTotal = nonCurrentAssetsTotal;
    this.nonCurrentLiabilitiesOther = nonCurrentLiabilitiesOther;
    this.nonCurrentLiabilitiesTotal = nonCurrentLiabilitiesTotal;
    this.otherAssets = otherAssets;
    this.otherLiabilities = otherLiabilities;
    this.preferredStockRedeemable = preferredStockRedeemable;
    this.preferredStockTotalEquity = preferredStockTotalEquity;
    this.propertyPlantAndEquipmentGross = propertyPlantAndEquipmentGross;
    this.propertyPlantEquipment = propertyPlantEquipment;
    this.retainedEarnings = retainedEarnings;
    this.retainedEarningsTotalEquity = retainedEarningsTotalEquity;
    this.shortLongTermDebt = shortLongTermDebt;
    this.shortLongTermDebtTotal = shortLongTermDebtTotal;
    this.shortTermDebt = shortTermDebt;
    this.shortTermInvestments = shortTermInvestments;
    this.temporaryEquityRedeemableNonControllingInterests = temporaryEquityRedeemableNonControllingInterests;
    this.totalAssets = totalAssets;
    this.totalCurrentAssets = totalCurrentAssets;
    this.totalCurrentLiabilities = totalCurrentLiabilities;
    this.totalLiabilities = totalLiabilities;
    this.totalPermanentEquity = totalPermanentEquity;
    this.totalStockholderEquity = totalStockholderEquity;
    this.treasuryStock = treasuryStock;
    this.warrants = warrants;

    this._calculate();
  }

  _calculate() {
    this.otherCurrentAssets = this.totalCurrentAssets - (this.cash + this.shortTermInvestments + this.netReceivables + this.inventory);
    this.otherNonCurrentAssets =
      this.nonCurrentAssetsTotal - (this.longTermInvestments + this.propertyPlantAndEquipmentGross + this.intangibleAssets + this.goodwill);
    this.otherStockHolderEquity = this.totalStockholderEquity - (this.commonStock + this.retainedEarnings);
    this.otherNonCurrentLiabilities = this.nonCurrentLiabilitiesTotal - (this.longTermDebtTotal + this.deferredLongTermLiabilities);
    this.otherCurrentLiabilities = this.totalCurrentLiabilities - (this.accountsPayable + this.shortTermDebt);
  }
}

export { CompanyBalanceSheet };
