<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import { PaymentMethodSelect, Steps } from '../../../components/index.js';

@Component({
  name: 'checkout',
  components: { PaymentMethodSelect, Steps },
  data: () => ({})
})
class Checkout extends Vue {
  profile = null;
  selectedCreditCard = null;
  isCheckingOut = false;
  taxDetails = null;

  get buttonDisabled() {
    return !this.store?.checkoutSession?.plan || !this.selectedCreditCard || this.isCheckingOut || !this.taxDetails;
  }

  get stripePriceId() {
    const billingFrequency = this.store.checkoutSession?.billingFrequency;
    const currency = this.store.checkoutSession?.currency?.toLowerCase();
    const stripePriceId = this.store.checkoutSession.plan.price[billingFrequency][currency];

    return stripePriceId;
  }

  async handleButtonClick() {
    this.isCheckingOut = true;
    try {
      await this.StockInvestingApi.paymentSubscriptionCreate({
        stripePriceId: this.stripePriceId,
        trial: this.store.checkoutSession.isTrial
      });
      await this.$router.push({ name: this.pageNames.stockInvesting.UPGRADE.SUCCESS });
    } catch (err) {
      console.error(err);
      this.ToastError('Something went wrong, please try again.');
    }
    this.isCheckingOut = false;
  }

  async mounted() {
    this.setTitle('Checkout - Upgrade');
    this.$gtag.event('upgrade_step3_pv');

    if (!this.store?.checkoutSession) {
      this.$router.push({ name: this.pageNames.stockInvesting.UPGRADE.PLANS });
    }

    try {
      this.taxDetails = await this.StockInvestingApi.paymentSubscriptionCalculateTax({ stripePriceId: this.stripePriceId });
    } catch (err) {
      console.error(err);
    }
  }
}
export default Checkout;
</script>
<style lang="scss" scoped>
@import './style.scss';
</style>
