<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import { BillingDetails, PaymentMethodList, PlanDetails, Invoices } from '../../components/index.js';
import { Country } from '../../../shared/models/index.js';

@Component({
  name: 'my-account',
  components: { BillingDetails, PaymentMethodList, PlanDetails, Invoices },
  data: () => ({
    Country,
    saving: false
  })
})
class MyAccount extends Vue {
  customer = null;

  handleBillingDetailsSaveSuccess() {
    this.ToastSuccess('Billing details have been saved');
  }

  handleBillingDetailsSaveFail(err) {
    if (err?.errors?.length) {
      this.ToastError(err?.errors.first());
    } else {
      this.ToastError('Something went wrong, please try again.');
    }
  }

  async mounted() {
    try {
      const customer = await this.StockInvestingApi.paymentCustomerDetails();
      if (customer?.billingDetails?.address?.country) {
        customer.billingDetails.address.country = customer.billingDetails.address.country.iso;
      }
      this.customer = customer;
    } catch (err) {
      console.error(err);
    }
  }
}
export default MyAccount;
</script>
<style lang="scss" scoped>
@import './style.scss';
</style>
