<template src="./template.vue" />

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import { CompanyScreenerItem } from '../../../shared/models/company/screener/CompanyScreenerItem.js';
import CompanyScreenerFilterInputView from './filter-input.vue';

const DEFAULT_CURRENT_PAGE = 1;

@Component({
  name: 'company-screener-filter-modal',
  props: {
    screener: {
      type: Object,
      required: true
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({ CompanyScreenerItem }),
  components: {
    CompanyScreenerFilterInputView
  },
  watch: {
    value(value) {
      if (value) {
        this.$bvModal.show(`filters-modal-${this._uid}`);
      } else {
        this.$bvModal.hide(`filters-modal-${this._uid}`);
      }
    },
    filtersSearchQuery: function filtersSearchQueryWatch() {
      this.$refs.filterTabs.activateTab(this.$refs.filterTabs.tabs.first());
    },
    screenerForm() {
      this.handleScreenerFormChange();
    },
    'screener.attributes.sectorIndustry.filter.sector.value': function sectorChangeWatch() {
      this.handleSectorChange();
    }
  }
})
class CompanyScreenerFilterModal extends Vue {
  filtersSearchQuery = '';
  showFilterModalContent = true;

  async resetFilters() {
    this.showFilterModalContent = false;
    this.screener.resetFilters();
    await this.$nextTick();
    this.showFilterModalContent = true;
  }

  categoryHasFilters(category) {
    if (category?.label) {
      return Object.values(category?.sub)
        .map((subCategory) => this.categoryHasFilters(subCategory))
        ?.includes(true);
    }
    return !!Object.values(this.screener.attributes)
      .filter((attr) => attr.category === category && attr.filter)
      .filter((attr) => this.matchesFilterSearch(attr))?.length;
  }

  matchesFilterSearch(attr) {
    if (this.filtersSearchQuery && this.filtersSearchQuery !== '') {
      return (
        (typeof attr?.name === typeof String() ? attr?.name : '')?.toLowerCase()?.includes(this.filtersSearchQuery?.toLowerCase()) ||
        attr?.name?.default?.toLowerCase()?.includes(this.filtersSearchQuery?.toLowerCase()) ||
        attr?.category?.toLowerCase()?.includes(this.filtersSearchQuery?.toLowerCase())
      );
    }
    return true;
  }

  // Filters

  // General
  async handleScreenerFormChange(attributeKey) {
    this.$emit('filtersChange', attributeKey);
    this.currentPage = DEFAULT_CURRENT_PAGE;
    this.screener.offset = 0;
  }

  // Sector / industries
  async getSectors() {
    try {
      this.sectorIndustries = await this.StockInvestingApi.fnSectorList();
      this.screener.attributes.sectorIndustry.filter.sector.options.items = Object.keys(this.sectorIndustries || {})
        .sort()
        .map((sector) => ({
          label: sector,
          value: sector
        }));
    } catch (err) {
      console.error(err);
    }
  }

  getIndustries() {
    const possibleIndustries = [];
    const selectedSectors = this.screener.attributes.sectorIndustry.filter.sector.value;
    for (let i = 0; i < selectedSectors?.length || 0; i++) {
      const sector = selectedSectors[i];
      const industries = this.sectorIndustries[sector.value];
      for (let y = 0; y < industries.length; y++) {
        const industry = industries[y];
        possibleIndustries.push(industry);
      }
    }
    possibleIndustries.sort();

    return possibleIndustries.map((industry) => ({ label: industry, value: industry })).distinct('value');
  }

  async handleSectorChange() {
    // Remove selected industries which aren't part of the selected sectors
    const possibleIndustries = this.getIndustries();
    this.screener.attributes.sectorIndustry.filter.industry.options.items = possibleIndustries;
    this.screener.attributes.sectorIndustry.filter.industry.options.disabled = !possibleIndustries.length;

    const selectedIndustries = this.screener.attributes.sectorIndustry.filter.industry.value || [];
    for (let i = selectedIndustries.length - 1; i >= 0; i--) {
      const selectedIndustry = selectedIndustries[i];
      if (!possibleIndustries.map((industry) => industry.value).includes(selectedIndustry.value)) {
        selectedIndustries.splice(i, 1);
      }
    }
    this.screener.attributes.sectorIndustry.filter.industry.value = selectedIndustries;
  }

  // Tags
  async getTags() {
    try {
      const tags = await this.StockInvestingApi.tagsList();
      this.filters.tag.filter.items = tags.map((tag) => ({ label: tag.name, value: tag._id }));
    } catch (err) {
      console.error(err);
    }
  }

  // Country
  async getCountries() {
    try {
      const countries = await this.StockInvestingApi.fnCountryList();
      this.screener.attributes.country.filter.options.items = countries.map((country) => ({ label: country, value: country }));
    } catch (err) {
      console.error(err);
    }
  }

  // Exchanges
  async getExchanges() {
    try {
      const exchanges = await this.StockInvestingApi.fnExchangeList();
      this.screener.attributes.exchange.filter.options.items = exchanges
        .map((exchange) => ({
          label: exchange.name,
          value: exchange.code
        }))
        .sort((a, b) => (a.label < b.label ? -1 : 1));
    } catch (err) {
      console.error(err);
    }
  }

  mounted() {
    this.getSectors();
    this.getCountries();
    this.getExchanges();
  }
}
export default CompanyScreenerFilterModal;
</script>
