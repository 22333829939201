<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Chart } from 'highcharts-vue';

import { Company } from '../../../../../shared/models/index.js';
import { CTable, UpgradeCtaModal } from '../../../../components/index.js';
import { EPermissions } from '../../../../../shared/models/user/index.js';

import { EPlans } from '../../../../../shared/enums.js';

@Component({
  name: 'company-details-insider-transactions',
  components: { Chart, CTable, UpgradeCtaModal },
  data: () => ({ EPlans })
})
class CompanyDetailsInsiderTransactions extends Vue {
  loaded = false;
  company = null;

  showUpgradeModal = false;

  async loadCompany() {
    const companyCode = this?.$route?.params?.companyCode;
    this.company = new Company({ companyCode });
    await this.company.load({ general: true, insiderTransactions: true, currencies: true });
  }

  handleDataClick(isBlurred) {
    if (isBlurred) {
      this.showUpgradeModal = true;
    }
  }

  get isLimited() {
    return !this.store.user.hasPermission(EPermissions.COMPANY_INSIDER_TRADING.READ);
  }

  async mounted() {
    await this.loadCompany();
    this.setTitle(`${this?.company?.general?.name} - Insider transactions`);
  }
}
export default CompanyDetailsInsiderTransactions;
</script>
