var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"homepage"}},[_c('div',{staticClass:"banner"},[_c('video',{staticClass:"banner-video",attrs:{"autoplay":"","loop":"","muted":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"public/video/bg-video-kmprss.webm","type":"video/webm"}}),_c('source',{attrs:{"src":"public/video/bg-video-kmprss.mp4","type":"video/mp4"}})]),_c('div',{staticClass:"banner-background"}),_c('div',{staticClass:"banner-content"},[_c('h1',{staticClass:"text-white mb-4"},[_vm._v(_vm._s(_vm.bannerTitle))]),(_vm.bannerButton)?_c('b-button',{attrs:{"variant":"warning","size":"lg","to":{ name: _vm.pageNames.stockInvesting.UPGRADE.PLANS }}},[_vm._v(" "+_vm._s(_vm.bannerButton)+" ")]):_vm._e()],1)]),_c('div',{staticClass:"container-fluid my-4"},[_c('b-row',[_c('b-col',{attrs:{"cols":4}},[_c('b-card',{attrs:{"no-body":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Top "+_vm._s(_vm.TOP_AMOUNT)+" - Market cap. ")]},proxy:true}])},[_c('CompanyScreener',{staticClass:"pb-0",attrs:{"show-action-bar":false,"visible-columns":['country', 'companyName', 'marketCapUsd', 'sharePrice'],"companies-amount":_vm.TOP_AMOUNT,"preset-column-sort":{
              column: 'marketCapUsd',
              desc: true
            }}})],1)],1),_c('b-col',{attrs:{"cols":4}},[_c('b-card',{attrs:{"no-body":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Top "+_vm._s(_vm.TOP_AMOUNT)+" - Revenue growth ")]},proxy:true}])},[_c('CompanyScreener',{staticClass:"pb-0",attrs:{"show-action-bar":false,"visible-columns":['country', 'companyName', 'avgRevenueGrowth5Year', 'sharePrice'],"companies-amount":_vm.TOP_AMOUNT,"preset-filters":{
              marketCapUsd: {
                min: 50 * 1000 ** 3 // 50 Billion
              }
            },"preset-column-sort":{
              column: 'avgRevenueGrowth5Year',
              desc: true
            }}})],1)],1),_c('b-col',{attrs:{"cols":4}},[_c('b-card',{attrs:{"no-body":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Top "+_vm._s(_vm.TOP_AMOUNT)+" - EBITDA growth ")]},proxy:true}])},[_c('CompanyScreener',{staticClass:"pb-0",attrs:{"show-action-bar":false,"visible-columns":['country', 'companyName', 'avgEbitdaGrowth5Year', 'sharePrice'],"companies-amount":_vm.TOP_AMOUNT,"preset-filters":{
              marketCapUsd: {
                min: 50 * 1000 ** 3 // 50 Billion
              }
            },"preset-column-sort":{
              column: 'avgEbitdaGrowth5Year',
              desc: true
            }}})],1)],1)],1)],1),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_c('GoogleAd',{attrs:{"ad-slot":"4919645416","format":"horizontal"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }