<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';

import { EPlans, ERatios, ETime } from '../../../../../shared/enums.js';
import { adjustForSplits } from '../../../../../shared/utils/index.js';

import { serializeChartData } from '../../../../utils/serialize-chart-data.js';

import { CTable, LoginModal, SignUpModal, AuthenticateModal, Formula, UpgradeCtaModal } from '../../../../components/index.js';
import PopoverChart from '../../popover-chart.vue';
import { Company } from '../../../../../shared/models/company/Company.js';
import { CompanyHistoricValuation } from '../../../../../shared/models/company/valuations/CompanyHistoricValuation.js';
import { EPermissions } from '../../../../../shared/models/user/index.js';

const HISTORIC_VALUATION_COOKIE_NAME = 'fundamentl-historic-valuation';

const CHART_HISTORY_YEARS_AMOUNT = 10;
const TABLE_HISTORY_YEARS_AMOUNT = 10;

const HOVER_KEYS = {
  GROWTH_NET_PROFIT: 'growth-net-profit',
  GROWTH_REVENUE: 'growth-revenue',
  GROWTH_BOOK_VALUE: 'growth-book-value',
  GROWTH_CASH_FLOW: 'growth-cash-flow',
  MULTIPLE_NET_PROFIT: 'multiple-net-profit',
  MULTIPLE_REVENUE: 'multiple-revenue',
  MULTIPLE_BOOK_VALUE: 'multiple-book-value',
  MULTIPLE_CASH_FLOW: 'multiple-cash-flow'
};

const VALUATION_TYPE = {
  TWO_YEAR: 2,
  FIVE_YEAR: 5,
  TEN_YEAR: 10,
  CUSTOM: 'custom'
};

@Component({
  name: 'company-details-historic-valuation',
  components: { Chart, PopoverChart, CTable, LoginModal, SignUpModal, AuthenticateModal, Formula, UpgradeCtaModal },
  data: () => ({ ERatios, EPlans, TABLE_HISTORY_YEARS_AMOUNT, HOVER_KEYS, VALUATION_TYPE }),
  watch: {
    source() {
      this.reload();
    }
  }
})
class CompanyDetailsHistoricValuation extends Vue {
  company = null;
  loaded = false;

  popoverChartData = null;
  charts = null;
  keyData = null;

  elevenYearData = null;

  growthData = null;
  fiveYearGrowthData = null;
  tenYearGrowthData = null;

  saving = false;

  historicValuations = {};
  historicValuationYear = null;

  newHistoricValuation = {
    growth: {
      netProfit: null,
      revenues: null,
      bookValue: null,
      operationalCashFlow: null
    },
    multiple: {
      netProfit: null,
      revenues: null,
      bookValue: null,
      operationalCashFlow: null
    },
    included: {
      netProfit: true,
      revenues: true,
      bookValue: true,
      operationalCashFlow: true
    }
  };

  advancedMultiples = false;

  allPriceEarningsKeys = ['priceOverEarningsHigh', 'priceOverEarningsMean', 'priceOverEarningsLow'];
  allPriceSalesKeys = ['priceOverSalesHigh', 'priceOverSalesMean', 'priceOverSalesLow'];
  allPriceBookKeys = ['priceOverBookHigh', 'priceOverBookMean', 'priceOverBookLow'];
  allPriceCashFlowKeys = ['priceOverCashFlowHigh', 'priceOverCashFlowMean', 'priceOverCashFlowLow'];

  growthTableCollapsed = false;

  multiplesTableCollapsed = false;
  candlesticksDaily = [];

  showAuthenticateModal = false;

  hoverKey = null;
  setHoverKeyTimeout = null;
  hoverKeyFocused = false;

  forceResultsUpdate = true;

  autoValuationSelection = VALUATION_TYPE.TWO_YEAR;

  showUpgradeModal = false;
  upgradeCtaModalPlan = EPlans.packageTier1;

  get priceEarningsKeys() {
    return this.advancedMultiples ? this.allPriceEarningsKeys : ['priceOverEarningsMean'];
  }

  get priceSalesKeys() {
    return this.advancedMultiples ? this.allPriceSalesKeys : ['priceOverSalesMean'];
  }

  get priceBookKeys() {
    return this.advancedMultiples ? this.allPriceBookKeys : ['priceOverBookMean'];
  }

  get priceCashFlowKeys() {
    return this.advancedMultiples ? this.allPriceCashFlowKeys : ['priceOverCashFlowMean'];
  }

  setHoverKey(hoverKey, focussed) {
    if (focussed || (!focussed && !this.hoverKeyFocused)) {
      clearTimeout(this.setHoverKeyTimeout);
      this.setHoverKeyTimeout = setTimeout(() => {
        this.hoverKey = hoverKey;
      });
    }

    if (focussed && hoverKey) {
      this.hoverKeyFocused = true;
    } else if (focussed && !hoverKey) {
      this.hoverKeyFocused = false;
    }
  }

  getAvgMultiple(key, years) {
    return this.company.ratios.yearly
      .slice(-years)
      .map((r) => r[key])
      .mean();
  }

  preparePopoverChartData() {
    const keys = [...this.allPriceEarningsKeys, ...this.allPriceBookKeys, ...this.allPriceCashFlowKeys, ...this.allPriceSalesKeys];

    const popoverChartData = {};
    keys.forEach((key) => {
      popoverChartData[key] = this.company.ratios.yearly
        .map((ratios) => ({
          date: new Date(ratios.date),
          value: ratios[key]
        }))
        .filter((record) => record.value)
        .slice(-this.store.user.maxDataHistory);
    });

    const lastIncomeStatementDate = new Date(this.company?.financialStatements?.incomeStatements?.yearly?.last()?.date);
    const lastCashFlowStatementDate = new Date(this.company?.financialStatements?.cashFlowStatements?.yearly?.last()?.date);
    const lastRatiosDate = new Date(this.company?.ratios?.yearly?.last()?.date);

    popoverChartData.netProfit = this.company?.financialStatements?.incomeStatements?.yearly
      .map((is) => is?.netIncome || 0)
      .pctChange()
      .reverse()
      .map((value, index) => ({
        date: new Date(lastIncomeStatementDate).setFullYear(lastIncomeStatementDate.getFullYear() - index),
        value: value.round(2)
      }))
      .reverse()
      .slice(-this.store.user.maxDataHistory);
    popoverChartData.revenues = this.company?.financialStatements?.incomeStatements?.yearly
      ?.map((is) => is?.totalRevenue || 0)
      .pctChange()
      .reverse()
      .map((value, index) => ({
        date: new Date(lastIncomeStatementDate).setFullYear(lastIncomeStatementDate.getFullYear() - index),
        value: value.round(2)
      }))
      .reverse()
      .slice(-this.store.user.maxDataHistory);
    popoverChartData.bookValue = this.company?.ratios?.yearly
      ?.map((ratio) => ratio?.bookValue || 0)
      .pctChange()
      .reverse()
      .map((value, index) => ({
        date: new Date(lastRatiosDate).setFullYear(lastRatiosDate.getFullYear() - index),
        value: value.round(2)
      }))
      .reverse()
      .slice(-this.store.user.maxDataHistory);
    popoverChartData.operationalCashFlow = this.company?.financialStatements?.cashFlowStatements?.yearly
      ?.map((cf) => cf?.totalCashFromOperatingActivities || 0)
      .pctChange()
      .reverse()
      .map((value, index) => ({
        date: new Date(lastCashFlowStatementDate).setFullYear(lastCashFlowStatementDate.getFullYear() - index),
        value: value.round(2)
      }))
      .reverse()
      .slice(-this.store.user.maxDataHistory);

    this.popoverChartData = popoverChartData;
  }

  calculateKeyData() {
    this.keyData = CompanyHistoricValuation.perShareData(this.company);
  }

  calculateHistoricGrowthAverages() {
    const growthAverages = CompanyHistoricValuation.growthAverages(this.company);

    this.elevenYearData = CompanyHistoricValuation.financialDataLastXYear(this.company, 11);
    this.growthData = CompanyHistoricValuation.growthData(this.company, 11);
    this.twoYearGrowthData = growthAverages.twoYearGrowthData;
    this.fiveYearGrowthData = growthAverages.fiveYearGrowthData;
    this.tenYearGrowthData = growthAverages.tenYearGrowthData;
  }

  get results() {
    this.forceResultsUpdate = !this.forceResultsUpdate;
    const hv = this.historicValuations[this.historicValuationYear];
    const netProfit =
      this.keyData?.netProfitPerShare.round(2) *
      ((hv?.growth?.netProfit || this.prefillData?.growth?.netProfit) / 100 + 1) *
      (hv?.multiple?.netProfit || this.prefillData.multiple.netProfit);
    const revenues =
      this.keyData?.revenuePerShare.round(2) *
      ((hv?.growth?.revenues || this.prefillData?.growth?.revenues) / 100 + 1) *
      (hv?.multiple?.revenues || this.prefillData?.multiple?.revenues);
    const bookValue =
      this.keyData?.bookValuePerShare.round(2) *
      ((hv?.growth?.bookValue || this.prefillData?.growth?.bookValue) / 100 + 1) *
      (hv?.multiple?.bookValue || this.prefillData?.multiple?.bookValue);
    const operationalCashFlow =
      this.keyData?.operationalCashFlowPerShare.round(2) *
      ((hv?.growth?.operationalCashFlow || this.prefillData?.growth?.operationalCashFlow) / 100 + 1) *
      (hv?.multiple?.operationalCashFlow || this.prefillData?.multiple?.operationalCashFlow);

    const valuations = [];
    if (this.historicValuations[this.historicValuationYear]?.included?.netProfit) {
      valuations.push(netProfit);
    }
    if (this.historicValuations[this.historicValuationYear]?.included?.revenues) {
      valuations.push(revenues);
    }
    if (this.historicValuations[this.historicValuationYear]?.included?.bookValue) {
      valuations.push(bookValue);
    }
    if (this.historicValuations[this.historicValuationYear]?.included?.operationalCashFlow) {
      valuations.push(operationalCashFlow);
    }

    const total = valuations.sum();
    const average = valuations.mean();
    const value = valuations.mean();

    return {
      netProfit,
      revenues,
      bookValue,
      operationalCashFlow,
      total,
      average,
      value
    };
  }

  get growthTableHeaders() {
    if (this.growthData) {
      const startYear = new Date(this.company.ratios.yearly.last().date).getFiscalPeriod(this.company.general.fiscalYearEndMonth).year;
      const yearsAmount = Object.values(this.growthData)
        .map((attr) => attr.length)
        .max();
      return yearsAmount
        ?.map((i) => startYear - i)
        .reverse()
        .slice(-Object.values(this.growthData).first().length);
    }
    return [];
  }

  get sharePriceChart() {
    if (!this?.company?.shares?.price?.yearly?.length) return null;

    const candlesticks = adjustForSplits(
      this.candlesticksDaily
        .filter(
          (candlestick) =>
            new Date().setHours(-ETime.hours.perDay * ETime.days.perYear * CHART_HISTORY_YEARS_AMOUNT) <
            new Date(candlestick.date).getTime()
        )
        .filter((candlestick) => new Date(candlestick.date).getTime() % ETime.milliseconds.perWeek === 0),
      this.company.shares.splits
    );

    const maxCandleStickHeight = candlesticks.map((cs) => cs.close).max();

    const chartOptions = {
      title: null,
      stockTools: null,
      rangeSelector: {
        enabled: false
      },
      yAxis: [
        {
          max: [maxCandleStickHeight, this.results.average].max() * 1.1,
          labels: {
            align: 'left',
            x: 10,
            y: 3
          },
          height: '100%',
          resize: {
            enabled: true
          },
          lineWidth: 1,
          tickLength: 5,
          tickWidth: 1,
          tickPixelInterval: 30,
          plotLines: [
            {
              label: {
                text: `<a style="color: #FFFFFFAA" href="${
                  this.$router.resolve({
                    name: this.pageNames.stockInvesting.COMPANY_DETAILS.VALUATION_FUNDAMENTL_VALUATION,
                    params: { companyCode: this.$route.params.companyCode }
                  }).href
                }">(${this?.company?.currencies?.sharePrices?.symbol() || ''}${this.Filter.financial(
                  this?.company?.valuations?.fundamentlValuations?.last()?.average
                )}) Fundamentl valuation</a>`
              },
              dashStyle: 'longdash',
              color: '#2072FF88',
              width: 1,
              zIndex: 2,
              value: this?.company?.valuations?.fundamentlValuations?.last()?.average
            },
            {
              label: {
                text: `<a style="color: #FFFFFFAA" href="${
                  this.$router.resolve({
                    name: this.pageNames.stockInvesting.COMPANY_DETAILS.ANALYST_RATINGS,
                    params: { companyCode: this.$route.params.companyCode }
                  }).href
                }">(${this?.company?.currencies?.sharePrices?.symbol() || ''}${this.Filter.financial(
                  this?.company?.analystRatings?.targetPrice
                )}) Analyst valuation</a>`
              },
              dashStyle: 'longdash',
              color: '#4B8CFF88',
              width: 1,
              zIndex: 2,
              value: this?.company?.analystRatings?.targetPrice
            },
            {
              label: {
                text: `(${this?.company?.currencies?.sharePrices?.symbol() || ''}${this.Filter.financial(
                  this.results.average
                )}) Your valuation`,
                style: {
                  color: '#FFFFFFAA'
                }
              },
              dashStyle: 'longdash',
              color: this.results.average >= this.company.sharePriceLive ? '#34c38f' : '#f46a6a',
              width: 1,
              zIndex: 2,
              value: this?.results?.average
            }
          ]
        }
      ],
      xAxis: {
        tickLength: 5,
        overscroll: ETime.milliseconds.perMonth,
        tickPixelInterval: 50,
        labels: {
          formatter: (val) => {
            const prevTick =
              val?.axis?.tickPositions?.[val.axis.tickPositions.indexOf(val.value) - 1] ||
              val.value - (val.axis.tickPositions[1] - val.axis.tickPositions[0]);
            const prevMonth = new Date(prevTick).getMonth();
            const prevYear = new Date(prevTick).getFullYear();
            const thisMonth = new Date(val.value).getMonth();
            const thisYear = new Date(val.value).getFullYear();
            if (val.tickPositionInfo.unitName === 'month') {
              if (prevYear !== thisYear) {
                return new Date(val.value).getFullYear();
              }
              return `<span style="color: #888">${new Date(val.value).toLocaleDateString('en-US', { month: 'short' })}</span>`;
            }
            if (val.tickPositionInfo.unitName === 'week') {
              if (prevMonth === thisMonth && prevYear === thisYear) {
                return `<span style="color: #888">${new Date(val.value).toLocaleDateString('en-US', { day: 'numeric' })}</span>`;
              }
              return new Date(val.value).toLocaleDateString('en-US', { month: 'short' });
            }
            return Highcharts.dateFormat(val.dateTimeLabelFormat, val.value);
          }
        }
      },
      plotOptions: {
        series: {
          lineWidth: 1,
          marker: {
            radius: 2,
            symbol: 'circle'
          }
        }
      },
      navigator: {
        enabled: false
      },
      scrollbar: {
        enabled: false
      },
      tooltip: {
        shape: 'square',
        headerShape: 'callout',
        borderWidth: 0,
        shadow: false,
        followPointer: true,
        followTouchMove: true,
        useHTML: true,
        padding: 5,
        currency: this?.company?.currencies?.sharePrices?.symbol(),
        formatter(val) {
          const hrDate = `${new Date(this.x).getFullYear()}-${
            new Date(this.x).getMonth() + 1 < 10 ? `0${new Date(this.x).getMonth() + 1}` : new Date(this.x).getMonth() + 1
          }-${new Date(this.x).getDate() < 10 ? `0${new Date(this.x).getDate()}` : new Date(this.x).getDate()}`;
          return `
          <div class="text-right text-body">
            ${val?.options?.currency}${this.y.toLocaleString(window.navigator.language, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}<br />
            <small class="text-light" style="font-size: 10px;">${hrDate}</small>
          </div>
        `;
        }
      },
      series: [
        {
          name: 'Share price',
          type: 'line',
          lineWidth: 1.5,
          color: '#bacbda',
          data: serializeChartData(candlesticks.map((candlestick) => [new Date(candlestick.date).getTime(), candlestick.close.round(2)]))
        }
      ]
    };

    return chartOptions;
  }

  async handleSaveButtonClick() {
    this.saving = true;

    const hv = this.historicValuations[this.historicValuationYear];

    // Set prefill data as values if no data is entered
    hv.growth.netProfit = hv.growth.netProfit || this.prefillData?.growth?.netProfit;
    hv.growth.revenues = hv.growth.revenues || this.prefillData?.growth?.revenues;
    hv.growth.bookValue = hv.growth.bookValue || this.prefillData?.growth?.bookValue;
    hv.growth.operationalCashFlow = hv.growth.operationalCashFlow || this.prefillData?.growth?.operationalCashFlow;

    hv.multiple.netProfit = hv.multiple.netProfit || this.prefillData?.multiple?.netProfit;
    hv.multiple.revenues = hv.multiple.revenues || this.prefillData?.multiple?.revenues;
    hv.multiple.bookValue = hv.multiple.bookValue || this.prefillData?.multiple?.bookValue;
    hv.multiple.operationalCashFlow = hv.multiple.operationalCashFlow || this.prefillData?.multiple?.operationalCashFlow;

    hv.valuation = {
      netProfit: parseFloat(this.results.netProfit),
      revenues: parseFloat(this.results.revenues),
      bookValue: parseFloat(this.results.bookValue),
      operationalCashFlow: parseFloat(this.results.operationalCashFlow)
    };

    Object.keys(hv.multiple).forEach((key) => {
      hv.multiple[key] = parseFloat(hv.multiple[key]);
    });
    Object.keys(hv.growth).forEach((key) => {
      hv.growth[key] = parseFloat(hv.growth[key]);
    });

    if (!this?.store?.user?.personal?.email) {
      this.$cookie.set(
        HISTORIC_VALUATION_COOKIE_NAME,
        JSON.stringify({
          companyCode: this.company.companyCode,
          historicValuation: {
            year: this.historicValuationYear,
            ...hv
          }
        })
      );
      this.showAuthenticateModal = true;
      this.saving = false;
      return false;
    }

    try {
      if (hv?._id) {
        this.$gtag.event('company_val_hv_update', {
          company_code: this.company?.companyCode,
          valuation_year: this.historicValuationYear
        });
        await this.StockInvestingApi.historicValuationsUpdate({
          _id: hv._id,
          companyCode: this.company.companyCode,
          year: this.historicValuationYear,
          valuation: hv.valuation,
          multiple: hv.multiple,
          growth: hv.growth,
          included: hv.included
        });
      } else {
        this.$gtag.event('company_val_hv_create', {
          company_code: this.company?.companyCode,
          valuation_year: this.historicValuationYear
        });
        this.historicValuations[this.historicValuationYear] = await this.StockInvestingApi.historicValuationsCreate({
          companyCode: this.company.companyCode,
          year: this.historicValuationYear,
          valuation: hv.valuation,
          multiple: hv.multiple,
          growth: hv.growth,
          included: hv.included
        });
      }
      this.store.hasUnsavedChanges = false;
      this.ToastSuccess(`Historic valuation for ${this.historicValuationYear} is updated`);
    } catch (err) {
      console.error(err);
      this.ToastError('Something went wrong while updating the historic valuation');
    }

    this.saving = false;
    return true;
  }

  async handleFormInteraction() {
    this.$gtag.event('company_val_hv_formInteract', {
      company_code: this.company?.companyCode,
      valuation_year: this.historicValuationYear
    });
    this.autoValuationSelection = VALUATION_TYPE.CUSTOM;
    this.forceResultsUpdate = !this.forceResultsUpdate;
    this.$forceUpdate();
  }

  setGrowth(key, value) {
    if (this.store.user.hasPermission(EPermissions.HISTORIC_VALUATION.CREATE)) {
      this.historicValuations[this.historicValuationYear].growth[key] = value;
      this.autoValuationSelection = VALUATION_TYPE.CUSTOM;
      this.forceResultsUpdate = !this.forceResultsUpdate;
      this.$forceUpdate();
    } else {
      this.upgradeCtaModalPlan = EPlans.packageTier2;
      this.showUpgradeModal = true;
    }
  }

  setMultiple(key, value) {
    if (this.store.user.hasPermission(EPermissions.HISTORIC_VALUATION.CREATE)) {
      this.historicValuations[this.historicValuationYear].multiple[key] = value;
      this.autoValuationSelection = VALUATION_TYPE.CUSTOM;
      this.forceResultsUpdate = !this.forceResultsUpdate;
      this.$forceUpdate();
    } else {
      this.upgradeCtaModalPlan = EPlans.packageTier2;
      this.showUpgradeModal = true;
    }
  }

  get valuationDifference() {
    return ((this.results.value - this.company.sharePriceLive) / this.results.value) * 100;
  }

  get prefillData() {
    return this.autoValuationData(2);
  }

  async reload() {
    this.preparePopoverChartData();
    this.calculateKeyData();
    this.calculateHistoricGrowthAverages();

    const response = await this.StockInvestingApi.companySharePriceData({ companyCode: this.company.companyCode });
    this.candlesticksDaily = response.candlesticks;

    // Set historic valuation year
    this.historicValuationYear = new Date(this.company.financialStatements?.balanceSheets?.yearly?.last()?.date).getFullYear() + 1;

    for (let i = 0; i < this.company?.valuations?.historicValuations?.length; i++) {
      const hv = this.company?.valuations?.historicValuations[i];
      const { year } = hv;
      this.historicValuations[year] = new CompanyHistoricValuation(hv);
    }

    // If no historic valuation exists yet -> create new one
    if (!this.historicValuations?.[this.historicValuationYear]) {
      this.historicValuations[this.historicValuationYear] = new CompanyHistoricValuation({ ...this.newHistoricValuation });
    } else {
      this.autoValuationSelection = VALUATION_TYPE.CUSTOM;
    }

    this.$forceUpdate();
  }

  async loadCompany() {
    const companyCode = this?.$route?.params?.companyCode;
    this.company = new Company({ companyCode });
    await this.company.load({
      general: true,
      currencies: true,
      sharePriceLive: true,
      financialStatements: {
        balanceSheets: {
          yearly: true
        },
        incomeStatements: {
          yearly: true
        },
        cashFlowStatements: {
          yearly: true
        }
      },
      shares: {
        price: {
          yearly: true
        },
        splits: true,
        outstanding: true
      },
      ratios: {
        yearly: true
      },
      valuations: {
        historicValuations: true,
        fundamentlValuations: true
      },
      analystRatings: true
    });

    const cookie = this.$cookie.get(HISTORIC_VALUATION_COOKIE_NAME) ? JSON.parse(this.$cookie.get(HISTORIC_VALUATION_COOKIE_NAME)) : null;
    if (cookie && cookie?.historicValuation && cookie.companyCode === this.company.companyCode) {
      this.company.valuations.historicValuations.push(new CompanyHistoricValuation(cookie.historicValuation));
      this.$cookie.delete(HISTORIC_VALUATION_COOKIE_NAME);
      this.store.hasUnsavedChanges = true;
    }
  }

  handleAutoValuationClick(autoValuationSelection) {
    if (autoValuationSelection === VALUATION_TYPE.CUSTOM && this.store.user.hasPermission(EPermissions.HISTORIC_VALUATION.CREATE)) {
      this.autoValuationSelection = autoValuationSelection;
    } else if (autoValuationSelection === VALUATION_TYPE.CUSTOM && !this.store.user.hasPermission(EPermissions.HISTORIC_VALUATION.CREATE)) {
      this.upgradeCtaModalPlan = EPlans.packageTier2;
      this.showUpgradeModal = true;
    } else if (autoValuationSelection > this.store.user.maxDataHistory) {
      if (autoValuationSelection === VALUATION_TYPE.FIVE_YEAR) {
        this.showAuthenticateModal = true;
      } else if (autoValuationSelection === VALUATION_TYPE.TEN_YEAR) {
        this.upgradeCtaModalPlan = EPlans.packageTier1;
        this.showUpgradeModal = true;
      }
    } else {
      this.historicValuations[this.historicValuationYear] = this.autoValuationData(autoValuationSelection);
      this.autoValuationSelection = autoValuationSelection;
      this.forceResultsUpdate = !this.forceResultsUpdate;
      this.$forceUpdate();
    }
  }

  autoValuationData(years) {
    return CompanyHistoricValuation.autoValuation(this.company, years);
  }

  handleClickDataReveal(plan) {
    this.upgradeCtaModalPlan = plan;
    this.showUpgradeModal = true;
  }

  handleCheckboxToggle() {
    this.$forceUpdate();
    this.forceResultsUpdate = !this.forceResultsUpdate;
  }

  async mounted() {
    await this.loadCompany();
    this.setTitle(`${this?.company?.general?.name} - Historic valuation model`);

    this.reload();

    this.loaded = true;
  }
}
export default CompanyDetailsHistoricValuation;
</script>
