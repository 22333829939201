<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import flatten from 'flat';

import { EPlans } from '../../../shared/enums.js';
import { Currency } from '../../../shared/models/index.js';
import { ERoles } from '../../../shared/models/user/index.js';

@Component({
  name: 'plan-details',
  components: {},
  data: () => ({ ERoles })
})
class PlanDetails extends Vue {
  plan = null;

  get billingFrequency() {
    return this?.plan?.items?.data?.first()?.plan?.interval === 'month' ? 'Monthly' : 'Yearly';
  }

  get price() {
    return this?.plan?.items?.data?.first()?.price?.unit_amount / 100;
  }

  get currency() {
    return new Currency(this?.plan?.items?.data?.first()?.price?.currency).symbol();
  }

  get planName() {
    const priceId = this?.plan?.items?.data?.first()?.price?.id;
    const plans = Object.values(EPlans);
    for (let i = 0; i < plans.length; i++) {
      if (Object.values(flatten(plans[i]?.price)).includes(priceId)) {
        return plans[i].name;
      }
    }
    return null;
  }

  get cancellationDate() {
    if (this?.plan?.cancel_at_period_end) {
      return new Date(this?.plan?.current_period_end * 1000);
    }
    return null;
  }

  get renewalDate() {
    return new Date(this?.plan?.current_period_end * 1000);
  }

  async mounted() {
    try {
      this.plan = await this.StockInvestingApi.paymentSubscriptionGet();
    } catch (err) {
      console.error(err);
    }
  }
}
export default PlanDetails;
</script>
