<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import { CreditCard } from '../../../shared/models/index.js';

@Component({
  name: 'payment-method-add',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    buttonVariant: {
      type: String,
      default: 'success'
    },
    buttonText: {
      type: String,
      default: 'Add'
    }
  },
  data: () => ({
    saving: false,
    creditCard: new CreditCard({})
  })
})
class PaymentMethodAdd extends Vue {
  async handleButtonClick() {
    this.saving = true;
    try {
      await this.StockInvestingApi.paymentMethodCreditCardCreate({ creditCard: this.creditCard });
      this.ToastSuccess('Credit card was added');
      this.creditCard = new CreditCard({});
      this.$emit('hide');
    } catch (err) {
      console.error(err);
      if (err?.errors?.length) {
        this.ToastError(err?.errors.first());
      } else {
        this.ToastError('Something went wrong, make sure the information is correct and try again.');
      }
    }
    this.saving = false;
  }

  handleHide() {
    this.$emit('hide');
  }
}
export default PaymentMethodAdd;
</script>
