import { Currency } from '../currency/index.js';
import { ExchangeHoliday } from './ExchangeHoliday.js';
import { ExchangeTradingHours } from './ExchangeTradingHours.js';

class Exchange {
  constructor({
    code = null,
    country = null,
    currency = null,
    name = null,
    operatingMic = null,
    timezone = null,
    holidays = [],
    tradingHours = []
  }) {
    this.code = code;
    this.country = country;
    this.currency = new Currency(currency);
    this.name = name;
    this.operatingMic = operatingMic;
    this.timezone = timezone;
    this.holidays = holidays.map((holiday) => new ExchangeHoliday(holiday));
    this.tradingHours = new ExchangeTradingHours(tradingHours);
  }
}

export { Exchange };
