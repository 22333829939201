import { Country } from './Country.js';

class Address {
  constructor({ street = null, city = null, zip = null, country = null }) {
    this.street = street;
    this.city = city;
    this.zip = zip;
    this.country = new Country(country)?.iso ? new Country(country) : Country.fromName(country);
  }
}

export { Address };
