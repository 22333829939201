<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import { CompanyScreener, GoogleAd } from '../../components/index.js';

@Component({
  name: 'stock-screener',
  data: () => ({}),
  components: { CompanyScreener, GoogleAd }
})
class StockScreener extends Vue {
  handleColumnsChange() {
    this.$gtag.event('stockScreener_columns_update');
  }

  handleFiltersChange() {
    this.$gtag.event('stockScreener_filters_update');
  }

  mounted() {
    this.setTitle('Stock screener');
  }
}
export default StockScreener;
</script>
