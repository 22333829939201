<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import { Country } from '../../../shared/models/index.js';

@Component({
  name: 'billing-details',
  components: {},
  props: {
    customer: {
      type: Object,
      required: true
    },
    saveButtonText: {
      type: String,
      default: 'Save'
    },
    saveButtonClass: {
      type: String,
      default: ''
    },
    saveButtonVariant: {
      type: String,
      default: 'primary'
    }
  },
  data: () => ({
    Country,
    showCompanyDetails: false,
    saving: false
  })
})
class BillingDetails extends Vue {
  get vatIdTypes() {
    if (this.customer?.billingDetails?.address?.country) {
      const country = new Country(this.customer?.billingDetails?.address?.country);
      if (!country.iso) {
        this.customer.billingDetails.vatIdType = null;
        return [];
      }
      const { vatIdTypes } = country;
      const vatIdTypeIds = vatIdTypes.map((vatIdType) => vatIdType.id);
      this.customer.billingDetails.vatIdType =
        (vatIdTypes?.length === 1 ? vatIdTypes.first().id : null) ||
        (vatIdTypeIds.includes(this.customer.billingDetails.vatIdType) ? this.customer.billingDetails.vatIdType : null);
      return vatIdTypes?.length ? vatIdTypes : [];
    }
    return [];
  }

  get vatIdExample() {
    return this.vatIdType?.example || this.vatIdTypes?.first()?.example || null;
  }

  get companyDataIsOptional() {
    return !this.customer?.billingDetails?.companyName && !this.customer?.billingDetails?.vatId;
  }

  async handleClickSave() {
    this.saving = true;
    try {
      this.$gtag.event('add_payment_info');
      this.$gtag.event('my_account/billing_details/update');
      await this.StockInvestingApi.paymentCustomerUpdate({ customer: this.customer });
      this.$emit('saveSuccess');
    } catch (err) {
      this.$emit('saveFail', err);
      console.error(err);
    }
    this.saving = false;
  }

  handleCountryChange(address) {
    if (address.country === 'clear') {
      address.country = null;
    }
  }

  handleVatIdTypeChange(billingDetails) {
    if (billingDetails.vatIdType === 'clear') {
      billingDetails.vatIdType = null;
    }
  }

  toggleCompanyDetailsInput() {
    this.showCompanyDetails = !this.showCompanyDetails;
    if (!this.showCompanyDetails) {
      this.customer.billingDetails.companyName = null;
      this.customer.billingDetails.vatId = null;
      this.customer.billingDetails.vatIdType = null;
    }
  }
}
export default BillingDetails;
</script>

<style lang="scss" scoped>
@import './style.scss';

.company-input-toggle {
  color: $light;
  text-decoration: underline;
  &:hover {
    color: lighten($light, 20%);
  }
}
</style>
