import { StockInvestingApi } from '../../../frontend/api/index.js';
import { Currency } from './Currency.js';
import { ETime } from '../../enums.js';

const stockInvestingApi = new StockInvestingApi();

// prettier-ignore
const currencyPairList = [
  'AEDAUD', 'AEDCAD', 'AEDCHF', 'AEDEUR', 'AEDGBP', 'AEDINR', 'AEDJPY', 'AEDNOK', 'AEDNZD', 'AEDPKR', 'AEDSAR', 'AEDSEK', 'AEDZAR', 'ARSBRL', 'ARSEUR', 'ARSGBP',
  'ARSJPY', 'ARSUSD', 'AUDAED', 'AUDARS', 'AUDBRL', 'AUDCAD', 'AUDCHF', 'AUDCNH', 'AUDCNY', 'AUDCZK', 'AUDDKK', 'AUDEUR', 'AUDFJD', 'AUDGBP', 'AUDHKD', 'AUDHUF',
  'AUDIDR', 'AUDILS', 'AUDINR', 'AUDJPY', 'AUDKRW', 'AUDMXN', 'AUDMYR', 'AUDNOK', 'AUDNZD', 'AUDPHP', 'AUDPKR', 'AUDPLN', 'AUDSEK', 'AUDSGD', 'AUDTHB', 'AUDTRY',
  'AUDTWD', 'AUDUSD', 'AUDZAR', 'BDTGBP', 'BDTJPY', 'BGNUSD', 'BHDEUR', 'BHDPKR', 'BHDUSD', 'BRLARS', 'BRLAUD', 'BRLCHF', 'BRLEUR', 'BRLHKD', 'BRLJPY', 'BRLKRW',
  'BRLMXN', 'BRLRUB', 'BRLSEK', 'BRLSGD', 'BRLUSD', 'BRLZAR', 'BWPEUR', 'BWPUSD', 'CADAED', 'CADARS', 'CADAUD', 'CADBRL', 'CADCHF', 'CADCNH', 'CADCNY', 'CADDKK',
  'CADEUR', 'CADGBP', 'CADHKD', 'CADIDR', 'CADILS', 'CADINR', 'CADISK', 'CADJPY', 'CADKRW', 'CADKWD', 'CADMXN', 'CADMYR', 'CADNOK', 'CADNZD', 'CADPKR', 'CADPLN',
  'CADRUB', 'CADSEK', 'CADSGD', 'CADTHB', 'CADTRY', 'CADTWD', 'CADUSD', 'CADZAR', 'CDFUSD', 'CHFAED', 'CHFARS', 'CHFAUD', 'CHFBRL', 'CHFBWP', 'CHFCAD', 'CHFCNH',
  'CHFCNY', 'CHFCZK', 'CHFDKK', 'CHFEUR', 'CHFGBP', 'CHFHKD', 'CHFHUF', 'CHFIDR', 'CHFILS', 'CHFINR', 'CHFISK', 'CHFJPY', 'CHFKRW', 'CHFMXN', 'CHFMYR', 'CHFNOK',
  'CHFNZD', 'CHFPKR', 'CHFPLN', 'CHFRON', 'CHFRUB', 'CHFSEK', 'CHFSGD', 'CHFSZL', 'CHFTHB', 'CHFTRY', 'CHFTWD', 'CHFUSD', 'CHFUZS', 'CHFZAR', 'CLPEUR', 'CLPGBP',
  'CLPUSD', 'CNHHKD', 'CNHIDR', 'CNHINR', 'CNHJPY', 'CNHKRW', 'CNHPHP', 'CNHTHB', 'CNHTWD', 'CNYAUD', 'CNYCAD', 'CNYCHF', 'CNYEUR', 'CNYGBP', 'CNYHKD', 'CNYJPY',
  'CNYKRW', 'CNYNZD', 'CNYPLN', 'CNYUSD', 'CNYZAR', 'CRCGBP', 'CZKCHF', 'CZKDKK', 'CZKEUR', 'CZKJPY', 'CZKPLN', 'CZKSEK', 'CZKUSD', 'DJFGBP', 'DKKAUD', 'DKKCAD',
  'DKKCHF', 'DKKCZK', 'DKKEUR', 'DKKGBP', 'DKKHKD', 'DKKHUF', 'DKKINR', 'DKKISK', 'DKKJPY', 'DKKNOK', 'DKKNZD', 'DKKPKR', 'DKKPLN', 'DKKSEK', 'DKKSGD', 'DKKTHB',
  'DKKUSD', 'DKKZAR', 'DOPGBP', 'DZDUSD', 'EGPEUR', 'EGPJPY', 'EGPPKR', 'EGPUSD', 'ETBGBP', 'EURAED', 'EURAFN', 'EURALL', 'EURAMD', 'EURARS', 'EURAUD', 'EURBAM',
  'EURBBD', 'EURBDT', 'EURBGN', 'EURBHD', 'EURBIF', 'EURBND', 'EURBOB', 'EURBRL', 'EURBSD', 'EURBWP', 'EURBZD', 'EURCAD', 'EURCHF', 'EURCLP', 'EURCNH', 'EURCNY',
  'EURCOP', 'EURCRC', 'EURCUP', 'EURCVE', 'EURCZK', 'EURDJF', 'EURDKK', 'EURDOP', 'EURDZD', 'EUREGP', 'EURETB', 'EUREUR', 'EURFJD', 'EURGBP', 'EURGHS', 'EURGNF',
  'EURGTQ', 'EURHKD', 'EURHNL', 'EURHRK', 'EURHTG', 'EURHUF', 'EURIDR', 'EURILS', 'EURINR', 'EURIQD', 'EURISK', 'EURJMD', 'EURJOD', 'EURJPY', 'EURKES', 'EURKHR',
  'EURKRW', 'EURKWD', 'EURKYD', 'EURKZT', 'EURLAK', 'EURLBP', 'EURLKR', 'EURLSL', 'EURLYD', 'EURMAD', 'EURMDL', 'EURMKD', 'EURMMK', 'EURMOP', 'EURMUR', 'EURMVR',
  'EURMWK', 'EURMXN', 'EURMYR', 'EURNAD', 'EURNGN', 'EURNIO', 'EURNOK', 'EURNPR', 'EURNZD', 'EUROMR', 'EURPAB', 'EURPEN', 'EURPGK', 'EURPHP', 'EURPKR', 'EURPLN',
  'EURPYG', 'EURQAR', 'EURRON', 'EURRSD', 'EURRUB', 'EURRWF', 'EURSAR', 'EURSCR', 'EURSEK', 'EURSGD', 'EURSOS', 'EURSVC', 'EURSZL', 'EURTHB', 'EURTND', 'EURTRY',
  'EURTTD', 'EURTWD', 'EURTZS', 'EURUAH', 'EURUGX', 'EURUSD', 'EURUYU', 'EURUZS', 'EURVND', 'EURXAF', 'EURXOF', 'EURXPF', 'EURYER', 'EURZAR', 'FJDUSD', 'GBPAED',
  'GBPALL', 'GBPARS', 'GBPAUD', 'GBPBDT', 'GBPBGN', 'GBPBHD', 'GBPBIF', 'GBPBMD', 'GBPBND', 'GBPBRL', 'GBPBWP', 'GBPCAD', 'GBPCHF', 'GBPCLP', 'GBPCNH', 'GBPCNY',
  'GBPCOP', 'GBPCRC', 'GBPCZK', 'GBPDJF', 'GBPDKK', 'GBPDOP', 'GBPDZD', 'GBPEGP', 'GBPETB', 'GBPEUR', 'GBPFJD', 'GBPGBP', 'GBPGTQ', 'GBPHKD', 'GBPHNL', 'GBPHRK',
  'GBPHTG', 'GBPHUF', 'GBPIDR', 'GBPILS', 'GBPINR', 'GBPIQD', 'GBPISK', 'GBPJMD', 'GBPJOD', 'GBPJPY', 'GBPKES', 'GBPKRW', 'GBPKWD', 'GBPKZT', 'GBPLBP', 'GBPLKR',
  'GBPMAD', 'GBPMUR', 'GBPMVR', 'GBPMWK', 'GBPMXN', 'GBPMYR', 'GBPNGN', 'GBPNIO', 'GBPNOK', 'GBPNZD', 'GBPOMR', 'GBPPEN', 'GBPPGK', 'GBPPHP', 'GBPPKR', 'GBPPLN',
  'GBPQAR', 'GBPRON', 'GBPRSD', 'GBPRUB', 'GBPSAR', 'GBPSCR', 'GBPSEK', 'GBPSGD', 'GBPSZL', 'GBPTHB', 'GBPTND', 'GBPTRY', 'GBPTTD', 'GBPTWD', 'GBPTZS', 'GBPUAH',
  'GBPUGX', 'GBPUSD', 'GBPVND', 'GBPXAF', 'GBPXCD', 'GBPXPF', 'GBPZAR', 'GHSEUR', 'GHSUSD', 'HKDAUD', 'HKDBRL', 'HKDCAD', 'HKDCHF', 'HKDCNY', 'HKDDKK', 'HKDEUR',
  'HKDGBP', 'HKDIDR', 'HKDINR', 'HKDJPY', 'HKDKRW', 'HKDMXN', 'HKDMYR', 'HKDNZD', 'HKDPKR', 'HKDPLN', 'HKDSEK', 'HKDSGD', 'HKDTHB', 'HKDTWD', 'HKDUSD', 'HKDZAR',
  'HRKEUR', 'HRKUSD', 'HUFCHF', 'HUFDKK', 'HUFEUR', 'HUFUSD', 'IDRAUD', 'IDRCAD', 'IDRCHF', 'IDRCNY', 'IDREUR', 'IDRGBP', 'IDRINR', 'IDRJPY', 'IDRKRW', 'IDRMYR',
  'IDRNZD', 'IDRSGD', 'IDRTHB', 'IDRTWD', 'IDRUSD', 'IDRZAR', 'ILSCHF', 'ILSEUR', 'ILSGBP', 'ILSJPY', 'ILSPLN', 'INRAUD', 'INRCAD', 'INRCHF', 'INRCNY', 'INREUR',
  'INRGBP', 'INRHKD', 'INRJPY', 'INRKRW', 'INRMYR', 'INRNZD', 'INRPKR', 'INRTHB', 'INRTWD', 'INRUSD', 'INRZAR', 'ISKCHF', 'ISKDKK', 'ISKGBP', 'JMDGBP', 'JODUSD',
  'JPYAED', 'JPYARS', 'JPYAUD', 'JPYBRL', 'JPYCAD', 'JPYCHF', 'JPYCLP', 'JPYCNY', 'JPYCZK', 'JPYDKK', 'JPYEUR', 'JPYGBP', 'JPYHKD', 'JPYIDR', 'JPYILS', 'JPYINR',
  'JPYISK', 'JPYKRW', 'JPYMXN', 'JPYMYR', 'JPYNOK', 'JPYNZD', 'JPYPKR', 'JPYPLN', 'JPYRUB', 'JPYSEK', 'JPYTHB', 'JPYTWD', 'JPYUSD', 'JPYZAR', 'KESEUR', 'KESUSD',
  'KESZAR', 'KRWAUD', 'KRWBRL', 'KRWCAD', 'KRWCHF', 'KRWCNY', 'KRWEUR', 'KRWGBP', 'KRWHKD', 'KRWIDR', 'KRWINR', 'KRWJPY', 'KRWMYR', 'KRWNZD', 'KRWSEK', 'KRWSGD',
  'KRWTHB', 'KRWTWD', 'KRWUSD', 'KRWZAR', 'KWDGBP', 'KWDPKR', 'KWDUSD', 'KZTGBP', 'LBPUSD', 'LKRUSD', 'LRDUSD', 'MADGBP', 'MADUSD', 'MDLUSD', 'MKDUSD', 'MUREUR',
  'MURGBP', 'MURUSD', 'MVRUSD', 'MWKGBP', 'MXNAUD', 'MXNBRL', 'MXNCAD', 'MXNCHF', 'MXNDKK', 'MXNEUR', 'MXNGBP', 'MXNHKD', 'MXNJPY', 'MXNSGD', 'MXNUSD', 'MXNZAR',
  'MYRAUD', 'MYRCAD', 'MYRCHF', 'MYRCNH', 'MYRCNY', 'MYREUR', 'MYRGBP', 'MYRHKD', 'MYRIDR', 'MYRINR', 'MYRJPY', 'MYRKRW', 'MYRNZD', 'MYRPKR', 'MYRSGD', 'MYRTHB',
  'MYRTWD', 'MYRUSD', 'MYRZAR', 'NGNGBP', 'NGNJPY', 'NOKCAD', 'NOKCHF', 'NOKCNH', 'NOKDKK', 'NOKEUR', 'NOKGBP', 'NOKISK', 'NOKJPY', 'NOKPLN', 'NOKSEK', 'NOKUSD',
  'NOKZAR', 'NZDAED', 'NZDAUD', 'NZDCAD', 'NZDCHF', 'NZDCNH', 'NZDCNY', 'NZDCZK', 'NZDDKK', 'NZDEUR', 'NZDGBP', 'NZDHKD', 'NZDHUF', 'NZDIDR', 'NZDINR', 'NZDJPY',
  'NZDKRW', 'NZDMXN', 'NZDMYR', 'NZDNOK', 'NZDPHP', 'NZDPKR', 'NZDPLN', 'NZDSEK', 'NZDSGD', 'NZDTHB', 'NZDTRY', 'NZDTWD', 'NZDUSD', 'NZDZAR', 'OMRAED', 'OMRGBP',
  'OMRPKR', 'OMRUSD', 'PENGBP', 'PGKGBP', 'PHPAUD', 'PHPEUR', 'PHPGBP', 'PHPJPY', 'PHPNZD', 'PHPUSD', 'PKRGBP', 'PKRJPY', 'PKRZAR', 'PLNCHF', 'PLNCZK', 'PLNDKK',
  'PLNEUR', 'PLNGBP', 'PLNHUF', 'PLNILS', 'PLNJPY', 'PLNRUB', 'PLNSEK', 'PLNUSD', 'QARAED', 'QARGBP', 'QARPKR', 'QARUSD', 'RONGBP', 'RONUSD', 'RSDUSD', 'RUBCHF',
  'RUBGBP', 'RUBJPY', 'RUBPLN', 'RUBUSD', 'SAREUR', 'SARPKR', 'SARUSD', 'SCRGBP', 'SCRUSD', 'SEKAUD', 'SEKCAD', 'SEKCHF', 'SEKCNH', 'SEKCZK', 'SEKDKK', 'SEKEUR',
  'SEKGBP', 'SEKILS', 'SEKINR', 'SEKISK', 'SEKJPY', 'SEKNOK', 'SEKPLN', 'SEKUSD', 'SEKZAR', 'SGDAED', 'SGDARS', 'SGDAUD', 'SGDBRL', 'SGDCHF', 'SGDCNH', 'SGDCNY',
  'SGDDKK', 'SGDEUR', 'SGDGBP', 'SGDHKD', 'SGDIDR', 'SGDINR', 'SGDJPY', 'SGDKRW', 'SGDMXN', 'SGDMYR', 'SGDNZD', 'SGDPKR', 'SGDPLN', 'SGDSEK', 'SGDTHB', 'SGDTWD',
  'SGDUSD', 'SGDZAR', 'SLLUSD', 'SZLGBP', 'SZLUSD', 'THBAUD', 'THBCAD', 'THBCHF', 'THBCNY', 'THBEUR', 'THBGBP', 'THBHKD', 'THBIDR', 'THBINR', 'THBJPY', 'THBKRW',
  'THBMYR', 'THBNZD', 'THBPKR', 'THBSGD', 'THBTWD', 'THBUSD', 'THBZAR', 'TNDGBP', 'TNDUSD', 'TRYCHF', 'TRYDKK', 'TRYEUR', 'TRYJPY', 'TRYPLN', 'TRYUSD', 'TRYZAR',
  'TTDGBP', 'TWDAUD', 'TWDCAD', 'TWDCHF', 'TWDCNY', 'TWDGBP', 'TWDHKD', 'TWDIDR', 'TWDINR', 'TWDJPY', 'TWDKRW', 'TWDMYR', 'TWDNZD', 'TWDPKR', 'TWDSEK', 'TWDSGD',
  'TWDTHB', 'TWDUSD', 'TWDZAR', 'UAHUSD', 'USDAED', 'USDAFN', 'USDALL', 'USDAMD', 'USDARS', 'USDAUD', 'USDBDT', 'USDBGN', 'USDBHD', 'USDBIF', 'USDBMD', 'USDBND',
  'USDBOB', 'USDBRL', 'USDBWP', 'USDBZD', 'USDCAD', 'USDCDF', 'USDCHF', 'USDCLP', 'USDCNH', 'USDCNY', 'USDCOP', 'USDCRC', 'USDCUP', 'USDCVE', 'USDCZK', 'USDDJF',
  'USDDKK', 'USDDOP', 'USDDZD', 'USDEGP', 'USDETB', 'USDEUR', 'USDFJD', 'USDGBP', 'USDGEL', 'USDGHS', 'USDGMD', 'USDGNF', 'USDGTQ', 'USDHKD', 'USDHNL', 'USDHRK',
  'USDHTG', 'USDHUF', 'USDIDR', 'USDILS', 'USDINR', 'USDIQD', 'USDISK', 'USDJOD', 'USDJPY', 'USDKES', 'USDKHR', 'USDKMF', 'USDKRW', 'USDKWD', 'USDKZT', 'USDLAK',
  'USDLBP', 'USDLKR', 'USDLRD', 'USDLSL', 'USDLYD', 'USDMAD', 'USDMDL', 'USDMKD', 'USDMMK', 'USDMOP', 'USDMUR', 'USDMVR', 'USDMWK', 'USDMXN', 'USDMYR', 'USDNAD',
  'USDNGN', 'USDNIO', 'USDNOK', 'USDNPR', 'USDNZD', 'USDOMR', 'USDPAB', 'USDPEN', 'USDPGK', 'USDPHP', 'USDPKR', 'USDPLN', 'USDPYG', 'USDQAR', 'USDRON', 'USDRSD',
  'USDRUB', 'USDRWF', 'USDSAR', 'USDSCR', 'USDSEK', 'USDSGD', 'USDSHP', 'USDSLL', 'USDSOS', 'USDSTD', 'USDSVC', 'USDSZL', 'USDTHB', 'USDTND', 'USDTRY', 'USDTWD',
  'USDTZS', 'USDUAH', 'USDUYU', 'USDUZS', 'USDVND', 'USDXAF', 'USDXOF', 'USDXPF', 'USDYER', 'USDZAR', 'UYUUSD', 'VNDJPY', 'XAGUSD', 'XAUUSD', 'XCDGBP', 'YERUSD',
  'ZARAED', 'ZARAUD', 'ZARBRL', 'ZARBWP', 'ZARCAD', 'ZARCHF', 'ZARCNY', 'ZARDKK', 'ZAREUR', 'ZARGBP', 'ZARHKD', 'ZARIDR', 'ZARINR', 'ZARJPY', 'ZARKES', 'ZARKRW',
  'ZARMXN', 'ZARNOK', 'ZARNZD', 'ZARPHP', 'ZARPKR', 'ZARPLN', 'ZARSEK', 'ZARSGD', 'ZARTHB', 'ZARTWD', 'ZARUSD', 'GBXAED', 'GBXALL', 'GBXARS', 'GBXAUD', 'GBXBDT',
  'GBXBGN', 'GBXBHD', 'GBXBIF', 'GBXBMD', 'GBXBND', 'GBXBRL', 'GBXBWP', 'GBXCAD', 'GBXCHF', 'GBXCLP', 'GBXCNH', 'GBXCNY', 'GBXCOP', 'GBXCRC', 'GBXCZK', 'GBXDJF',
  'GBXDKK', 'GBXDOP', 'GBXDZD', 'GBXEGP', 'GBXETB', 'GBXEUR', 'GBXFJD', 'GBXGBX', 'GBXGTQ', 'GBXHKD', 'GBXHNL', 'GBXHRK', 'GBXHTG', 'GBXHUF', 'GBXIDR', 'GBXILS',
  'GBXINR', 'GBXIQD', 'GBXISK', 'GBXJMD', 'GBXJOD', 'GBXJPY', 'GBXKES', 'GBXKRW', 'GBXKWD', 'GBXKZT', 'GBXLBP', 'GBXLKR', 'GBXMAD', 'GBXMUR', 'GBXMVR', 'GBXMWK',
  'GBXMXN', 'GBXMYR', 'GBXNGN', 'GBXNIO', 'GBXNOK', 'GBXNZD', 'GBXOMR', 'GBXPEN', 'GBXPGK', 'GBXPHP', 'GBXPKR', 'GBXPLN', 'GBXQAR', 'GBXRON', 'GBXRSD', 'GBXRUB',
  'GBXSAR', 'GBXSCR', 'GBXSEK', 'GBXSGD', 'GBXSZL', 'GBXTHB', 'GBXTND', 'GBXTRY', 'GBXTTD', 'GBXTWD', 'GBXTZS', 'GBXUAH', 'GBXUGX', 'GBXUSD', 'GBXVND', 'GBXXAF',
  'GBXXCD', 'GBXXPF', 'GBXZAR',
];

export class CurrencyPair {
  constructor(currency1, currency2) {
    this.currency1 = new Currency(currency1);
    this.currency2 = new Currency(currency2);
    this.currencyPairList = currencyPairList;

    this.exchangeRateHistory = [];

    this.selected = null;
    if (currencyPairList.includes(`${this.currency1.iso()}${this.currency2.iso()}`)) {
      this.selected = `${this.currency1.iso()}${this.currency2.iso()}`;
    } else if (currencyPairList.includes(`${this.currency2.iso()}${this.currency1.iso()}`)) {
      this.selected = `${this.currency2.iso()}${this.currency1.iso()}`;
    }
  }

  base() {
    return this.selected?.slice(0, 3) || null;
  }

  quote() {
    return this.selected?.slice(3, 6) || null;
  }

  toString() {
    return this.selected;
  }

  toFormattedString(delimiter) {
    return this.base() + delimiter + this.quote();
  }

  list() {
    return this.currencyPairList;
  }

  async loadExchangeRateHistory({ from, till }) {
    if (this.currency1.iso() !== this.currency2.iso()) {
      const exchangeRateHistoryResponse = await stockInvestingApi.currencyExchangeRateHistory({
        currencyPair: this.toString(),
        from,
        till
      });
      const exchangeRateHistory = exchangeRateHistoryResponse.map((candlestick) => ({
        ...candlestick,
        date: new Date(candlestick.date)
      }));

      // Forward fill exchange rates
      const days = (till.removeTime().getTime() - from.removeTime().getTime()) / ETime.milliseconds.perDay + 1; // +1 -> include the till date
      days.forEach((day) => {
        // Get dates
        const previousDate = new Date(from.removeTime().getTime() + ETime.milliseconds.perDay * (day - 1)).removeTime().getTime();
        const date = new Date(from.removeTime().getTime() + ETime.milliseconds.perDay * day).removeTime().getTime();

        // Get candlesticks
        const previousFoundExchangeRate = exchangeRateHistory.find(
          (candlestick) => new Date(candlestick.date).removeTime().getTime() === previousDate
        );
        const foundExchangeRate = exchangeRateHistory.find((candlestick) => new Date(candlestick.date).removeTime().getTime() === date);

        // Check if current date has matching candlestick
        if (!foundExchangeRate) {
          // Push new candlestick if the current date doesn't have a matching candlestick
          exchangeRateHistory.push({
            ...(previousFoundExchangeRate || exchangeRateHistory[0]), // Use last known candlestick or use very first known candlestick (use case: first day could be a holiday/weekend)
            date: new Date(date)
          });
        }
      });

      this.exchangeRateHistory = exchangeRateHistory;
    }
  }

  baseToQuote(value, exchangeRate) {
    return value / exchangeRate;
  }

  quoteToBase(value, exchangeRate) {
    return value * exchangeRate;
  }

  convert({ value, from = null, to = null, date = new Date(), exchangeRate = null }) {
    const currencyExchangeRate =
      exchangeRate ||
      this.exchangeRateHistory.find(
        (candlestick) =>
          new Date(candlestick.date).removeTime().getTime() === new Date(date).removeTime().getTime() ||
          new Date(candlestick.date).removeTime().getTime() === new Date(date).getTime()
      )?.close;
    if (this.currency1.iso() === this.currency2.iso()) {
      return value;
    }
    if (this.quote() === from || this.base() === to) {
      return this.quoteToBase(value, currencyExchangeRate);
    }
    if (this.base() === from || this.quote() === to) {
      return this.baseToQuote(value, currencyExchangeRate);
    }
    return null;
  }
}
