<template src="./template.vue"></template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import { CompanyScreener, GoogleAd } from '../../components/index.js';
import { ERoles } from '../../../shared/models/user/index.js';

const TOP_AMOUNT = 10;

@Component({
  name: 'homepage',
  data: () => ({ TOP_AMOUNT, ERoles }),
  components: { CompanyScreener, GoogleAd }
})
class Homepage extends Vue {
  get bannerTitle() {
    return 'The most efficient way to analyze fundamental data';
  }

  get bannerButton() {
    if (!this?.store?.user?.hasRole(ERoles.user)) {
      return 'Start for free now';
    }
    if (this?.store?.user?.hasRole(ERoles.user) || this?.store?.user?.isPaidAccount) {
      return null;
    }
    if (this?.store?.user?.roles?.includes(ERoles.user) && !this?.store?.user?.isPaidAccount) {
      return 'Upgrade now';
    }
    return null;
  }

  mounted() {
    this.setTitle('Fundamentl - Efficiently analyze stocks', false);
  }
}
export default Homepage;
</script>
<style lang="scss" scoped>
@import './style.scss';
</style>
