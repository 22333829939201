const SUCCESS_RESPONSE_CODE = 200;
const GENERAL_ERROR_MESSAGE = 'Error';

const handleResponse = (res) => {
  if (res?.status === SUCCESS_RESPONSE_CODE) {
    return Promise.resolve(res.data?.payload);
  }
  return Promise.reject(res?.response?.data || GENERAL_ERROR_MESSAGE);
};

export { handleResponse };
