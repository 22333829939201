const cleanupObject = (object, options) => {
  const opts = {
    undefined: options?.undefined || true,
    null: options?.null || true,
    emptyObject: options?.emptyObject || true,
    emptyString: options?.emptyString || true,
    emptyArray: options?.emptyArray || true
  };

  const cleanObject = { ...object };
  Object.keys(cleanObject).forEach((key) => {
    if (
      (opts.undefined && cleanObject[key] === undefined) ||
      (opts.null && cleanObject[key] === null) ||
      (opts.emptyObject && JSON.stringify(cleanObject[key]) === JSON.stringify({})) ||
      (opts.emptyString && cleanObject[key] === '') ||
      (opts.emptyArray && Array.isArray(cleanObject[key]) && !cleanObject[key].length)
    ) {
      delete cleanObject[key];
    }
  });
  return cleanObject;
};

export { cleanupObject };
